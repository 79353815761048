import api from '../../services/api';
import _ from 'lodash';


import * as exceptionsActions from '../exceptions/actions';
import * as partnersSelectors from './selectors';
import { browserHistory } from '../../helpers';

export const types = {
    FETCH_ALL_ITEMS_DONE: 'partners.FETCH_ALL_ITEMS_DONE',
    FETCH_ITEMS_DONE: 'partners.FETCH_ITEMS_DONE',
    FETCH_ITEM_DONE: 'partners.FETCH_ITEM_DONE',
    FETCH_UPDATE: 'partners.FETCH_UPDATE',
    SET_SEARCH_TERM: 'partners.SET_SEARCH_TERM',
    SET_CURRENT_PAGE: 'partners.SET_CURRENT_PAGE',
    SET_CURRENT_ITEM_ID: 'partners.SET_CURRENT_ITEM_ID',
    SET_CURRENT_ITEM_STATUS: 'SET_CURRENT_ITEM_STATUS',
    TOGGLE_SORTER: 'partners.TOGGLE_SORTER',
    CLEAR_CACHE: 'partners.CLEAR_CACHE',
    SET_SORTER: 'partners.SET_SORTER'
}

export function setSorter(sorter) {
    return {
        type: types.SET_SORTER,
        payload: {
            sorter
        }
    }
}

export function setSearchTerm(searchTerm) {
    return {
      type: types.SET_SEARCH_TERM,
      payload: {
        searchTerm,
      },
    };
  }

export function setCurrentPage(page) {
    return {
        type: types.SET_CURRENT_PAGE,
        payload: {
            page
        }
    }
}

export function setCurrentItemId(id) {
    return {
        type: types.SET_CURRENT_ITEM_ID,
        payload: {
            id
        }
    }
}

export function unsetCurrentItemId() {
    return {
        type: types.SET_CURRENT_ITEM_ID,
        payload: {
            id: null,
        }
    }
}

export function clearCache() {
    return {
        type: types.CLEAR_CACHE
    }
}

export function fetchAllItems() {
    return async (dispatch, getState) => {
        try {
            let items = await api.get('/partners');
            dispatch(clearCache());
            dispatch({
                type: types.FETCH_ALL_ITEMS_DONE,
                payload: {
                    items
                }
            });
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}

export function fetchItem(id) {
    return async (dispatch) => {
        try {
            // GET request from API
            let item = await api.get(`/partners/${id}`);
            dispatch({
                type: types.FETCH_ITEM_DONE,
                payload: {
                    item
                }
            });
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}

export function fetchItems(deleteCache = false) {
    return async (dispatch, getState) => {
        let state = getState();
        try {
            // Set additional params
            let params = new Map();
            let filters = partnersSelectors.getFilters(state);
            let pagination = partnersSelectors.getPagination(state);
            let sorter = partnersSelectors.getSorter(state);
            params.set('page_size', pagination.pageSize);
            params.set('page_number', deleteCache ? 1 : pagination.currentPage);
            params.set("name~", filters.searchTerm);
            params.set('page_size', sorter.pageLimit);
            params.set("sort_by", sorter.column);
            params.set("sort_desc", sorter.descending);

            // GET request from API
            let [response, items] = await api.get('/partners', params, true);

            // Clear cache if deleteCache is enabled
            if (deleteCache) {
                dispatch(clearCache());
            }

            dispatch({
                type: types.FETCH_ITEMS_DONE,
                payload: {
                    totalPages: parseInt(response.headers.get('X-Total-Pages')),
                    items
                }
            });
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}

export function createItem(data) {
    return async (dispatch) => {
        try {
            let params = new Map();
            _.map(data, (value, key) => {
                params.set(key, value);
            })
            // POST request to API
            await api.post(`/partners`, params);
            browserHistory.push('/modules/partners');
            
            dispatch(exceptionsActions.clear());
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}

export function updateItem(id, data) {
    return async (dispatch) => {
        try {
            let params = new Map();
            _.map(data, (value, key) => {
                params.set(key, value);
            })
            // PUT request to API
            await api.put(`/partners/${id}`, params);
            dispatch({
                type: types.SET_CURRENT_ITEM_STATUS,
                payload: {
                    key: `_${id}`, 
                    item: {
                        visible: data.visible
                    }
                }
            });
            browserHistory.push(`/modules/partners`);
            dispatch({
                type: types.FETCH_UPDATE,
                payload: { id, data}
            })
            dispatch(exceptionsActions.clear());
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}

export function uploadItemLogo(id, file) {
    return async (dispatch) => {
        try {
            let params = new Map();
            params.set('file', file);
            // POST request to API
            await api.postFiles(`/partners/${id}/image`, params);

            dispatch(fetchItem(id));
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}

export function createItemWithLogo(data, file) {
    return async (dispatch) => {
        try {
            let params = new Map();
            _.map(data, (value, key) => {
                params.set(key, value);
            })
            // POST request to API
            let item = await api.post('/partners', params);
            browserHistory.push(`/modules/partners`);

            params = new Map();
            params.set('file', file);
            // POST request to API for Upload
            await api.postFiles(`/partners/${item.id}/image`, params);

            dispatch(fetchItem(item.id));
            dispatch(exceptionsActions.clear());
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}

export function deleteItem(id) {
    return async (dispatch) => {
        try {
            await api.delete(`/partners/${id}`);

            dispatch(fetchItems());
            dispatch(exceptionsActions.clear());
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}
