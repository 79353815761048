import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import strings from "../../services/strings";

import "../Page.scss";
import Topbar from "../../components/Topbar";
import ArticleCategoryForm from "../../components/articleCategory/ArticleCategoryForm";

import * as articleCategoriesActions from "../../store/articleCategories/actions";
import * as articleCategoriesSelectors from "../../store/articleCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

class ArticleCategoriesAddPage extends Component {
  componentDidMount() {
    this.props.fetchArticleCategories();
  }

  componentWillUnmount() {
    this.props.clearExceptions();
  }

  saveArticleCategory = (data) => {
    if (data.bgFile) {
      this.props.createArticleCategoryWithLogo(data.form, data.bgFile);
    } else {
      this.props.createArticleCategory(data.form);
    }
  };

  render() {
    return (
      <div className="ArticleCategoriesAddPage">
        <Topbar>
          <div className="title">
            <Link to="/articles/categories">
              {strings.get("App.articleCategoryPages.add.title")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/articles/categories/add">
                <span>{strings.get("App.articleCategoryPages.add.add")}</span>{" "}
                <i className="ion-plus"></i>
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content">
          <ArticleCategoryForm
            exceptions={this.props.exceptions}
            saveItem={this.saveArticleCategory}
            formHeading={strings.get("App.articleCategoryForm.addFormHeading")}
            articleCategories={this.props.articleCategories}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    exceptions: exceptionsSelectors.getItems(state),
    articleCategories: articleCategoriesSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createArticleCategory: (data) => {
      dispatch(articleCategoriesActions.createItem(data));
    },
    fetchArticleCategories: () => {
      dispatch(articleCategoriesActions.fetchAllItems());
    },
    createArticleCategoryWithLogo: (data, bgFile) => {
      dispatch(articleCategoriesActions.createItemWithLogo(data, bgFile));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleCategoriesAddPage);
