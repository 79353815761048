import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import strings from "../../services/strings";
import Topbar from "../../components/Topbar";
import WorkflowCategoryForm from "../../components/workflowCategory/WorkflowCategoryForm";

import "../Page.scss";

import * as workflowCategoriesActions from "../../store/workflowCategories/actions";
import * as workflowCategoriesSelectors from "../../store/workflowCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

class WorkflowCategoriesEditPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchAllWorkflowCategories();
    this.props.fetchWorkflowCategory(this.props.match.params.id);
    this.props.setCurrentWorkflowCategoryId(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.unsetCurrentWorkflowCategoryId();
    this.props.clearExceptions();
  }

  saveWorkflowCategory(data) {
    this.props.updateWorkflowCategory(this.props.match.params.id, data.form);
    if (data.bgFile) {
      this.props.uploadWorkflowCategoryLogo(this.props.match.params.id, data.bgFile);
    }
  }

  render() {
    return (
      <div className="WorkflowCategoriesEditPage">
        <Topbar>
          <div className="title">
            <Link to="/workflows/categories">
              {strings.get("App.workflowCategoryPages.edit.title")}
            </Link>
            <span className="xs-hidden">
              <span className="divider">/</span>
              <Link to={`/workflows/categories/${this.props.match.params.id}`}>
                {strings.get("App.workflowCategoryPages.edit.edit")}
              </Link>
            </span>
          </div>
          <div className="main-btns">
            <Link to={`/workflows/add`} className="btn btn-primary">
              <span>
                {strings.get("App.workflowCategoryPages.add.addWorkflow")}
              </span>{" "}
              <i className="ion-plus"></i>
            </Link>
          </div>
        </Topbar>

        <div className="content">
          <WorkflowCategoryForm
            exceptions={this.props.exceptions}
            workflowCategories={this.props.workflowCategories}
            currentItem={this.props.currentWorkflowCategory}
            saveItem={this.saveWorkflowCategory}
            formHeading={strings.get(
              "App.workflowCategoryForm.editFormHeading"
            )}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentWorkflowCategory: workflowCategoriesSelectors.getCurrentItem(state),
    workflowCategories: workflowCategoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllWorkflowCategories: (deleteCache) => {
      dispatch(workflowCategoriesActions.fetchAllItems(deleteCache));
    },
    fetchWorkflowCategory: (id) => {
      dispatch(workflowCategoriesActions.fetchItem(id));
    },
    setCurrentWorkflowCategoryId: (id) => {
      dispatch(workflowCategoriesActions.setCurrentItemId(id));
    },
    unsetCurrentWorkflowCategoryId: () => {
      dispatch(workflowCategoriesActions.unsetCurrentItemId());
    },
    updateWorkflowCategory: (id, data) => {
      dispatch(workflowCategoriesActions.updateItem(id, data));
    },
    createWorkflowCategory: (data) => {
      dispatch(workflowCategoriesActions.createItem(data));
    },
    uploadWorkflowCategoryLogo: (id, bgFile) => {
      dispatch(workflowCategoriesActions.uploadItemLogo(id, bgFile));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowCategoriesEditPage);
