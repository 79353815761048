import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import strings from "../../services/strings";
import Topbar from "../../components/Topbar";
import WorkflowCategoryList from "../../components/workflowCategory/WorkflowCategoryList";
import WorkflowCategoryPagesize from "../../components/workflowCategory/WorkflowCategoryPagesize";
import Pagination from "../../components/Pagination";

import "../Page.scss";

import * as workflowCategoriesActions from "../../store/workflowCategories/actions";
import * as workflowCategoriesSelectors from "../../store/workflowCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

class WorkflowCategoriesListPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchWorkflowCategories();
  }

  componentWillUnmount() {
    this.props.unsetCurrentWorkflowCategoryId();
    this.props.clearExceptions();
  }

  render() {
    return (
      <div className="WorkflowCategoriesListPage">
        <Topbar>
          <div className="title">
            <Link to="/workflows/categories">
              {strings.get("App.workflowCategoryPages.listPage.title")}
            </Link>
          </div>
          <div className="main-btns">
            <Link to="/workflows" className="btn btn-default btn-color-change">
              <span>
                {strings.get("App.workflowCategoryPages.listPage.workflowList")}
              </span>{" "}
              <i className="ion-ios-list-outline"></i>
            </Link>
            <Link to="/workflows/categories/add" className="btn btn-primary">
              <span>
                {strings.get(
                  "App.workflowCategoryPages.listPage.addWorkflowCategory"
                )}
              </span>{" "}
              <i className="ion-plus"></i>
            </Link>
          </div>
        </Topbar>

        <div className="content">
          <div className="row">
            <div className="col-xs-12">
              <WorkflowCategoryPagesize
                sorter={this.props.sorter}
                fetchItems={this.props.fetchWorkflowCategories}
                setSorter={this.props.setSorter}
              />
            </div>
          </div>
          <WorkflowCategoryList
            items={this.props.workflowCategories}
            currentItem={this.props.currentWorkflowCategory}
            fetchItems={this.props.fetchWorkflowCategories}
            setCurrentItemId={this.props.setCurrentWorkflowCategoryId}
            unsetCurrentItemId={this.props.unsetCurrentWorkflowCategoryId}
            deleteItem={this.props.deleteWorkflowCategory}
            updateItem={this.props.updateWorkflowCategory}
            orderItems={this.props.orderItems}
          />

          <Pagination
            pagination={this.props.pagination}
            setCurrentPage={this.props.setCurrentPage}
            fetchItems={this.props.fetchWorkflowCategories}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sorter: workflowCategoriesSelectors.getSorter(state),
    workflowCategories: workflowCategoriesSelectors.getItemsByPage(
      state,
      workflowCategoriesSelectors.getPagination(state).currentPage
    ),
    pagination: workflowCategoriesSelectors.getPagination(state),
    currentWorkflowCategory: workflowCategoriesSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchWorkflowCategories: (deleteCache) => {
      dispatch(workflowCategoriesActions.fetchItems(deleteCache));
    },
    setCurrentPage: (page) => {
      dispatch(workflowCategoriesActions.setCurrentPage(page));
    },
    setCurrentWorkflowCategoryId: (id) => {
      dispatch(workflowCategoriesActions.setCurrentItemId(id));
    },
    unsetCurrentWorkflowCategoryId: () => {
      dispatch(workflowCategoriesActions.unsetCurrentItemId());
    },
    deleteWorkflowCategory: (id, handleCatDeleteResponse) => {
      dispatch(
        workflowCategoriesActions.deleteItem(id, handleCatDeleteResponse)
      );
    },
    updateWorkflowCategory: (id, data) => {
      dispatch(workflowCategoriesActions.updateItem(id, data));
    },
    toggleSorter: (searchTerm) => {
      dispatch(workflowCategoriesActions.toggleSorter(searchTerm));
    },
    setSorter: (sorter) => {
      dispatch(workflowCategoriesActions.setSorter(sorter));
    },
    orderItems: (items) => {
      dispatch(workflowCategoriesActions.orderItems(items));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowCategoriesListPage);
