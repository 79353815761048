import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import settings from "../../../services/settings";
import { Link } from "react-router-dom";
import "../settings.scss";
import "../../Page.scss";

import * as settingsActions from "../../../store/settings/actions";
import * as settingsSelectors from "../../../store/settings/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";

// import Modal from "reboron/DropModal";
import Topbar from "../../../components/Topbar";
import SubTopbar from "../../../components/SubTopbar";
// import SearchBar from "../../../components/SearchBar";
import SettingsTabs from "../../../components/settings/SettingsTabs";
import ContactSettingForm from "../../../components/settings/contact/ContactSettingForm";
import "./ContactSettingPage.scss";
import { NotificationManager } from "react-notifications";

class ContactSettingPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchAllSettings();
  }

  // showSaveModal() {
  //   this.refs.saveModal.show();
  // }

  // hideSaveModal() {
  //   this.refs.saveModal.hide();
  // }

  saveSettings(data) {
    let contact_phone = this.props.updateSetting(
      data.form[settings.keys.CONTACT_PHONE]
    );
    let contact_fax = this.props.updateSetting(
      data.form[settings.keys.CONTACT_FAX]
    );
    let contact_email = this.props.updateSetting(
      data.form[settings.keys.CONTACT_EMAIL]
    );
    let contact_address = this.props.updateSetting(
      data.form[settings.keys.CONTACT_ADDRESS]
    );
    let contact_site_name = this.props.updateSetting(
      data.form[settings.keys.CONTACT_SITE_NAME]
    );
    let header_logo = this.props.updateImageSetting(
      data.form[settings.keys.HEADER_LOGO]
    );
    let footer_logo = this.props.updateImageSetting(
      data.form[settings.keys.FOOTER_LOGO]
    );
    let banner_img = this.props.updateImageSetting(
      data.form[settings.keys.BANNER_IMG]
    );
    let bottom_banner_img = this.props.updateImageSetting(
      data.form[settings.keys.BOTTOM_BANNER_IMG]
    );
    let client_title_favicon = this.props.updateImageSetting(
      data.form[settings.keys.CLIENT_TITLE_FAVICON]
    );
    let admin_title_favicon = this.props.updateImageSetting(
      data.form[settings.keys.ADMIN_TITLE_FAVICON]
    );
    let site_meta_description = this.props.updateSetting(
      data.form[settings.keys.SITE_META_DESCRIPTION]
    );
    let site_meta_keywords = this.props.updateSetting(
      data.form[settings.keys.SITE_META_KEYWORDS]
    );
    let facebook_link = this.props.updateSetting(
      data.form[settings.keys.FACEBOOK_LINK]
    );
    let linkedin_link = this.props.updateSetting(
      data.form[settings.keys.LINKEDIN_LINK]
    );
    let instagram_link = this.props.updateSetting(
      data.form[settings.keys.INSAGRAM_LINK]
    );

    Promise.all([
      contact_phone,
      contact_fax,
      contact_email,
      contact_address,
      contact_site_name,
      header_logo,
      footer_logo,
      banner_img,
      bottom_banner_img,
      client_title_favicon,
      admin_title_favicon,
      site_meta_description,
      site_meta_keywords,
      facebook_link,
      linkedin_link,
      instagram_link
    ]).then(() => {
      // this.showSaveModal();
      NotificationManager.success(
        "",
        strings.get("App.settings.settingsSaved")
      );
    });
  }

  // handleShowModal() {
  //   _.delay(() => {
  //     this.hideSaveModal();
  //   }, 500);
  // }

  render() {
    return (
      <div className="ContactSettingPage">
        {/* <Modal
          className="boron-modal no-body"
          ref="saveModal"
          onShow={this.hideSaveModal}
        >
          <span>

            <h2>{strings.get("App.settings.settingsSaved")}</h2>
          </span>
        </Modal> */}
        <Topbar>
          <div className="title">
            <Link to="/settings/ContactSetting">
              {strings.get("App.settings.title")}
            </Link>
          </div>
        </Topbar>

        <div className="content settings-container">
          <SubTopbar>
            <SettingsTabs />
          </SubTopbar>
          <div className="setting-content">
            <ContactSettingForm
              settings={this.props.settings}
              saveSettings={this.saveSettings}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: settingsSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllSettings: () => {
      dispatch(settingsActions.fetchAllItems());
    },
    updateSetting: (data) => {
      return dispatch(settingsActions.updateItem(data));
    },
    updateImageSetting: (data) => {
      return dispatch(settingsActions.updateImageItem(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactSettingPage);
