import api from "../../services/api";
import language from "../../services/language";
import _ from "lodash";

import * as exceptionsActions from "../exceptions/actions";
import * as settingsAction from '../settings/actions';
import * as stepsSelectors from "./selectors";
import { browserHistory } from "../../helpers";

export const types = {
  FETCH_ALL_ITEMS_DONE: "steps.FETCH_ALL_ITEMS_DONE",
  FETCH_ITEM_DONE: "steps.FETCH_ITEM_DONE",
  SET_CURRENT_ITEM_ID: "steps.SET_CURRENT_ITEM_ID",
  CLEAR_CACHE: "steps.CLEAR_CACHE",
  SET_CATEGORY_ID: "steps.SET_CATEGORY_ID",
};

export function setCurrentPage(page) {
  return {
    type: types.SET_CURRENT_PAGE,
    payload: {
      page,
    },
  };
}

export function setCurrentItemId(id) {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id,
    },
  };
}

export function setCategoryId(id) {
  return {
    type: types.SET_CATEGORY_ID,
    payload: {
      id,
    },
  };
}

export function unsetCurrentItemId() {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id: null,
    },
  };
}

export function clearCache() {
  return {
    type: types.CLEAR_CACHE,
  };
}

export function fetchAllItems() {
  return async (dispatch, getState) => {
    try {
      dispatch(settingsAction.setLoadingScreen(true))
      
      let params = new Map();
      params.set("language_id", language.get());
      let items = await api.get("/steps", params);
      dispatch(clearCache());
      dispatch({
        type: types.FETCH_ALL_ITEMS_DONE,
        payload: {
          items,
        },
      });
      dispatch(settingsAction.setLoadingScreen())
    } catch (e) {
      dispatch(settingsAction.setLoadingScreen())
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchAllItemsCategoryWise() {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let filters = stepsSelectors.getFilters(state);
      let params = new Map();
      params.set("language_id", language.get());
      params.set("category_id", filters.categoryId ? filters.categoryId : "");
      let items = await api.get("/steps", params);
      dispatch(clearCache());
      dispatch({
        type: types.FETCH_ALL_ITEMS_DONE,
        payload: {
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItem(id) {
  return async (dispatch) => {
    try {
      // GET request from API
      let item = await api.get(`/steps/${id}`);
      dispatch({
        type: types.FETCH_ITEM_DONE,
        payload: {
          item,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function orderItems(items) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(items, (value, key) => {
        params.set(`steps[${key}][id]`, value.id);
        params.set(`steps[${key}][order]`, key + 1);
      });
      // PUT request to API
      await api.put("/steps/order", params);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function createItem(data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      await api.post("/steps", params);
      browserHistory.push(`/glossaries/steps`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateItem(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // PUT request to API
      await api.put(`/steps/${id}`, params);
      browserHistory.push(`/glossaries/steps`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function deleteItem(id) {
  return async (dispatch) => {
    try {
      // DELETE request to API
      await api.delete("/steps/" + id);
      dispatch(fetchAllItems());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateStepInGlossary(data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("codeType", data.codeType);
      _.map(data.data, (value, key) => {
        params.set(key, value);
      });

      // PUT request to API
      await api.put(`/glossaries/updateStep`, params);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}
