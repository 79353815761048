import React, { Component } from "react";
import autoBind from "react-autobind";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import strings from "../../services/strings";

import _ from "lodash";

import * as authActions from "../../store/auth/actions";
import { browserHistory } from "../../helpers";

class CompanyCategoryForm extends Component {
  state = {
    currentItemLoaded: false,
    form: {
      name: "",
      parent_id: "",
      slug: "",
    },
  };

  componentDidMount() {
    this.tryLoadCurrentItem();
  }

  componentDidUpdate() {
    this.tryLoadCurrentItem();
  }

  constructor(props) {
    super(props);
    autoBind(this);
  }

  tryLoadCurrentItem() {
    if (this.props.currentItem && !this.state.currentItemLoaded) {
      let form = _.extend({}, this.state.form);
      _.map(this.state.form, (value, key) => {
        form[key] = this.props.currentItem[key]
          ? this.props.currentItem[key]
          : this.state.form[key];
      });
      this.setState({
        currentItemLoaded: true,
        form,
      });
    }
  }

  hasError(inputName) {
    return !!this.props.exceptions[inputName];
  }

  getErrorClass(inputName, defaultClasses = "") {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  }

  getErrorMessage(inputName) {
    return this.props.exceptions[inputName];
  }

  handleInputChange(e) {
    let form = {};
    form[e.target.name] = e.target.value;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  }

  handleSaveClick(e) {
    e.preventDefault();
    this.props.saveItem(this.state);
  }

  handleCancelClick(e) {
    e.preventDefault();
    browserHistory.push("/glossaries/companies/categories");

  }

  getCompanyCategories() {
    return _.map(this.props.companyCategories, (item) => {
      return (
        <option key={`item-${item.id}`} value={item.id}>
          {item.tree_name}
        </option>
      );
    });
  }

  render() {
    let nameLabel = this.hasError("name")
      ? `${strings.get(
          "App.companies.companyCategoryForm.name"
        )} ${this.getErrorMessage("name")}`
      : strings.get("App.companies.companyCategoryForm.name");

    let codeLabel = this.hasError("slug")
      ? `${strings.get(
          "App.companies.companyCategoryForm.code"
        )} ${this.getErrorMessage("slug")}`
      : strings.get("App.companies.companyCategoryForm.code");

    return (
      <div className="CompanyCategoryForm row">
        <form className="col-sm-12 col-md-6">
          <div className={this.getErrorClass("name", "form-group")}>
            <label className="control-label">{nameLabel}</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={this.state.form.name}
              onChange={this.handleInputChange}
            />
          </div>
          <div className={this.getErrorClass("slug", "form-group")}>
            <label className="control-label">{codeLabel}</label>
            <input
              className="form-control"
              type="text"
              name="slug"
              value={this.state.form.slug}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group companyCategories">
            <label className="control-label">
              {strings.get("App.articleCategoryForm.parent")}
            </label>
            <select
              className="form-control"
              name="parent_id"
              value={this.state.form.parent_id}
              onChange={this.handleInputChange}
            >
              <option value="">
                {strings.get("App.articleCategoryForm.parent_choose")}
              </option>
              {this.getCompanyCategories()}
            </select>
          </div>
          <div className="form-actions">
            <button className="btn btn-primary" onClick={this.handleSaveClick}>
              {strings.get("App.companies.companyCategoryForm.save")}
            </button>
            <button
              className="btn btn-default btn-color-change"
              onClick={this.handleCancelClick}
            >
              {strings.get("App.companies.companyCategoryForm.cancel")}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

CompanyCategoryForm.propTypes = {
  exceptions: PropTypes.object.isRequired,
  saveItem: PropTypes.func.isRequired,
};

export default CompanyCategoryForm;
