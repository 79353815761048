
import api from '../../services/api';
import _ from 'lodash';

import * as exceptionsActions from '../exceptions/actions';
import { browserHistory } from '../../helpers';


export const types = {
	FETCH_ALL_ITEMS_DONE: 'translations.FETCH_ALL_ITEMS_DONE',
	FETCH_ITEM_DONE: 'translations.FETCH_ITEM_DONE',
	SET_CURRENT_ITEM_ID: 'translations.SET_CURRENT_ITEM_ID',
	CLEAR_CACHE: 'translations.CLEAR_CACHE',
}

export function setCurrentItemId(id) {
	return {
		type: types.SET_CURRENT_ITEM_ID,
		payload: {
			id
		}
	}
}

export function unsetCurrentItemId() {
	return {
		type: types.SET_CURRENT_ITEM_ID,
		payload: {
			id: null,
		}
	}
}

export function clearCache() {
	return {
		type: types.CLEAR_CACHE
	}
}

export function fetchAllItems() {
	return async (dispatch, getState) => {
		try {
			let items = await api.get('/translations');
			dispatch(clearCache());
			dispatch({
				type: types.FETCH_ALL_ITEMS_DONE,
				payload: {
					items
				}
			});
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function fetchItem(id) {
	return async (dispatch) => {
		try {
			// GET request from API
			let item = await api.get(`/translations/${id}`);
			dispatch({
				type: types.FETCH_ITEM_DONE,
				payload: {
					item
				}
			})
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function updateItem(id, data) {
	return async (dispatch) => {
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// PUT request to API
			await api.put(`/translations/${id}`, params);
			browserHistory.push(`/settings/translations`);

			dispatch(exceptionsActions.clear());
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function createItem(data) {
    return async (dispatch) => {
        try {
            let params = new Map();
            _.map(data, (value, key) => {
                params.set(key, value);
            })
            // POST request to API
            await api.post(`/translations`, params);
						browserHistory.push(`/settings/translations`);
            dispatch(exceptionsActions.clear());
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}

export function updateStrings(data, id) {
	return async (dispatch) => {
		try {
			data = JSON.stringify(data);
			let params = new Map();
			params.set('strings', data);
			await api.put(`/strings/${id}`, params);

			dispatch(exceptionsActions.clear());
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function deleteItem(id) {
    return async (dispatch) => {
        try {
            await api.delete(`/translations/${id}`);

            dispatch(fetchAllItems());
            dispatch(exceptionsActions.clear());
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}

export function createItemWithLogo(data, file) {
    return async (dispatch) => {
        try {
            let params = new Map();
            _.map(data, (value, key) => {
                params.set(key, value);
            });
						params.set('file', file); // This is only for validation purpose.
            // POST request to API
						let item = await api.postFiles(`/translations`, params);

            params = new Map();
            params.set('file', file);
            // POST request to API for Upload
            await api.postFiles(`/translations/${item.id}/image`, params);

            dispatch(fetchAllItems());
            browserHistory.push(`/settings/translations`);
            dispatch(exceptionsActions.clear());
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}

export function updateItemWithLogo(id, data, file) {
    return async (dispatch) => {
        try {
            let params = new Map();
            _.map(data, (value, key) => {
                params.set(key, value);
            });
						// PUT request to API
						let item = await api.put(`/translations/${id}`, params);

            params = new Map();
            params.set('file', file);
            // POST request to API for Upload
            await api.postFiles(`/translations/${item.id}/image`, params);

            dispatch(fetchAllItems());
            browserHistory.push(`/settings/translations`);
            dispatch(exceptionsActions.clear());
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}
