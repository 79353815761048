import React, { Component } from "react";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as companyCategoriesActions from "../../store/companyCategories/actions";
import * as companyCategoriesSelectors from "../../store/companyCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
// import SubTopbar from "../../components/SubTopbar";
import CompanyCategoryForm from "../../components/companyCategory/CompanyCategoryForm";

class CompanyCategoryAddPage extends Component {
  componentDidMount() {
    this.props.fetchCompanyCategories();
  }

  componentWillUnmount() {
    this.props.clearExceptions();
  }

  saveCompanyCategory = (data) => {
    this.props.createCompanyCategory(data.form);
  };

  render() {
    return (
      <div className="CompanyCategoryAddPage">
        <Topbar>
          <div className="title">
            <Link to="/glossaries/companies">
              {strings.get("App.companyCategoryPages.title")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/glossaries/companies/categories">
                {strings.get("App.companyCategoryPages.categories")}
              </Link>
              <span className="divider">/</span>
              <Link to="/glossaries/companies/categories/add">
                {strings.get("App.companyCategoryPages.add")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content">
          <CompanyCategoryForm
            exceptions={this.props.exceptions}
            saveItem={this.saveCompanyCategory}
            companyCategories={this.props.companyCategories}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    companyCategories: companyCategoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createCompanyCategory: (data) => {
      dispatch(companyCategoriesActions.createItem(data));
    },
    fetchCompanyCategories: () => {
      dispatch(companyCategoriesActions.fetchAllItems());
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyCategoryAddPage);
