
import api from '../../services/api';
import language from '../../services/language';
import _ from 'lodash';

import * as exceptionsActions from '../exceptions/actions';
import * as glossariesSelectors from './selectors';
import * as settingsActions from "../settings/actions";
import { NotificationManager } from 'react-notifications';
import strings from '../../services/strings';
import { browserHistory } from '../../helpers';
export const types = {
	FETCH_ALL_ITEMS_DONE: 'glossaries.FETCH_ALL_ITEMS_DONE',
	FETCH_ITEMS_DONE: 'glossaries.FETCH_ITEMS_DONE',
	FETCH_ITEM_DONE: 'glossaries.FETCH_ITEM_DONE',
	SET_SEARCH_TERM: 'glossaries.SET_SEARCH_TERM',
	SET_CURRENT_PAGE: 'glossaries.SET_CURRENT_PAGE',
	SET_CURRENT_ITEM_ID: 'glossaries.SET_CURRENT_ITEM_ID',
	TOGGLE_SORTER: 'glossaries.TOGGLE_SORTER',
	CLEAR_CACHE: 'glossaries.CLEAR_CACHE',
	UPDATE_CURRENT_GLOSSARY: 'glossaries.UPDATE_CURRENT_GLOSSARY'
}

export function setCurrentPage(page) {
	return {
		type: types.SET_CURRENT_PAGE,
		payload: {
			page
		}
	}
}

export function setCurrentItemId(id) {
	return {
		type: types.SET_CURRENT_ITEM_ID,
		payload: {
			id
		}
	}
}

export function unsetCurrentItemId() {
	return {
		type: types.SET_CURRENT_ITEM_ID,
		payload: {
			id: null,
		}
	}
}

export function toggleSorter(column) {
	return {
		type: types.TOGGLE_SORTER,
		payload: {
			column
		}
	}
}

export function setSearchTerm(searchTerm) {
	return {
		type: types.SET_SEARCH_TERM,
		payload: {
			searchTerm
		}
	}
}

export function clearCache() {
	return {
		type: types.CLEAR_CACHE
	}
}

export function fetchAllItems(callback=()=>{}) {
	return async (dispatch, getState) => {
		try {
			dispatch(settingsActions.setLoadingScreen(true))
			let params = new Map();
			params.set('language_id', language.get());

			let items = await api.get('/glossaries', params);
			// dispatch(clearCache());
			dispatch({
				type: types.FETCH_ALL_ITEMS_DONE,
				payload: {
					items
				}
			});
			dispatch(settingsActions.setLoadingScreen())
			_.delay(()=>{
				callback();
			},300);
		} catch (e) {
			dispatch(settingsActions.setLoadingScreen())
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function fetchItems(deleteCache = false, expandItems = null) {
	return async (dispatch, getState) => {
		let state = getState();
		try {
			// Set additional params
			let params = new Map();
			let filters = glossariesSelectors.getFilters(state);
			let sorter = glossariesSelectors.getSorter(state);
			let pagination = glossariesSelectors.getPagination(state);
			params.set('language_id', language.get());
			params.set('name~', filters.searchTerm);
			params.set('page_size', pagination.pageSize);
			params.set('page_number', deleteCache ? 1 : pagination.currentPage);
			params.set('sort_by', sorter.column);
			params.set('sort_desc', sorter.descending);

      if(expandItems !== null){
        params.set('expandItems', expandItems);
			}
			// GET request from API
			let [response, items] = await api.get('/glossaries', params, true);

			// Clear cache if deleteCache is enabled
			if (deleteCache) {
				dispatch(clearCache());
			}

			dispatch({
				type: types.FETCH_ITEMS_DONE,
				payload: {
					totalPages: parseInt(response.headers.get('X-Total-Pages')),
					items
				}
			});
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function fetchItem(id) {
	return async (dispatch) => {
		try {
			// GET request from API
      let item = await api.get(`/glossaries/${id}`);
			dispatch({
				type: types.FETCH_ITEM_DONE,
				payload: {
					item
				}
			})
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function createItem(data) {
	return async (dispatch) => {
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// POST request to API
			await api.post('/glossaries', params);
			NotificationManager.success("", strings.get("Client.notifications.added"));
			browserHistory.push(`/glossaries`);
			
			dispatch(fetchAllItems());
			dispatch(exceptionsActions.clear());
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function createSelector(data) {
	return async (dispatch) => {
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// POST request to API
      let item = await api.post('/glossary-selectors', params);
      // browserHistory.push(`/glossaries`);
      dispatch(exceptionsActions.clear());
      return item;
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function createClause(data) {
	return async (dispatch) => {
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// POST request to API
      let item = await api.post('/glossary-clauses', params);
			// browserHistory.push(`/glossaries`);
      dispatch(exceptionsActions.clear());
      return item;
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}
export function createField(data) {
	return async (dispatch) => {
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// POST request to API
			let item = await api.post('/glossary-fields', params);
			// browserHistory.push(`/glossaries`);
      dispatch(exceptionsActions.clear());
      return item;
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}
export function createCodeBlock(data) {
	return async (dispatch) => {
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// POST request to API
			let item = await api.post('/glossary-code-blocks', params);
			// browserHistory.push(`/glossaries`);
      dispatch(exceptionsActions.clear());
      return item;
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function createCarousel(data) {
	return async (dispatch) => {
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			let item = await api.post('/glossary-carousels', params);
      		dispatch(exceptionsActions.clear());
      		return item;
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function updateItem(id, data, fetchWithoutFilters = false, callback= () =>{}) {
	return async (dispatch) => {
		try {
			dispatch(settingsActions.setLoadingScreen(true))

			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// PUT request to API
			await api.put(`/glossaries/${id}`, params);
			
			//generate rules json of all documents
			//start
			// params = new Map();
            // params.set('language_id', language.get());
			// let items = await api.get('/documents/getAllPagesDocuments', params);
			// for (const doc of items) {
			// 	await api.get(`/documents/generate-doc-rules/${doc.id}`);
			// }
			//end
			NotificationManager.success("", strings.get("Client.notifications.edited"));
			const documentsPage = window.location.pathname.search('/documents/')
      		if(documentsPage === -1) browserHistory.push(`/glossaries`);
			dispatch(exceptionsActions.clear());
			if(fetchWithoutFilters){
				//if glossary edited from glossary edit page 
				//then fecth all glossaries
				dispatch(fetchAllItems(callback));
			}else{
				//if glossary edited from glossary list page 
				//then fecth glossaries according to filters
				dispatch(fetchItems());
			}
			dispatch(settingsActions.setLoadingScreen(false))
		} catch (e) {
			dispatch(settingsActions.setLoadingScreen(false))
			dispatch(exceptionsActions.process(e));
		}
	}
}
export function updateSelector(id, data,documentCodeEdit) {
	return async (dispatch, getState) => {
		const state = getState()
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// PUT request to API
			await api.put(`/glossary-selectors/${id}`, params);
			// browserHistory.push(`/glossaries`);
			dispatch(fetchItem(glossariesSelectors.getCurrentItemId(state)));
			if (documentCodeEdit) {
				localStorage.setItem("localStroage-value", _.uniqueId());
				window.close();
			}

			dispatch(exceptionsActions.clear());
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function updateClause(id, data,documentCodeEdit) {
	return async (dispatch, getState) => {
		const state = getState();
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// PUT request to API
			await api.put(`/glossary-clauses/${id}`, params);
			// browserHistory.push(`/glossaries`);
			dispatch(fetchItem(glossariesSelectors.getCurrentItemId(state)));
			if (documentCodeEdit) {
				localStorage.setItem("localStroage-value", _.uniqueId());
				window.close();
			}

			dispatch(exceptionsActions.clear());
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function updateField(id, data,documentCodeEdit) {
	return async (dispatch,getState) => {
		const state = getState()
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// PUT request to API
			await api.put(`/glossary-fields/${id}`, params);
			// browserHistory.push(`/glossaries`);
			dispatch(fetchItem(glossariesSelectors.getCurrentItemId(state)));
			if (documentCodeEdit) {
				localStorage.setItem("localStroage-value", _.uniqueId());
				window.close();
			}

			dispatch(exceptionsActions.clear());
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function updateCodeBlock(id, data,documentCodeEdit) {
	return async (dispatch, getState) => {
		let state = getState();
		try {
			dispatch(settingsActions.setLoadingScreen(true));
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// PUT request to API
			await api.put(`/glossary-code-blocks/${id}`, params);
			dispatch(fetchItem(glossariesSelectors.getCurrentItemId(state)));
			if (documentCodeEdit) {
				localStorage.setItem("localStroage-value", _.uniqueId());
				window.close();
			}

			// browserHistory.push(`/glossaries`);
			NotificationManager.success("", strings.get("Client.notifications.edited"));
			dispatch(exceptionsActions.clear());
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		} finally {
			dispatch(settingsActions.setLoadingScreen(false));
		}
	}
}

export function updateCarousel(id, data, documentCodeEdit) {
	return async (dispatch,getState) => {
		const state = getState();
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			await api.put(`/glossary-carousels/${id}`, params);
			dispatch(fetchItem(glossariesSelectors.getCurrentItemId(state)));
			if (documentCodeEdit) {
				 localStorage.setItem("localStroage-value", _.uniqueId());
				 window.close();
				}

			dispatch(exceptionsActions.clear());
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function onDeleteClause(id) {
	return async (dispatch) => {
		try {
			// DELETE request to API
			await api.delete('/glossary-clauses/' + id);
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function onDeleteSelector(id) {
	return async (dispatch) => {
		try {
			// DELETE request to API
			await api.delete('/glossary-selectors/' + id);
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function onDeleteField(id) {
	return async (dispatch) => {
		try {
			// DELETE request to API
			await api.delete('/glossary-fields/' + id);
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function onDeleteCodeBlock(id) {
	return async (dispatch) => {
		try {
			// DELETE request to API
			await api.delete('/glossary-code-blocks/' + id);
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function onDeleteCarousel(id) {
	return async (dispatch) => {
		try {
			await api.delete('/glossary-carousels/' + id);
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function deleteItem(id, handleGloDeleteResponse) {
	return async (dispatch) => {
		try {
			// DELETE request to API
      await api.delete('/glossaries/' + id)
      .then(
        (response) => {
          handleGloDeleteResponse(response);
          if(response === 'success')
          dispatch(fetchItems());
        }
      );
			dispatch(fetchItems());
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function importItem(data) {
    return async (dispatch) => {
        try {
            let csvData = data.csvdata;
            csvData.shift(); // Remove Header
			let params = new Map();
			params.set('language_id', data.language_id);
			params.set('rows', JSON.stringify(csvData));
			await api.post('/glossaries/import', params);

            dispatch(exceptionsActions.clear());
            dispatch(fetchItems());
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}
export function exportGlossaries(id, name=''){
  return async (dispatch) => {
		try {
			// Export glossaries
      await api.getFile('/glossaries/' + id + '/export', new Map(), false, name);

		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function importGlossaries(id, data, succ = ()=>{}, err = ()=>{}){
	return async (dispatch) => {
		  try {
			  // Import glossaries
    let item = await api.postFiles('/glossaries/' + id + '/import', data);
    item?succ():err();
		  } catch (e) {
        err();
			  dispatch(exceptionsActions.process(e));
		  }
	  }
  }

export function undoImportGlossary(id, succ = ()=>{}, err = ()=>{}){
	return async (dispatch) => {
		try {
			let item = await api.get('/glossaries/' + id + '/undo-import', new Map(), false);
			item?succ():err();
		} catch (e) {
			err();
			dispatch(exceptionsActions.process(e));
		}
	  }
}

export function updateCurrentGlossaryStore(currentItem, currentGlossary, documentCodeEdit){
	return async (dispatch) => {
		dispatch({
			type: types.UPDATE_CURRENT_GLOSSARY,
			payload: {
				currentItem, currentGlossary
			}
		});
	if (documentCodeEdit) localStorage.setItem("localStroage-value", _.uniqueId());
	}
 }