import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import strings from "../../services/strings";

import moment from "moment";
import _ from "lodash";
import "./UserList.scss";
import { makeSEFUrl } from "../../helpers";

class UserSharedDraftList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  timeConverter(updated) {
    return moment(updated.date).format("DD-MM-YYYY HH:mm:ss");
  }

  processTemplateLink(item) {
    let locale = JSON.parse(localStorage.getItem("language")).iso2 || "NL";
    return `${this.props.siteURL}${locale}/documenten/${makeSEFUrl(
      item.category.name
    )}-${item.document.category_id}/${makeSEFUrl(item.document.name)}-${
      item.document.id
    }`;
  }

  processCategoryLink(item) {
    let locale = JSON.parse(localStorage.getItem("language")).iso2 || "NL";
    return `${this.props.siteURL}${locale}/documenten/${makeSEFUrl(
      item.category.name
    )}-${item.document.category_id}`;
  }

  render() {
    let items = _.map(this.props.items, (item) => {
      return (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td>{this.timeConverter(item.latestrevision_modified)}</td>
          <td>
            <a href={this.processTemplateLink(item)} target="_blank">
              {item.document.name}
            </a>
          </td>
          <td>
            <a href={this.processCategoryLink(item)} target="_blank">
              {item.category.name}
            </a>
          </td>
        </tr>
      );
    });
    return (
      <span className="UserSharedDraftList">
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>{strings.get("App.users.sharedDrafts.draftName")}</th>
                      <th>
                        {strings.get("App.users.sharedDrafts.lastModified")}
                      </th>
                      <th>{strings.get("App.users.sharedDrafts.docName")}</th>
                      <th>{strings.get("App.users.sharedDrafts.category")}</th>
                    </tr>
                  </thead>
                  {items.length > 0 ? (
                    <tbody>{items}</tbody>
                  ) : (
                    <tbody>
                      {" "}
                      <tr>
                        {" "}
                        <td colSpan="4">
                          {" "}
                          {strings.get("App.settings.documents.noFiles")}{" "}
                        </td>{" "}
                      </tr>{" "}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  }
}

UserSharedDraftList.propTypes = {
  items: PropTypes.array.isRequired,
};

export default UserSharedDraftList;
