import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import * as moment from "moment";
import strings from "../../../services/strings";
import { makeSEFUrl } from "../../../helpers";
import { Link } from "react-router-dom";
import _ from "lodash";
import "./AlertList.scss";

import ReactCollapsingTable from "react-collapsing-table";

// const nl2br = require('react-nl2br');
let componentInstance = null;

const TableDocument = ({ row, accessor }) => {
  return (
    <span>
      {/* <a href={componentInstance.processLink(row)} target="_blank" className="doc-name"> */}
      {row.documentName}
      {/* </a> */}
      <br />
      <small>
        {/* <a href={componentInstance.processCategoryLink(row)} target="_blank"> */}
        {row.category_name}
        {/* </a> */}
      </small>
    </span>
  );
};

const TableDate = ({ row, accessor }) => {
  return <span>{row.date}</span>;
};

class AlertList extends Component {
  state = {
    currentItemLoaded: false,
  };

  constructor(props) {
    super(props);
    autoBind(this);
    componentInstance = this;
  }

  componentDidMount() {
    this.tryLoadCurrentItem();
  }

  componentDidUpdate() {
    this.tryLoadCurrentItem();
  }

  tryLoadCurrentItem() {
    if (
      this.props.currentItem &&
      !_.isEmpty(this.props.currentItem) &&
      !this.state.currentItemLoaded
    ) {
      let form = _.extend({}, this.state.form);

      if (this.props.currentItem.settings) {
        let settingObject = JSON.parse(this.props.currentItem.settings);
        form = _.merge(form, settingObject);
      }

      this.setState({
        currentItemLoaded: true,
        form,
      });
    }
  }

  hasError(inputName) {
    return !!this.props.exceptions[inputName];
  }

  getErrorClass(inputName, defaultClasses = "") {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  }

  getErrorMessage(inputName) {
    return this.props.exceptions[inputName];
  }

  getAlertRows() {
    let rows = [];
    if (this.props.items) {
      _.forEach(this.props.items, function (item, key) {
        rows.push(item);
      });
    }
    //After rows loaded, then set columnsLoaded to True.
    if (!this.state.columnsLoaded && rows.length > 0) {
      _.delay(() => {
        this.setState({
          columnsLoaded: true,
        });
      }, 500);
    }
    return rows;
  }

  getAlertColumns() {
    let columns = [
      {
        accessor: "documentName",
        label: strings.get("Client.downloadsPage.documentName"),
        priorityLevel: 3,
        position: 3,
        minWidth: 150,
        sortable: false,
        CustomComponent: TableDocument,
      },
      {
        accessor: "message",
        label: strings.get("Client.settings.messages.alerts.message"),
        priorityLevel: 3,
        position: 3,
        minWidth: 150,
        sortable: false,
      },
      {
        accessor: "email",
        label: strings.get("Client.settings.messages.alerts.alertEmail"),
        sortable: false,
      },
      {
        accessor: "date",
        label: strings.get("Client.settings.messages.alerts.alertDate"),
        sortable: false,
        CustomComponent: TableDate,
      },
    ];
    return columns;
  }

  processLink(item) {
    let locale = JSON.parse(localStorage.getItem("language")).iso2 || "NL";
    return `${window.location.protocol}//${
      window.location.host
    }/${locale}/documenten/${makeSEFUrl(item.category_name)}-${
      item.category_id
    }/${makeSEFUrl(item.documentName)}-${item.documentId}`;
  }

  processCategoryLink(item) {
    let locale = JSON.parse(localStorage.getItem("language")).iso2 || "NL";
    return `${window.location.protocol}//${
      window.location.host
    }/${locale}/documenten/${makeSEFUrl(item.category_name)}-${
      item.category_id
    }`;
  }

  render() {
    let rows = this.getAlertRows();
    let columns = this.getAlertColumns();

    return (
      <div className="AlertList">
        <ReactCollapsingTable
          theme="react-collapsible-custom-theme"
          rows={rows}
          columns={columns}
        />

        {rows.length === 0 ? (
          <div className="col-sm-9 defaultMsg">
            <div> {strings.get("App.settings.documents.noFiles")} </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

AlertList.propTypes = {
  currentItem: PropTypes.object,
};

export default AlertList;
