import React, { Component } from "react";
import _ from "lodash";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as stepsActions from "../../store/steps/actions";
import * as stepsSelectors from "../../store/steps/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
// import SubTopbar from "../../components/SubTopbar";
import StepList from "../../components/step/StepList";
import * as glossariesSelectors from "../../store/glossaries/selectors";
import * as glossariesActions from "../../store/glossaries/actions";
import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import DocumentCategoryFilter from "../../components/document/DocumentCategoryFilter";

class StepListPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchAllSteps();
    if (!_.size(this.props.glossaries)) {
      this.props.fetchAllGlossaries();
    }
  }

  render() {
    return (
      <div className="StepListPage">
        <Topbar>
          <div className="title">
            <Link to="/glossaries">
              {strings.get("App.stepPages.glossaries")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/glossaries/steps">
                {strings.get("App.stepPages.edit")}
              </Link>
            </span>
          </div>
          <div className="main-btns">
            <Link to="/glossaries/steps/add" className="btn btn-primary">
              <span>{strings.get("App.stepPages.addStep")}</span>{" "}
              <i className="ion-plus"></i>
            </Link>
          </div>
        </Topbar>

        <div className="content">
          <div className="row">
            <div className="col-xs-6">
              <DocumentCategoryFilter
                filters={this.props.filters}
                categories={this.props.categories}
                fetchItems={this.props.fetchAllSteps}
                setCategoryId={this.props.setCategoryId}
              />
            </div>
          </div>
          <StepList
            items={this.props.steps}
            currentItem={this.props.currentStep}
            fetchItems={this.props.fetchAllSteps}
            orderItems={this.props.orderSteps}
            setCurrentItemId={this.props.setCurrentStepId}
            unsetCurrentItemId={this.props.unsetCurrentStepId}
            deleteItem={this.props.deleteStep}
            glossaries={this.props.glossaries}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    steps: stepsSelectors.getItems(state),
    currentStep: stepsSelectors.getCurrentItem(state),
    categories: categoriesSelectors.getItems(state),
    filters: stepsSelectors.getFilters(state),
    glossaries: glossariesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllCategories: () => {
      dispatch(categoriesActions.fetchAllItems());
    },
    fetchAllSteps: (deleteCache) => {
      dispatch(stepsActions.fetchAllItemsCategoryWise(deleteCache));
    },
    orderSteps: (items) => {
      dispatch(stepsActions.orderItems(items));
    },
    setCurrentStepId: (id) => {
      dispatch(stepsActions.setCurrentItemId(id));
    },
    unsetCurrentStepId: () => {
      dispatch(stepsActions.unsetCurrentItemId());
    },
    deleteStep: (id) => {
      dispatch(stepsActions.deleteItem(id));
    },
    setCategoryId: (id) => {
      dispatch(stepsActions.setCategoryId(id));
    },
    fetchAllGlossaries: () => {
      dispatch(glossariesActions.fetchAllItems());
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepListPage);
