import React, { Component } from "react";
import _ from "lodash"
import autoBind from "react-autobind";
import strings from "../../services/strings";
import { connect } from "react-redux";
import language from "../../services/language";

import './Milestone.scss';
import ActiveLabel from '../ActiveLabel';
import Editor from "../Editor";
import Modal from "reboron/DropModal";
import SelectSearch from 'react-select';

class Milestone extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      showDetailMilestone: false,
      form: {
        id: 0,
        workflow_id: props.milestone.workflow_id && props.milestone.workflow_id,
        name: "",
        order: props.milestone.order && props.milestone.order,
        duration: props.milestone.duration ? (props.milestone.duration) : {},
        documents: [],
        content: "",
        helperText: "",
        helperLink: ""
      },
      tmpItem: [],
      setOrder: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.milestone) {

      let form = _.extend({}, this.state.form);
      _.map(this.state.form, (value, key) => {
        form[key] = nextProps.milestone[key]
          ? nextProps.milestone[key]
          : this.state.form[key];
      });
      form.duration = nextProps.milestone.duration ? (nextProps.milestone.duration) : {};
      this.setState({
        form
      })
    }
  }
  getErrorClass(inputName, defaultClasses = "") {
    return defaultClasses;
  }

  handleInputChange(e) {
    let form = { ...this.state.form };
    let name = e.target.getAttribute('data-key');
    let value = e.target.value;
    let duration = form.duration;
    if (e.target.getAttribute('data-key') && form[e.target.name]) {
      duration = {
        ...duration,
        [name]: value
      }
      form.duration = duration;
    } else {
      form[e.target.name] = e.target.value;
    }
    this.setState({
      form: _.extend(this.state.form, form)
    }, () => {
      this.handleChangeMilestone(this.state.form)
    });
  }

  handleRelatedDocuments = (e) => {
    let id = e.value;
    let form = this.state.form;
    let added = (_.findIndex(this.state.form.documents, (item) => {
      return item.id === id;
    }) > -1);

    if (!added) {
      form.documents = [
        ...this.state.form.documents,
        this.props.documents[`_${id}`]
      ];
      this.setState({
        ...this.state.form,
        form
      }, () => {
        this.handleChangeMilestone(this.state.form)
      });
    }
  }

  handleDocumentsRemove(data) {
    let { form } = this.state;
    let temDocs = form.documents.filter((doc) => {
      return doc.id !== data;
    })
    form.documents = temDocs;

    this.setState({ form }, () => {
      this.handleChangeMilestone(this.state.form)
    });
  }

  handleEditorChange(e) {
    this.setState({
      form: _.extend(this.state.form, {
        helperText: e.target.getContent()
      })
    }, () => {
      this.handleChangeMilestone(this.state.form)
    });
  }

  handleEditorChangeContent(e) {
    this.setState({
      form: _.extend(this.state.form, {
        content: e.target.getContent()
      })
    }, () => {
      this.handleChangeMilestone(this.state.form)
    });
  }

  handleChangeMilestone = (data) => {
    this.props.handleChangeMilestone(data);
  }

  getCategoryTree(items, item, arr, depth = 0) {
    if (_.size(items) && _.find(items, item)) {
      if (item.parent_id && depth === 0)
        return;
      arr[0].push(item);
      arr[1].push(depth);
      _.map(items, (it) => {
        if (item.id === it.parent_id) {
          this.getCategoryTree(items, it, arr, depth + 1);
        }
      });
    }
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  handleDeleteClick() {
    // this.props.setCurrentItemId(id);
    this.showDeleteModal();
  }

  handleConfirmDeleteClick(id) {
    this.props.deleteMilestone(id);
    _.delay(() => 250);
    this.hideDeleteModal();
  }

  handleCancelDeleteClick() {
    _.delay(() =>250);
    this.hideDeleteModal();
  }

  handleChangeOrder = (e)=>{
    let selectedOrder = parseInt(e.target.value);
    let curOrder = this.state.form.order;
    this.props.handleChangeOrder(selectedOrder, curOrder);
  }

  render() {
    
    let { showDetailMilestone } = this.state;
    let arr = [[], []];
    _.map(this.props.documents, (item) => {
      this.getCategoryTree(this.props.documents, item, arr);
    });
    let arrDoc =  _.map(arr[0], (document, i)=>{
      return {
        value: document.id,
        label: document.name
      }
    });
    let documentsRender = (
      <SelectSearch 
        options={arrDoc}  
        placeholder={strings.get("App.workflows.milestones.chooseDocument")}
        value={arrDoc.value}
        name="document"
        onChange={this.handleRelatedDocuments}
      />
    );
    // let documentsRender = _.map(arr[0], (document, i) => {
    //   let sub = arr[1][i] ? " ".repeat(Math.round(arr[1][i] * 3 + arr[1][i] * 3)) + "|" + "_" : " ";
    //   return <option className={`depth-${arr[1][i]}`} value={document.id} key={document.id}>
    //     {`${sub} ${document.name}`}
    //   </option>;
    // });

    let selectedDocuments = this.state.form.documents && _.map(this.state.form.documents, (item) => {
      if (typeof (item) !== "object") {
        return false;
      }
      return (
        <div key={`document-${item.id}`}>
          <ActiveLabel
            name={item.name}
            value={item.id}
            onRemove={this.handleDocumentsRemove}
          />
        </div>
      );
    });

    let orderArr = new Array(this.props.sumOrder);
    let orderRender = [...orderArr.keys()].map((item, index)=>{
      return <option className={`order-${index+1}`} value={index+1} key={`order-${index}`}>
        {index+1}
      </option>;
    })
    

    let deleteModalContent = this.props.milestone ? (
      <span>
        <div className="modal-close-btn"
        onClick={this.handleCancelDeleteClick}>
          
          <i
            className="ion-android-cancel clickable"
            
          />
        </div>
        <h2>
          {strings.get("App.deleteModal.message", {
            itemName: this.props.milestone.name
          })}
        </h2>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger"
            onClick={()=>this.handleConfirmDeleteClick(this.props.milestone.id)}
          >
            {strings.get("App.deleteModal.delete")}
          </button>
          <div
            className="btn btn-lg btn-default cancel cancel"
            onClick={this.handleCancelDeleteClick}
          >
            {strings.get("App.deleteModal.cancel")}
          </div>
        </div>
      </span>
    ) : null;

    const date = {
      'year': strings.get("App.workflows.milestones.unit.year"),
      'month': strings.get("App.workflows.milestones.unit.month"),
      'week': strings.get("App.workflows.milestones.unit.week"),
      'day': strings.get("App.workflows.milestones.unit.day"),
      'hour': strings.get("App.workflows.milestones.unit.hour"),
    }

    let milestoneItem = (
      <div className="form-group">
        <div className="col-sm-12">
          <div className='ml-0'>

            <div className="col-sm-12 col-md-12 titleDiv" onClick={() => this.setState({ showDetailMilestone: !this.state.showDetailMilestone })}>

              <div className="title-name">
                <div className="arrowLeft">
                  <i className="ion-android-exit" />
                  {strings.get("App.workflows.milestones.title")} {this.props.index + 1}
                </div>
                <div className="arrowLeft buttonDelete" onClick={this.handleDeleteClick}>
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </div>
              </div>

              <div className="col-sm-1 col-md-1 col-xs-1 arrowDiv">
                {
                  showDetailMilestone ? (
                    <i className="fa fa-arrow-down arrow-icon" aria-hidden="true" ></i>
                  ) : (
                      <i className="fa fa-arrow-up arrow-icon" aria-hidden="true" ></i>
                    )
                }
              </div>
              
            </div>
          </div>
          {/* {selectedCategories} */}
          {showDetailMilestone ?
            (
              <div>
                <div className="col-sm-6">
                  <div className="mb-10">
                    <label className="control-label mb-10">
                      <b>{strings.get("App.workflows.milestones.name")}</b>
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={this.state.form.name}
                      onChange={this.handleInputChange}
                    >
                    </input>
                  </div>
                  <div className="mb-10">
                    <label className="control-label mb-10">
                      <b>{strings.get("App.workflows.milestones.documents")}</b>
                    </label>
                    {documentsRender}
                    {/* <select
                      className="form-control"
                      name="document"
                      value=""
                      onChange={this.handleRelatedDocuments}
                    >
                      <option value="" disabled>{strings.get('App.glossaries.glossaryForm.choose')}</option>
                      {documentsRender}
                    </select> */}
                  </div>
                  <div className="col-sm-6 documents-selected" style={{ display: 'inline-flex', marginTop: '10px', flexFlow: 'wrap' }}>{selectedDocuments}</div>

                  <div className="mb-10">
                    <label className="control-label mb-10">
                      <b>{strings.get("App.workflows.milestones.setTime")}</b>
                    </label>
                    <br />
                    <div className="flex-title">
                      {
                        ["year", "month", "week", "day", "hour"].map(key => (
                          <input
                            type="number"
                            className="form-control set-time"
                            name="duration"
                            value={this.state.form.duration && this.state.form.duration[key]}
                            onChange={this.handleInputChange}
                            placeholder={date[key]}
                            data-key={key}
                            key={key}
                          />

                        ))
                      }
                    </div>
                  </div>
                  <div className="mb-10 ChangOrder">
                    <button 
                      type="button" 
                      className="btn btn-info btn-change setOrder"
                      onClick={() => this.setState({ setOrder: !this.state.setOrder })}
                    >
                      {strings.get("App.workflows.milestones.setOrder")}
                    </button>
                    {
                      this.state.setOrder?(
                        <select
                          className="form-control chooseOder"
                          name="changeOder"
                          onChange = {this.handleChangeOrder}
                        >
                          <option value="">{strings.get('App.glossaries.glossaryForm.choose')}</option>
                          {orderRender}
                        </select>
                      ):""
                    }
                    
                  </div>
                </div>

                <div className="col-sm-6">
                  <div>
                    <label className="control-label mb-10">{strings.get("App.workflows.milestones.content")}</label>
                    <Editor
                      name="content"
                      content={this.state.form.content}
                      handleChange={this.handleEditorChangeContent}
                      height={115}
                    />
                  </div>
                  <div>
                    <label className="control-label mb-10">{strings.get("App.workflows.milestones.helperText")}</label>
                    <Editor
                      name="helperText"
                      content={this.state.form.helperText}
                      handleChange={this.handleEditorChange}
                      height={115}
                    />
                  </div>
                  <div>
                    <label className="control-label mb-10">
                      <b>{strings.get("App.workflows.milestones.helperLink")}</b>
                    </label>
                    <input
                      type="text"
                      name="helperLink"
                      className="form-control"
                      value={this.state.form.helperLink}
                      onChange={this.handleInputChange}
                    >
                    </input>
                  </div>
                </div>

              </div>
            ) : ""
          }
        </div>
      </div>
    );
    //render return
    return (
      <div className="row">
        <div className="col-sm-12">
          <Modal className="boron-modal" ref="deleteModal">
            {deleteModalContent}
          </Modal>
          {milestoneItem}
        </div>
      </div>
    );
  }
}

export default Milestone;
