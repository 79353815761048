
import api from '../../services/api';
import language from '../../services/language';
import _ from 'lodash';

import * as exceptionsActions from '../exceptions/actions';
import * as downloadsSelectors from './selectors';

export const types = {
  SET_CURRENT_USER_ID: 'downloads.SET_CURRENT_USER_ID',
  CLEAR_CACHE: 'downloads.CLEAR_CACHE',
  FETCH_ALL_DOWNLOADS_DONE : "downloads.FETCH_ALL_DOWNLOADS_DONE",
  SET_CURRENT_PAGE: 'downloads.SET_CURRENT_PAGE',
  SET_CURRENT_ITEM_ID: 'downloads.SET_CURRENT_ITEM_ID',
  SET_SORTER: 'downloads.SET_SORTER',
  SET_CATEGORY_ID: 'downloads.SET_CATEGORY_ID',
};

export function clearCache() {
	return {
		type: types.CLEAR_CACHE
	}
}

export function setCurrentUserId(id) {
  return {
    type: types.SET_CURRENT_USER_ID,
    payload: {
      id
    }
  }
}

export function unsetCurrentUserId() {
  return {
    type: types.SET_CURRENT_USER_ID,
    payload: {
      id: null
    }
  }
}

export function setCurrentItemId(id) {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id
    }
  }
}

export function unsetCurrentItemId() {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id: null
    }
  }
}

export function fetchAllDownloads(deleteCache = false) {
	return async (dispatch, getState) => {
		let state = getState();
		try {
			let params = new Map();
			let filters = downloadsSelectors.getFilters(state);
			let pagination = downloadsSelectors.getPagination(state);
			let currentUsetId = downloadsSelectors.getCurrentUserId(state);
			let sorter = downloadsSelectors.getSorter(state);

			params.set('expand', 'document,user,reminder');
			if(filters.categoryId)
				params.set('category_id',filters.categoryId);
			if(filters.searchTerm)
				params.set('name',filters.searchTerm);
			if(currentUsetId)
				params.set('user_id',currentUsetId);

			params.set('page_size', sorter.pageLimit);
			params.set('page_number', deleteCache ? 1 : pagination.currentPage);
      		params.set('sort_by', sorter.column);
			params.set('sort_desc', sorter.descending);
      		params.set('key', 'sorter');

			// GET request from API
			let [response, items] = await api.get(`/downloads`, params, true);

			// Clear cache if deleteCache is enabled
			if (deleteCache) {
				dispatch(clearCache());
			}

			dispatch({
				type: types.FETCH_ALL_DOWNLOADS_DONE,
				payload: {
					totalPages: parseInt(response.headers.get('X-Total-Pages')),
					items
				}
			})
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function setCurrentPage(page) {
	return {
		type: types.SET_CURRENT_PAGE,
		payload: {
			page
		}
	}
}

export function updateItemReminder(id, data) {
	return async (dispatch) => {
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// PUT request to API
			await api.post(`/downloads/${id}/setreminder`, params);
			// browserHistory.push(`/documents/${id}`);

			dispatch(exceptionsActions.clear());
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function setSorter(sorter) {
	return {
	  type: types.SET_SORTER,
	  payload: {
		sorter,
	  },
	};
  }

export function setCategoryId(id) {
    return {
        type: types.SET_CATEGORY_ID,
        payload: {
            id
        }
    }
}
