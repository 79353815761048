import React from "react";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import { browserHistory } from "../../helpers";

export default function SettingsTabs(props) {
  const getLinkClassName = (linkName) => {
    let path = window.location.pathname.split("/");
    return path[2] === linkName ? "active" : "";
  };

  //NavItem href is not working, So onClick props is using in NavItem.
  const navItemClick = (link) => {
    browserHistory.push(link);
  };

  return (
    <span className="SettingsTabs">
      <Navbar collapseOnSelect>
        <span className="visible-xs navbar-header">
          <Navbar.Brand>
            <Link to="/settings/translations">
              {strings.get("App.settings.title")}
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </span>
        <Navbar.Collapse>
          <Nav className="nav nav-tabs">
            <Nav.Link
              eventkey={1}
              href="/settings/translations"
              className={getLinkClassName("translations")}
              onClick={() => navItemClick("/settings/translations")}
            >
              {strings.get("App.settings.multiLanguage")}
            </Nav.Link>
            <Nav.Link
              eventkey={2}
              href="/settings/languages"
              className={getLinkClassName("languages")}
              onClick={() => navItemClick("/settings/languages")}
            >
              {strings.get("App.settings.languages.title")}
            </Nav.Link>
            <Nav.Link
              eventkey={3}
              href="/settings/searchPlaceholder"
              className={getLinkClassName("searchPlaceholder")}
              onClick={() => navItemClick("/settings/searchPlaceholder")}
            >
              {strings.get("App.settings.messages")}
            </Nav.Link>

            <Nav.Link
              eventkey={5}
              href="/settings/smtpSetting"
              className={getLinkClassName("smtpSetting")}
              onClick={() => navItemClick("/settings/smtpSetting")}
            >
              {strings.get("App.settings.smtpSetting.tabName")}
            </Nav.Link>

            <Nav.Link
              eventkey={7}
              href="/settings/contact"
              className={getLinkClassName("contact")}
              onClick={() => navItemClick("/settings/contact")}
            >
              {strings.get("App.settings.contactSetting.title")}
            </Nav.Link>
            <Nav.Link
              eventkey={8}
              href="/settings/maintenance"
              className={getLinkClassName("maintenance")}
              onClick={() => navItemClick("/settings/maintenance")}
            >
              {strings.get("App.settings.maintenanceSetting.title")}
            </Nav.Link>
            <Nav.Link
              eventkey={8}
              href="/settings/apiSettings"
              className={getLinkClassName("apiSettings")}
              onClick={() => navItemClick("/settings/apiSettings")}
            >
              {strings.get("App.settings.apiSettings.title")}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </span>
  );
}
