import React from "react";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import _ from "lodash";
import "./SettingsTabs.scss";

export default function SmtpSettingsLeftTabs(props) {
  const getMenuItems = (primaryId) => {
    let itemsContent = [
      [strings.get("App.settings.smtpSetting.title"), `/settings/smtpSetting`],
      [strings.get("App.ipAddressForm.title"), `/settings/ipAddresses`],
      [
        strings.get("App.settings.gdpr-googleAnalytics.title"),
        `/settings/cookieMessage`,
      ],
    ];
    let items = _.map(itemsContent, (item, i) => (
      <Link
        className={
          i === primaryId
            ? "btn btn-primary right pull-left btn-color-change"
            : "btn btn-default right pull-left btn-color-change"
        }
        to={`${item[1]}`}
        key={i}
      >
        {item[0]}
      </Link>
    ));
    return items;
  };

  return (
    <div className="SettingsTabs col-xs-12 col-sm-3 col-md-3">
      <div className="left-menu">{getMenuItems(props.currentItemId)}</div>
    </div>
  );
}
