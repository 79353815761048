import React from "react";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import _ from "lodash";
import "./SettingsTabs.scss";

export default function SettingsLeftTabs(props) {
  const getMenuItems = (primaryId) => {
    let itemsContent = [
      [
        strings.get("App.settings.documents.title"),
        `/settings/searchPlaceholder`,
      ],
      [strings.get("App.settings.downloadEmail.title"), `/settings/email`],
      [strings.get("App.settings.shareEmail.title"), `/settings/shareEmail`],
      [
        strings.get("App.settings.reminderEmail.downloadTitle"),
        `/settings/downloadReminderEmail`,
      ],
      [
        strings.get("App.settings.reminderEmail.draftTitle"),
        `/settings/draftReminderEmail`,
      ],
      [
        strings.get("App.settings.shareConceptEmail.title"),
        `/settings/shareConceptEmailPage`,
      ],
      [
        strings.get("App.settings.shareInstructionMessage.title"),
        `/settings/shareMessage`,
      ],
      [
        strings.get("App.settings.welcomeEmail.userWelcome"),
        `/settings/userWelcomeEmail`,
      ],
      [
        strings.get("App.settings.welcomeEmail.userWelcomeAdmin"),
        `/settings/userWelcomeAdminEmail`,
      ],
      [
        strings.get("App.settings.reminderEmail.milestoneEmail"),
        `/settings/milestoneExpireEmail`,
      ],
      [
        strings.get("App.settings.templateNotes.title"),
        `/settings/templateNotes`,
      ],
      [
        strings.get("App.settings.emailVerify"),
        `/settings/userEmailVerification`,
      ],
      [
        strings.get("App.settings.otpEmail"),
        `/settings/otpEmail`,
      ],
      // nned to add setting once strings.json ftp was received
      [
        strings.get("App.ForgotPasswordMail.title"),
        `/settings/forgotPasswordEmail`,
      ]
    ];
    let items = _.map(itemsContent, (item, i) => (
      <Link
        className={
          i === primaryId
            ? "btn btn-primary right pull-left btn-color-change"
            : "btn btn-default right pull-left btn-color-change"
        }
        to={`${item[1]}`}
        key={i}
      >
        {item[0]}
      </Link>
    ));
    return items;
  };

  return (
    <div className="SettingsTabs col-xs-12 col-sm-3 col-md-3">
      <div className="left-menu">{getMenuItems(props.currentItemId)}</div>
    </div>
  );
}
