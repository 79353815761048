import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as glossariesActions from "../../store/glossaries/actions";
import * as glossariesSelectors from "../../store/glossaries/selectors";
import * as stepsSelectors from "../../store/steps/selectors";
import * as stepsActions from "../../store/steps/actions";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
// import SubTopbar from '../../components/SubTopbar';
import SearchBar from "../../components/SearchBar";
import GlossaryList from "../../components/glossary/GlossaryList";
import Pagination from "../../components/Pagination";
// import CsvDownloader from 'react-csv-downloader';
import _ from "lodash";

class GlossaryListPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  componentDidMount() {
    this.props.fetchGlossariesExpandItems();
    if (!_.size(this.props.steps)) {
      this.props.fetchAllSteps();
    }
    // this.props.fetchAllGlossaries();
  }

  render() {
    // let exportDatas = [];
    // _.map(this.props.allGlossaries, (value, key) => {
    //     if(_.size(value.categories)) {
    //         _.map(value.categories, (cat, k) => {
    //             exportDatas.push({
    //                 'glossaries.ID': value.id,
    //                 'Category': cat.name,
    //                 'Glossary': value.name
    //             });
    //         });
    //     }else{
    //         exportDatas.push({
    //             'glossaries.ID': value.id,
    //             'Category': '',
    //             'Glossary': value.name
    //         });
    //     }
    // });

    return (
      <div className="GlossaryListPage">
        <Topbar>
          <div className="title">
            <Link to="/glossaries">
              {strings.get("App.glossaryPage.title")}
            </Link>
          </div>
          <SearchBar
            placeholder={strings.get("App.searchPlaceholders.glossary")}
            searchTerm={this.props.filters.searchTerm}
            fetchItems={this.props.fetchGlossariesExpandItems}
            setSearchTerm={this.props.setSearchTerm}
          />
          <div className="main-btns">
            {/*<div className="link-button">
                            <CsvDownloader datas={exportDatas} filename="export-glossaries" suffix={true}>
                                <button><span>{strings.get('App.glossaryPages.exportGlossary')}</span> <i className="ion-ios-cloud-upload"></i></button>
                            </CsvDownloader>
                        </div>
                        <Link to="/glossaries/import" className="btn btn-primary"><span>{strings.get('App.glossaryPages.importGlossary')}</span> <i className="ion-ios-cloud-download"></i></Link>
                        */}
            <Link to="/glossaries/steps" className="btn btn-default btn-color-change">
              <span>{strings.get("App.glossaryPages.manage")}</span>{" "}
              <i className="ion-ios-skipforward"></i>
            </Link>
            <Link to="/glossaries/add" className="btn btn-primary">
              <span>{strings.get("App.glossaryPages.addGlossary")}</span>{" "}
              <i className="ion-plus"></i>
            </Link>
          </div>
        </Topbar>

        <div className="content">
          <GlossaryList
            items={this.props.glossaries}
            sorter={this.props.sorter}
            currentItem={this.props.currentGlossary}
            fetchItems={this.props.fetchGlossariesExpandItems}
            setCurrentItemId={this.props.setCurrentGlossaryId}
            unsetCurrentItemId={this.props.unsetCurrentGlossaryId}
            deleteItem={this.props.deleteGlossary}
            toggleSorter={this.props.toggleSorter}
          />

          <Pagination
            pagination={this.props.pagination}
            setCurrentPage={this.props.setCurrentPage}
            fetchItems={this.props.fetchGlossaries}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    glossaries: glossariesSelectors.getItemsByPage(
      state,
      glossariesSelectors.getPagination(state).currentPage
    ),
    sorter: glossariesSelectors.getSorter(state),
    filters: glossariesSelectors.getFilters(state),
    pagination: glossariesSelectors.getPagination(state),
    currentGlossary: glossariesSelectors.getCurrentItem(state),
    steps: stepsSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
    // allGlossaries: glossariesSelectors.getItems(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // fetchAllGlossaries: (deleteCache) => {
    //     dispatch(glossariesActions.fetchAllItems(deleteCache))
    // },
    fetchGlossaries: (deleteCache) => {
      dispatch(glossariesActions.fetchItems(deleteCache));
    },
    fetchAllSteps: (deleteCache) => {
      dispatch(stepsActions.fetchAllItems(deleteCache));
    },
    fetchGlossariesExpandItems: (deleteCache) => {
      dispatch(glossariesActions.fetchItems(deleteCache, 0));
    },
    setSearchTerm: (searchTerm) => {
      dispatch(glossariesActions.setSearchTerm(searchTerm));
    },
    toggleSorter: (searchTerm) => {
      dispatch(glossariesActions.toggleSorter(searchTerm));
    },
    setCurrentPage: (page) => {
      dispatch(glossariesActions.setCurrentPage(page));
    },
    setCurrentGlossaryId: (id) => {
      dispatch(glossariesActions.setCurrentItemId(id));
    },
    unsetCurrentGlossaryId: () => {
      dispatch(glossariesActions.unsetCurrentItemId());
    },
    deleteGlossary: (id, handleGloDeleteResponse) => {
      dispatch(glossariesActions.deleteItem(id, handleGloDeleteResponse));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GlossaryListPage);
