/**
 * Created by Admin on 7/25/2017.
 */
import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as blockedUsersActions from "../../store/blockedUsers/actions";
import * as UsersActions from "../../store/users/actions";
import * as blockedUsersSelectors from "../../store/blockedUsers/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
import SearchBar from "../../components/SearchBar";
import BlockedUserList from "../../components/blockedUsers/BlockedUserList";
import BlockedUserSorter from "../../components/blockedUsers/BlockedUserSorter";
import Pagination from "../../components/Pagination";

class BlockedUsersListPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchBlockedUsers();
  }

  componentWillUnmount() {
    this.props.unsetCurrentUserId();
    this.props.clearExceptions();
  }

  render() {
    return (
      <div className="UserListPage">
        <Topbar>
          <div className="title">
            <Link to="/users">
              {strings.get("App.blockedUsers.listPage.title")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/users/blockedUsers">
                {strings.get("App.blockedUsers.listPage.blockedUsers")}
              </Link>
            </span>
          </div>
          <SearchBar
            placeholder={strings.get("App.searchPlaceholders.blockedUser")}
            searchTerm={this.props.filters.searchTerm}
            fetchItems={this.props.fetchBlockedUsers}
            setSearchTerm={this.props.setSearchTerm}
          />
        </Topbar>

        <div className="content">
          <div className="row">
            <div className="col-xs-12">
              <BlockedUserSorter
                sorter={this.props.sorter}
                fetchItems={this.props.fetchBlockedUsers}
                setSorter={this.props.setSorter}
              />
            </div>

            <BlockedUserList
              currentItem={this.props.currentItem}
              setCurrentItemId={this.props.setCurrentUserId}
              unsetCurrentItemId={this.props.unsetCurrentUserId}
              deleteItem={this.props.deleteUser}
              unBlockItem={this.props.unBlockUser}
              items={this.props.blockedUsers}
              fetchItems={this.props.fetchBlockedUsers}
            />

            <Pagination
              pagination={this.props.pagination}
              setCurrentPage={this.props.setCurrentPage}
              fetchItems={this.props.fetchBlockedUsers}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    blockedUsers: blockedUsersSelectors.getItemsByPage(
      state,
      blockedUsersSelectors.getPagination(state).currentPage
    ),
    sorter: blockedUsersSelectors.getSorter(state),
    filters: blockedUsersSelectors.getFilters(state),
    pagination: blockedUsersSelectors.getPagination(state),
    currentItem: blockedUsersSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchBlockedUsers: (deleteCache) => {
      dispatch(blockedUsersActions.fetchItems(deleteCache));
    },
    setSearchTerm: (searchTerm) => {
      dispatch(blockedUsersActions.setSearchTerm(searchTerm));
    },
    setCurrentPage: (page) => {
      dispatch(blockedUsersActions.setCurrentPage(page));
    },
    fetchAllUsers: (deleteCache) => {
      dispatch(blockedUsersActions.fetchAllItems(deleteCache));
    },
    setCurrentUserId: (id) => {
      dispatch(blockedUsersActions.setCurrentItemId(id));
    },
    setSorter: (sorter) => {
      dispatch(blockedUsersActions.setSorter(sorter));
    },
    fetchUser: (id) => {
      dispatch(blockedUsersActions.fetchItem(id));
    },
    unsetCurrentUserId: () => {
      dispatch(blockedUsersActions.unsetCurrentItemId());
    },
    updateUser: (id, data) => {
      dispatch(blockedUsersActions.updateItem(id, data));
    },
    deleteUser: (id, type) => {
      dispatch(UsersActions.deleteItem(id, type));
    },
    unBlockUser: (id) => {
      dispatch(blockedUsersActions.unBlockItem(id));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockedUsersListPage);
