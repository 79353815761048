import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import { Link } from "react-router-dom";
import _ from "lodash";
import "../settings.scss";
import "../../Page.scss";

import * as translationsActions from "../../../store/translations/actions";
import * as translationsSelectors from "../../../store/translations/selectors";
import * as glossariesActions from "../../../store/glossaries/actions";
import * as glossariesSelectors from "../../../store/glossaries/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";

import Topbar from "../../../components/Topbar";
import SubTopbar from "../../../components/SubTopbar";
import SearchBar from "../../../components/SearchBar";
import SettingsTabs from "../../../components/settings/SettingsTabs";
import TranslationView from "../../../components/settings/translations/TranslationView";

class TranslationViewPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchTranslation(this.props.match.params.id);
    this.props.setCurrentTranslationId(this.props.match.params.id);
    this.props.fetchAllGlossaries();
  }

  componentWillUnmount() {
    this.props.unsetCurrentTranslationId();
  }

  render() {
    return (
      <div className="TranslationViewPage">
        <Topbar>
          <div className="title">
            <Link to="/settings">{strings.get("App.settings.title")}</Link>
          </div>
        </Topbar>

        <div className="content settings-container">
          <SubTopbar>
            <SettingsTabs />
          </SubTopbar>
          <div className="setting-content">
            <TranslationView
              currentItem={this.props.currentTranslation}
              glossaries={this.props.glossaries}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentTranslation: translationsSelectors.getCurrentItem(state),
    glossaries: glossariesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllGlossaries: () => {
      dispatch(glossariesActions.fetchAllItems());
    },
    fetchTranslation: (id) => {
      dispatch(translationsActions.fetchItem(id));
    },
    setCurrentTranslationId: (id) => {
      dispatch(translationsActions.setCurrentItemId(id));
    },
    unsetCurrentTranslationId: () => {
      dispatch(translationsActions.unsetCurrentItemId());
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationViewPage);
