import _ from 'lodash';

export function getFilters(state) {
	return state.downloads.filters;
}

export function getPagination(state) {
	return state.downloads.pagination;
}

export function getItemsByPage(state, page) {
	if (!state.downloads.idsByPage['_' + page]) {
		page = (getPagination(state)).previousPage;
	};
	return _.map(state.downloads.idsByPage['_' + page], (itemId) => {
		return state.downloads.itemsById['_' + itemId]
	});
}

export function getCurrentUserId(state){
  return state.downloads.currentUserId;
}

export function getItemById(state, id) {
	return state.downloads.itemsById['_' + id];
}

export function getCurrentItem(state) {
	return state.downloads.currentItemId ? getItemById(state, state.downloads.currentItemId) : null;
}

export function getSorter(state) {
	return state.downloads.sorter;
}
