import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import PropTypes from "prop-types";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import _ from "lodash";

import FadeModal from "reboron/FadeModal";
import DropModal from "reboron/DropModal";
import ActiveLabel from "../ActiveLabel";
import Editor from "../Editor";

import ShowMoreText from "react-show-more-text";
import Select from "react-select";
import { FixedSizeList as List } from "react-window";
import "./GlossaryFormSelectors.scss";

let componentInstance = null;

const SortableDragger = SortableHandle(() => {
  return <div className="Showcase_style_handle" />;
});

const SortableTableRow = SortableElement(({ value, prefix = "" }) => {
  let description = _.isString(value.description)
    ? value.description
    : value.description.default
    ? value.description.default
    : "";
  let name = value.name.substr(value.name.indexOf("_") + 1);
  // let name = value.name;
  return (
    <tr>
      <td>
        <SortableDragger />
      </td>
      <td>select_{value.id}</td>
      {/* <td>{prefix === "" ? value.name : `${prefix}_${name}`}</td> */}
      <td>{value.name}</td>
      <td>{value.placeholderField}</td>
      <td>
        <ShowMoreText
          lines={2}
          more={strings.get("App.glossaries.glossaryForm.showMore")}
          less={strings.get("App.glossaries.glossaryForm.showLess")}
          anchorClass=""
        >
          {componentInstance.buildLabel(description)}
        </ShowMoreText>
      </td>
      {/* <td>
        <ShowMoreText
          lines={2}
          more={strings.get("App.glossaries.glossaryForm.showMore")}
          less={strings.get("App.glossaries.glossaryForm.showLess")}
          anchorClass=""
        >
          <span dangerouslySetInnerHTML={{ __html: value.helperText }} />
        </ShowMoreText>
      </td>
      <td>{value.helperLink}</td> */}
      <td>
        <i
          className="btn btn-default edit-btn ion-edit"
          onClick={() => componentInstance.handleItemClick(value.index)}
        />
        &nbsp;&nbsp;&nbsp;
        <i
          className="btn btn-default delete-btn ion-trash-b"
          onClick={() =>
            componentInstance.handleItemRemove(value.index, value.id)
          }
        />
        &nbsp;&nbsp;&nbsp;
        <i
          className="btn btn-default ion-eye"
          onClick={() => componentInstance.handleItemDocumentList(value.id)}
        />
      </td>
    </tr>
  );
});

const SortableTable = SortableContainer(({ items, prefix }) => {
  let rows = _.map(items, (value) => {
    return (
      <SortableTableRow
        key={`item-${value.index}`}
        index={value.index}
        value={value}
        prefix={prefix}
      />
    );
  });

  return (
    <table className="table table-bordered table-list">
      <thead>
        <tr>
          <th>{strings.get("App.glossaries.glossaryForm.order")}</th>
          <th>Id</th>
          <th>{strings.get("App.glossaries.glossaryForm.selectors.name")}</th>
          <th>
            {strings.get(
              "App.glossaries.glossaryForm.selectors.placeholderField"
            )}
          </th>
          <th>
            {strings.get("App.glossaries.glossaryForm.selectors.description")}
          </th>
          {/* <th>
            {strings.get("App.glossaries.glossaryForm.selectors.helperText")}
          </th>
          <th>
            {strings.get("App.glossaries.glossaryForm.selectors.helperLink")}
          </th> */}
          <th>{strings.get("General.action")}</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
});

const height = 30;

class MenuList extends Component {
  render() {
    const { children, maxHeight } = this.props;
    // const [value] = getValue();
    // const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        // initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

class GlossaryFormSelectors extends Component {
  state = {
    itemsLoaded: false,
    currentItemKey: -1,
    currentItem: null,
    errors: {},
    tmpItem: {
      stepId: -1,
      name: "",
      description: [],
      helperText: "",
      helperLink: "",
      isRequired: 0,
      warningText: "",
      warningTextRwdo: "",
      placeholderField: "",
      relatedFields: [],
      selectOption: [],
    },
    items: [],
    warningClass: "form-group",
    warningRwdoClass: "form-group hidden",
    articlesName: [],
    documentsByCode: [],
    code: "",
    deleteError: false,
    visibleList: [],
    visibleStepsLoading: false,
    visibleSteps: {},
    codeSearchTerm: "",
    selectedCategories: [],
    stepsOptions: [],
  };

  componentDidMount() {
    this.tryLoadItems();
  }

  componentDidUpdate(prevProps, prevState) {
    this.tryLoadItems();
    if (
      _.size(this.props.documentsByCode) > 0 &&
      _.size(this.props.documentsByCode) !== _.size(prevProps.documentsByCode)
    ) {
      if (!this.state.documentsByCodeLoaded) {
        let documentsByCode = [];
        _.forEach(this.props.documentsByCode, function (value, key) {
          documentsByCode.push(value);
        });
        this.setState({
          documentsByCodeLoaded: true,
          documentsByCode: documentsByCode,
        });
      }
    }

    //Form validation scroll concept.
    if (_.size(this.state.errors)) {
      let el = document.getElementsByClassName("has-error");
      if (_.size(el)) {
        el[0].scrollIntoView({ behavior: "smooth" });
      }
    }
    if (_.size(prevProps.steps) && !this.state.visibleStepsLoading) {
      this.getCurrentSteps(prevProps);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.size(nextProps.steps) && !this.state.visibleStepsLoading) {
      this.getCurrentSteps(nextProps);
    }
    if(!_.isEqual(_.sortBy(nextProps.items), _.sortBy(this.state.items)) || 
    !_.isEqual(_.sortBy(nextProps.activeItems), _.sortBy(this.state.activeItems))) {

      this.setState({
        items: nextProps.items,
        activeItems: nextProps.activeItems
      });
      
      if(this.props.activeItems.id) {
        const activeIndex = _.findIndex(this.props.items,(val)=>val.id == this.props.activeItems.id);
      _.delay(()=>{
        this.handleItemClick(activeIndex);
      },2000);
    }
    }
  }

  constructor(props) {
    super(props);
    autoBind(this);
    componentInstance = this;
  }

  tryLoadItems() {
    if (_.size(this.props.items) && !this.state.itemsLoaded) {
      this.setState(
        {
          itemsLoaded: true,
          items: this.props.items,
        },
        () => {
          this.getCurrentSteps(this.props);
        }
      );
      if(this.props.activeItems.id) {
      const activeIndex = _.findIndex(this.props.items,(val)=>val.id == this.props.activeItems.id);
      _.delay(()=>{
        this.handleItemClick(activeIndex);
      },2000)
    }

      
    } else if (
      !this.state.tmpItem.name &&
      this.props.currentItem &&
      this.props.currentItem.type === "selector"
    ) {
      let tmpItem = _.extend({}, this.state.tmpItem);
      tmpItem.name = this.props.currentItem.name;
      if (tmpItem.isRequired === "1") {
        this.setState({ warningClass: "form-group" });
      } else {
        this.setState({ warningClass: "form-group hidden" });
      }
      if (tmpItem.isRequired === "2") {
        this.setState({ warningRwdoClass: "form-group" });
      } else {
        this.setState({ warningRwdoClass: "form-group hidden" });
      }
      this.setState({
        tmpItem,
      });
      // } else if (
      //   _.size(this.state.items) &&
      //   _.size(this.state.items) &&
      //   this.state.items !== this.state.items
      // ) {
      //   this.setState({ items: this.state.items },()=>{this.getCurrentSteps(this.props)});
    } else if (
      _.size(this.state.items) === 0 &&
      this.props.items &&
      _.size(this.props.items) &&
      this.state.itemsLoaded
    ) {
      this.setState({ itemsLoaded: false, items: this.props.items }, () => {
        this.getCurrentSteps(this.props);
      });
    }

    if (_.size(this.props.articles) && !this.state.articlesLoaded) {
      let arN = this.state.articlesName;
      _.forEach(this.props.articles, function (value, key) {
        arN.push({
          value: value.articleURL,
          label: value.name,
        });
      });
      this.setState({
        articlesLoaded: true,
        articlesName: arN,
      });
    }
  }

  getCurrentSteps(props = this.props) {
    let { visibleSteps, visibleStepsLoading } = this.state;
    if (_.size(props.steps)) {
      let newItems = this.state.items;
      let y = [];
      newItems.map((item, index) => {
        if (!y[item["stepId"] - 1]) y[item["stepId"] - 1] = [];
        item.index = index;
        y[item["stepId"] - 1].push(item);
      });
      newItems = [];
      y.map((item) => {
        if (typeof item !== "undefined") newItems.push(item);
      });
      newItems.map((items, i) => {
        let stepOrder =
          _.size(props.steps) && props.steps["_" + items[0].stepId]
            ? props.steps["_" + items[0].stepId].order
            : null;
        items.stepOrder = stepOrder;
      });
      newItems = _.orderBy(newItems, ["stepOrder"], ["asc"]);
      newItems.map((item, i) => {
        visibleSteps["item-" + "selector-" + i] = item;
      });
      this.setState({
        visibleSteps,
        visibleStepsLoading: true,
      });
    }
  }

  validate() {
    let item = this.state.tmpItem;
    let duplicate = this.isDuplicated(item);
    let errors = {};

    if (item.name === "") {
      errors["name"] = strings.get("Exceptions.required");
    } else if (!/^[a-z0-9_]+$/.test(item.name)) {
      errors["name"] = strings.get("Exceptions.onlyLowercase");
    }

    if (!_.isObject(item.description)) {
      errors["description"] = strings.get("Exceptions.required");
    } else if (
      item.description.length >= 1 &&
      item.description.default &&
      item.description.default === ""
    ) {
      errors["description"] = strings.get("Exceptions.required");
    }

    if (item.stepId === -1) {
      errors["stepId"] = strings.get("Exceptions.required");
    }

    if (duplicate) {
      if (_.isObject(duplicate)) {
        errors["name"] = strings.get("Exceptions.glossaryDuplicate", {
          itemName: duplicate.name,
        });
      } else {
        errors["name"] = strings.get("Exceptions.glossaryDuplicate");
      }
    }

    if (_.size(errors)) {
      this.setState({ errors });
      return false;
    }

    return true;
  }

  isDuplicated(item) {
    let duplicate;
    let currentGlossary = this.props.currentGlossary;

    // If item has been edited and name didn't change is not duplicate
    if (this.state.currentItem && item.name === this.state.currentItem.name) {
      return null;
    }

    // Check duplicates in other glossaries
    _.each(this.props.glossaries, (glossary) => {
      // Skip current glossary
      if (!currentGlossary || currentGlossary.id !== glossary.id) {
        let value = JSON.parse(glossary.value);

        _.each(value.selectors, (selector) => {
          if (selector.name === item.name) {
            duplicate = glossary;
          }
        });
      }
    });

    // Check duplicates into current glossary
    _.each(this.state.items, (selector) => {
      if (selector.name === item.name) {
        duplicate = true;
      }
    });

    return duplicate;
  }

  listSelectors() {
    let currentGlossary = this.props.currentGlossary;

    let selectors = [];

    _.each(this.props.glossaries, (glossary) => {
      // Skip current glossary
      if (
        (!currentGlossary || currentGlossary.id !== glossary.id) &&
        glossary.value
      ) {
        let value = JSON.parse(glossary.value);

        _.each(value.selectors, (selector) => {
          selectors.push({
            value: `selectors_${selector.id}`,
            label: selector.name,
          });
        });
      }
    });
    return selectors;
  }

  listObj() {
    let currentGlossary = this.props.currentGlossary;

    let obj = {};
    _.each(this.props.glossaries, (glossary) => {
      // Skip current glossary
      if (
        (!currentGlossary || currentGlossary.id !== glossary.id) &&
        glossary.value
      ) {
        let value = JSON.parse(glossary.value);

        _.each(value.selectors, (selector) => {
          obj[`selectors_${selector.id}`] = selector.name;
        });
      }
    });

    return obj;
  }

  hasError(inputName) {
    return !!this.state.errors[inputName];
  }

  getErrorClass(inputName, defaultClasses = "") {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  }

  getErrorMessage(inputName) {
    return this.state.errors[inputName];
  }

  showFormModal() {
    this.refs.formModal.show();
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideFormModal() {
    this.refs.formModal.hide();
    if(this.props.activeItems.id)  window.close();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  processItems(item) {
    let description = _.isObject(item.description)
      ? item.description
      : {
          default: item.description,
        };
    if (item.relatedFields) {
      try {
        if (typeof item.relatedFields === "string")
          item.relatedFields = JSON.parse(item.relatedFields);
      } catch (error) {
        item.relatedFields = [];
      }
    }
    return _.extend(item, {
      description,
      selectOption: this.idToLabelConverter(item.relatedFields || []) || [],
    });
  }

  idToLabelConverter = (id = []) => {
    let obj = this.listObj();

    let value = [];

    id.forEach((v, i, a) => {
      obj[a[i]]
        ? value.push({ label: obj[id[i]], value: id[i] })
        : value.push({ label: "", value: "" });
    });

    return value;
  };
  loadAllDocuments(code) {
    if (code !== this.state.code) {
      this.setState({
        documentsByCodeLoaded: false,
        documentsByCode: [],
      });
    }
    this.props.setGlossaryCode(code, "selector");
    this.props.fetchAllDocuments(true);
  }

  handleItemClick(key) {
    // this.loadAllDocuments(code);
    this.showFormModal();
    this.setState(
      {
        currentItemKey: key,
        currentItem: this.processItems(this.state.items[key]||{}),
        tmpItem: this.processItems(this.state.items[key]||{}),
      },
      () => {
        let tmpItemObj = this.state.tmpItem;
        //Old object, doesn't have "isRequired" key,
        //Key, if not exists or false(not 0), create "isRequired" property with value "1"
        if (
          // !tmpItemObj.hasOwnProperty("isRequired") ||
          // !tmpItemObj["isRequired"]
          !tmpItemObj.hasOwnProperty("isRequired")
        ) {
          this.setState({
            tmpItem: _.extend(this.state.tmpItem, {
              isRequired: "0",
            }),
          });
        }
        this.setWarningText();
      }
    );
    // _.delay(() => {
    //   let tmpItemObj = this.state.tmpItem;
    //   //Old object, doesn't have "isRequired" key,
    //   //Key, if not exists or false(not 0), create "isRequired" property with value "1"
    //   if (
    //     !tmpItemObj.hasOwnProperty("isRequired") ||
    //     !tmpItemObj["isRequired"]
    //   ) {
    //     this.setState({
    //       tmpItem: _.extend(this.state.tmpItem, {
    //         isRequired: "1"
    //       })
    //     });
    //   }
    //   this.setWarningText();
    // }, 1000);
  }

  setWarningText() {
    if (this.state.tmpItem) {
      if (this.state.tmpItem.isRequired === "1") {
        this.setState({ warningClass: "form-group" });
      } else {
        this.setState({ warningClass: "form-group hidden" });
      }
      if (this.state.tmpItem.isRequired === "2") {
        this.setState({ warningRwdoClass: "form-group" });
      } else {
        this.setState({ warningRwdoClass: "form-group hidden" });
      }
    }
  }

  handleItemRemove(key, code) {
    this.loadAllDocuments(code);
    _.delay(() => {
      this.showDeleteModal();
    }, 500);
    this.setState({
      currentItemKey: key,
    });
  }

  handleItemDocumentList(code) {
    this.loadAllDocuments(code);
    this.setState({
      code: code,
    });
    _.delay(() => {
      this.showDocumentListModal();
    }, 500);
  }

  showDocumentListModal() {
    this.refs.documentListModal.show();
  }

  hideDocumentListModal() {
    this.refs.documentListModal.hide();
  }

  documentsList() {
    let items = _.map(this.state.documentsByCode, (item) => {
      return (
        <tr key={item.id}>
          <td>
            <a href={`/documents/${item.id}`} target="_blank">
              {item.name}
            </a>
          </td>
          <td>{item.category ? item.category.name : ""}</td>
        </tr>
      );
    });
    return items;
  }

  handleConfirmItemRemove(e) {
    e.preventDefault();
    if (this.state.documentsByCode.length) {
      this.setState({
        deleteError: true,
      });
    } else {
      let index = this.state.currentItemKey;
      let id_selector = this.state.items[index].id;
      if (this.props.currentGlossary) {
        this.props.onDeleteSelector(id_selector);
      }
      let items = _.filter(this.state.items, (item, key) => {
        return key !== this.state.currentItemKey;
      });

      this.props.onChange(items);
      this.hideDeleteModal();
      _.delay(
        () =>
          this.setState({
            currentItemKey: -1,
            items,
          }),
        250
      );
    }
  }

  handleAddClick() {
    if (this.props.currentItem) this.props.addClick();

    this.showFormModal();
  }

  handleSortEnd({ oldIndex, newIndex }) {
    let items = arrayMove(this.state.items, oldIndex, newIndex);
    items.forEach((item, index) => {
      item.codeOrder = index;
    });
    this.props.onChange(items);
    this.setState({ items });
  }

  handleInputChange(e) {
    let item = { ...this.state.tmpItem };
    if (e.target.getAttribute("data-key")) {
      item[e.target.name] = {
        ...item[e.target.name],
        [e.target.getAttribute("data-key")]: e.target.value,
      };
    } else {
      // if (e.target.name === "name" && this.state.documentsByCode.length > 0) {
      //   let {errors} = this.state
      //   errors["name"] = strings.get("App.glossaries.glossaryForm.renameError");
      //   this.setState({errors});
      //   _.delay(() => {
      //     this.setState({errors : {}})
      //   }, 2000);
      // }
      // else{
      item[e.target.name] = e.target.value;
      // }
    }
    this.setState({
      tmpItem: _.extend(this.state.tmpItem, item),
    });
  }

  // handleEditorChange(e) {
  //   this.setState({
  //     tmpItem: _.extend(this.state.tmpItem, {
  //       helperText: e.target.getContent(),
  //     }),
  //   });
  // }

  handleEditorChange(content) {
    this.setState({
      tmpItem: _.extend(this.state.tmpItem, {
        helperText: content,
      }),
    });
  }

  handleCheckboxChange(e) {
    let item = {};
    item[e.target.name] = e.target.checked;
    this.setState({
      tmpItem: _.extend(this.state.tmpItem, item),
    });
  }

  handleRequiredChange(e) {
    let item = {};
    item[e.target.name] = e.target.value;
    this.setState({
      tmpItem: _.extend(this.state.tmpItem, item),
    });

    if (e.target.value === "1") {
      this.setState({ warningClass: "form-group" });
    } else {
      this.setState({ warningClass: "form-group hidden" });
    }
    if (e.target.value === "2") {
      this.setState({ warningRwdoClass: "form-group" });
    } else {
      this.setState({ warningRwdoClass: "form-group hidden" });
    }
  }

  // handleEditorChangeWarning(e) {
  //   this.setState({
  //     tmpItem: _.extend(this.state.tmpItem, {
  //       warningText: e.target.getContent(),
  //     }),
  //   });
  // }

  handleEditorChangeWarning(content) {
    this.setState({
      tmpItem: _.extend(this.state.tmpItem, {
        warningText: content,
      }),
    });
  }

  // handleEditorChangeWarningRwdo(e) {
  //   this.setState({
  //     tmpItem: _.extend(this.state.tmpItem, {
  //       warningTextRwdo: e.target.getContent(),
  //     }),
  //   });
  // }
  handleEditorChangeWarningRwdo(content) {
    this.setState({
      tmpItem: _.extend(this.state.tmpItem, {
        warningTextRwdo: content,
      }),
    });
  }

  handleFormModalHide() {
    this.setState(
      {
        itemsLoaded: false,
        currentItemKey: -1,
        currentItem: null,
        errors: {},
        tmpItem: {
          stepId: -1,
          name: "",
          description: "",
          helperText: "",
          helperLink: "",
          isRequired: 0,
          warningText: "",
          warningTextRwdo: "",
          relatedFields: [],
          selectOption: [],
        },
        items: [],
        warningClass: "form-group",
        warningRwdoClass: "form-group hidden",
        code: "",
        documentsByCode: [],
        selectedCategories: [],
      },
      () => {
        this.handleStepsCategoryWise();
      }
    );
    if(this.props.activeItems.id)  window.close();
  }

  getNameCount = (name) =>{
    let overallCount,currentCount
    let currentGlossary = this.props.currentGlossary;
    _.each(this.props.glossaries, (glossary) => {
      // Skip current glossary
      if (!currentGlossary || currentGlossary.id !== glossary.id) {
        let value = JSON.parse(glossary.value);

        overallCount =  _.filter(value.selectors, (selector) => 
        selector.name.search(name) !== -1
        );
      }
    });
    currentCount =  _.filter(this.state.items, (selector) => 
    selector.name.search(name) !== -1 
    );
    return currentCount.length +  overallCount.length + 1;
  }

  getCopyName = (item) =>{
    if (item.name.search('_copy_') === -1) {
      const count = this.getNameCount(`${item.name}_copy`)
      return `${item.name}_copy_${count}`
    }
    const splitval = item.name.split('_');
    splitval.splice(splitval.length - 1, 1);
    const count = this.getNameCount(splitval.join('_'));
  
    return  `${splitval.join('_')}_${count}`
  }

  async handleSaveClick(e, isCopy) {
    e.preventDefault();
    // Validate temporary item
    if (this.validate() ||( this.state.currentItemKey !== -1 && isCopy )) {
      let items = _.extend([], this.state.items);
      let tmpItem = this.state.tmpItem;
      if (this.state.currentItemKey === -1) {
        // Add item
        tmpItem.id = `${_.uniqueId("temp_")}`;
        items.push(tmpItem);
        // let data = this.state.tmpItem;
        // data.glossary_id = this.props.currentGlossary.id;

        // let resData = await this.props.createSelector({
        //   ...data,
        //   description: JSON.stringify(data.description),
        //   relatedFields: JSON.stringify(data.relatedFields)
        // });
        // items.push(resData);
      } else {
        // Edit item
        if (!isNaN(tmpItem.id)) {
          let selector_id = this.state.currentItem.id;
          let data = tmpItem;
          delete data.index;
          delete data.created_at;
          delete data.deleted_at;
          delete data.updated_at;
          data.copy = !!isCopy;
          if(isCopy){
            data.copy_name = this.getCopyName(tmpItem);
          }
          this.props.onSaveSelector(selector_id, {
            ...data,
            description: JSON.stringify(data.description),
            relatedFields: JSON.stringify(data.relatedFields),
          },
          !!this.props.activeItems.id);
        }
        items[this.state.currentItemKey] = tmpItem;
      }

      if (!this.props.activeItems.id) this.hideFormModal();

      this.props.onChange(items);
    }
  }

  handleCancelClick(e) {
    e.preventDefault();
    this.hideFormModal();
    this.hideDeleteModal();
  }

  hanldeDeleteModalHide() {
    this.setState({
      deleteError: false,
      code: "",
    });
  }

  handleAccordionToggle() {
    if (this.props.currentItem)
      this.refs.hidden.style.display =
        this.refs.hidden.style.display === "none" ? "" : "none";
  }

  buildLabel(Item, item) {
    if (!Item || Item === undefined) return Item;
    let glossariesCodeIdToName = this.props.glossariesCodeIdToName;
    let expandGlossaries = this.props.expandGlossaries;
    // let _glossaries = this.props.glossaries;
    let _label = Item;
    // separate out all selectors and loop through
    let result = strings.getFromBetween.get(_label, "[[", "]]");
    // result.forEach(e => {
    //   const reg = new RegExp(e);
    //   // work out pattern [[*.*_*]] for selects
    //   if (e.indexOf(".") > -1) {
    //     const field = e.split(".")[0];
    //     if(expandGlossaries && _.size(expandGlossaries)){
    //       if(expandGlossaries[field] && expandGlossaries[field].placeholderField){
    //         _label = _label
    //         .replace(reg, expandGlossaries[field].placeholderField)
    //         .replace(/\[\[|\]\]/g, "");
    //       }else if(expandGlossaries[glossariesCodeIdToName[field]] &&
    //           expandGlossaries[glossariesCodeIdToName[field]].placeholderField){
    //         _label = _label
    //         .replace(reg, expandGlossaries[glossariesCodeIdToName[field]].placeholderField)
    //         .replace(/\[\[|\]\]/g, "");
    //       }else {
    //         _label = _label.replace(reg, "").replace(/\[\[|\]\]/g, "");
    //       }
    //     }
    //   } else {
    //     // if field not a selector, then search in all glossaries
    //     if(expandGlossaries && _.size(expandGlossaries)){
    //       if(expandGlossaries[e] && expandGlossaries[e].placeholderField){
    //         _label = _label
    //         .replace(reg, expandGlossaries[e].placeholderField)
    //         .replace(/\[\[|\]\]/g, "");
    //       }else if(expandGlossaries[glossariesCodeIdToName[e]] &&
    //           expandGlossaries[glossariesCodeIdToName[e]].placeholderField){
    //         _label = _label
    //         .replace(reg, expandGlossaries[glossariesCodeIdToName[e]].placeholderField)
    //         .replace(/\[\[|\]\]/g, "");
    //       }else{
    //         _label = _label.replace(reg, "").replace(/\[\[|\]\]/g, "");
    //       }
    //     }
    //   }
    // });
    result.forEach((e) => {
      const reg = new RegExp(e);

      //if multiple codes are responsible for name of a step
      let stepNameOptions = e.split("||");
      if (stepNameOptions.length > 1) {
        stepNameOptions.forEach((element) => {
          element = element.trim();
          // work out pattern [[*.*_*]] for selects
          if (element.indexOf(".") > -1) {
            const field = element.split(".")[0];
            if (expandGlossaries && _.size(expandGlossaries)) {
              if (
                expandGlossaries[field] &&
                expandGlossaries[field].placeholderField
              ) {
                _label = _label
                  .replace(element, expandGlossaries[field].placeholderField)
                  .replace(/\[\[|\]\]/g, "");
              } else if (
                expandGlossaries[glossariesCodeIdToName[field]] &&
                expandGlossaries[glossariesCodeIdToName[field]].placeholderField
              ) {
                _label = _label
                  .replace(
                    element,
                    expandGlossaries[glossariesCodeIdToName[field]]
                      .placeholderField
                  )
                  .replace(/\[\[|\]\]/g, "");
              } else {
                _label = _label
                  .replace(element, "< ## >")
                  .replace(/\[\[|\]\]/g, "");
              }
            }
          } else {
            if (expandGlossaries && _.size(expandGlossaries)) {
              if (
                expandGlossaries[element] &&
                expandGlossaries[element].placeholderField
              ) {
                _label = _label
                  .replace(element, expandGlossaries[element].placeholderField)
                  .replace(/\[\[|\]\]/g, "");
              } else if (
                expandGlossaries[glossariesCodeIdToName[element]] &&
                expandGlossaries[glossariesCodeIdToName[element]]
                  .placeholderField
              ) {
                _label = _label
                  .replace(
                    element,
                    expandGlossaries[glossariesCodeIdToName[element]]
                      .placeholderField
                  )
                  .replace(/\[\[|\]\]/g, "");
              } else {
                _label = _label
                  .replace(element, "< ## >")
                  .replace(/\[\[|\]\]/g, "");
              }
            }
          }
        });
      }
      //if single code is responsible for name of a step
      // work out pattern [[*.*_*]] for selects
      else if (e.indexOf(".") > -1) {
        const field = e.split(".")[0];
        if (expandGlossaries && _.size(expandGlossaries)) {
          if (
            expandGlossaries[field] &&
            expandGlossaries[field].placeholderField
          ) {
            _label = _label
              .replace(reg, expandGlossaries[field].placeholderField)
              .replace(/\[\[|\]\]/g, "");
          } else if (
            expandGlossaries[glossariesCodeIdToName[field]] &&
            expandGlossaries[glossariesCodeIdToName[field]].placeholderField
          ) {
            _label = _label
              .replace(
                reg,
                expandGlossaries[glossariesCodeIdToName[field]].placeholderField
              )
              .replace(/\[\[|\]\]/g, "");
          } else {
            _label = _label.replace(reg, "< ## >").replace(/\[\[|\]\]/g, "");
          }
        }
      } else {
        // if there's no placeholder there, then find placeholder in all glossaries
        if (expandGlossaries && _.size(expandGlossaries)) {
          if (expandGlossaries[e] && expandGlossaries[e].placeholderField) {
            _label = _label
              .replace(reg, expandGlossaries[e].placeholderField)
              .replace(/\[\[|\]\]/g, "");
          } else if (
            expandGlossaries[glossariesCodeIdToName[e]] &&
            expandGlossaries[glossariesCodeIdToName[e]].placeholderField
          ) {
            _label = _label
              .replace(
                reg,
                expandGlossaries[glossariesCodeIdToName[e]].placeholderField
              )
              .replace(/\[\[|\]\]/g, "");
          } else {
            _label = _label.replace(reg, "< ## >").replace(/\[\[|\]\]/g, "");
          }
        }
      }
    });
    return _label;
  }

  handleArticleChange = (selectedOption) => {
    if (selectedOption) {
      this.setState({
        tmpItem: _.extend(this.state.tmpItem, {
          helperLink: selectedOption.value,
        }),
      });
    } else {
      this.setState({
        tmpItem: _.extend(this.state.tmpItem, {
          helperLink: "",
        }),
      });
    }
  };

  handleFieldsChange = (value) => {
    this.setState({
      tmpItem: _.extend(this.state.tmpItem, {
        relatedFields: value ? value.map((v) => v.value) : [],
      }),
      tmpItem: _.extend(this.state.tmpItem, {
        selectOption: value ? value.map((v) => v) : [],
      }),
    });
  };

  handleCollapse(e, id) {
    e.preventDefault();
    let visibleList = this.state.visibleList;
    if (visibleList.includes(id)) {
      visibleList.splice(visibleList.indexOf(id), 1);
    } else {
      visibleList.push(id);
    }
    this.setState({
      visibleList,
    });

    let targetArrow = document.getElementById(`arrow-${id}`);
    if (targetArrow.classList.contains("fa-arrow-up")) {
      targetArrow.classList.remove("fa-arrow-up");
      targetArrow.classList.add("fa-arrow-down");
    } else {
      targetArrow.classList.remove("fa-arrow-down");
      targetArrow.classList.add("fa-arrow-up");
    }
  }

  handleShowAllCodes = (onlySetShow = false) => {
    let {visibleList, visibleSteps} = this.state;
    let visibleStepsKeys = Object.keys(visibleSteps);
    if(visibleStepsKeys.length === visibleList.length && !onlySetShow){
      this.setState({
        visibleList : []
      },()=>{
        visibleStepsKeys.forEach(element => {
          let targetArrow = document.getElementById(`arrow-${element}`);
          if (targetArrow && targetArrow.classList.contains("fa-arrow-down")) {
            targetArrow.classList.remove("fa-arrow-down");
            targetArrow.classList.add("fa-arrow-up");
          }
        });
      })
    }else{
      this.setState({
        visibleList : visibleStepsKeys
      },()=>{
        visibleStepsKeys.forEach(element => {
          let targetArrow = document.getElementById(`arrow-${element}`);
          if (targetArrow && targetArrow.classList.contains("fa-arrow-up")) {
            targetArrow.classList.remove("fa-arrow-up");
            targetArrow.classList.add("fa-arrow-down");
          }
        });
      })
    }
  };

  // handleCodeSearch= () => {
  //   let {visibleSteps, visibleList, codeSearchTerm} = this.state;
  //   visibleList = [];
  //   if(codeSearchTerm.length){
  //     for (const visibleStep in visibleSteps) {
  //       if (visibleSteps.hasOwnProperty(visibleStep)) {
  //         const element = visibleSteps[visibleStep];
  //         // if (!visibleList.includes(visibleStep)) {
  //         //   visibleList.push(visibleStep);
  //         // }
  //         element.forEach((codes) => {
  //           if (this.checkForSearchTerm(codes)) {
  //             if (!visibleList.includes(visibleStep)) {
  //               visibleList.push(visibleStep);
  //             }
  //           }
  //         });
  //       }
  //     }
  //     this.setState({
  //       visibleList
  //     })
  //   }
  // }

  checkForSearchTerm = (code, codeSearchTerm = this.state.codeSearchTerm) => {
    if (
      code.name.indexOf(codeSearchTerm) > -1 ||
      (code.description && code.description.default &&
        code.description.default.indexOf(codeSearchTerm) > -1) ||
      code.helperText.indexOf(codeSearchTerm) > -1 ||
      code.helperLink.indexOf(codeSearchTerm) > -1 ||
      code.placeholderField.indexOf(codeSearchTerm) > -1 ||
      code.warningText.indexOf(codeSearchTerm) > -1 ||
      code.warningTextRwdo.indexOf(codeSearchTerm) > -1
    ) {
      return true;
    }
    return false;
  };

  handleSearchTerm = (e) => {
    let value = e.target.value.trimStart();
    this.setState({
      codeSearchTerm: value,
    },()=>{
      if(value)
      this.handleShowAllCodes(true);
    });
  };

  clearSearchTerm = (e) => {
    if(this.state.codeSearchTerm)
    this.setState({
      codeSearchTerm: "",
    },()=>{
      this.handleShowAllCodes(true);
    });
  };

  listCategories = () => {
    let categories = this.props.categories;
    let selectors = [];
    selectors.push({
      value: 0,
      label: strings.get("App.glossaries.glossaryForm.all"),
    });
    _.each(categories, (category) => {
      selectors.push({
        value: category.id,
        label: category.name,
      });
    });
    return selectors;
  };

  handleCategorySelect = (event) => {
    let id = event.value;
    let { selectedCategories } = this.state;
    if (!selectedCategories.includes(id)) {
      selectedCategories.push(id);
    }
    if (id === 0) {
      selectedCategories = [];
    }
    this.setState({ selectedCategories }, () => {
      this.handleStepsCategoryWise();
    });
  };

  handleStepsCategoryWise = () => {
    let { stepsCategoryWise } = this.props;
    let { stepsOptions, selectedCategories } = this.state;
    stepsOptions = [];
    _.each(stepsCategoryWise, (data, index) => {
      if (
        selectedCategories.includes(index) ||
        selectedCategories.includes(Number(index))
      )
        _.each(data, (step) => {
          if (!stepsOptions.includes(step)) {
            stepsOptions.push(step);
          }
        });
    });
    this.setState({ stepsOptions });
  };

  handleCategoryRemove = (data) => {
    let { selectedCategories } = this.state;
    if (typeof data === "string") {
      data = Number(data);
    }
    if (selectedCategories.includes(data)) {
      selectedCategories.splice(selectedCategories.indexOf(data), 1);
      this.setState({ selectedCategories }, () => {
        this.handleStepsCategoryWise();
      });
    }
  };

  render() {
    let nameLabel = this.hasError("name")
      ? `${strings.get(
          "App.glossaries.glossaryForm.selectors.name"
        )} ${this.getErrorMessage("name")}`
      : strings.get("App.glossaries.glossaryForm.selectors.name");
    let descriptionLabel = this.hasError("description")
      ? `${strings.get(
          "App.glossaries.glossaryForm.selectors.description"
        )} ${this.getErrorMessage("description")}`
      : strings.get("App.glossaries.glossaryForm.selectors.description");
    let stepLabel = this.hasError("stepId")
      ? `${strings.get(
          "App.glossaries.glossaryForm.selectors.step"
        )} ${this.getErrorMessage("stepId")}`
      : strings.get("App.glossaries.glossaryForm.selectors.step");
    let helperTextLabel = this.hasError("helperText")
      ? `${strings.get(
          "App.glossaries.glossaryForm.selectors.helperText"
        )} ${this.getErrorMessage("helperText")}`
      : strings.get("App.glossaries.glossaryForm.selectors.helperText");
    let helperLinkLabel = this.hasError("helperLink")
      ? `${strings.get(
          "App.glossaries.glossaryForm.selectors.helperLink"
        )} ${this.getErrorMessage("helperLink")}`
      : strings.get("App.glossaries.glossaryForm.selectors.helperLink");
    let isRequiredLabel = this.hasError("isRequired")
      ? `${strings.get(
          "App.glossaries.glossaryForm.selectors.isRequired"
        )} ${this.getErrorMessage("isRequired")}`
      : strings.get("App.glossaries.glossaryForm.selectors.isRequired");
    let placeholderFieldLabel = this.hasError("placeholderField")
      ? `${strings.get(
          "App.glossaries.glossaryForm.selectors.placeholderField"
        )} ${this.getErrorMessage("placeholderField")}`
      : strings.get("App.glossaries.glossaryForm.selectors.placeholderField");
    let warningTextLabel = this.hasError("warningText")
      ? `${strings.get(
          "App.glossaries.glossaryForm.selectors.warningText"
        )} ${this.getErrorMessage("warningText")}`
      : strings.get("App.glossaries.glossaryForm.selectors.warningText");
    let warningTextRwdoLabel = this.hasError("warningTextRwdo")
      ? `${strings.get(
          "App.glossaries.glossaryForm.selectors.warningTextRwdo"
        )} ${this.getErrorMessage("warningTextRwdo")}`
      : strings.get("App.glossaries.glossaryForm.selectors.warningTextRwdo");

    let fields = this.listSelectors();

    // let relatedFields = this.state.tmpItem.relatedFields.map((r) => {
    //   return {
    //     value: r,
    //     label: r,
    //   };
    // });

    let selectOption = this.state.tmpItem.selectOption.map((r) => {
      return {
        value: r.value,
        label: r.label,
      };
    });

    let stepsOption = _.size(this.state.selectedCategories)
      ? this.state.stepsOptions
      : this.props.steps;
    let steps = _.map(stepsOption, (item) => {
      let newLabel = this.buildLabel(item.name);
      return (
        <option value={item.id} key={item.id}>
          {newLabel}
        </option>
      );
    });

    let deleteModalContent =
      this.state.currentItemKey !== -1 ? (
        <span>
          <div className="modal-close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.handleCancelClick}
            />
          </div>
          <h2>
            {strings.get("App.deleteModal.message", {
              itemName: this.state.items[this.state.currentItemKey].name,
            })}
          </h2>
          {this.state.deleteError ? (
            <div style={{ color: "red" }}>
              {strings.get("App.deleteModal.error")}
            </div>
          ) : null}
          <div className="form-actions">
            <button
              className="btn btn-lg btn-danger"
              onClick={this.handleConfirmItemRemove}
            >
              {strings.get("App.deleteModal.delete")}
            </button>
            <button
              className="btn btn-lg cancel"
              onClick={this.handleCancelClick}
            >
              {strings.get("App.deleteModal.cancel")}
            </button>
          </div>
        </span>
      ) : null;

    let deleteModal = (
      <DropModal
        className="boron-modal"
        ref="deleteModal"
        onHide={this.hanldeDeleteModalHide}
      >
        {deleteModalContent}
      </DropModal>
    );

    let documentListModalContent = (
      <div className="documentsList">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th> {strings.get("App.users.downloads.documentName")} </th>
              <th> {strings.get("App.users.downloads.category")} </th>
            </tr>
          </thead>
          <tbody>{this.documentsList()}</tbody>
        </table>
      </div>
    );

    let categoriesOptions = this.listCategories();

    let selectedCategories = _.map(this.state.selectedCategories, (item) => {
      if (item === 0) {
        return false;
      }
      return (
        <ActiveLabel
          name={this.props.categories[`_${item}`].name}
          value={this.props.categories[`_${item}`].id}
          onRemove={this.handleCategoryRemove}
          key={`categories-${this.props.categories[`_${item}`].id}`}
        />
      );
    });
    let categories = (
      <div className="form-group">
        <label className="control-label">
          {strings.get("App.documents.documentForm.category")}
        </label>
        <Select
          options={categoriesOptions}
          name="allCategories"
          value={categoriesOptions.value}
          onChange={this.handleCategorySelect}
          placeholder={strings.get("App.glossaries.glossaryForm.all")}
        />
        <div style={{ marginTop: "10px" }}>{selectedCategories}</div>
      </div>
    );

    let formModal = () => {
      return (
      <FadeModal
        className="boron-modal form-modal"
        ref="formModal"
        onHide={this.handleFormModalHide}
      >
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleCancelClick}
          />
        </div>
        <div className="scrollable">
          <div className={this.getErrorClass("name", "form-group")}>
            <label className="control-label">{nameLabel}</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={this.state.tmpItem.name}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group">
            <label className="control-label">
              {strings.get("App.glossaries.glossaryForm.profiles.relatedCodes")}
            </label>
            <Select
              options={fields}
              isClearable={true}
              isMulti
              value={selectOption}
              closeMenuOnSelect={false}
              onChange={this.handleFieldsChange}
              placeholder={strings.get(
                "App.glossaries.glossaryForm.fields.selectPlaceholder"
              )}
            />
          </div>
          <div className={this.getErrorClass("placeholderField", "form-group")}>
            <label className="control-label">{placeholderFieldLabel}</label>
            <input
              className="form-control"
              type="text"
              name="placeholderField"
              value={this.state.tmpItem.placeholderField}
              onChange={this.handleInputChange}
            />
          </div>
          <div className={this.getErrorClass("description", "form-group")}>
            <label className="control-label">{descriptionLabel}</label>
            {["default", "me", "client"].map((key) => (
              <textarea
                key={key}
                className="form-control"
                name="description"
                data-key={key}
                value={this.state.tmpItem.description[key]}
                onChange={this.handleInputChange}
                placeholder={key}
              />
            ))}
          </div>
          {categories}
          <div className={this.getErrorClass("stepId", "form-group")}>
            <label className="control-label">{stepLabel}</label>
            <select
              className="form-control"
              name="stepId"
              value={this.state.tmpItem.stepId}
              onChange={this.handleInputChange}
            >
              <option value="-1" disabled>
                {strings.get("App.glossaries.glossaryForm.selectors.choose")}
              </option>
              {steps}
            </select>
          </div>
          <div className={this.getErrorClass("helperText", "form-group")}>
            <label className="control-label">{helperTextLabel}</label>
            <Editor
              content={this.state.tmpItem.helperText}
              handleChange={this.handleEditorChange}
              height={200}
            />
          </div>

          <div className="form-group">
            <label className="control-label">
              {strings.get("App.glossaries.glossaryForm.fields.selectArticle")}
            </label>
            <Select
              options={this.state.articlesName}
              isClearable={true}
              onChange={this.handleArticleChange}
              placeholder={strings.get(
                "App.glossaries.glossaryForm.fields.selectPlaceholder"
              )}
            />
          </div>

          <div className={this.getErrorClass("helperLink", "form-group")}>
            <label className="control-label">{helperLinkLabel}</label>
            <input
              className="form-control"
              type="text"
              name="helperLink"
              value={this.state.tmpItem.helperLink}
              onChange={this.handleInputChange}
            />
          </div>
          <div className={this.getErrorClass("isRequired", "form-group")}>
            <label className="control-label">{isRequiredLabel}</label>
            <select
              className="form-control"
              name="isRequired"
              value={this.state.tmpItem.isRequired}
              onChange={this.handleRequiredChange}
            >
              <option value={1}>
                {strings.get(
                  "App.glossaries.glossaryForm.selectors.isRequired"
                )}
              </option>
              <option value={2}>
                {strings.get(
                  "App.glossaries.glossaryForm.selectors.requiredWithOption"
                )}
              </option>
              <option value={0}>
                {strings.get("App.glossaries.glossaryForm.selectors.optional")}
              </option>
            </select>
          </div>
          <div
            className={this.getErrorClass(
              "warningText",
              this.state.warningClass
            )}
          >
            <label className="control-label">{warningTextLabel}</label>
            <Editor
              name="warningText"
              content={this.state.tmpItem.warningText}
              handleChange={this.handleEditorChangeWarning}
              height={200}
            />
          </div>
          <div
            className={this.getErrorClass(
              "warningTextRwdo",
              this.state.warningRwdoClass
            )}
          >
            <label className="control-label">{warningTextRwdoLabel}</label>
            <Editor
              name="warningTextRwdo"
              content={this.state.tmpItem.warningTextRwdo}
              handleChange={this.handleEditorChangeWarningRwdo}
              height={200}
            />
          </div>
        </div>
        <div className="form-actions">
          <button className="btn btn-primary" onClick={this.handleSaveClick}>
            {strings.get("App.glossaries.glossaryForm.selectors.save")}
          </button>
          <button
            className="btn btn-default cancel"
            onClick={this.handleCancelClick}
          >
            {strings.get("App.glossaries.glossaryForm.selectors.cancel")}
          </button>
          { this.state.currentItemKey !== -1 && 
            (<button
              className="btn btn-primary"
              onClick={(e)=>this.handleSaveClick(e, true)}
            >
              {strings.get("App.glossaries.glossaryForm.selectors.duplicate")}
            </button>
            )}
        </div>
      </FadeModal>
    )};

    //1- Order of the Selectors
    let newItems = this.state.items;
    let y = [];
    newItems && newItems.map((item, index) => {
      if(!this.checkForSearchTerm(item)){
        //work like filter
        return false
      }
      if (!y[item["stepId"] - 1]) y[item["stepId"] - 1] = [];
      item.index = index;
      y[item["stepId"] - 1].push(item);
    });
    newItems = [];
    y &&
      y.map((item) => {
        if (typeof item !== "undefined") newItems.push(item);
      });
    newItems &&
      newItems.map((items, i) => {
        let stepOrder =
          _.size(this.props.steps) && this.props.steps["_" + items[0].stepId]
            ? this.props.steps["_" + items[0].stepId].order
            : null;
        items.stepOrder = stepOrder;
      });
    newItems = _.orderBy(newItems, ["stepOrder"], ["asc"]);
    let list = newItems.map((item, i) => {
      let stepName =
        _.size(this.props.steps) && this.props.steps["_" + item[0].stepId]
          ? this.buildLabel(this.props.steps["_" + item[0].stepId].name)
          : null;
      return (
        <div className="col-sm-12 col-md-12 titleDiv" key={`item-${i}`}>
          <div
            className="col-sm-12 col-md-12 col-xs-12 label-arrow-container"
            onClick={(e) => this.handleCollapse(e, "item-" + "selector-" + i)}
          >
            <div className="panel-body">
              <div className="text-start">
                <label>{stepName}</label>
              </div>
            </div>
            <i
              className="fa fa-arrow-up"
              aria-hidden="true"
              id={"arrow-item-" + "selector-" + i}
            ></i>
          </div>
          <div
            className={
              this.state.visibleList.includes("item-" + "selector-" + i)
                ? "table-responsive listDiv showList"
                : "table-responsive listDiv hideList"
            }
            id={"item-" + "selector-" + i}
          >
            {this.state.visibleList.includes("item-" + "selector-" + i) ? (
              <SortableTable
                items={item}
                prefix={this.props.prefix}
                onSortEnd={this.handleSortEnd}
                axis="xy"
                useDragHandle={true}
                lockToContainerEdges={true}
                helperClass="glossarySortableRow Field"
              />
            ) : null}
          </div>
        </div>
      );
    });
    //END

    let labelClassName = this.props.currentItem
      ? "pointer control-label"
      : "control-label";
    let divStyle = {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    };

    return (
      <div className="GlossaryFormSelectors form-group">
        {deleteModal}
        {formModal()}
        <DropModal className="boron-modal" ref="documentListModal">
          <div className="modal-close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.hideDocumentListModal}
            />
          </div>
          {documentListModalContent}
        </DropModal>
        <div className="header" style={{marginBottom: "15px"}}>
          <span
              className="btn btn-primary"
              onClick={(e)=>this.handleShowAllCodes()}
            >
            {this.state.visibleList.length && this.state.visibleList.length === Object.keys(this.state.visibleSteps).length
            ? strings.get("App.glossaries.glossaryForm.hideAll")
            : strings.get("App.glossaries.glossaryForm.showAll")}
          </span>
          <span className="search-bar">
            <input type="text" value={this.state.codeSearchTerm} placeholder={strings.get("App.glossaries.glossaryForm.codeSearchPlaceholder")} onChange={this.handleSearchTerm}/>
            {/* <i className="ion-android-search" onClick={this.handleCodeSearch}/> */}
            <i className="ion-android-refresh" onClick={this.clearSearchTerm}/>
          </span>
          <span
            className="btn btn-primary small-add"
            onClick={this.handleAddClick}
          >
            <i className="ion-android-add" />
          </span>
        </div>
        <div style={!!!_.size(this.props.steps) ? divStyle : null} ref="hidden">
          {list.length ? (
            !!_.size(this.props.steps) ? (
              list
            ) : (
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
              </div>
            )
          ) : (
            <div style={{ textAlign: "center" }}>
              {strings.get("App.glossaries.glossaryForm.noData")}
            </div>
          )}
        </div>
      </div>
    );
  }
}

GlossaryFormSelectors.propTypes = {
  items: PropTypes.array,
  steps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default GlossaryFormSelectors;
