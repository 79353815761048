import React, { Component } from "react";
import autoBind from "react-autobind";
import _ from "lodash";
import PropTypes from "prop-types";

import Editor from "../../Editor";

import strings from "../../../services/strings";
import settings from "../../../services/settings";

class TemplateNotesForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      currentSettingsLoaded: false,
      form: {
        code_block_content_notes: { value: "" },
        template_common_notes: { value: "" },
      },
    };
  }
  componentDidMount() {
    this.tryLoadCurrentSettings();
  }

  componentDidUpdate() {
    this.tryLoadCurrentSettings();
  }

  tryLoadCurrentSettings() {
    if (_.size(this.props.settings) && !this.state.currentSettingsLoaded) {
      this.setState({
        currentSettingsLoaded: true,
        form: {
          code_block_content_notes: this.props.settings[
            settings.keys.CODE_BLOCK_CONTENT_NOTES
          ],
          template_common_notes: this.props.settings[
            settings.keys.TEMPLATE_COMMON_NOTES
          ],
        },
      });
    }
  }

  handleInputChange(e) {
    let form = _.extend(this.state.form, {});
    form[e.target.name] = form[e.target.name].asMutable
      ? form[e.target.name].asMutable()
      : form[e.target.name];
    form[e.target.name].value = e.target.value;

    this.setState({ form });
  }

  // handleMessageChange(e) {
  //   e.target.value =  e.target.getContent();
  //   e.target.name = settings.keys.CODE_BLOCK_CONTENT_NOTES;
  //   this.handleInputChange(e);
  // }

  handleMessageChange(e) {
    let form = _.extend(this.state.form, {});
    form[settings.keys.CODE_BLOCK_CONTENT_NOTES] = form[
      settings.keys.CODE_BLOCK_CONTENT_NOTES
    ].asMutable
      ? form[settings.keys.CODE_BLOCK_CONTENT_NOTES].asMutable()
      : form[settings.keys.CODE_BLOCK_CONTENT_NOTES];
    form[settings.keys.CODE_BLOCK_CONTENT_NOTES].value = e;

    this.setState({ form });
  }

  // handleTemplateCommonNotesChange(e) {
  //   e.target.value = e.target.getContent();
  //   e.target.name = settings.keys.TEMPLATE_COMMON_NOTES;
  //   this.handleInputChange(e);
  // }

  handleTemplateCommonNotesChange(e) {
    let form = _.extend(this.state.form, {});
    form[settings.keys.TEMPLATE_COMMON_NOTES] = form[
      settings.keys.TEMPLATE_COMMON_NOTES
    ].asMutable
      ? form[settings.keys.TEMPLATE_COMMON_NOTES].asMutable()
      : form[settings.keys.TEMPLATE_COMMON_NOTES];
    form[settings.keys.TEMPLATE_COMMON_NOTES].value = e;

    this.setState({ form });
  }

  handleSaveClick(e) {
    e.preventDefault();
    this.props.saveSettings(this.state);
  }

  handleCancelClick(e) {
    e.preventDefault();
    this.setState({
      form: {
        code_block_content_notes: this.props.settings[
          settings.keys.CODE_BLOCK_CONTENT_NOTES
        ],
        template_common_notes: this.props.settings[
          settings.keys.TEMPLATE_COMMON_NOTES
        ],
      },
    });
  }

  getTemplateCommonNotesEditor = () => {
    return (
      <div className="form-group">
        <label className="control-label">
          {strings.get("App.settings.templateNotes.templateCommonNotes")}
        </label>
        <Editor
          content={
            this.state.form.template_common_notes &&
            this.state.form.template_common_notes.value
              ? this.state.form.template_common_notes.value
              : ""
          }
          handleChange={this.handleTemplateCommonNotesChange}
          height={250}
        />
      </div>
    );
  };

  getCodeBlockContentEditor = () => {
    return (
      <div className="form-group">
        <label className="control-label">
          {strings.get("App.settings.templateNotes.codeBlockContent")}
        </label>
        <Editor
          content={
            this.state.form.code_block_content_notes &&
            this.state.form.code_block_content_notes.value
              ? this.state.form.code_block_content_notes.value
              : ""
          }
          handleChange={this.handleMessageChange}
          height={250}
        />
      </div>
    );
  };

  getActionButtons = () => {
    return (
      <div className="form-actions">
        <button className="btn btn-primary" onClick={this.handleSaveClick}>
          {strings.get("App.email.save")}
        </button>
        <button className="btn btn-default" onClick={this.handleCancelClick}>
          {strings.get("App.email.cancel")}
        </button>
      </div>
    );
  };

  render() {
    return (
      <div className="EmailForm col-xs-12 col-sm-12 col-md-9">
        <form>
          {this.getTemplateCommonNotesEditor()}
          {this.getCodeBlockContentEditor()}
          {this.getActionButtons()}
        </form>
      </div>
    );
  }
}

TemplateNotesForm.propTypes = {
  settings: PropTypes.object,
  saveSettings: PropTypes.func.isRequired,
};

export default TemplateNotesForm;
