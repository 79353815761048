import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";

import "../Page.scss";
import * as documentsActions from "../../store/documents/actions";
import * as documentsSelectors from "../../store/documents/selectors";
import * as workflowsActions from "../../store/workflows/actions";
import * as workflowssSelectors from "../../store/workflows/selectors";
import * as workflowCategoriesSelectors from "../../store/workflowCategories/selectors";
import * as workflowCategoriesActions from "../../store/workflowCategories/actions";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";
import * as articleCategoriesSelectors from "../../store/articleCategories/selectors";

import Topbar from "../../components/Topbar";
import WorkflowForm from "../../components/workflow/WorkflowForm";

class WorkflowsAddPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  componentDidMount() {
    this.props.fetchDocuments();
    this.props.fetchWorkflows();
    this.props.fetchAllCategories();

    // this.props.fetchWorkflow(this.props.match.params.id);

    this.props.setCurrentWorkflowId(this.props.match.params.id);
  }
  componentWillUnmount() {
    this.props.clearExceptions();
    this.props.unsetCurrentWorkflowId();
  }

  saveWorkflow(data) {
    this.props.updateWorkflow(this.props.match.params.id, data.form);
    if (data.file) {
      this.props.uploadWorkflowLogo(this.props.match.params.id, data.file);
    }
  }
  deleteMilestone = (id) => {
    this.props.deleteMilestone(id);
  };

  render() {

    return (
      <div className="WorkflowsAddPage">
        <Topbar>
          <div className="title">
            <Link to="/workflows">
              {strings.get("App.workflowPages.title")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/workflows/add">
                {strings.get("App.workflowPages.edit")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content">
          <WorkflowForm
            documents={this.props.documents}
            currentItem={this.props.currentWorkflow}
            categories={this.props.categories}
            saveItem={this.saveWorkflow}
            formHeading={strings.get("App.workflowPages.edit")}
            deleteMilestone={this.deleteMilestone}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    documents: documentsSelectors.getItems(state),
    // documents: documentsSelectors.getItemsByPage(state, (documentsSelectors.getPagination(state)).currentPage),
    currentWorkflow: workflowssSelectors.getCurrentItem(state),
    categories: workflowCategoriesSelectors.getItems(state),
    articleCategories: articleCategoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
    fetchDocuments: (deleteCache) => {
      dispatch(documentsActions.fetchItemAllPages(deleteCache));
    },
    fetchWorkflow: (id) => {
      dispatch(workflowsActions.fetchItem(id));
    },
    setCurrentWorkflowId: (id) => {
      dispatch(workflowsActions.setCurrentItemId(id));
    },
    unsetCurrentWorkflowId: () => {
      dispatch(workflowsActions.unsetCurrentItemId());
    },
    fetchWorkflows: (deleteCache) => {
      dispatch(workflowsActions.fetchItems(deleteCache));
    },
    fetchAllCategories: (deleteCache) => {
      dispatch(workflowCategoriesActions.fetchItemAllPages(deleteCache));
    },
    updateWorkflow: (id, data) => {
      dispatch(workflowsActions.updateItem(id, data));
    },
    uploadWorkflowLogo: (id, file) => {
      dispatch(workflowsActions.uploadItemLogo(id, file));
    },
    deleteMilestone: (id) => {
      dispatch(workflowsActions.deleteMilestone(id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowsAddPage);
