import React, { Component } from "react";
import autoBind from "react-autobind";
import PropTypes from "prop-types";
import strings from "../../services/strings";

import _ from "lodash";
import "./GlossaryList.scss";

import Modal from "reboron/DropModal";
import { browserHistory } from "../../helpers";

class GlossaryList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      deleteStatus: "",
    };
  }

  getLogo(item) {
    return <div className="item-logo default-logo ion-clipboard" />;
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  showDeleteMsgModal() {
    this.refs.deleteMsgModal.show();
  }

  hideDeleteMsgModal() {
    this.refs.deleteMsgModal.hide();
    this.setState({ deleteStatus: "" });
  }

  handleGloDeleteResponse = (deleteStatus) => {
    this.setState({ deleteStatus }, () => {
      this.showDeleteMsgModal();
    });
  };

  handleCancelDeleteMsgClick() {
    this.hideDeleteMsgModal();
  }

  handleDeleteClick(id) {
    this.props.setCurrentItemId(id);
    this.showDeleteModal();
  }

  handleConfirmDeleteClick() {
    this.props.deleteItem(
      this.props.currentItem.id,
      this.handleGloDeleteResponse
    );
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleCancelDeleteClick() {
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleEditClick(id) {
    browserHistory.push(`/glossaries/${id}`);
  }

  render() {
    let items = _.map(this.props.items, (value) => {
      return (
        <tr key={value.id}>
          <td
            style={{ cursor: "pointer" }}
            onClick={() => this.handleEditClick(value.id)}
          >
            {this.getLogo(value)}
          </td>
          <td>
            <div className="details">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => this.handleEditClick(value.id)}
                className="name"
              >
                {value.name}
              </div>
              <div className="description">{value.description}</div>
            </div>
          </td>
          <td>
            <i
              onClick={() => this.handleEditClick(value.id)}
              className="btn btn-default edit-btn ion-edit"
            />
          </td>
          <td>
            <i
              onClick={() => this.handleDeleteClick(value.id)}
              className="btn btn-default delete-btn ion-trash-b"
            />
          </td>
        </tr>
      );
    });

    let deleteModalContent = this.props.currentItem ? (
      <span>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleCancelDeleteClick}
          />
        </div>
        <h2>
          {strings.get("App.deleteModal.message", {
            itemName: this.props.currentItem.name,
          })}
        </h2>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger"
            onClick={this.handleConfirmDeleteClick}
          >
            {strings.get("App.deleteModal.delete")}
          </button>
          <button
            className="btn btn-lg btn-default cancel"
            onClick={this.handleCancelDeleteClick}
          >
            {strings.get("App.deleteModal.cancel")}
          </button>
        </div>
      </span>
    ) : null;

    let deleteMsgModalContent =
      this.state.deleteStatus !== "" ? (
        <span>
          <div className="modal-close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.handleCancelDeleteMsgClick}
            />
          </div>
          {/* <h2>
          {strings.get("App.deleteModal.message")}
        </h2> */}
          <div className="form-actions">
            <h2>
              {this.state.deleteStatus === "success"
                ? strings.get("App.glossaries.glossaryList.deleteSuccessMsg")
                : strings.get("App.glossaries.glossaryList.deleteErrorMsg")}
            </h2>
          </div>
        </span>
      ) : null;

    return (
      <span className="GlossaryList">
        <Modal className="boron-modal" ref="deleteModal">
          {deleteModalContent}
        </Modal>
        <Modal className="boron-modal" ref="deleteMsgModal">
          {deleteMsgModalContent}
        </Modal>
        <table className="table">
          <tbody>{items}</tbody>
        </table>
      </span>
    );
  }
}

GlossaryList.propTypes = {
  items: PropTypes.array.isRequired,
  sorter: PropTypes.object.isRequired,
  fetchItems: PropTypes.func.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  toggleSorter: PropTypes.func.isRequired,
};

export default GlossaryList;
