import React, { Component } from "react";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as companiesActions from "../../store/companies/actions";
import * as companiesSelectors from "../../store/companies/selectors";
import * as companyCategoriesActions from "../../store/companyCategories/actions";
import * as companyCategoriesSelectors from "../../store/companyCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
// import SubTopbar from "../../components/SubTopbar";
import CompanyForm from "../../components/company/CompanyForm";

class CompanyEditPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchCompany(this.props.match.params.id);
    this.props.setCurrentCompanyId(this.props.match.params.id);

    this.props.fetchAllCompanyCategories();
  }

  componentWillUnmount() {
    this.props.unsetCurrentCompanyId();
    this.props.clearExceptions();
  }

  saveCompany = (data) => {
    this.props.updateCompany(this.props.match.params.id, data.form);
    if (data.file) {
      this.props.uploadCompanyLogo(this.props.match.params.id, data.file);
    }
  };

  render() {
    return (
      <div className="CompanyEditPage">
        <Topbar>
          <div className="title">
            <Link to="/glossaries/companies">
              {strings.get("App.companyPages.title")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to={`/glossaries/companies/${this.props.match.params.id}`}>
                {strings.get("App.companyPages.edit")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content">
          <CompanyForm
            exceptions={this.props.exceptions}
            companyCategories={this.props.companyCategories}
            currentItem={this.props.currentCompany}
            saveItem={this.saveCompany}
            formHeading={strings.get("App.companyPages.edit")}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    companyCategories: companyCategoriesSelectors.getItems(state),
    currentCompany: companiesSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllCompanyCategories: (deleteCache) => {
      dispatch(companyCategoriesActions.fetchAllItems(deleteCache));
    },
    fetchCompany: (id) => {
      dispatch(companiesActions.fetchItem(id));
    },
    setCurrentCompanyId: (id) => {
      dispatch(companiesActions.setCurrentItemId(id));
    },
    unsetCurrentCompanyId: () => {
      dispatch(companiesActions.unsetCurrentItemId());
    },
    updateCompany: (id, data) => {
      dispatch(companiesActions.updateItem(id, data));
    },
    uploadCompanyLogo: (id, file) => {
      dispatch(companiesActions.uploadItemLogo(id, file));
    },
    createCompany: (data) => {
      dispatch(companiesActions.createItem(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEditPage);
