import React, { Component } from "react";
import autoBind from "react-autobind";
import strings from "../../services/strings";
import language from "../../services/language";
import _ from "lodash";
import "./DocumentOrderField.scss";
import { DropdownButton, Dropdown } from "react-bootstrap";
import ActiveLabel from "../ActiveLabel";
import ReactLoading from "react-loading";
import "./WorkFlowCodes.scss";
import SelectSearch from "react-select";
class WorkFlowCodes extends Component {
  state = {
    docs: {},
    workFlows: {},
    codesFromWorkFlows: {},
    loading: false,
    noData: false
  };

  componentDidMount() {}

  componentDidUpdate() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  constructor(props) {
    super(props);
    autoBind(this);
  }

  buildLabel(text) {
    let { expandGlossaries, glossariesCodeIdToName } = this.props;
    let _label = text;
    // separate out all selectors and loop through
    let result = strings.getFromBetween.get(_label, "[[", "]]");
    result.forEach(e => {
      const reg = new RegExp(e);

      //if multiple codes are responsible for name of a step
      let stepNameOptions = e.split("||");
      if (stepNameOptions.length > 1) {
        stepNameOptions.forEach(element => {
          element = element.trim();
          // work out pattern [[*.*_*]] for selects
          if (element.indexOf(".") > -1) {
            const field = element.split(".")[0];
            if (expandGlossaries && _.size(expandGlossaries)) {
              if (
                expandGlossaries[field] &&
                expandGlossaries[field].placeholderField
              ) {
                _label = _label
                  .replace(element, expandGlossaries[field].placeholderField)
                  .replace(/\[\[|\]\]/g, "");
              } else if (
                expandGlossaries[glossariesCodeIdToName[field]] &&
                expandGlossaries[glossariesCodeIdToName[field]].placeholderField
              ) {
                _label = _label
                  .replace(
                    element,
                    expandGlossaries[glossariesCodeIdToName[field]]
                      .placeholderField
                  )
                  .replace(/\[\[|\]\]/g, "");
              } else {
                _label = _label
                  .replace(element, "< ## >")
                  .replace(/\[\[|\]\]/g, "");
              }
            }
          } else {
            if (expandGlossaries && _.size(expandGlossaries)) {
              if (
                expandGlossaries[element] &&
                expandGlossaries[element].placeholderField
              ) {
                _label = _label
                  .replace(element, expandGlossaries[element].placeholderField)
                  .replace(/\[\[|\]\]/g, "");
              } else if (
                expandGlossaries[glossariesCodeIdToName[element]] &&
                expandGlossaries[glossariesCodeIdToName[element]]
                  .placeholderField
              ) {
                _label = _label
                  .replace(
                    element,
                    expandGlossaries[glossariesCodeIdToName[element]]
                      .placeholderField
                  )
                  .replace(/\[\[|\]\]/g, "");
              } else {
                _label = _label
                  .replace(element, "< ## >")
                  .replace(/\[\[|\]\]/g, "");
              }
            }
          }
        });
      }
      //if single code is responsible for name of a step
      // work out pattern [[*.*_*]] for selects
      else if (e.indexOf(".") > -1) {
        const field = e.split(".")[0];
        if (expandGlossaries && _.size(expandGlossaries)) {
          if (
            expandGlossaries[field] &&
            expandGlossaries[field].placeholderField
          ) {
            _label = _label
              .replace(reg, expandGlossaries[field].placeholderField)
              .replace(/\[\[|\]\]/g, "");
          } else if (
            expandGlossaries[glossariesCodeIdToName[field]] &&
            expandGlossaries[glossariesCodeIdToName[field]].placeholderField
          ) {
            _label = _label
              .replace(
                reg,
                expandGlossaries[glossariesCodeIdToName[field]].placeholderField
              )
              .replace(/\[\[|\]\]/g, "");
          } else {
            _label = _label.replace(reg, "< ## >").replace(/\[\[|\]\]/g, "");
          }
        }
      } else {
        // if there's no placeholder there, then find placeholder in all glossaries
        if (expandGlossaries && _.size(expandGlossaries)) {
          if (expandGlossaries[e] && expandGlossaries[e].placeholderField) {
            _label = _label
              .replace(reg, expandGlossaries[e].placeholderField)
              .replace(/\[\[|\]\]/g, "");
          } else if (
            expandGlossaries[glossariesCodeIdToName[e]] &&
            expandGlossaries[glossariesCodeIdToName[e]].placeholderField
          ) {
            _label = _label
              .replace(
                reg,
                expandGlossaries[glossariesCodeIdToName[e]].placeholderField
              )
              .replace(/\[\[|\]\]/g, "");
          } else {
            _label = _label.replace(reg, "< ## >").replace(/\[\[|\]\]/g, "");
          }
        }
      }
    });
    return _label;
  }

  // handleInputChange(e) {
  //   if (e.target.name === "workFlowOptions") {
  //     let { workFlows } = this.state;
  //     if (!Object.keys(workFlows).includes(e.target.value)) {
  //       let allWorkflows = this.props.workflows;
  //       let workFlow = allWorkflows.filter(
  //         workflow => workflow.id === parseInt(e.target.value)
  //       )[0];
  //       workFlows[workFlow.id] = workFlow;
  //       this.setState({ workFlows, noData: false });
  //     }
  //   }
  // }

  handleWorkFlowRemove = data => {
    let { workFlows } = this.state;
    if (Object.keys(workFlows).includes(data.toString())) {
      delete workFlows[data];
      this.setState({ workFlows, noData: false });
    }
  };

  handleDocRemove = data => {
    let { docs } = this.state;
    if (Object.keys(docs).includes(data.toString())) {
      delete docs[data];
      this.setState({ docs, noData: false });
    }
  };

  handleMarkedCodeRemove = data => {
    let marked_codes = this.props.marked_codes;
    if (marked_codes.includes(data)) {
      marked_codes.splice(marked_codes.indexOf(data), 1);
      this.props.handleMarkedCodeRemove(marked_codes);
    }
  };

  handleFetchCodesFromWorkFlows = event => {
    event.preventDefault();
    let { workFlows, codesFromWorkFlows } = this.state;
    if (_.size(codesFromWorkFlows)) {
      this.setState({
        codesFromWorkFlows: {},
        loading: false
      });
    }
    let codesContainer = document.getElementById("codesFromWorkFlow");
    if (_.size(workFlows)) {
      this.setState({ loading: true });
      codesContainer.classList.remove("workFlow-codes-hide");
      let data = Object.keys(workFlows);
      this.props.fetchCodesFromWorkFlows(data, response => {
        if (response.stepWiseCode) {
          this.setState({
            codesFromWorkFlows: response.stepWiseCode,
            steps: response.steps,
            loading: false,
            noData: _.size(response.stepWiseCode) ? false : true
          });
        }
      });
    }
  };

  handleFetchCodesFromDocs = event => {
    event.preventDefault();
    let { docs, codesFromWorkFlows } = this.state;
    if (_.size(codesFromWorkFlows)) {
      this.setState({
        codesFromWorkFlows: {},
        loading: false
      });
    }
    let codesContainer = document.getElementById("codesFromWorkFlow");
    if (_.size(docs)) {
      this.setState({ loading: true });
      codesContainer.classList.remove("workFlow-codes-hide");
      let data = Object.keys(docs);
      this.props.fetchCodesFromDocs(data, response => {
        if (response.stepWiseCode) {
          this.setState({
            codesFromWorkFlows: response.stepWiseCode,
            steps: response.steps,
            loading: false,
            noData: _.size(response.stepWiseCode) ? false : true
          });
        }
      });
    }
  };

  returnCodesFromStep = (step = []) => {
    return _.map(step, element => {
      let name = this.props.glossariesCodeIdToName[element.name];
      let description = this.getLabelFromCode(element);
      return (
        <Dropdown.Item eventKey={element.name} key={_.uniqueId()}>
          <div
            className="helpbox-item workflow-codes-list"
            id={element.id}
            key={element.id}
          >
            <b>{element.name}</b>
            <br />
            <h6>
              {strings.get(
                "App.documents.documentForm.workFlow.codeDetail.name"
              )}
              : {name ? name : element.name}
            </h6>
            <h6>
              {strings.get(
                "App.documents.documentForm.workFlow.codeDetail.description"
              )}
              : {description}
            </h6>
          </div>
        </Dropdown.Item>
      );
    });
  };

  getLabelFromCode = element => {
    let description = "";
    if (element.label) {
      if (typeof element.label === "object") {
        description = element.label.default;
      } else {
        try {
          description = JSON.parse(element.label).default;
        } catch (error) {
          description = element.label;
        }
      }
    }
    if (element.description) {
      if (typeof element.description === "object") {
        description = element.description.default;
      } else {
        try {
          description = JSON.parse(element.description).default;
        } catch (error) {
          description = element.description;
        }
      }
    }
    return description;
  };

  copyToClipboard = async text => {
    try {
      await navigator.clipboard.writeText(text);
      this.props.handleCopyCodesMarking(text);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  handleNoData = () => {
    setTimeout(() => {
      let element = document.getElementById("no-data");
      if (element) element.style.display = "none";
    }, 3000);
    if (!document.getElementById("no-data"))
      return (
        <span id="no-data">
          {strings.get("App.glossaries.glossaryForm.noData")}
        </span>
      );
  };

  handleWorkFlowSelect = event => {
    let id = event.value;
    let { workFlows } = this.state;
    if (!Object.keys(workFlows).includes(id)) {
      let allWorkflows = this.props.workflows;
      let workFlow = allWorkflows.filter(workflow => workflow.id === id)[0];
      workFlows[workFlow.id] = workFlow;
      this.setState({ workFlows, noData: false });
    }
  };

  handleDocSelect = event => {
    let id = event.value;
    let { docs } = this.state;
    if (!Object.keys(docs).includes(id)) {
      let allDocs = this.props.allDocs;
      let doc = allDocs.filter(doc => doc.id === id)[0];
      docs[doc.id] = doc;
      this.setState({ docs, noData: false });
    }
  };

  render() {
    let allWorkFlows = _.map(this.props.workflows, item => {
      return {
        value: item.id,
        label: item.name
      };
    });
    let autoSuggestWorkFlow = (
      <SelectSearch
        options={allWorkFlows}
        placeholder={strings.get('App.documents.documentForm.workFlow.selectWorkFlowOption')}
        value={allWorkFlows.value}
        name="allWorkFlow"
        onChange={this.handleWorkFlowSelect}
      />
    );

    // let workFlowOptions = _.map(this.props.workflows, item => {
    //   return (
    //     <option value={item.id} key={item.id}>
    //       {item.name}
    //     </option>
    //   );
    // });

    let selectedworkFlows = _.map(this.state.workFlows, item => {
      if (typeof item !== "object") {
        return false;
      }
      return (
        <ActiveLabel
          name={item.name}
          value={item.id}
          onRemove={this.handleWorkFlowRemove}
          key={`workFlow-${item.id}`}
        />
      );
    });

    let codes_from_workFlows = _.map(
      this.state.codesFromWorkFlows,
      (step, key) => {
        let copiedText = "";
        _.map(step, item => {
          if (item.name) {
            copiedText = copiedText.concat(
              `${this.getLabelFromCode(item)} [[${item.name}]] \n`
            );
          }
        });
        return (
          <div key={key}>
            <DropdownButton
              id={key}
              title={this.buildLabel(this.state.steps[key].name)}
              bsStyle="default"
              noCaret
            >
              <Dropdown.Item
                key={_.uniqueId()}
                eventKey={_.uniqueId()}
                onClick={() => {
                  this.copyToClipboard(copiedText);
                }}
              >
                {strings.get("App.documents.documentForm.workFlow.copyMessage")}
              </Dropdown.Item>
              <Dropdown.Item key={_.uniqueId()} eventKey={_.uniqueId()} divider />
              {this.returnCodesFromStep(step)}
            </DropdownButton>
          </div>
        );
      }
    );

    let markedCodes = _.map(this.props.marked_codes, item => {
      return (
        <ActiveLabel
          name={item}
          value={item}
          onRemove={this.handleMarkedCodeRemove}
          key={`markedCode-${item}`}
        />
      );
    });

    let selectedDocs = _.map(this.state.docs, item => {
      if (typeof item !== "object") {
        return false;
      }
      return (
        <ActiveLabel
          name={item.name}
          value={item.id}
          onRemove={this.handleDocRemove}
          key={`workFlow-${item.id}`}
        />
      );
    });
    let allDocs = _.map(this.props.allDocs, item => {
      return {
        value: item.id,
        label: item.name
      };
    });
    let autoSuggestAllDoc = (
      <SelectSearch
        options={allDocs}
        placeholder={strings.get('App.documents.documentForm.workFlow.selectDocOption')}
        value={allDocs.value}
        name="allWorkFlow"
        onChange={this.handleDocSelect}
      />
    );
    let docDropDown = (
      <div>
        <label className="col-sm-3 control-label">
          {strings.get(
            "App.documents.documentForm.workFlow.selectDocLabel"
          )}
        </label>
        <div className="col-sm-6 col-xs-9" style={{ marginBottom: "5px" }}>
          {autoSuggestAllDoc}
        </div>
        <div
          className="col-sm-3 col-xs-3"
          style={{ marginBottom: "5px", paddingLeft: 0 }}
        >
          <button
            className="btn btn-primary"
            onClick={this.handleFetchCodesFromDocs}
          >
            {strings.get("App.documents.documentForm.workFlow.fetch")}
          </button>
        </div>
      </div>
    );

    let stepsOfCodesLabel =
      this.state.loading || _.size(codes_from_workFlows) ? (
        <label className="col-sm-3 control-label">
          {strings.get("App.documents.documentForm.workFlow.stepsOfCodes")}
        </label>
      ) : null;

    let showCodes = (
      <div
        id="codesFromWorkFlow"
        className="workFlow-codes workFlow-codes-hide col-sm-9 col-xs-9"
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {this.state.loading ? (
            <div>
              <ReactLoading type="spinningBubbles" color="#CCC" />
            </div>
          ) : (
            codes_from_workFlows
          )}
          {this.state.noData ? this.handleNoData() : null}
        </div>
      </div>
    );

    let showMarkedCodes = (
      <div style={{ marginTop: "5px" }}>
        <label className="col-sm-3 control-label">
          {strings.get("App.documents.documentForm.workFlow.markedCodes")}
        </label>
        <div className="col-sm-9 col-md-9 col-xs-12">
          {_.size(this.props.marked_codes)
          ? ( markedCodes )
          : <div style={{ height: "30px", border: "1px solid lightgray" }}></div>
          }
        </div>
      </div>
    );

    let showSelectedDocs = (
      !_.size(this.state.docs) ? null : (
        <div className="col-sm-offset-3 col-sm-9 col-xs-12">
          {selectedDocs}
        </div>
      )
    );

    let workFlowDropDownLabel = (
      <label className="col-sm-3 control-label">
        {strings.get(
          "App.documents.documentForm.workFlow.selectWorkFlowLabel"
        )}
      </label>
    );
    let workFlowDropDown = (
      <div className="col-sm-6 col-xs-9" style={{ marginBottom: "5px" }}>
      {/* <select
        className="form-control"
        name="workFlowOptions"
        onChange={this.handleInputChange}
        defaultValue="select_workflow"
      >
        <option disabled value="select_workflow">
          {strings.get('App.documents.documentForm.workFlow.selectWorkFlowOption')}
        </option>
        {workFlowOptions}
      </select> */}
      {autoSuggestWorkFlow}
    </div>
    );

    let workFlowDropDownFetchButton = (
      <div
      className="col-sm-3 col-xs-3"
      style={{ marginBottom: "5px", paddingLeft: 0 }}
      >
        <button
          className="btn btn-primary"
          onClick={this.handleFetchCodesFromWorkFlows}
        >
          {strings.get("App.documents.documentForm.workFlow.fetch")}
        </button>
      </div>
    );

    let showSelectedWorkFlows = (
      !_.size(this.state.workFlows) ? null : (
        <div className="col-sm-offset-3 col-sm-9 col-xs-12">
          {selectedworkFlows}
        </div>
      )
    );

    return (
      <div className="form-group WorkFlowCodes">
        {workFlowDropDownLabel}
        {workFlowDropDown}
        {workFlowDropDownFetchButton}
        {showSelectedWorkFlows}
        <div className="clearfix"></div>
        {docDropDown}
        {showSelectedDocs}
        <div className="clearfix"></div>
        {stepsOfCodesLabel}
        {showCodes}
        <div className="clearfix"></div>
        {showMarkedCodes}
      </div>
    );
  }
}

export default WorkFlowCodes;
