
import _ from 'lodash';

export const types = {
  TOGGLE: 'sidebar.TOGGLE',
  SET_CLIENT_SITE_URL : 'SET_CLIENT_SITE_URL'
}

export function toggle(id) {
	return {
		type: types.TOGGLE,
	}
}

export function setClientSiteUrl(clientSiteUrl = '') {
	return {
    type: types.SET_CLIENT_SITE_URL,
    payload: {
      clientSiteUrl
    }
	}
}
