import React, { Component } from "react";
import _ from "lodash";

import { connect } from "react-redux";
import { NotificationContainer } from "react-notifications";
import strings from "./services/strings";
// import language from "./services/language";
import auth from "./services/auth";

import * as authActions from "./store/auth/actions";
import * as exceptionsSelectors from "./store/exceptions/selectors";
import * as exceptionsActions from "./store/exceptions/actions";
import * as sidebarSelectors from "./store/sidebar/selectors";
import * as sidebarActions from "./store/sidebar/actions";
import * as settingsActions from "./store/settings/actions";
import * as settingsSelectors from "./store/settings/selectors";
import * as glossariesActions from "./store/glossaries/actions";
import * as stepsActions from "./store/steps/actions";
// import { Helmet } from "react-helmet";

import Sidebar from "./components/Sidebar";
import LoadingScreen from "./components/LoadingScreen";
import { Routes } from "./routes";
import { browserHistory } from "./helpers";

import "./App.scss";
import "./AppTabletLandscape.scss";
import "./AppTabletPortret.scss";
import "./AppMobile.scss";

import "react-datepicker/dist/react-datepicker.css";
import "react-notifications/lib/notifications.css";
// import "react-flags-select/scss/react-flags-select.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      lang: {},
      titlePrefix: "",
    };
  }

  componentDidMount() {
    console.log("React 16.3.1");
    if (!auth.isAuthenticated()) {
      browserHistory.push("/login");
    }
    this.props.clearExceptions();
    this.props.clearExtraSettings();
    this.props.fetchAllSettings();
    this.props.fetchAllSteps();
    this.props.fetchAllGlossaries();
    this.setCurrentLanguage(this.props);
  }

  componentDidUpdate(prevProps) {
    if (_.size(this.props.exceptions)) {
      let el = document.getElementsByClassName("has-error");
      if (_.size(el)) {
        el[0].scrollIntoView({ behavior: "smooth" });
      }
    }
    if (_.size(this.props.settings)) {
      _.map(this.props.settings, (item) => {
        //custom title favicon
        if (item.key === "admin_title_favicon") {
          let link = document.getElementById("favicon");
          if (!link) {
            link = document.createElement("link");
            link.id = "favicon";
            link.rel = "shortcut icon";
            document.head.appendChild(link);
          }
          link.href = item.imageURL;
        }
      });
    }
    if (prevProps.currentLanguage !== this.props.currentLanguage) {
      this.setCurrentLanguage(this.props);
    }
    if (
      this.props.settings &&
      this.props.settings.client_site_url &&
      this.props.settings.client_site_url.value
    ) {
      this.props.setClientSiteUrl(this.props.settings.client_site_url.value);
    }
  }

  setCurrentLanguage = (props = this.props) => {
    if (props.currentLanguage) {
      this.setState(
        {
          lang: props.currentLanguage,
          titlePrefix: strings.get("App.titlePrefix"),
        },
        () => {
          this.getTitle();
        }
      );
    }
  };

  getTitle = () => {
    this.props.fetchAllSettings();
  };

  getMetaFields = (title) => {
    // return (
    //   <Helmet>
    //     {/* <title>{`${ this.state.title === undefined || this.state.title === '' ? this.state.titlePrefix : `${this.state.titlePrefix + '-' + this.state.title}` }`}</title> */}
    //     <title>{title}</title>
    //   </Helmet>
    // );
    document.title = title;
  };

  // handleLangChange() {
  //   let lang = {};
  //   lang["id"] = language.get();
  //   lang["iso2"] = language.getIso2();
  //   this.setState({ lang, titlePrefix: strings.get("App.titlePrefix") }, () => {
  //     this.getTitle(this.state.lang ? this.state.lang.iso2 : "NL");
  //   });
  // }

  getClassName = () => {
    if (this.props.sidebarOpened) {
      return "App sidebar-opened";
    }

    return "App";
  };

  handleSidebarIconClick = () => {
    this.props.toggleSidebar();
  };

  render() {
    let title = "";
    const { screenLoading } = this.props
    if (this.props.settings && this.props.settings.admin_site_name) {
      title = this.props.settings.admin_site_name.value;
    }
    // if (!strings.get("App.turnDevice")) {
    //   // loading screen will be visible if strings are not loaded
    //   return <LoadingScreen />;
    // }
    return (
      <>
        {screenLoading && <LoadingScreen />}
        <div className={this.getClassName()}>
          <NotificationContainer />
          {this.props.screenLoading ? <LoadingScreen /> : null}
          {this.getMetaFields(title)}
          <div className="sidebar transation-2s-in-out">
            <Sidebar
              toggleSidebar={this.props.toggleSidebar}
              logout={this.props.logout}
              lang={this.state.lang}
              title={title}
            />
          </div>
          <div className="container transation-2s-in-out">
            <div className="sidebar-icon" onClick={this.handleSidebarIconClick}>
              <i className="ion-android-menu"></i>
            </div>
            <Routes />
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    exceptions: exceptionsSelectors.getItems(state),
    sidebarOpened: sidebarSelectors.isOpened(state),
    settings: settingsSelectors.getItems(state),
    screenLoading: settingsSelectors.getScreenLoadingStatus(state),
    currentLanguage: settingsSelectors.getCurrentLanguage(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => {
      dispatch(authActions.logout());
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
    toggleSidebar: () => {
      dispatch(sidebarActions.toggle());
    },
    setClientSiteUrl: (clientSiteUrl) => {
      dispatch(sidebarActions.setClientSiteUrl(clientSiteUrl));
    },
    fetchAllSettings: () => {
      dispatch(settingsActions.fetchAllItems());
    },
    clearExtraSettings: () => {
      dispatch(settingsActions.clearExtraSettings());
    },
    fetchAllGlossaries: () => {
      dispatch(glossariesActions.fetchAllItems());
    },
    fetchAllSteps: (deleteCache) => {
      dispatch(stepsActions.fetchAllItems(deleteCache));
    },
    handleLangChange: () => {
      dispatch(settingsActions.handleLanguageChange());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
