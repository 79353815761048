import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Modal from "reboron/DropModal";

import strings from "../../../services/strings";

import Switch from "../../Switch.js";

import "./RequiredOptionsList.scss";

export default function RequiredOptionsList(props) {
  const stepVerificationModal = React.useRef(null);

  const handleChange = (enabled) => {
    props.updateItem({
      required_fields: enabled ? 1 : 0,
    });
  };

  const confirmStepVerification = () =>{
    props.updateItem({
      otp_verification: 0,
    });
    stepVerificationModal.current.hide()
  }

  const cancelStepVerification=() => {
    stepVerificationModal.current.hide()
  }

  const handleStepChange = (e) =>{
    if(!e){
      stepVerificationModal.current.show()
    }
    else{
      props.updateItem({
        otp_verification: 1,
      });
    }
  }

  const deleteModalContent = (
    <span>
    <div className="modal-close-btn">
      <i
        className="ion-android-cancel clickable"
        onClick={cancelStepVerification}
      />
    </div>
    <h2>
      {strings.get("Client.settings.otpMessage")}
    </h2>
    <div className="form-actions">
      <button
        className="btn btn-lg btn-danger"
        onClick={confirmStepVerification}
      >
        {strings.get("App.workflows.workflowForm.orderFieldsModal.save")}
      </button>
      <button
        className="btn btn-lg btn-default cancel"
        onClick={cancelStepVerification}
      >
        {strings.get("App.deleteModal.cancel")}
      </button>
    </div>
  </span>
  )

  let stepVerificationModalConfirm = (
    <Modal className="boron-modal" ref={stepVerificationModal}>
      {deleteModalContent}
    </Modal>
  );
  
  let items = _.map(props.items, (value) => {
    return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 "> 
    <li className="list-group-item clearfix">
      {strings.get("Client.settings.requiredOptions")}
      <span className="ml-auto">
        <Switch
          docsize=""
          enabled={!!value.required_fields}
          onChange={(enabled) => handleChange(enabled)}
        />
      </span>
    </li>
    </div>
      <div className="col-xs-12 col-sm-12 col-md-6 ">
        <li className="list-group-item clearfix">
          {strings.get("Client.settings.otpVerification")}
          <span className="ml-auto">
            <Switch
              docsize=""
              enabled={!!value.otp_verification}
              onChange={(enabled) => handleStepChange(enabled)}
            />
          </span>
        </li>
      </div>
    </div>
    );
  });

  return (
    <div className="RequiredOptionsList">
      <ul className="list-group">{items}</ul>
      {stepVerificationModalConfirm}
    </div>
  );
}

RequiredOptionsList.propTypes = {
  items: PropTypes.object.isRequired,
  updateItem: PropTypes.func.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
};
