
import api from '../../services/api';
import _ from 'lodash';

import * as exceptionsActions from '../exceptions/actions';
import * as companyCategoriesSelectors from './selectors';
import { browserHistory } from '../../helpers';



export const types = {
	FETCH_ALL_ITEMS_DONE: 'companyCategories.FETCH_ALL_ITEMS_DONE',
	FETCH_ITEMS_DONE: 'companyCategories.FETCH_ITEMS_DONE',
	FETCH_ITEM_DONE: 'companyCategories.FETCH_ITEM_DONE',
	SET_SEARCH_TERM: 'companyCategories.SET_SEARCH_TERM',
	SET_CURRENT_PAGE: 'companyCategories.SET_CURRENT_PAGE',
	SET_CURRENT_ITEM_ID: 'companyCategories.SET_CURRENT_ITEM_ID',
	TOGGLE_SORTER: 'companyCategories.TOGGLE_SORTER',
	CLEAR_CACHE: 'companyCategories.CLEAR_CACHE',
	SET_SORTER: 'companyCategories.SET_SORTER',
}

export function setCurrentPage(page) {
	return {
		type: types.SET_CURRENT_PAGE,
		payload: {
			page
		}
	}
}

export function setCurrentItemId(id) {
	return {
		type: types.SET_CURRENT_ITEM_ID,
		payload: {
			id
		}
	}
}

export function unsetCurrentItemId() {
	return {
		type: types.SET_CURRENT_ITEM_ID,
		payload: {
			id: null,
		}
	}
}

export function toggleSorter(column) {
	return {
		type: types.TOGGLE_SORTER,
		payload: {
			column
		}
	}
}

export function setSearchTerm(searchTerm) {
	return {
		type: types.SET_SEARCH_TERM,
		payload: {
			searchTerm
		}
	}
}

export function clearCache() {
	return {
		type: types.CLEAR_CACHE
	}
}

export function fetchAllItems() {
	return async (dispatch, getState) => {
		try {
			let items = await api.get('/company-categories/buildCompanyCategories');
			// dispatch(clearCache());
			dispatch({
				type: types.FETCH_ALL_ITEMS_DONE,
				payload: {
					items
				}
			});
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function fetchItems(deleteCache = false) {
	return async (dispatch, getState) => {
		let state = getState();
		try {
			// Set additional params
			let params = new Map();
			// let filters = companyCategoriesSelectors.getFilters(state);
			// params.set('search_term', filters.searchTerm);
			let pagination = companyCategoriesSelectors.getPagination(state);
			params.set('page_size', pagination.pageSize);
			params.set('page_number', deleteCache ? 1 : pagination.currentPage);
			let sorter = companyCategoriesSelectors.getSorter(state);
			params.set('page_size', sorter.pageLimit);

			// GET request from API
			let [response, items] = await api.get('/company-categories/getAppList', params, true);

			// Clear cache if deleteCache is enabled
			if (deleteCache) {
				dispatch(clearCache());
			}

			dispatch({
				type: types.FETCH_ITEMS_DONE,
				payload: {
					totalPages: parseInt(response.headers.get('X-Total-Pages')),
					items
				}
			});
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function fetchItem(id) {
	return async (dispatch) => {
		try {
			// GET request from API
			let item = await api.get(`/company-categories/${id}`);
			dispatch({
				type: types.FETCH_ITEM_DONE,
				payload: {
					item
				}
			})
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function createItem(data) {
	return async (dispatch) => {
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// POST request to API
			await api.post('/company-categories', params);
			browserHistory.push(`/glossaries/companies/categories`);

			dispatch(exceptionsActions.clear());
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function updateItem(id, data) {
	return async (dispatch) => {
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// PUT request to API
			await api.put(`/company-categories/${id}`, params);
			browserHistory.push(`/glossaries/companies/categories`);

			dispatch(exceptionsActions.clear());
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function deleteItem(id, handleCatDeleteResponse) {
	return async (dispatch) => {
		try {
			// DELETE request to API
      await api.delete('/company-categories/' + id)
      .then(
        (response) => {
          handleCatDeleteResponse(response);
          if(response === "success"){
            dispatch(fetchItems());
          }
        }
      )
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function setSorter(sorter) {
	return {
		type: types.SET_SORTER,
		payload: {
			sorter
		}
	}
}

export function orderItems(items) {
	return async (dispatch) => {
		try {
			let params = new Map();
      let parent_id = items.source.parent_id;
      if(parent_id === null && typeof parent_id === "object"){
        parent_id = '';
      }
			params.set('id', items.source.id);
			params.set('to', items.target.id);
			params.set('direction', items.direction);
			params.set('parent_id', parent_id);
			// PUT request to API
			await api.put('/company-categories/order', params);

			dispatch(exceptionsActions.clear());
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}
