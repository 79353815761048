import React, { Component } from "react";
import _ from "lodash";
import strings from "../../../../services/strings";
import Dropzone from "react-dropzone";
import ReactAvatarEditor from "react-avatar-editor";
import settings from "../../../../services/settings";

class HeaderLogo extends Component {
  state = {
    image: null,
    cropImageData: null,
    headerFile: null,
  };
  setEditorRef = (editor) => (this.editor = editor);

  setEditorRef = (editor: any) => {
    if (editor) {
      this.editor = editor;
      // console.log('this.editor', this.editor)
      const img = this.editor.getImageScaledToCanvas().toDataURL();
      // console.log('hello i am here', img)
    }
  };

  cropImage = () => {
    this.setState({
      image: null,
      cropImageData: this.editor.getImageScaledToCanvas().toDataURL(),
    });
    // var contentType = 'image/png'

    let crooperD = this.editor.getImageScaledToCanvas().toDataURL();
    // console.log('++++++', crooperD.split(',')[1])

    // var blob = b64toBlob(crooperD.split(',')[1], contentType)
    // console.log('b64toBlob', blob)
    // var blobUrl = URL.createObjectURL(blob)

    let form = _.extend(this.state.form, {});
    form[settings.keys.HEADER_LOGO] = form[settings.keys.HEADER_LOGO].asMutable
      ? form[settings.keys.HEADER_LOGO].asMutable()
      : form[settings.keys.HEADER_LOGO];
    form[settings.keys.HEADER_LOGO].file.preview = crooperD;

    this.setState({
      // header_logo: this.editor.getImageScaledToCanvas().toDataURL(),
      headerFile: this.editor.getImageScaledToCanvas().toDataURL(),
    });
  };

  handleHeaderFileDrop = (dropped) => {
    // console.log('drop', dropped)
    let form = _.extend(this.state.form, {});
    form[settings.keys.HEADER_LOGO] = form[settings.keys.HEADER_LOGO].asMutable
      ? form[settings.keys.HEADER_LOGO].asMutable()
      : form[settings.keys.HEADER_LOGO];
    form[settings.keys.HEADER_LOGO].file = dropped[0];
    console.log(this.state.form);
    this.setState({ image: dropped[0], cropClass: false });
    console.log(this.state.form);
  };

  render() {
    const { image, cropImageData, headerFile } = this.state;
    const { dropzoneContent } = this.props;
    return (
      <div className="form-group">
        <label className="control-label">
          {strings.get("App.settings.contactSetting.headerLogo")}
        </label>

        {image || cropImageData || headerFile ? (
          <input
            type="button"
            className="close-btn"
            onClick={this.props.removeImage(
              "image",
              "headerFile",
              "header_logo"
            )}
            value="X"
          />
        ) : this.props.isImage("headerFile", "header_logo") ? (
          <input
            type="button"
            className="close-btn"
            onClick={this.props.setImage("headerFile", "header_logo")}
            value="X"
          />
        ) : (
          ""
        )}

        {/* <input type='button' className={this.state.active ? 'your_className': null}  onClick={this.handleImage} value='X' /> */}

        <Dropzone
          className="dropzone dropzone-first"
          onDrop={this.handleHeaderFileDrop}
          multiple={false}
          maxSize={4096000}
          accept="image/*"
        >
          {/* {dropzoneContent}
              {this.state.image ? (
                <ReactAvatarEditor
                  scale={this.state.scale}
                  rotate={0}
                  className='image-placeholder'
                  image={this.state.image}
                  width={320}
                  height={150}
                  ref={ref => this.setEditorRef(ref)}
                />
              ) : this.state.headerFile ? (
                <img src={this.state.headerFile} />
              ) : (
                ''
              )} */}
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              {dropzoneContent}

              {this.state.image ? (
                <ReactAvatarEditor
                  scale={this.state.scale}
                  rotate={0}
                  className="image-placeholder"
                  image={this.state.image}
                  width={320}
                  height={150}
                  ref={(ref) => this.setEditorRef(ref)}
                />
              ) : this.state.headerFile ? (
                <img src={this.state.headerFile} />
              ) : (
                ""
              )}
            </div>
          )}
        </Dropzone>

        {this.state.cropClass === false ? (
          <div className="help-block">
            {/* <div className='help-block'>(Suggestie: breedte 142px, hoogte 20px)</div> */}
            <div className="help-block">
              ( {strings.get("App.commonImage.sizeSuggestion")} )
            </div>
            <small className="text-danger headerErrorUpload" />
            {this.state.image !== null ? (
              <input
                type="range"
                step="0.01"
                min="0.1"
                max="2"
                name="scale"
                value={this.state.scale}
                onChange={this.handleChange}
              />
            ) : (
              ""
            )}

            {this.state.image !== null ? (
              <input
                type="button"
                className="crop-image-btn"
                onClick={this.cropImage}
                value="Crop"
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default HeaderLogo;
