import React, { Component } from "react";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import settings from "../../../services/settings";
import { Link } from "react-router-dom";

import * as settingsActions from "../../../store/settings/actions";
import * as settingsSelectors from "../../../store/settings/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";

import Topbar from "../../../components/Topbar";
import SubTopbar from "../../../components/SubTopbar";
import TemplateNotesForm from "../../../components/settings/templateNotes/TemplateNotesForm";
import SettingsTabs from "../../../components/settings/SettingsTabs";
import SettingsLeftTabs from "../../../components/settings/SettingsLeftTabs";
import { NotificationManager } from "react-notifications";

import "../settings.scss";
import "../../Page.scss";

class TemplateNotes extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchAllSettings();
  }

  saveSettings = (data) => {
    let codeBlockNotesPromise = this.props.updateSetting(
      data.form[settings.keys.CODE_BLOCK_CONTENT_NOTES]
    );

    let templateCommonNotes = this.props.updateSetting(
      data.form[settings.keys.TEMPLATE_COMMON_NOTES]
    );

    Promise.all([codeBlockNotesPromise, templateCommonNotes]).then(() => {
      NotificationManager.success(strings.get("App.settings.settingsSaved"));
    });
  };

  render() {
    return (
      <div className="LanguageListPage">
        <Topbar>
          <div className="title">
            <Link to="/settings/email">
              {strings.get("App.settings.title")}
            </Link>
          </div>
        </Topbar>

        <div className="content settings-container">
          <SubTopbar>
            <SettingsTabs />
          </SubTopbar>
          <div className="setting-content row">
            <SettingsLeftTabs currentItemId={10} />
            <TemplateNotesForm
              settings={this.props.settings}
              saveSettings={this.saveSettings}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: settingsSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllSettings: () => {
      dispatch(settingsActions.fetchAllItems());
    },
    updateSetting: (data) => {
      return dispatch(settingsActions.updateItem(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateNotes);
