import React, { Component } from "react";
import _ from "lodash";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as glossariesActions from "../../store/glossaries/actions";
import * as glossariesSelectors from "../../store/glossaries/selectors";
import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as stepsActions from "../../store/steps/actions";
import * as stepsSelectors from "../../store/steps/selectors";
import * as articlesActions from "../../store/articles/actions";
import * as articlesSelectors from "../../store/articles/selectors";
import * as settingsActions from "../../store/settings/actions";
import * as settingsSelectors from "../../store/settings/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";
import Topbar from "../../components/Topbar";
// import SubTopbar from "../../components/SubTopbar";
import GlossaryForm from "../../components/glossary/GlossaryForm";

class GlossaryEditPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  onImport = () => {
    this.componentDidMount();
  };

  componentDidMount() {
    if (!_.size(this.props.settings)) {
      this.props.fetchAllSettings();
    }
    this.props.fetchGlossary(this.props.match.params.id);
    this.props.setCurrentGlossaryId(this.props.match.params.id);

    this.props.fetchAllCategories();
    if (!_.size(this.props.steps)) {
      this.props.fetchAllSteps();
    }
    if (!_.size(this.props.glossaries)) {
      this.props.fetchAllGlossaries();
    }
    if (_.size(this.props.articles)) {
      this.props.fetchAllArticles();
    }
  }

  componentWillUnmount() {
    this.props.unsetCurrentGlossaryId();
    this.props.clearExceptions();
  }

  async saveGlossary(data) {
    await this.props.updateGlossary(this.props.match.params.id, data.form);
  }

  onSaveCodeBlock = async (id, data) => {
    await this.props.onSaveCodeBlock(id, data);
    this.props.fetchGlossary(this.props.match.params.id);
  };

  render() {
    return (
      <div className="GlossaryEditPage">
        <Topbar templateGuide={true}>
          <div className="title">
            <Link to="/glossaries">
              {strings.get("App.glossaryPages.title")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to={`/glossaries/${this.props.match.params.id}`}>
                {strings.get("App.glossaryPages.edit")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content">
          <GlossaryForm
            params={this.props.match.params}
            exceptions={this.props.exceptions}
            categories={this.props.categories}
            glossaries={this.props.glossaries}
            currentItem={this.props.currentGlossary}
            steps={this.props.steps}
            saveItem={this.saveGlossary}
            articles={this.props.articles}
            onSaveClause={this.props.onSaveClause}
            onSaveField={this.props.onSaveField}
            onSaveSelector={this.props.onSaveSelector}
            onSaveCodeBlock={this.props.onSaveCodeBlock}
            onSaveCarousel={this.props.onSaveCarousel}
            onDeleteClause={this.props.onDeleteClause}
            onDeleteSelector={this.props.onDeleteSelector}
            onDeleteField={this.props.onDeleteField}
            onDeleteCodeBlock={this.props.onDeleteCodeBlock}
            onDeleteCarousel={this.props.onDeleteCarousel}
            settings={this.props.settings}
            exportGlossaries={this.props.exportGlossaries}
            createSelector={this.props.createSelector}
            createClause={this.props.createClause}
            createField={this.props.createField}
            createCodeBlock={this.props.createCodeBlock}
            createCarousel={this.props.createCarousel}
            importGlossaries={this.props.importGlossaries}
            undoImportGlossary={this.props.undoImportGlossary}
            onImport={this.onImport}
            updateCurrentGlossaryStore={this.props.updateCurrentGlossaryStore}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentGlossary: glossariesSelectors.getCurrentItem(state),
    glossaries: glossariesSelectors.getItems(state),
    categories: categoriesSelectors.getItems(state),
    steps: stepsSelectors.getItems(state),
    articles: articlesSelectors.getItems(state),
    settings: settingsSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
    fetchAllGlossaries: () => {
      dispatch(glossariesActions.fetchAllItems());
    },
    fetchAllCategories: (deleteCache) => {
      dispatch(categoriesActions.fetchAllItems(deleteCache));
    },
    fetchAllSteps: (deleteCache) => {
      dispatch(stepsActions.fetchAllItems(deleteCache));
    },
    fetchGlossary: (id) => {
      dispatch(glossariesActions.fetchItem(id));
    },
    setCurrentGlossaryId: (id) => {
      dispatch(glossariesActions.setCurrentItemId(id));
    },
    unsetCurrentGlossaryId: () => {
      dispatch(glossariesActions.unsetCurrentItemId());
    },
    updateGlossary: (id, data, fetchWithoutFilters = true) => {
      dispatch(glossariesActions.updateItem(id, data, fetchWithoutFilters));
    },
    createGlossary: (data) => {
      dispatch(glossariesActions.createItem(data));
    },
    fetchAllArticles: (deleteCache) => {
      dispatch(articlesActions.fetchAllItems(deleteCache));
    },
    onSaveClause: (id, data, documentCodeEdit) => {
      dispatch(glossariesActions.updateClause(id, data, documentCodeEdit));
    },
    onSaveField: (id, data, documentCodeEdit) => {
      dispatch(glossariesActions.updateField(id, data, documentCodeEdit));
    },
    onSaveSelector: (id, data, documentCodeEdit) => {
      dispatch(glossariesActions.updateSelector(id, data, documentCodeEdit));
    },
    onSaveCodeBlock: (id, data, documentCodeEdit) => {
      return dispatch(glossariesActions.updateCodeBlock(id, data, documentCodeEdit));
    },
    onSaveCarousel: (id, data, documentCodeEdit) => {
      return dispatch(glossariesActions.updateCarousel(id, data, documentCodeEdit));
    },
    onDeleteClause: (id) => {
      dispatch(glossariesActions.onDeleteClause(id));
    },
    onDeleteSelector: (id) => {
      dispatch(glossariesActions.onDeleteSelector(id));
    },
    onDeleteField: (id) => {
      dispatch(glossariesActions.onDeleteField(id));
    },
    onDeleteCodeBlock: (id) => {
      dispatch(glossariesActions.onDeleteCodeBlock(id));
    },
    onDeleteCarousel: (id) => {
      dispatch(glossariesActions.onDeleteCarousel(id));
    },
    fetchAllSettings: () => {
      dispatch(settingsActions.fetchAllItems());
    },
    exportGlossaries: (id) => {
      return dispatch(glossariesActions.exportGlossaries(id));
    },
    createSelector: (data) => {
      return dispatch(glossariesActions.createSelector(data));
    },
    createClause: (data) => {
      return dispatch(glossariesActions.createClause(data));
    },
    createField: (data) => {
      return dispatch(glossariesActions.createField(data));
    },
    createCodeBlock: (data) => {
      return dispatch(glossariesActions.createCodeBlock(data));
    },
    createCarousel: (data) => {
      return dispatch(glossariesActions.createCarousel(data));
    },
    exportGlossaries: (id, name) => {
      return dispatch(glossariesActions.exportGlossaries(id, name));
    },
    importGlossaries: (id, data, succ, err) => {
      return dispatch(glossariesActions.importGlossaries(id, data, succ, err));
    },
    undoImportGlossary: (id, succ, err) => {
      return dispatch(glossariesActions.undoImportGlossary(id, succ, err));
    },
    updateCurrentGlossaryStore: (currentItem, currentGlossary, documentEditCode) => {
      return dispatch(
        glossariesActions.updateCurrentGlossaryStore(
          currentItem,
          currentGlossary,
          documentEditCode
        )
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GlossaryEditPage);
