import React, { Component } from "react";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import { browserHistory } from "../../helpers";

class UserSettingsTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
    };
  }

  componentDidMount() {
    this.setState({
      userId: this.props.userId,
    });
  }

  getLinkClassName = (linkName) => {
    let path = window.location.pathname.split("/");
    return path[3] === linkName ? "active" : "";
  };

  //NavItem href is not working, So onClick props is using in NavItem.
  navItemClick = (link) => {
    browserHistory.push(link);
  };

  render() {
    return (
      <span className="SettingsTabs UserSettingsTabs">
        <Navbar collapseOnSelect>
          <span className="visible-xs navbar-header">
            <Navbar.Brand>
              <Link to={`/users/${this.state.userId}/profile`}>
                {strings.get("App.users.settingTab.profile")}
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </span>

          <Navbar.Collapse className="nav nav-tabs">
            {/* <Nav className="nav nav-tabs"> */}
            <Nav.Link
              eventkey={1}
              href={`/users/${this.state.userId}/profile`}
              className={this.getLinkClassName("profile")}
              onClick={() =>
                this.navItemClick(`/users/${this.state.userId}/profile`)
              }
            >
              {strings.get("App.users.settingTab.profile")}
            </Nav.Link>
            <Nav.Link
              eventkey={2}
              href={`/users/${this.state.userId}/drafts`}
              className={this.getLinkClassName("drafts")}
              onClick={() =>
                this.navItemClick(`/users/${this.state.userId}/drafts`)
              }
            >
              {strings.get("App.users.settingTab.drafts")}
            </Nav.Link>
            <Nav.Link
              eventkey={3}
              href={`/users/${this.state.userId}/roledrafts`}
              className={this.getLinkClassName("roledrafts")}
              onClick={() =>
                this.navItemClick(`/users/${this.state.userId}/roledrafts`)
              }
            >
              {strings.get("App.users.settingTab.roles")}
            </Nav.Link>
            <Nav.Link
              eventkey={8}
              href={`/users/${this.state.userId}/workflow`}
              className={this.getLinkClassName("workflow")}
              onClick={() =>
                this.navItemClick(`/users/${this.state.userId}/workflow`)
              }
            >
              {strings.get("App.users.settingTab.workflow")}
            </Nav.Link>
            <Nav.Link
              eventkey={4}
              href={`/users/${this.state.userId}/shareddrafts`}
              className={this.getLinkClassName("shareddrafts")}
              onClick={() =>
                this.navItemClick(`/users/${this.state.userId}/shareddrafts`)
              }
            >
              {strings.get("App.users.settingTab.shareddrafts")}
            </Nav.Link>
            <Nav.Link
              eventkey={5}
              href={`/users/${this.state.userId}/downloads`}
              className={this.getLinkClassName("downloads")}
              onClick={() =>
                this.navItemClick(`/users/${this.state.userId}/downloads`)
              }
            >
              {strings.get("App.users.settingTab.downloads")}
            </Nav.Link>
            <Nav.Link
              eventkey={6}
              href={`/users/${this.state.userId}/messages/shareemail`}
              className={this.getLinkClassName("messages")}
              onClick={() =>
                this.Nav.Link(`/users/${this.state.userId}/messages/shareemail`)
              }
            >
              {strings.get("App.users.settingTab.messages")}
            </Nav.Link>
            <Nav.Link
              eventkey={7}
              href={`/users/${this.state.userId}/settings`}
              className={this.getLinkClassName("settings")}
              onClick={() =>
                this.navItemClick(`/users/${this.state.userId}/settings`)
              }
            >
              {strings.get("App.settings.title")}
            </Nav.Link>
            {/* </Nav> */}
          </Navbar.Collapse>
        </Navbar>
      </span>
    );
  }
}

UserSettingsTabs.propTypes = {
  //
};

export default UserSettingsTabs;
