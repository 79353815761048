import React, { Component } from "react";
import _ from "lodash";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "./UserSettings.scss";
import "../Page.scss";

import Topbar from "../../components/Topbar";
import SubTopbar from "../../components/SubTopbar";
import UserSettingsTabs from "../../components/user/UserSettingsTabs";

import NavigationLeftTabs from "./NavigationLeftTabs";
import ShareEmailSettingForm from "../../components/user/shareEmail/ShareEmailSettingForm";

import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";
import * as usersActions from "../../store/users/actions";
import * as usersSelectors from "../../store/users/selectors";
import * as settingsSelectors from "../../store/settings/selectors";

import CustomDropModal from "../../components/common/Popup";

class UserShareEmailPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchUser(this.props.match.params.id);
    this.props.setCurrentUserId(this.props.match.params.id);
  }

  handleShowModal() {
    _.delay(() => {
      this.hideSaveModal();
    }, 1000);
  }

  showSaveModal() {
    this.refs.saveModal.show();
  }

  hideSaveModal() {
    this.refs.saveModal.hide();
  }

  saveUserSettings(data) {
    this.props.updateUser(this.props.match.params.id, data.form);
    this.showSaveModal();
  }

  render() {
    const {loader} =  this.props
    return (
      <div className="UserDownloadPage">
        <CustomDropModal
          className="boron-modal no-body"
          ref="saveModal"
          onShow={this.handleShowModal}
        >
          <span>
            <h2>{strings.get("App.settings.settingsSaved")}</h2>
          </span>
        </CustomDropModal>

        <Topbar>
          <div className="title">
            <Link to="/users">{strings.get("App.userPages.title")}</Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to={`/users/${this.props.match.params.id}/profile`}>
                {strings.get("App.userPages.edit")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content user-settings-container">
          <SubTopbar>
            <UserSettingsTabs userId={this.props.match.params.id} />
          </SubTopbar>
          <div className="user-setting-content">
            <div className="container-row">
              <div className="row">
                {!loader && (
                  <NavigationLeftTabs
                    currentItemId={0}
                    currentLang={this.props.currentLang}
                    currentUserId={this.props.match.params.id}
                  />
                )}
                <ShareEmailSettingForm
                  exceptions={this.props.exceptions}
                  currentItem={this.props.currentUser}
                  saveItem={this.saveUserSettings}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: usersSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
    loader: settingsSelectors.getScreenLoadingStatus(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentUserId: (id) => {
      dispatch(usersActions.setCurrentItemId(id));
    },
    fetchUser: (id) => {
      dispatch(usersActions.fetchItem(id));
    },
    updateUser: (id, data) => {
      dispatch(usersActions.updateItemSettings(id, data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserShareEmailPage);
