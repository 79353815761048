import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import _ from "lodash";
import "./NavigationLeftTabs.scss";

class NavigationLeftTabs extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  getMenuItems(primaryId) {
    // let locale = JSON.parse(localStorage.getItem("language")).iso2;
    let itemsContent = [
      [
        strings.get("App.users.settingTab.shareDocument"),
        `/messages/shareemail`,
      ],
      [
        strings.get("App.users.settingTab.instructionMsg"),
        `/messages/sharedraft`,
      ],
      [strings.get("App.users.settingTab.alerts"), `/messages/alerts`],
    ];
    let items = _.map(itemsContent, (item, i) => (
      <Link
        className={
          i === primaryId
            ? "btn btn-primary right pull-left"
            : "btn btn-default right pull-left"
        }
        to={`/users/${this.props.currentUserId}${item[1]}`}
        key={i}
      >
        {item[0]}
      </Link>
    ));
    return items;
  }

  render() {
    return (
      <div className="navigation-left-tabs col-xs-12 col-sm-3 col-md-3">
        <div className="left-menu">
          {this.getMenuItems(this.props.currentItemId)}
        </div>
      </div>
    );
  }
}
NavigationLeftTabs.propTypes = {
  currentItemId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationLeftTabs);
