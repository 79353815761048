import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import strings from "../../services/strings";
import Topbar from "../../components/Topbar";
import PartnerForm from "../../components/partner/PartnerForm";

import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import "../Page.scss";

import * as partnersActions from "../../store/partners/actions";
import * as partnersSelectors from "../../store/partners/selectors";

class PartnersEditPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchPartner(this.props.match.params.id);
    this.props.setCurrentPartnerId(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.unsetCurrentPartnerId();
    this.props.clearExceptions();
  }

  savePartner(data) {
    this.props.updatePartner(this.props.match.params.id, data.form);
    if (data.file) {
      this.props.uploadPartnerLogo(this.props.match.params.id, data.file);
    }
  }

  render() {
    return (
      <div className="PartnersEditPage">
        <Topbar>
          <div className="title">
            <Link to="/modules/partners">
              {strings.get("App.partnerPages.edit.title")}
            </Link>
            <span className="xs-hidden">
              <span className="divider">/</span>
              <Link to={`/modules/partners/${this.props.match.params.id}`}>
                {strings.get("App.partnerPages.edit.edit")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content">
          <PartnerForm
            exceptions={this.props.exceptions}
            partners={this.props.partners}
            currentItem={this.props.currentPartner}
            saveItem={this.savePartner}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentPartner: partnersSelectors.getCurrentItem(state),
    partners: partnersSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllPartners: (deleteCache) => {
      dispatch(partnersActions.fetchAllItems(deleteCache));
    },
    fetchPartner: (id) => {
      dispatch(partnersActions.fetchItem(id));
    },
    setCurrentPartnerId: (id) => {
      dispatch(partnersActions.setCurrentItemId(id));
    },
    unsetCurrentPartnerId: () => {
      dispatch(partnersActions.unsetCurrentItemId());
    },
    updatePartner: (id, data) => {
      dispatch(partnersActions.updateItem(id, data));
    },
    uploadPartnerLogo: (id, file) => {
      dispatch(partnersActions.uploadItemLogo(id, file));
    },
    createPartner: (data) => {
      dispatch(partnersActions.createItem(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnersEditPage);
