import { NotificationManager } from "react-notifications";
import api from "../../services/api";
import language from "../../services/language";
import _ from "lodash";

import * as exceptionsActions from "../exceptions/actions";
import * as documentsSelectors from "./selectors";
import * as settingsActions from "../settings/actions";

import strings from "../../services/strings";
import { viewModals, browserHistory } from "../../helpers";

export const types = {
  FETCH_ALL_ITEMS_DONE: "documents.FETCH_ALL_ITEMS_DONE",
  FETCH_ITEMS_DONE: "documents.FETCH_ITEMS_DONE",
  FETCH_ITEM_DONE: "documents.FETCH_ITEM_DONE",
  VALIDATE_ITEM_DONE: "documents.VALIDATE_ITEM_DONE",
  SET_SORTER: "documents.SET_SORTER",
  SET_SEARCH_TERM: "documents.SET_SEARCH_TERM",
  SET_CATEGORY_ID: "documents.SET_CATEGORY_ID",
  SET_CURRENT_PAGE: "documents.SET_CURRENT_PAGE",
  SET_CURRENT_ITEM_ID: "documents.SET_CURRENT_ITEM_ID",
  TOGGLE_SORTER: "documents.TOGGLE_SORTER",
  CLEAR_CACHE: "documents.CLEAR_CACHE",
  SET_GLOSSARY_CODE: "documents.SET_GLOSSARY_CODE",
  CLEAR_ORDER_FIELDS_CACHE: "documents.CLEAR_ORDER_FIELDS_CACHE",
  FETCH_ITEMS_ELEMENTS: "documents.FETCH_ITEMS_ELEMENTS",
};

export function setGlossaryCode(code, codeType) {
  return {
    type: types.SET_GLOSSARY_CODE,
    payload: {
      code,
      codeType,
    },
  };
}

export function setCategoryId(id) {
  return {
    type: types.SET_CATEGORY_ID,
    payload: {
      id,
    },
  };
}

export function setCurrentPage(page) {
  return {
    type: types.SET_CURRENT_PAGE,
    payload: {
      page,
    },
  };
}

export function setCurrentItemId(id) {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id,
    },
  };
}

export function unsetFieldsOrder() {
  return {
    type: types.VALIDATE_ITEM_DONE,
    payload: {
      fields: null,
      fieldsOrder: null,
      selectors: null,
      clauses: null,
      steps: null,
    },
  };
}

export function unsetCurrentItemId() {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id: null,
    },
  };
}

export function toggleSorter(column) {
  return {
    type: types.TOGGLE_SORTER,
    payload: {
      column,
    },
  };
}

export function setSorter(sorter) {
  return {
    type: types.SET_SORTER,
    payload: {
      sorter,
    },
  };
}

export function setSearchTerm(searchTerm) {
  return {
    type: types.SET_SEARCH_TERM,
    payload: {
      searchTerm,
    },
  };
}

export function clearCache() {
  return {
    type: types.CLEAR_CACHE,
  };
}

export function fetchAllItems() {
  return async (dispatch, getState) => {
    let state = getState();
    try {
      let params = new Map();
      params.set("language_id", language.get());
      params.set("expand", "downloads,category");

      //By Nad.
      let filters = documentsSelectors.getFilters(state);
      params.set(
        "glossary_code",
        filters.glossaryCode ? filters.glossaryCode : ""
      );
      params.set(
        "glossary_code_type",
        filters.glossaryCodeType ? filters.glossaryCodeType : ""
      );
      //End

      let items = await api.get("/documents", params);
      dispatch(clearCache());
      dispatch({
        type: types.FETCH_ALL_ITEMS_DONE,
        payload: {
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItems(deleteCache = false) {
  return async (dispatch, getState) => {
    let state = getState();
    try {
      // Set additional params
      let params = new Map();
      let filters = documentsSelectors.getFilters(state);
      let sorter = documentsSelectors.getSorter(state);
      let pagination = documentsSelectors.getPagination(state);
      params.set("language_id", language.get());
      params.set("expand", "downloads");
      params.set("name~", filters.searchTerm);
      params.set("category_id", filters.categoryId ? filters.categoryId : "");
      // params.set('page_size', pagination.pageSize);
      params.set("page_number", deleteCache ? 1 : pagination.currentPage);
      params.set("sort_by", sorter.column);
      params.set("sort_desc", sorter.descending);
      params.set("page_size", sorter.pageLimit);

      // GET request from API
      let [response, items] = await api.get("/documents", params, true);
      // Clear cache if deleteCache is enabled
      if (deleteCache) {
        dispatch(clearCache());
      }

      dispatch({
        type: types.FETCH_ITEMS_DONE,
        payload: {
          totalPages: parseInt(response.headers.get("X-Total-Pages")),
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItemAllPages(deleteCache = false) {
  return async (dispatch, getState) => {
    // let state = getState();
    try {
      // Set additional params
      let params = new Map();
      params.set("language_id", language.get());
      let [response, items] = await api.get(
        "/documents/getAllPagesDocuments",
        params,
        true
      );
      // Clear cache if deleteCache is enabled
      if (deleteCache) {
        dispatch(clearCache());
      }

      dispatch({
        type: types.FETCH_ITEMS_DONE,
        payload: {
          totalPages: parseInt(response.headers.get("X-Total-Pages")),
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchListElements(deleteCache = false) {
  return async (dispatch, getState) => {
    // let state = getState();
    try {
      // Set additional params
      let params = new Map();
      // GET request from API
      let items = await api.get("/glossaries-elements", params);

      // Clear cache if deleteCache is enabled
      if (deleteCache) {
        dispatch(clearCache());
      }

      dispatch({
        type: types.FETCH_ITEMS_ELEMENTS,
        payload: { items },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItem(id) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("expand", "downloads");

      // GET request from API
      let item = await api.get(`/documents/${id}`, params);
      dispatch({
        type: types.FETCH_ITEM_DONE,
        payload: {
          item,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function createItem(data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      await api.post("/documents", params);
      browserHistory.push(`/documents`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateItem(id, data) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setLoadingScreen(true));
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // PUT request to API
      await api.put(`/documents/${id}`, params);
      _.delay(() => {
        browserHistory.push(`/documents`);
      }, 100);
      NotificationManager.success(
        "",
        strings.get("Exceptions.notifications.edited")
      );
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    } finally {
      dispatch(settingsActions.setLoadingScreen(false));
    }
  };
}

export function updateItemOrder(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("fields_order", JSON.stringify(data));
      // PUT request to API
      await api.put(`/documents/${id}/order`, params);
      // browserHistory.push(`/documents/${id}`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateStepOrder(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("steps_order", JSON.stringify(data));
      // PUT request to API
      await api.put(`/documents/${id}/stepOrder`, params);
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function clearOrderFieldsCache() {
  return {
    type: types.CLEAR_ORDER_FIELDS_CACHE,
  };
}

export function validateItem(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // GET request to API
      let {
        fields,
        fieldsOrder,
        selectors,
        clauses,
        steps,
        stepsOrder,
        codeBlocks,
        carousels,
      } = await api.post(`/documents/${id}/validate`, params);
      dispatch(clearCache());
      dispatch(clearOrderFieldsCache());

      dispatch({
        type: types.VALIDATE_ITEM_DONE,
        payload: {
          fields,
          fieldsOrder,
          selectors,
          clauses,
          steps,
          stepsOrder,
          codeBlocks,
          carousels,
        },
      });
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function verifyTemplate(data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // GET request to API
      await api.post(`/documents/verifyTemplate`, params);
      dispatch(exceptionsActions.clear());
      NotificationManager.success(
        "",
        strings.get("Client.notifications.validTemplate")
      );
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function uploadItemLogo(id, file) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("file", file);
      // POST request to API
      await api.postFiles(`/documents/${id}/image`, params);

      dispatch(fetchItem(id));
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function createItemWithLogo(data, file) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      let item = await api.post("/documents", params);
      browserHistory.push(`/documents`);

      params = new Map();
      params.set("file", file);
      // POST request to API for Upload
      await api.postFiles(`/documents/${item.id}/image`, params);

      dispatch(fetchItem(item.id));
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function deleteItem(id) {
  return async (dispatch) => {
    try {
      // DELETE request to API
      await api.delete("/documents/" + id);
      dispatch(fetchItems());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateColumn(id, column, value) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setLoadingScreen(true));
      let params = new Map();
      params.set(column, value);
      // PUT request to API
      await api.put(`/documents/${id}/updateColumn`, params);
      dispatch(fetchItems());
      NotificationManager.success(
        strings.get("Exceptions.notifications.edited")
      );
      browserHistory.push(`/documents`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    } finally {
      dispatch(settingsActions.setLoadingScreen(false));
    }
  };
}

export function validateTemplate(data) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setLoadingScreen(true));
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // GET request to API
      let { fields, selectors, clauses, steps } = await api.post(
        `/documents/validateTemplate`,
        params
      );

      dispatch(clearCache());
      dispatch(clearOrderFieldsCache());

      dispatch({
        type: types.VALIDATE_ITEM_DONE,
        payload: {
          fields,
          selectors,
          clauses,
          steps,
        },
      });
      dispatch(exceptionsActions.clear());
      _.delay(() => {
        dispatch(
          settingsActions.setCurrentModal(viewModals.CODEBLOCK_CODES_ORDERING)
        );
      }, 50);
    } catch (e) {
      dispatch(exceptionsActions.process(e));
      return false
    } finally {
      dispatch(settingsActions.setLoadingScreen(false));
    }
  };
}


export function validateGlossaryTemplate(data) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setLoadingScreen(true));
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // GET request to API
      await api.post(
        `/documents/verifyGlossaryTemplate`,
        params
      );
      dispatch(exceptionsActions.clear());
      NotificationManager.success(
        "",
        strings.get("Client.notifications.validTemplate")
      );
      
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    } finally {
      dispatch(settingsActions.setLoadingScreen(false));
    }
  };
}

export function fetchCodesFromDocs(data, success = () => {}) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("docsId", JSON.stringify(data));
      // PUT request to API
      let responseData = await api.get(`/codes-from-docs`, params);
      success(responseData);
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}
