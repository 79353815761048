import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import strings from "../../../services/strings";

import _ from "lodash";
import "./IpAddressesList.scss";

import Modal from "reboron/DropModal";
 import { browserHistory } from "../../../helpers";



class IpAddressesList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleEditClick(id) {
    browserHistory.push(`/settings/ipAddresses/${id}`);

  }

  handleDeleteClick(id) {
    this.props.setCurrentItemId(id);
    this.showDeleteModal();
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  handleConfirmDeleteClick() {
    this.props.deleteItem(this.props.currentItem.id);
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleCancelDeleteClick() {
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  render() {
    let deleteModalContent = this.props.currentItem ? (
      <span>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleCancelDeleteClick}
          />
        </div>
        <h2>
          {strings.get("App.deleteModal.message", {
            itemName: this.props.currentItem.name
          })}
        </h2>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger"
            onClick={this.handleConfirmDeleteClick}
          >
            {strings.get("App.deleteModal.delete")}
          </button>
          <button
            className="btn btn-lg btn-default cancel"
            onClick={this.handleCancelDeleteClick}
          >
            {strings.get("App.deleteModal.cancel")}
          </button>
        </div>
      </span>
    ) : null;

    let items = _.map(this.props.items, value => {
      return (
        <tr key={value.id}>
          <td
            style={{ cursor: "pointer" }}
            onClick={() => this.handleEditClick(value.id)}
          >
            <div className="details">
              <div className="name">{value.ip_address}</div>
            </div>
          </td>
          <td>
            <div className="details">
              <div className="name">{value.name}</div>
            </div>
          </td>
          <td>
            <div className="details">
              <div className="name">
                {strings.get(
                  "App.ipAddressForm." + value.typeFull.toLowerCase()
                )}
              </div>
            </div>
          </td>
          <td>
            <i
              onClick={() => this.handleEditClick(value.id)}
              className="btn btn-default edit-btn ion-edit"
            />
            <i
              onClick={() => this.handleDeleteClick(value.id)}
              className="btn btn-default delete-btn ion-trash-b"
            />
          </td>
        </tr>
      );
    });

    return (
      <span className="IpAddressesList">
        <Modal className="boron-modal" ref="deleteModal">
          {deleteModalContent}
        </Modal>
        <div className="panel panel-default">
          <div className="panel-body">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>{strings.get("App.ipAddressForm.ipAddress")}</th>
                  <th>{strings.get("App.ipAddressForm.name")}</th>
                  <th>{strings.get("App.ipAddressForm.type")}</th>
                  <th>{strings.get("General.action")}</th>
                </tr>
              </thead>
              <tbody>{items}</tbody>
            </table>
          </div>
        </div>
      </span>
    );
  }
}

IpAddressesList.propTypes = {
  items: PropTypes.object.isRequired,
  fetchItems: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired
};

export default IpAddressesList;
