import React, { Component } from "react";
import ReactLoading from "react-loading";
import autoBind from "react-autobind";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import strings from "../../services/strings";
import language from "../../services/language";
// import { Link } from "react-router-dom";
import _ from "lodash";
import "./GlossaryForm.scss";
import Switch from "../Switch.js";
import settings from "../../services/settings";

import ActiveLabel from "../ActiveLabel";
import GlossaryFormSelectors from "./GlossaryFormSelectors";
import GlossaryFormClauses from "./GlossaryFormClauses";
import GlossaryFormFields from "./GlossaryFormFields";
import GlossaryFormCodeBlocks from "./GlossaryFormCodeBlocks";
import GlossaryFormCarousels from "./GlossaryFormCarousels";

import { Tabs, Tab } from "react-bootstrap";

import * as documentsActions from "../../store/documents/actions";
import * as documentsSelectors from "../../store/documents/selectors";
import * as settingsActions from "../../store/settings/actions";
import * as settingsSelectors from "../../store/settings/selectors";
import { browserHistory } from "../../helpers";
import CustomDropModal from "../common/Popup";

class GlossaryForm extends Component {
  state = {
    currentItemLoaded: false,
    allCategories: true,
    activeTab: "selectors",
    categories: [],
    glossary: {
      selectors: [],
      clauses: [],
      fields: [],
      codeBlocks: [],
      carousels: [],
    },
    activeGlossaries: {
      itemKeySelectors: {},
      itemKeyClauses: {},
      itemKeyFields: {},
      itemKeyCodeBlocks: {},
      itemKeyCarousels: {},
    },
    currentItem: this.props.currentItem,
    form: {
      language_id: language.get(),
      category_ids: "",
      name: "",
      prefix: "",
      value: "",
    },
    timeOut: {
      value: "",
      status: false,
    },
    isLoading: false,
    message: "",
    imported: false,
    expandGlossaries: {},
    expandGlossariesLoaded: false,
    glossariesCodeNameToId: {},
    glossariesCodeIdToName: {},
    stepsCategoryWise: {},
  };

  componentDidMount() {
    this.props.fetchListElements();

    this.tryLoadCurrentItem();
    this.handleStepsCategoriesWise();
  }

  componentDidUpdate() {
    this.tryLoadCurrentItem();
    if (!this.state.errorOnSave && _.size(this.props.exceptions)) {
      this.refs.warningModal.hide();
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(
      {
        currentItem: props.currentItem,
      },
      () => {
        if (this.state.currentItem && _.size(this.state.currentItem.value)) {
          let value = JSON.parse(this.state.currentItem.value);

          let glossary = JSON.parse(
            JSON.stringify({
              selectors: this.state.currentItem.selectors,
              fields: this.state.currentItem.fields,
              clauses: this.state.currentItem.clauses,
              codeBlocks: this.state.currentItem.code_blocks,
              carousels: this.state.currentItem.carousels,
            })
          );

          // let { selectors, fields, clauses, codeBlocks, carousels } = value;
          this.setState({
            glossary: _.extend(this.state.glossary, glossary),
          });

        }
      }
    );
    if (
      props.glossaries &&
      _.size(props.glossaries) &&
      !this.state.expandGlossariesLoaded
    ) {
      this.loadGlossaries(props);
    }
    if (
      _.size(props.steps) &&
      _.size(props.categories) &&
      _.size(!this.state.stepsCategoryWise)
    ) {
      this.handleStepsCategoriesWise(props);
    }
    if(this.props.params && this.props.params.code) this.handleEditCode(this.props.params.code);
  }

  handleEditCode = (codeName) =>{
    let isSelector = /(select_\d+)/g;
    let isClause = /(clause_\d+)/g;
    let isField = /(field_\d+)/g;
    let iscodeBlock = /(block_\d+)/g;
    let isCarousel = /(carousel_\d+)/g;
    const selector = isSelector.exec(codeName);
    const clause = isClause.exec(codeName);
    const field = isField.exec(codeName);
    const codeBlocks = iscodeBlock.exec(codeName);
    const carousel = isCarousel.exec(codeName);
    let match = {}
    const tabNames = { 
      itemKeySelectors: "selectors",
      itemKeyClauses: "clauses",
      itemKeyFields: "fields",
      itemKeyCodeBlocks: "codeBlocks",
      itemKeyCarousels: "carousels",
    }
    const {elements} = this.props
       if(selector){
        match = {
          activeGlossaries:{
            itemKeySelectors: elements.itemKeySelectors[codeName],
          },
          activeTab:"selectors"
        };
       } else if (clause){
        match = {
          activeGlossaries:{
            itemKeyClauses: elements.itemKeyClauses[codeName],
          },
          activeTab:"clauses"
        };

       } else if (field) {
        match = {
          activeGlossaries:{
            itemKeyFields: elements.itemKeyFields[codeName],
          },
          activeTab:"fields"

        };
       } else if (codeBlocks) {
        match = {
          activeGlossaries:{
            itemKeyCodeBlocks: elements.itemKeyCodeBlocks[codeName],
          },
          activeTab:"codeBlocks"
        };

       } else if (carousel) {
        match = {
          activeGlossaries:{
            itemKeyCarousels: elements.itemKeyCarousels[codeName], 
          },
          activeTab:"carousels"
        };

       } else {
          _.map(elements,(element,key)=>{
            if(_.has(element, codeName)) {
              match = {
                activeGlossaries:{
                  [key]: element[key][codeName]
                },
                activeTab: tabNames[key]
              };
            }
          });
       }
       if(match)
       this.setState({
         ...match
       })

  }

  handleStepsCategoriesWise = (props = this.props) => {
    if (_.size(props.steps) && _.size(props.categories)) {
      let stepsCategoryWise = {};
      _.each(props.steps, (step) => {
        if (step.categories) {
          let categories = step.categories.split(",");
          categories.forEach((category) => {
            if (category !== "") {
              if (!Object.keys(stepsCategoryWise).includes(category)) {
                stepsCategoryWise[category] = [];
                stepsCategoryWise[category].push(step);
              } else {
                stepsCategoryWise[category].push(step);
              }
            }
          });
        }
      });
      this.setState({ stepsCategoryWise });
    }
  };

  componentWillUnmount() {
    if (localStorage.getItem("timeOut")) {
      let timeOuts = JSON.parse(localStorage.getItem("timeOut"));
      timeOuts.forEach((element) => {
        window.clearTimeout(element);
      });
      localStorage.removeItem("timeOut");
    }
  }

  constructor(props) {
    super(props);
    autoBind(this);
  }

  tryLoadCurrentItem() {
    if (
      this.props.currentItem &&
      !this.state.currentItemLoaded &&
      this.props.currentItem["value"]
    ) {
      let form = _.extend({}, this.state.form);
      _.map(this.state.form, (value, key) => {
        form[key] = this.props.currentItem[key]
          ? this.props.currentItem[key]
          : this.state.form[key];
      });
      this.setState(
        {
          currentItemLoaded: true,
          allCategories: !_.size(this.props.currentItem.categories),
          categories: this.props.currentItem.categories,
          glossary: JSON.parse(this.props.currentItem["value"]),
          form,
        },
        () => {
          // this.loadPrefix()
        }
      );
    } /*else if( this.props.currentRule && !this.state.form.name) {
            let form = _.extend({}, this.state.form);
            let glossary = _.extend({}, this.state.glossary);
            _.map(this.props.glossaries, (item) => {
               if (!form.name) {
                   form.name = item.name;
                   glossary = JSON.parse(item.value);
               }
            });
            this.setState({form, glossary});
        }*/
    this.handleGlossaryTimeOut();
    if (this.props.glossaries && _.size(this.props.glossaries)) {
      this.loadGlossaries(this.props);
    }
  }

  loadGlossaries = (props = this.props) => {
    if (
      props.glossaries &&
      _.size(props.glossaries) > 1 &&
      !this.state.expandGlossariesLoaded
    ) {
      let temp = {};
      let tempCodeNameToId = {};
      let tempCodeIdToName = {};
      let _glossaries = props.glossaries;
      _.map(_glossaries, (element) => {
        if (element.clauses && element.clauses.length) {
          element.clauses.forEach((clause) => {
            temp[`${clause.name}`] = clause;
            tempCodeNameToId[`${clause.name}`] = `clause_${clause.id}`;
            tempCodeIdToName[`clause_${clause.id}`] = `${clause.name}`;
          });
        }
        if (element.fields && element.fields.length) {
          element.fields.forEach((field) => {
            temp[`${field.name}`] = field;
            tempCodeNameToId[`${field.name}`] = `field_${field.id}`;
            tempCodeIdToName[`field_${field.id}`] = `${field.name}`;
          });
        }
        if (element.selectors && element.selectors.length) {
          element.selectors.forEach((selector) => {
            temp[`${selector.name}`] = selector;
            tempCodeNameToId[`${selector.name}`] = `selector_${selector.id}`;
            tempCodeIdToName[`selector_${selector.id}`] = `${selector.name}`;
          });
        }
      });
      this.setState({
        expandGlossaries: temp,
        glossariesCodeNameToId: tempCodeNameToId,
        glossariesCodeIdToName: tempCodeIdToName,
        expandGlossariesLoaded: true,
      });
    }
  };

  handleGlossaryTimeOut = () => {
    if (_.size(this.props.settings) && !this.state.timeOut.status) {
      let { timeOut } = this.state;
      timeOut.value = parseInt(
        this.props.settings[settings.keys.GLOSSARY_TIME_OUT].value
      );
      timeOut.status = true;
      this.setState({
        timeOut,
      });
      this.setTime(timeOut.value);
    }
  };

  setTime = (minutes) => {
    //redirect user to list
    let timeOut1 = setTimeout(() => {
      browserHistory.push("/glossaries");
    }, minutes * 60000);

    //warning message before 5 minutes
    let timeOut2 = setTimeout(() => {
      this.refs.timeOutWarningModal.show();
    }, (minutes - 5) * 60000);
    localStorage.setItem("timeOut", JSON.stringify([timeOut1, timeOut2]));
  };

  hasError(inputName) {
    return !!this.props.exceptions[inputName];
  }

  getErrorClass(inputName, defaultClasses = "") {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  }

  getErrorMessage(inputName) {
    return this.props.exceptions[inputName];
  }

  handleInputChange(e) {
    let form = {};
    form[e.target.name] = e.target.value;
    this.setState({
      form: _.extend(this.state.form, form),
    });
    if (this.props.currentRule) {
      let glossary = _.extend({}, this.state.glossary);
      let flag = false;
      _.map(this.props.glossaries, (item) => {
        if (item.name === this.state.form.name) {
          glossary = JSON.parse(item.value);
          this.setState({
            categories: item.categories,
            allCategories: !Boolean(_.size(item.categories)),
          });
          flag = true;
          return;
        }
      });
      if (flag === false)
        glossary = {
          selectors: [],
          clauses: [],
          fields: [],
          codeBlocks: [],
          carousels: [],
        };
      this.setState({ glossary, itemsLoaded: false });
    }
  }

  handleAllCategoriesChange(e) {
    this.setState({
      allCategories: e.target.checked,
    });
  }

  handleCategoryAdd(e) {
    let id = e.target.value;
    let added =
      _.findIndex(this.state.categories, (item) => {
        return item.id === id;
      }) > -1;

    if (!added) {
      let categories = _.extend([], this.state.categories);
      categories.push(this.props.categories[`_${id}`]);
      this.setState(
        {
          categories,
          allCategories: false,
        },
        () => {
          // this.loadPrefix()
        }
      );
    }
  }

  handleCategoryRemove(value) {
    let categories = _.filter(this.state.categories, (item) => {
      return item.id !== value;
    });

    this.setState(
      {
        categories,
        allCategories: _.size(categories) === 0,
      },
      () => {
        // this.loadPrefix();
      }
    );
  }

  handleSelectorsChange(items) {
    this.setState({
      glossary: _.extend(this.state.glossary, {
        selectors: items,
      }),
    });
    if (this.state.currentItem) {
      this.props.updateCurrentGlossaryStore(
        this.state.currentItem,
        this.state.glossary,
        !!this.props.params.code
      ); // used to update store when click delete icon
    }
  }

  handleCodeBlocksChange(items) {
    this.setState({
      glossary: _.extend(this.state.glossary, {
        codeBlocks: items,
      }),
    });
    if (this.state.currentItem) {
      this.props.updateCurrentGlossaryStore(
        this.state.currentItem,
        this.state.glossary,
        !!this.props.params.code
      ); // used to update store when click delete icon
    }
  }

  handleCarouselChange(items) {
    this.setState({
      glossary: _.extend(this.state.glossary, {
        carousels: items,
      }),
    });
    if (this.state.currentItem) {
      this.props.updateCurrentGlossaryStore(
        this.state.currentItem,
        this.state.glossary,
        !!this.props.params.code
      ); // used to update store when click delete icon
    }
  }

  handleClausesChange(items) {
    this.setState({
      glossary: _.extend(this.state.glossary, {
        clauses: items,
      }),
    });
    if (this.state.currentItem) {
      this.props.updateCurrentGlossaryStore(
        this.state.currentItem,
        this.state.glossary,
        !!this.props.params.code
      ); // used to update store when click delete icon
    }
  }

  handleFieldsChange(items) {
    this.setState({
      glossary: _.extend(this.state.glossary, {
        fields: items,
      }),
    });
    if (this.state.currentItem) {
      this.props.updateCurrentGlossaryStore(
        this.state.currentItem,
        this.state.glossary,
        !!this.props.params.code
      ); // used to update store when click delete icon
    }
  }

  handleSaveClick(e) {
    e.preventDefault();
    this.refs.warningModal.show();
    let categoryIds = this.state.allCategories
      ? ""
      : _.map(this.state.categories, (item) => {
          return item.id;
        }).join(",");

    let value = JSON.stringify(this.state.glossary);
    this.props.saveItem({
      form: {
        language_id: language.get(),
        name: this.state.form.name,
        category_ids: categoryIds,
        value,
        prefix: this.state.form.prefix,
      },
    });
  }

  handleCancelClick(e) {
    e.preventDefault();
    if (!this.props.currentRule) browserHistory.push("/glossaries");
    else this.props.hideAddRuleModal();
  }

  getCategoryTree(items, item, arr, depth = 0) {
    if (_.size(items) && _.find(items, item)) {
      if (item.parent_id && depth === 0) return;
      arr[0].push(item);
      arr[1].push(depth);
      _.map(items, (it) => {
        if (item.id === it.parent_id) {
          this.getCategoryTree(items, it, arr, depth + 1);
        }
      });
    }
  }

  addClick() {
    let arr = ["fields", "clauses", "selectors", "codeBlocks", "carousels"];
    _.map(arr, (item) => {
      this.refs[item].refs.hidden.style.display =
        this.refs[item].refs.hidden.style.display !== "none"
          ? "none"
          : this.refs[item].refs.hidden.style.display;
    });
  }

  handleChange(enabled) {
    this.setState({ allCategories: enabled }, () => {
      // this.loadPrefix();
    });
  }

  onImport = (e) => {
    let data = new Map();
    data.set("glossaryFile", e.target.files[0]);
    let id_glossaries = this.state.currentItem && this.state.currentItem.id;
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.props.importGlossaries(
          id_glossaries,
          data,
          () => {
            setTimeout(() => {
              this.showImportModal(
                strings.get("App.glossaries.glossaryForm.importSuccess")
              );
              document.getElementById("importFile").value = "";
              this.setState({
                isLoading: false,
                imported: true,
              });
              this.props.onImport();
            }, 1000);
          },
          () => {
            this.showImportModal(
              strings.get("App.glossaries.glossaryForm.importError")
            );
            document.getElementById("importFile").value = "";
            this.setState({
              isLoading: false,
            });
          }
        );
      }
    );
  };

  undoImportGlossary = (id_glossaries) => {
    if (this.state.imported) {
      this.props.undoImportGlossary(
        id_glossaries,
        () => {
          this.showImportModal(
            strings.get("App.glossaries.glossaryForm.undoImportSuccess")
          );
          this.setState({
            imported: false,
          });
          this.props.onImport();
        },
        () => {
          this.showImportModal(
            strings.get("App.glossaries.glossaryForm.undoImportError")
          );
        }
      );
    }
  };

  showImportModal(message) {
    this.setState(
      {
        message,
      },
      () => {
        this.refs.importModal.show();
      }
    );
  }

  hideImportModal() {
    this.refs.importModal.hide();
  }

  handleShowModal() {
    _.delay(() => {
      this.hideImportModal();
    }, 2000);
  }

  render() {
    let id_glossaries = this.state.currentItem && this.state.currentItem.id;
    let name = this.state.currentItem && this.state.currentItem.name;
    let nameLabel = this.hasError("name")
      ? `${strings.get(
          "App.glossaries.glossaryForm.name"
        )} ${this.getErrorMessage("name")}`
      : strings.get("App.glossaries.glossaryForm.name");
    let prefixLabel = this.hasError("prefix")
      ? `${strings.get("App.categoryForm.prefix")} ${this.getErrorMessage(
          "prefix"
        )}`
      : strings.get("App.categoryForm.prefix");
    let categoriesLabel = this.hasError("category_ids")
      ? `${strings.get(
          "App.glossaries.glossaryForm.category"
        )} ${this.getErrorMessage("category_ids")}`
      : strings.get("App.glossaries.glossaryForm.category");

    let arr = [[], []];
    _.map(this.props.categories, (item) => {
      this.getCategoryTree(this.props.categories, item, arr);
    });

    let categories = _.map(arr[0], (category, i) => {
      let sub = arr[1][i]
        ? " ".repeat(Math.round(arr[1][i] * 3 + arr[1][i] * 3)) + "|" + "_"
        : " ";
      return (
        <option
          className={`depth-${arr[1][i]}`}
          value={category.id}
          key={category.id}
        >
          {`${sub} ${category.name}`}
        </option>
      );
    });

    let selectedCategories = this.state.allCategories
      ? null
      : _.map(this.state.categories, (item) => {
          return (
            <div key={`category-${item.id}`}>
              <ActiveLabel
                name={item.name}
                value={item.id}
                onRemove={this.handleCategoryRemove}
              />
            </div>
          );
        });

    let nameInput;
    if (this.props.currentRule) {
      nameInput = (
        <div style={{ position: "relative" }}>
          <select
            className="form-control"
            name="name"
            value={this.state.form.name}
            onChange={this.handleInputChange}
          >
            {_.map(this.props.glossaries, (glossary) => {
              return (
                <option key={`item-${glossary.id}`} value={glossary.name}>
                  {glossary.name}
                </option>
              );
            })}
          </select>
          <input
            className="form-control editableInput"
            type="text"
            name="name"
            value={this.state.form.name}
            onChange={this.handleInputChange}
          />
        </div>
      );
    } else {
      nameInput = (
        <input
          className="form-control"
          type="text"
          name="name"
          value={this.state.form.name}
          onChange={this.handleInputChange}
        />
      );
    }
    let prefixInput = (
      <input
        className="form-control"
        type="text"
        name="prefix"
        value={this.state.form.prefix}
        onChange={this.handleInputChange}
      />
    );

    return (
      <div className="GlossaryForm row ">
        <CustomDropModal className="boron-modal" ref="warningModal">
          <span>
            <h2>{strings.get("App.glossaries.glossaryForm.warningOnSave")}</h2>
          </span>
          {/* <h3>{strings.get("App.glossaries.glossaryForm.updatingProgress")}</h3> */}
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </CustomDropModal>
        <CustomDropModal className="boron-modal" ref="timeOutWarningModal">
          <span>
            <div className="modal-close-btn">
              <i
                className="ion-android-cancel clickable"
                onClick={() => {
                  this.refs.timeOutWarningModal.hide();
                }}
              />
            </div>
            <h2>{strings.get("App.glossaries.glossaryForm.timeOutWarning")}</h2>
          </span>
        </CustomDropModal>
        <CustomDropModal
          className="boron-modal no-body"
          ref="importModal"
          onShow={this.handleShowModal}
        >
          <span>
            <h2>{this.state.message}</h2>
          </span>
        </CustomDropModal>
        {/* <form> */}
        <div className="panel panel-default">
          <div className="panel-body">
            <div className="col-sm-4">
              <div className={this.getErrorClass("name", "form-group")}>
                <label className="control-label">{nameLabel}</label>
                {nameInput}
              </div>
            </div>
            <div className="col-sm-2">
              <div className={this.getErrorClass("prefix", "form-group")}>
                <label className="control-label">{prefixLabel}</label>
                {prefixInput}
              </div>
            </div>
            <div className="col-sm-6">
              <div className={this.getErrorClass("category_ids", "form-group")}>
                <label className="control-label">{categoriesLabel}</label>
                <div className="checkbox">
                  <div className="col-sm-12 sliderPadding">
                    <Switch
                      enabled={this.state.allCategories}
                      onChange={(enabled) => {
                        this.handleChange(enabled);
                      }}
                    />
                    <label className="sliderLabel">
                      {strings.get("App.glossaries.glossaryForm.all")}
                    </label>
                  </div>
                  {/* <label> */}
                  {/* <input type="checkbox" checked={ this.state.allCategories } onChange={ this.handleAllCategoriesChange } /> */}
                  {/* {strings.get('App.glossaries.glossaryForm.all')} */}
                  {/* </label> */}
                </div>
                {selectedCategories}
                <select
                  className="form-control"
                  value=""
                  onChange={this.handleCategoryAdd}
                >
                  <option value="" disabled>
                    {strings.get("App.glossaries.glossaryForm.choose")}
                  </option>
                  {categories}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="import-export-glossaries">
          {this.props.importGlossaries && (
            <div className="action-import">
              <label
                htmlFor="importFile"
                type="button"
                className="btn btn-large btn-info"
              >
                <i className="ion-android-exit" />
                {strings.get("App.glossaries.glossaryImport.import")}
                {this.state.isLoading ? (
                  <ReactLoading
                    className="react-loading"
                    type="spinningBubbles"
                    color="#fff"
                  />
                ) : (
                  ""
                )}
              </label>
              <input
                type="file"
                id="importFile"
                style={{ display: "none" }}
                onChange={this.onImport}
                accept=".xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </div>
          )}

          {this.props.exportGlossaries && (
            <div className="action-export">
              <button
                type="button"
                onClick={() => this.props.exportGlossaries(id_glossaries, name)}
                className="btn btn-large btn-info"
              >
                <i className="ion-android-archive"></i>
                {strings.get("App.glossaries.glossaryImport.export")}
              </button>
            </div>
          )}

          {/* {
              this.props.undoImportGlossary &&
              <div className="action-export">
                <button type="button" onClick={()=>this.undoImportGlossary(id_glossaries)} className={`btn btn-large btn-info ${!this.state.imported?"disabled":""}`}>
                  <i className="icon ion-ios-undo"> </i>
                  {strings.get('App.glossaries.glossaryImport.undo')}
                </button>
              </div>
            } */}
        </div>
        <div className="panel panel-default">
          <div className="panel-body">
            <div className="col-sm-12">
              <Tabs 
                activeKey={this.state.activeTab} 
                onSelect={(activeTab)=>this.setState({activeTab})} 
                id="uncontrolled-tab-example"
              >
                <Tab
                  eventKey="selectors"
                  title={strings.get(
                    "App.glossaries.glossaryForm.selectors.title"
                  )}
                >
                  <GlossaryFormSelectors
                    ref="selectors"
                    steps={this.props.steps}
                    glossaries={this.props.glossaries}
                    items={this.state.glossary.selectors}
                    activeItems={this.state.activeGlossaries.itemKeySelectors || {}}
                    currentGlossary={this.state.currentItem}
                    onChange={this.handleSelectorsChange}
                    currentItem={this.props.currentRule}
                    addClick={this.addClick}
                    articles={this.props.articles}
                    setGlossaryCode={this.props.setGlossaryCode}
                    fetchAllDocuments={this.props.fetchAllDocuments}
                    documentsByCode={this.props.documentsByCode}
                    prefix={this.state.form.prefix}
                    onSaveSelector={this.props.onSaveSelector}
                    onDeleteSelector={this.props.onDeleteSelector}
                    createSelector={this.props.createSelector}
                    expandGlossaries={this.state.expandGlossaries}
                    glossariesCodeIdToName={this.state.glossariesCodeIdToName}
                    glossariesCodeNameToId={this.state.glossariesCodeNameToId}
                    categories={this.props.categories}
                    stepsCategoryWise={this.state.stepsCategoryWise}
                  />
                </Tab>
                <Tab
                  eventKey="clauses"
                  title={strings.get(
                    "App.glossaries.glossaryForm.clauses.title"
                  )}
                >
                  <GlossaryFormClauses
                    ref="clauses"
                    steps={this.props.steps}
                    glossaries={this.props.glossaries}
                    items={this.state.glossary.clauses}
                    activeItems={this.state.activeGlossaries.itemKeyClauses || {}}
                    currentGlossary={this.state.currentItem}
                    onChange={this.handleClausesChange}
                    currentItem={this.props.currentRule}
                    addClick={this.addClick}
                    articles={this.props.articles}
                    setGlossaryCode={this.props.setGlossaryCode}
                    fetchAllDocuments={this.props.fetchAllDocuments}
                    documentsByCode={this.props.documentsByCode}
                    prefix={this.state.form.prefix}
                    onSaveClause={this.props.onSaveClause}
                    onDeleteClause={this.props.onDeleteClause}
                    createClauses={this.props.createClause}
                    expandGlossaries={this.state.expandGlossaries}
                    glossariesCodeIdToName={this.state.glossariesCodeIdToName}
                    glossariesCodeNameToId={this.state.glossariesCodeNameToId}
                    categories={this.props.categories}
                    stepsCategoryWise={this.state.stepsCategoryWise}
                  />
                </Tab>
                <Tab
                  eventKey="fields"
                  title={strings.get(
                    "App.glossaries.glossaryForm.fields.title"
                  )}
                >
                  <GlossaryFormFields
                    ref="fields"
                    steps={this.props.steps}
                    glossaries={this.props.glossaries}
                    items={this.state.glossary.fields}
                    activeItems={this.state.activeGlossaries.itemKeyFields || {}}
                    currentGlossary={this.state.currentItem}
                    onChange={this.handleFieldsChange}
                    currentItem={this.props.currentRule}
                    addClick={this.addClick}
                    articles={this.props.articles}
                    setGlossaryCode={this.props.setGlossaryCode}
                    fetchAllDocuments={this.props.fetchAllDocuments}
                    documentsByCode={this.props.documentsByCode}
                    prefix={this.state.form.prefix}
                    onSaveField={this.props.onSaveField}
                    onDeleteField={this.props.onDeleteField}
                    createField={this.props.createField}
                    expandGlossaries={this.state.expandGlossaries}
                    glossariesCodeIdToName={this.state.glossariesCodeIdToName}
                    glossariesCodeNameToId={this.state.glossariesCodeNameToId}
                    categories={this.props.categories}
                    stepsCategoryWise={this.state.stepsCategoryWise}
                  />
                </Tab>
                <Tab
                  eventKey="codeBlocks"
                  title={strings.get(
                    "App.glossaries.glossaryForm.codeBlocks.title"
                  )}
                >
                  <GlossaryFormCodeBlocks
                    exceptions={this.props.exceptions}
                    ref="codeBlocks"
                    // steps={this.props.steps}
                    glossaries={this.props.glossaries}
                    items={this.state.glossary.codeBlocks}
                    activeItems={this.state.activeGlossaries.itemKeyCodeBlocks || {}}
                    currentGlossary={this.state.currentItem}
                    onChange={this.handleCodeBlocksChange}
                    currentItem={this.props.currentRule}
                    addClick={this.addClick}
                    articles={this.props.articles}
                    setGlossaryCode={this.props.setGlossaryCode}
                    fetchAllDocuments={this.props.fetchAllDocuments}
                    documentsByCode={this.props.documentsByCode}
                    prefix={this.state.prefix}
                    onSaveCodeBlock={this.props.onSaveCodeBlock}
                    onDeleteCodeBlock={this.props.onDeleteCodeBlock}
                    createCodeBlock={this.props.createCodeBlock}
                    itemElements={this.props.elements}
                    expandGlossaries={this.state.expandGlossaries}
                    glossariesCodeIdToName={this.state.glossariesCodeIdToName}
                    glossariesCodeNameToId={this.state.glossariesCodeNameToId}
                    validateTemplate={this.props.validateTemplate}
                    validateGlossaryTemplate={this.props.validateGlossaryTemplate}
                    fields={this.props.fields}
                    selectors={this.props.selectors}
                    clauses={this.props.clauses}
                    stepsFromValidation={this.props.stepsFromValidation}
                    setCurrentModal={this.props.setCurrentModal}
                    currentModal={this.props.currentModal}
                    setLoadingScreen={this.props.setLoadingScreen}
                    settings={this.props.settings}
                  />
                </Tab>
                <Tab
                  eventKey="carousels"
                  title={strings.get(
                    "App.glossaries.glossaryForm.carousels.title"
                  )}
                >
                  <GlossaryFormCarousels
                    exceptions={this.props.exceptions}
                    ref="carousels"
                    steps={this.props.steps}
                    glossaries={this.props.glossaries}
                    items={this.state.glossary.carousels}
                    activeItems={this.state.activeGlossaries.itemKeyCarousels || {}}
                    currentGlossary={this.state.currentItem}
                    onChange={this.handleCarouselChange}
                    currentItem={this.props.currentRule}
                    addClick={this.addClick}
                    articles={this.props.articles}
                    setGlossaryCode={this.props.setGlossaryCode}
                    fetchAllDocuments={this.props.fetchAllDocuments}
                    documentsByCode={this.props.documentsByCode}
                    prefix={this.state.prefix}
                    onSaveCarousel={this.props.onSaveCarousel}
                    onDeleteCarousel={this.props.onDeleteCarousel}
                    createCarousel={this.props.createCarousel}
                    itemElements={this.props.elements}
                    expandGlossaries={this.state.expandGlossaries}
                    glossariesCodeIdToName={this.state.glossariesCodeIdToName}
                    glossariesCodeNameToId={this.state.glossariesCodeNameToId}
                    validateTemplate={this.props.validateTemplate}
                    fields={this.props.fields}
                    selectors={this.props.selectors}
                    clauses={this.props.clauses}
                    stepsFromValidation={this.props.stepsFromValidation}
                    setCurrentModal={this.props.setCurrentModal}
                    currentModal={this.props.currentModal}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>

        <div className="form-actions">
          <button className="btn btn-primary" onClick={this.handleSaveClick}>
            {strings.get("App.glossaries.glossaryForm.save")}
          </button>
          <button
            className="btn btn-default btn-color-change"
            onClick={this.handleCancelClick}
          >
            {strings.get("App.glossaries.glossaryForm.cancel")}
          </button>
        </div>
        {/* </form> */}
      </div>
    );
  }
}

GlossaryForm.propTypes = {
  exceptions: PropTypes.object,
  glossaries: PropTypes.object,
  categories: PropTypes.object,
  steps: PropTypes.object,
  saveItem: PropTypes.func,
  setCurrentModal: PropTypes.func.isRequired,
  currentModal: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    documentsByCode: documentsSelectors.getItems(state),
    elements: documentsSelectors.getElements(state),
    fields: documentsSelectors.getFields(state),
    selectors: documentsSelectors.getSelectors(state),
    clauses: documentsSelectors.getClauses(state),
    stepsFromValidation: documentsSelectors.getSteps(state),
    currentModal: settingsSelectors.getCurrentModal(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setGlossaryCode: (code, codeType) => {
      dispatch(documentsActions.setGlossaryCode(code, codeType));
    },
    fetchAllDocuments: (deleteCache) => {
      dispatch(documentsActions.fetchAllItems(deleteCache));
    },
    fetchListElements: () => {
      dispatch(documentsActions.fetchListElements());
    },
    validateTemplate: (data) => {
      dispatch(documentsActions.validateTemplate(data));
    },
    validateGlossaryTemplate: (data) => {
      return dispatch(documentsActions.validateGlossaryTemplate(data));
    },
    setCurrentModal: (currentModal = "") => {
      dispatch(settingsActions.setCurrentModal(currentModal));
    },
    setLoadingScreen: (status) => {
      dispatch(settingsActions.setLoadingScreen(status));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GlossaryForm);
