import { browserHistory } from '../../helpers';
import api from "../../services/api";
import auth from "../../services/auth";
import _ from "lodash";
import * as exceptionsActions from "../exceptions/actions";
import * as settingsActions from "../settings/actions";
import { NotificationManager } from "react-notifications";
import strings from "../../services/strings";
import { setIdentifier } from "../../helpers";
export const types = {
  GET_TOKEN_DONE: "auth.GET_TOKEN_DONE",
};

export function login(data, handleOtpSent = () => {}) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setLoadingScreen(true));
      dispatch(exceptionsActions.clear());
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      let payload = await api.post("/auth/login", params);
      if (payload.status === "OTP Sent") {
        NotificationManager.success(
          strings.get("Exceptions.notifications.login.otpSent")
        );
        handleOtpSent();
      }
      if (payload.error && payload.error === "Not Verified") {
        NotificationManager.error(
          strings.get("Exceptions.notifications.login.notVerified.message"),
          strings.get("Exceptions.notifications.login.notVerified.title"),
          5000
        );
      }
      
      if (payload.error && payload.error === "Admin Not Approved"){
        NotificationManager.error(
          strings.get("Exceptions.notifications.login.adminNotApproved.message"),
          strings.get("Exceptions.notifications.login.adminNotApproved.title"),
          5000
        );
       }

      if (payload.token) {
        auth.setLocalAccessToken(payload.token);
        browserHistory.push("/documents");
      }
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    } finally {
      dispatch(settingsActions.setLoadingScreen(false));
    }
  };
}

export function otpSubmit(data) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setLoadingScreen(true));
      dispatch(exceptionsActions.clear());
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      let payload = await api.post("/auth/verifyOtp", params);
      if (payload.status === "error") {
        if (payload.error && payload.error === "Invalid Otp") {
          NotificationManager.error(
            strings.get("Exceptions.notifications.login.invalidOtp")
          );
        } else {
          NotificationManager.error(
            strings.get("Exceptions.notifications.error")
          );
        }
      }
      if (payload.identifier) {
        setIdentifier(payload.identifier);
      }
      if (payload.token) {
        auth.setLocalAccessToken(payload.token);
        browserHistory.push("/documents");
      }
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    } finally {
      dispatch(settingsActions.setLoadingScreen(false));
    }
  };
}

export function logout() {
  return async (dispatch) => {
    localStorage.clear();
    dispatch({
      type: "LOGOUT",
    });
    browserHistory.push("/login");
  };
}
