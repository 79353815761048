import React, { Component } from "react";

import CustomDropModal from "../../common/Popup";

import strings from "../../../services/strings";

class ButtonDelete extends Component {
  toggleModal = (value) => {
    const ref = this.refs.helperTextModal;
    if (ref) value ? ref.show() : ref.hide();
  };

  handleSubmit = (values) => {
    this.toggleModal();
    this.props.listDeleteDraft(this.props.id);
  };

  onCancel = () => {
    this.toggleModal();
  };

  render() {
    return (
      <span className="button">
        <span className="cancel">
          <a onClick={this.toggleModal}>
            <i className="fas fa-trash"></i>
          </a>
        </span>
        {/* <button className="btn btn-danger delete" onClick={this.toggleModal}><i className="ion-trash-b"></i><span>{strings.get('Client.draftsPage.delete')}</span>
                </button> */}
        <CustomDropModal
          className="boron-modal form-modal workflow-modal"
          ref={"helperTextModal"}
          onHide={this.toggleModal(false)}
        >
          <div className="close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.onCancel}
            ></i>
          </div>
          <h4>{strings.get("Client.draftsPage.deleteDraft")}?</h4>
          <h3>{this.props.document.name}</h3>
          <form>
            <button
              type="button"
              className="btn btn4"
              onClick={this.handleSubmit}
            >
              <i className="ion-trash-b"></i>
              {strings.get("Client.draftsPage.delete")}
            </button>
            <button type="button" className="btn btn3" onClick={this.onCancel}>
              <i className="ion-android-cancel"></i>
              {strings.get("Client.draftsPage.cancel")}
            </button>
          </form>
        </CustomDropModal>
      </span>
    );
  }
}

export default ButtonDelete;
