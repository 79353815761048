import exceptions from './exceptions/reducer';
import sidebar from './sidebar/reducer';
import categories from './categories/reducer';
import companyCategories from './companyCategories/reducer';
import companies from './companies/reducer';
import documents from './documents/reducer';
import workflows from './workflows/reducer';
import workflowCategories from './workflowCategories/reducer';
import glossaries from './glossaries/reducer';
import steps from './steps/reducer';
import genderStrings from './genderStrings/reducer';
import languages from './languages/reducer';
import users from './users/reducer';
import groups from './groups/reducer';
import settings from './settings/reducer';
import blockedUsers from './blockedUsers/reducer';
import blockedGroups from './blockedGroups/reducer';
import partners from './partners/reducer';
import articles from './articles/reducer';
import articleCategories from './articleCategories/reducer';
import scopes from './scopes/reducer';
import translations from './translations/reducer';
import drafts from './drafts/reducer';
import sharedDrafts from './sharedDrafts/reducer';
import downloads from './downloads/reducer';
import ipAddresses from './ipAddresses/reducer';
import userAlerts from './userAlerts/reducer';
import requiredOptions from './requiredOptions/reducer';
import { combineReducers } from 'redux';

export const adminReducer = combineReducers({
    exceptions,
    sidebar,
    categories,
    companyCategories,
    companies,
    documents,
    workflows,
    workflowCategories,
    glossaries,
    steps,
    genderStrings,
    languages,
    users,
    groups,
    settings,
    blockedUsers,
    blockedGroups,
    partners,
    articles,
    articleCategories,
    scopes,
    translations,
    drafts,
    sharedDrafts,
    downloads,
    ipAddresses,
    userAlerts,
    requiredOptions
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return adminReducer(state, action);
};

export default rootReducer;
