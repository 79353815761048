import React, { Component } from "react";
import PropTypes from "prop-types";

import strings from "../../services/strings";

import "./DocumentCategoryFilter.scss";

class UserDocumentFilter extends Component {
  handleChange = () => (e) => {
    this.props.handleFilter(e);
  };
  render() {
    return (
      <div className="DocumentCategoryFilter">
        <select
          className="form-control cate-name"
          name="sorter"
          value={this.props.filter}
          onChange={this.handleChange()}
        >
          <option value={"paid"}>
            {strings.get("Client.downloadsPage.filter.payed")}
          </option>
          <option value={"free"}>
            {strings.get("Client.downloadsPage.filter.free")}
          </option>
          <option value={"all"}>
            {strings.get("Client.downloadsPage.filter.all")}
          </option>
        </select>
      </div>
    );
  }
}

UserDocumentFilter.propTypes = {
  sorter: PropTypes.object,
  // setSorter: PropTypes.func.isRequired,
  // fetchItems: PropTypes.func.isRequired,
};

export default UserDocumentFilter;