import React, { Component } from "react";
import autoBind from "react-autobind";
import Immutable from "seamless-immutable";
import PropTypes from "prop-types";
import strings from "../../services/strings";

import _ from "lodash";
import "./CompanyList.scss";

import Switch from "../Switch.js";
import { browserHistory } from "../../helpers";
import CustomDropModal from "../common/Popup";

class CompanyList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      items: {},
    };
  }

  componentDidMount() {
    this.setItems(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.items.length === 0 && nextProps.items) {
      this.setItems(nextProps);
    }
    if (this.state.items.length !== 0 && nextProps.items !== this.props.items) {
      this.setItems(nextProps);
    }
  }

  setItems = (props) => {
    if (props.items) {
      this.setState({
        items: props.items,
      });
    }
  };

  getLogo(item) {
    if (item.imageURL) {
      return (
        <div
          className="item-logo"
          style={{ backgroundImage: `url('${item.imageURL}')` }}
        />
      );
    }

    return <div className="item-logo default-logo ion-briefcase" />;
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  handleDeleteClick(id) {
    this.props.setCurrentItemId(id);
    this.showDeleteModal();
  }

  handleConfirmDeleteClick() {
    this.props.deleteItem(this.props.currentItem.id);
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleCancelDeleteClick() {
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleEditClick(id) {
    browserHistory.push(`/glossaries/companies/${id}`);
    
  }

  handleSwitchChange(id, column, enabled, index) {
    let items = Immutable.asMutable(this.state.items);
    let oldItem = Immutable.asMutable(items[index]);
    oldItem.visible = enabled ? 1 : 0;
    items[index] = oldItem;
    this.setState({ items });

    let value = +enabled;
    this.props.updateColumn(id, column, value);
  }

  render() {
    let items = _.map(this.state.items, (value, index) => {
      return (
        <tr key={value.id}>
          <td
            style={{ cursor: "pointer" }}
            onClick={() => this.handleEditClick(value.id)}
          >
            {this.getLogo(value)}
          </td>
          <td
            style={{ cursor: "pointer" }}
            onClick={() => this.handleEditClick(value.id)}
          >
            <div className="details">
              <div className="name">{value.name}</div>
            </div>
          </td>
          <td>{value.company_category ? value.company_category.name : "-"}</td>
          <td>
            <Switch
              enabled={!!value.visible}
              onChange={(enabled) =>
                this.handleSwitchChange(value.id, "visible", enabled, index)
              }
            />
          </td>
          <td>
            <i
              onClick={() => this.handleEditClick(value.id)}
              className="btn btn-default edit-btn ion-edit"
            />
            <i
              onClick={() => this.handleDeleteClick(value.id)}
              className="btn btn-default delete-btn ion-trash-b"
            />
          </td>
        </tr>
      );
    });

    let deleteModalContent = this.props.currentItem ? (
      <span>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleCancelDeleteClick}
          />
        </div>
        <h2>
          {strings.get("App.deleteModal.message", {
            itemName: this.props.currentItem.name,
          })}
        </h2>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger"
            onClick={this.handleConfirmDeleteClick}
          >
            {strings.get("App.deleteModal.delete")}
          </button>
          <button
            className="btn btn-lg btn-default cancel cancel"
            onClick={this.handleCancelDeleteClick}
          >
            {strings.get("App.deleteModal.cancel")}
          </button>
        </div>
      </span>
    ) : null;

    return (
      <span className="CompanyList">
        
        <CustomDropModal className="boron-modal" ref="deleteModal">
          {deleteModalContent}
        </CustomDropModal>
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th />
                      <th>{strings.get("App.companies.companyForm.name")}</th>
                      <th>
                        {strings.get(
                          "App.companies.companyForm.companyCategory"
                        )}
                      </th>
                      <th>{strings.get("General.visibility")}</th>
                      <th>{strings.get("General.action")}</th>
                    </tr>
                  </thead>
                  <tbody>{items}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  }
}

CompanyList.propTypes = {
  items: PropTypes.array.isRequired,
  sorter: PropTypes.object.isRequired,
  fetchItems: PropTypes.func.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  toggleSorter: PropTypes.func.isRequired,
};

export default CompanyList;
