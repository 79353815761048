export function getItems(state) {
  return state.settings.itemsByKey;
}

export function getItemByKey(state, key) {
  return state.settings.itemsByKey[key];
}

export function getScreenLoadingStatus(state) {
  return state.settings.screenLoading;
}

export function getCurrentModal(state) {
  return state.settings.currentModal;
}

export function getCurrentLanguage(state) {
  return state.settings.lang;
}
