
import api from '../../services/api';
import language from '../../services/language';
import _ from 'lodash';

import * as exceptionsActions from '../exceptions/actions';
import * as draftsSelectors from './selectors';

export const types = {
  SET_CURRENT_USER_ID: 'drafts.SET_CURRENT_USER_ID',
  CLEAR_CACHE: 'drafts.CLEAR_CACHE',
  FETCH_ALL_DRAFTS_DONE : "drafts.FETCH_ALL_DRAFTS_DONE",
  SET_CURRENT_PAGE: 'drafts.SET_CURRENT_PAGE',
  SET_CURRENT_ITEM_ID: 'drafts.SET_CURRENT_ITEM_ID',
  SET_DRAFTS_TYPE: "drafts.SET_DRAFTS_TYPE",
  SET_CATEGORY_ID: "drafts.SET_CATEGORY_ID",
  SET_SEARCH_TERM: "drafts.SET_SEARCH_TERM",
  SET_FETCH_ONLY_WORKFLOW_DRAFTS: "drafts.SET_FETCH_ONLY_WORKFLOW_DRAFTS",
  SET_SAVED_WORKFLOW_ID: "drafts.SET_SAVED_WORKFLOW_ID",

};

export function clearCache() {
	return {
		type: types.CLEAR_CACHE
	}
}

export function setCurrentUserId(id) {
  return {
    type: types.SET_CURRENT_USER_ID,
    payload: {
      id
    }
  }
}

export function unsetCurrentUserId() {
  return {
    type: types.SET_CURRENT_USER_ID,
    payload: {
      id: null
    }
  }
}

export function setCurrentItemId(id) {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id
    }
  }
}


export function setDraftType(type = "drafts") {
	return async (dispatch) => {
	  dispatch({
		type: types.SET_DRAFTS_TYPE,
		payload: {
		  type,
		},
	  });
	};
  };

  export function setCategoryId(id) {
	return {
	  type: types.SET_CATEGORY_ID,
	  payload: {
		id,
	  },
	};
  };

	export function setSearchTerm(searchTerm) {
		return {
		type: types.SET_SEARCH_TERM,
		payload: {
			searchTerm,
		},
		};
	}
  

export function setFetchOnlyWorkflowDraftStatus(status) {
	return {
	  type: types.SET_FETCH_ONLY_WORKFLOW_DRAFTS,
	  payload: {
		status,
	  },
	};
}

export function setSavedWorkflowId(id) {
	return {
	  type: types.SET_SAVED_WORKFLOW_ID,
	  payload: {
		id,
	  },
	};
  }

export function unsetCurrentItemId() {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id: null
    }
  }
}

export function fetchAllDrafts(deleteCache = false) {
	return async (dispatch, getState) => {
		let state = getState();
		try {
			let params = new Map();
			let filters = draftsSelectors.getFilters(state);
			let pagination = draftsSelectors.getPagination(state);
			let currentUsetId = draftsSelectors.getCurrentUserId(state);
			  
			params.set('expand', 'document,reminder,user');

			if(filters.categoryId)	params.set('category_id',filters.categoryId);
			if(filters.searchTerm)	params.set('name',filters.searchTerm);
		    if(currentUsetId)	params.set('user_id',currentUsetId);
			if (filters.savedWorkflowId)	params.set("userWorkflowId", filters.savedWorkflowId);
			if (filters.fetchOnlyWorkflowDraft)	params.set("fetchOnlyWorkflowDraft", filters.fetchOnlyWorkflowDraft);
			  
			params.set("type", filters.type);
			params.set('page_size', pagination.pageSize);
			params.set('page_number', deleteCache ? 1 : pagination.currentPage);
			// GET request from API
			let [response, items] = await api.get(`/drafts`, params, true);

			// Clear cache if deleteCache is enabled
			if (deleteCache) {
				dispatch(clearCache());
			}

			dispatch({
				type: types.FETCH_ALL_DRAFTS_DONE,
				payload: {
					totalPages: parseInt(response.headers.get('X-Total-Pages')),
					items
				}
			})
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function fetchRoleDrafts(deleteCache = false) {
	return async (dispatch, getState) => {
		let state = getState();
		try {
			let params = new Map();
			let filters = draftsSelectors.getFilters(state);
			let pagination = draftsSelectors.getPagination(state);
			let currentUsetId = draftsSelectors.getCurrentUserId(state);
			params.set('expand', 'document,reminder,user');
			if(filters.categoryId)
				params.set('category_id',filters.categoryId);
			if(filters.searchTerm)
				params.set('name',filters.searchTerm);
      if(currentUsetId)
        params.set('user_id',currentUsetId);

			params.set('page_size', pagination.pageSize);
			params.set('page_number', deleteCache ? 1 : pagination.currentPage);

			// GET request from API
			let [response, items] = await api.get(`/roleDrafts`, params, true);

			// Clear cache if deleteCache is enabled
			if (deleteCache) {
				dispatch(clearCache());
			}

			dispatch({
				type: types.FETCH_ALL_DRAFTS_DONE,
				payload: {
					totalPages: parseInt(response.headers.get('X-Total-Pages')),
					items
				}
			})
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function setCurrentPage(page) {
	return {
		type: types.SET_CURRENT_PAGE,
		payload: {
			page
		}
	}
}

export function updateDraftName(id, data) {
	return async (dispatch) => {
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// PUT request to API
			await api.put(`/drafts/${id}/name`, params);
			// browserHistory.push(`/documents/${id}`);

			dispatch(exceptionsActions.clear());
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function updateItemReminder(id, data) {
	return async (dispatch) => {
		try {
			let params = new Map();
			_.map(data, (value, key) => {
				params.set(key, value);
			})
			// PUT request to API
			await api.post(`/drafts/${id}/setreminder`, params);
			// browserHistory.push(`/documents/${id}`);

			dispatch(exceptionsActions.clear());
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}
