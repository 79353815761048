import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import settings from "../../../services/settings";
import language from "../../../services/language";
import { Link } from "react-router-dom";
import _ from "lodash";
import Switch from "../../Switch";

class CookieMessageForm extends Component {
  state = {
    currentSettingsLoaded: false,
    form: {
      maintenance_cookie_msg: { value: "" },
      google_analytics_status: { value: "" },
      google_analytics_id: { value: "" },
    },
  };
  componentDidMount() {
    this.tryLoadCurrentSettings();
  }

  componentDidUpdate() {
    this.tryLoadCurrentSettings();
  }

  constructor(props) {
    super(props);
    autoBind(this);
  }

  tryLoadCurrentSettings() {
    if (_.size(this.props.settings) && !this.state.currentSettingsLoaded) {
      this.setState({
        currentSettingsLoaded: true,
        form: {
          maintenance_cookie_msg: this.props.settings[
            settings.keys.MAINTENANCE_COOKIE_MSG
          ],
          google_analytics_id: this.props.settings[
            settings.keys.GOOGLE_ANALYTICS_ID
          ],
          google_analytics_status: this.props.settings[
            settings.keys.GOOGLE_ANALYTICS_STATUS
          ],
        },
      });
    }
  }

  handleInputChange(e) {
    let form = _.extend(this.state.form, {});
    form[e.target.name] = form[e.target.name].asMutable
      ? form[e.target.name].asMutable()
      : form[e.target.name];
    form[e.target.name].value = e.target.value;
    this.setState({ form });
  }

  handleAnalyticsStatusChange(status) {
    let form = _.extend(this.state.form, {});
    form["google_analytics_status"] = form["google_analytics_status"].asMutable
      ? form["google_analytics_status"].asMutable()
      : form["google_analytics_status"];
    form["google_analytics_status"].value = status.toString();
    this.setState({ form });
  }

  handleSaveClick(e) {
    e.preventDefault();
    this.props.saveSettings(this.state);
  }

  handleCancelClick(e) {
    e.preventDefault();
    this.setState({
      form: {
        maintenance_cookie_msg: this.props.settings[
          settings.keys.MAINTENANCE_COOKIE_MSG
        ],
        google_analytics_id: this.props.settings[
          settings.keys.GOOGLE_ANALYTICS_ID
        ],
        google_analytics_status: this.props.settings[
          settings.keys.GOOGLE_ANALYTICS_STATUS
        ],
      },
    });
  }

  render() {
    return (
      <div className="EmailForm col-xs-12 col-sm-12 col-md-9">
        <form>
          <div className="form-group">
            <label className="control-label">
              {strings.get(
                "App.settings.gdpr-googleAnalytics.cookie_banner_msg"
              )}
            </label>
            <textarea
              rows="6"
              className="form-control"
              name="maintenance_cookie_msg"
              value={this.state.form.maintenance_cookie_msg.value}
              onChange={this.handleInputChange}
            ></textarea>
          </div>
          <div
            className="form-group"
            style={{ display: "flex", alignItems: "center" }}
          >
            <label className="control-label" style={{ marginRight: "15px" }}>
              {strings.get("App.settings.gdpr-googleAnalytics.status")}
            </label>
            <Switch
              enabled={
                this.state.form.google_analytics_status.value === "true"
                  ? true
                  : false
              }
              onChange={this.handleAnalyticsStatusChange}
            />
          </div>
          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.gdpr-googleAnalytics.id")}
            </label>
            <input
              type="text"
              className="form-control"
              name="google_analytics_id"
              value={this.state.form.google_analytics_id.value}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-actions">
            <button className="btn btn-primary" onClick={this.handleSaveClick}>
              {strings.get("App.email.save")}
            </button>
            <button
              className="btn btn-default"
              onClick={this.handleCancelClick}
            >
              {strings.get("App.email.cancel")}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

CookieMessageForm.propTypes = {
  settings: PropTypes.object,
  saveSettings: PropTypes.func.isRequired,
};

export default CookieMessageForm;
