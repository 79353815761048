import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import strings from "../../services/strings";
import Topbar from "../../components/Topbar";
import ArticleCategoryList from "../../components/articleCategory/ArticleCategoryList";
import ArticleCategoryPagesize from "../../components/articleCategory/ArticleCategoryPagesize";
import Pagination from "../../components/Pagination";

import "../Page.scss";

import * as articleCategoriesActions from "../../store/articleCategories/actions";
import * as articleCategoriesSelectors from "../../store/articleCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

class ArticleCategoriesListPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchArticleCategories();
  }

  componentWillUnmount() {
    this.props.unsetCurrentArticleCategoryId();
    this.props.clearExceptions();
  }

  render() {
    return (
      <div className="ArticleCategoriesListPage">
        <Topbar>
          <div className="title">
            <Link to="/articles/categories">
              {strings.get("App.articleCategoryPages.listPage.title")}
            </Link>
          </div>
          <div className="main-btns">
            <Link to="/articles" className="btn btn-default btn-color-change">
              <span>
                {strings.get("App.articleCategoryPages.listPage.articleList")}
              </span>{" "}
              <i className="ion-ios-list-outline"></i>
            </Link>
            <Link to="/articles/categories/add" className="btn btn-primary">
              <span>
                {strings.get(
                  "App.articleCategoryPages.listPage.addArticleCategory"
                )}
              </span>{" "}
              <i className="ion-plus"></i>
            </Link>
          </div>
        </Topbar>

        <div className="content">
          <div className="row">
            <div className="col-xs-12">
              <ArticleCategoryPagesize
                sorter={this.props.sorter}
                fetchItems={this.props.fetchArticleCategories}
                setSorter={this.props.setSorter}
              />
            </div>
          </div>
          <ArticleCategoryList
            items={this.props.articleCategories}
            currentItem={this.props.currentArticleCategory}
            fetchItems={this.props.fetchArticleCategories}
            setCurrentItemId={this.props.setCurrentArticleCategoryId}
            unsetCurrentItemId={this.props.unsetCurrentArticleCategoryId}
            deleteItem={this.props.deleteArticleCategory}
            updateItem={this.props.updateArticleCategory}
            orderItems={this.props.orderItems}
          />

          <Pagination
            pagination={this.props.pagination}
            setCurrentPage={this.props.setCurrentPage}
            fetchItems={this.props.fetchArticleCategories}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sorter: articleCategoriesSelectors.getSorter(state),
    articleCategories: articleCategoriesSelectors.getItemsByPage(
      state,
      articleCategoriesSelectors.getPagination(state).currentPage
    ),
    pagination: articleCategoriesSelectors.getPagination(state),
    currentArticleCategory: articleCategoriesSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchArticleCategories: (deleteCache) => {
      dispatch(articleCategoriesActions.fetchItems(deleteCache));
    },
    setCurrentPage: (page) => {
      dispatch(articleCategoriesActions.setCurrentPage(page));
    },
    setCurrentArticleCategoryId: (id) => {
      dispatch(articleCategoriesActions.setCurrentItemId(id));
    },
    unsetCurrentArticleCategoryId: () => {
      dispatch(articleCategoriesActions.unsetCurrentItemId());
    },
    deleteArticleCategory: (id, handleCatDeleteResponse) => {
      dispatch(
        articleCategoriesActions.deleteItem(id, handleCatDeleteResponse)
      );
    },
    updateArticleCategory: (id, data) => {
      dispatch(articleCategoriesActions.updateItem(id, data));
    },
    toggleSorter: (searchTerm) => {
      dispatch(articleCategoriesActions.toggleSorter(searchTerm));
    },
    setSorter: (sorter) => {
      dispatch(articleCategoriesActions.setSorter(sorter));
    },
    orderItems: (items) => {
      dispatch(articleCategoriesActions.orderItems(items));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleCategoriesListPage);
