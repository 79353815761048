import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import PropTypes from "prop-types";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import _ from "lodash";

import ActiveLabel from "../ActiveLabel";
import Editor from "../Editor";

import ShowMoreText from "react-show-more-text";
import Select from "react-select";
import "./GlossaryFormCarousels.scss";
import Helpbox from "../document/Helpbox";
import CarouselOption from "./carousels/CarouselOption";
import FadeModal from "reboron/FadeModal";

import CustomDropModal from "../common/Popup";

let componentInstance = null;

const SortableDragger = SortableHandle(() => {
  return <div className="fieldOptionsDrag" />;
});

const SortableItem = SortableElement(
  ({ value, handleFieldSize, fieldsSize }) => {
    let fieldName = value.name;
    if (value.placeholderField && value.placeholderField !== "") {
      fieldName = value.placeholderField;
    }
    let fieldWidth =
      fieldsSize[value.name] !== undefined ? fieldsSize[value.name].size : 100;
    return (
      <div
        style={{
          padding: "2px",
          flex: `0 0 ${
            parseInt(fieldWidth)
              ? parseInt(fieldWidth) > 20
                ? parseInt(fieldWidth)
                : 20
              : 20
          }%`,
          zIndex: "1100",
          minWidth: "0px",
        }}
      >
        <div className="ActiveLabel activeFields">
          <div
            className="label-content fieldsContent"
            style={{ display: "flex" }}
          >
            <SortableDragger />
            <span />
            {fieldName}
          </div>
          <div className="fieldSizeContent">
            <input
              type="number"
              key={value.name}
              className="fieldSize"
              value={fieldWidth}
              onChange={(e) => {
                handleFieldSize(e.target.value, value);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, handleFieldSize, fieldsSize }) => {
    let newItems = [];
    _.map(items, (value, ind) => {
      newItems.push(_.extend({ newIndex: ind }, value));
    });
    let rows = _.map(newItems, (value) => {
      if (isNaN(value.index)) {
        value.index = parseInt(value.index);
      }
      return (
        <SortableItem
          key={`item-${value.newIndex}`}
          index={value.newIndex}
          value={value}
          handleFieldSize={handleFieldSize}
          fieldsSize={fieldsSize}
        />
      );
    });
    return <div className="fieldWrap">{rows}</div>;
  }
);

const SortableTableRow = SortableElement(({ value, prefix = "" }) => {
  let description =
    _.isString(value.description) && value.description
      ? value.description
      : value.description.default
      ? value.description.default
      : "";
  // let name = value.name.substr(value.name.indexOf("_")+1);
  let name = value.name;

  return (
    <tr>
      <td>
        <SortableDragger />
      </td>
      <td>carousel.carousel_{value.id}</td>
      <td>{prefix === "" ? value.name : `${prefix}.${name}`}</td>
      <td>
        <ShowMoreText
          lines={2}
          more={strings.get("App.glossaries.glossaryForm.showMore")}
          less={strings.get("App.glossaries.glossaryForm.showLess")}
          anchorClass=""
        >
          {componentInstance.buildLabel(description)}
        </ShowMoreText>
      </td>
      <td>
        <i
          className="btn btn-default edit-btn ion-edit"
          onClick={() => componentInstance.handleItemClick(value.index)}
        />
        <i
          className="btn btn-default delete-btn ion-trash-b"
          onClick={() =>
            componentInstance.handleItemRemove(value.index, value.id)
          }
        />
        <i
          className="btn btn-default ion-eye"
          onClick={() => componentInstance.handleItemDocumentList(value.id)}
        />
        {/* <i
          className="btn btn-default ion-android-list"
          onClick={() => componentInstance.handleCodeOrderList(value.id)}
        /> */}
      </td>
    </tr>
  );
});

const SortableTable = SortableContainer(({ items, prefix }) => {
  let rows = _.map(items, (value) => {
    return (
      <SortableTableRow
        key={`item-${value.index}`}
        index={value.index}
        value={value}
        prefix={prefix}
        style={{ border: "3px solid blue" }}
      />
    );
  });

  return (
    <table className="table table-bordered table-list">
      <thead>
        <tr>
          <th>{strings.get("App.glossaries.glossaryForm.order")}</th>
          <th>Id</th>
          <th>Carousel</th>
          <th>
            {strings.get(
              "App.glossaries.glossaryForm.carousels.formModal.description"
            )}
          </th>
          <th>{strings.get("General.action")}</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
});

class StepTabs extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      orderedItem: null,
    };
  }

  componentDidMount() {
    if (this.props.items) {
      this.setState({
        orderedItem: this.props.items,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      orderedItem: nextProps.items,
    });
  }

  render() {
    let { orderedItem } = this.state;
    if (!orderedItem) {
      return null;
    }
    const StepList = ({ items }) => {
      let rows = _.map(items, (value) => {
        let className =
          value.id === this.props.currentItemId
            ? "tabItem step-label-content active"
            : "tabItem step-label-content";
        return (
          <div className="ActiveLabel" key={_.uniqueId()}>
            <div
              className={className}
              onClick={() => {
                this.props.onClick(value.id);
              }}
            >
              <span />
              {this.props.buildLabel(value.name)}
            </div>
          </div>
        );
      });
      return <div style={{ width: "100%" }}>{rows}</div>;
    };

    return <StepList items={orderedItem} />;
  }
}

class GlossaryFormCarousels extends Component {
  state = {
    itemsLoaded: false,
    currentItemKey: -1,
    currentItem: null,
    errors: {},
    tmpItem: {
      name: "",
      description: {
        default: "",
        client: "",
        me: "",
      },
      placeholder: "",
      options: [],
      optionsDelete: [],
    },
    items: [],
    documentsByCode: [],
    code: "",
    deleteError: false,
    currentStepId: null,
    fieldsLoaded: false,
    fields: [],
    fieldsSize: {},
    fieldsPercentageStatus: {
      minPer: 20,
      error: false,
    },
    stepsLoaded: false,
    steps: [],
    options: [],
    fieldSuggest: [],
    codeSuggest: [],
  };

  initFormData = `{
    "name": "",
    "description": {
      "default": "",
      "client": "",
      "me": ""
    },
    "placeholder": "",
    "options": [],
    "optionsDelete": []
  }`;

  componentDidMount() {
    this.tryLoadItems();
  }

  componentDidUpdate(prevProps, prevState) {
    this.tryLoadItems();
    if (
      _.size(this.props.documentsByCode) > 0 &&
      _.size(this.props.documentsByCode) !== _.size(prevProps.documentsByCode)
    ) {
      if (!this.state.documentsByCodeLoaded) {
        let documentsByCode = [];
        _.forEach(this.props.documentsByCode, function (value, key) {
          documentsByCode.push(value);
        });
        this.setState({
          documentsByCodeLoaded: true,
          documentsByCode: documentsByCode,
        });
      }
    }

    //Form validation scroll concept.
    if (_.size(this.state.errors)) {
      let el = document.getElementsByClassName("has-error");
      if (_.size(el)) {
        el[0].scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.size(nextProps.steps) && !this.state.visibleStepsLoading) {
      this.getCurrentSteps(nextProps);
    }
    if(!_.isEqual(_.sortBy(nextProps.items), _.sortBy(this.state.items)) || 
    !_.isEqual(_.sortBy(nextProps.activeItems), _.sortBy(this.state.activeItems))) {

      this.setState({
        items: nextProps.items,
        activeItems: nextProps.activeItems
      });
      if(this.props.activeItems.id) {
        const activeIndex = _.findIndex(this.props.items,(val)=>val.id == this.props.activeItems.id);
        this.handleItemClick(activeIndex);
      }
    }
    if (
      this.state.fieldsLoaded &&
      (_.size(nextProps.fields) ||
        _.size(nextProps.selectors) ||
        _.size(nextProps.clauses)) &&
      _.size({
        ...nextProps.fields,
        ...nextProps.clauses,
        ...nextProps.selectors,
      }) !== _.size(this.state.fields)
    ) {
      this.loadFields(nextProps);
    }

    if (
      this.state.stepsLoaded &&
      nextProps.stepsFromValidation !== this.state.steps
    ) {
      this.loadSteps(nextProps);
    }

    if (nextProps.prefix !== this.props.prefix) {
      let { tmpItem } = this.state;
      tmpItem.name = nextProps.prefix;
      this.setState({
        tmpItem,
      });
    }
  }
  constructor(props) {
    super(props);
    autoBind(this);
    componentInstance = this;
  }

  handleChangeCarouselOption = (option) => {
    let options = this.state.tmpItem.options.map((item) => {
      if (item.id !== option.id) return item;
      return option;
    });
    this.setState({
      tmpItem: {
        ...this.state.tmpItem,
        options,
      },
    });
  };

  tryLoadItems() {
    if (_.size(this.props.items) && !this.state.itemsLoaded) {
      this.setState({
        itemsLoaded: true,
        items: this.props.items,
      });
      if(this.props.activeItems.id) {
        const activeIndex = _.findIndex(this.props.items,(val)=>val.id == this.props.activeItems.id);
        this.handleItemClick(activeIndex);
      }
    } else if (
      !this.state.tmpItem.name &&
      this.props.currentItem &&
      this.props.currentItem.type === "carousel"
    ) {
      let tmpItem = _.extend({}, this.state.tmpItem);
      tmpItem.name = this.props.currentItem.name;

      this.setState({
        tmpItem,
      });
    } else if (
      _.size(this.state.items) === 0 &&
      this.props.items &&
      _.size(this.props.items) &&
      this.state.itemsLoaded
    ) {
      this.setState({ itemsLoaded: false, items: this.props.items });
    }
    if (
      (_.size(this.props.fields) ||
        _.size(this.props.selectors) ||
        _.size(this.props.clauses)) &&
      !this.state.fieldsLoaded
    ) {
      this.loadFields(this.props);
    }

    if (_.size(this.props.stepsFromValidation) && !this.state.stepsLoaded) {
      this.loadSteps(this.props);
    }
  }

  loadFields(props = this.props) {
    let newFields = _.map(props.fields, (item) => {
      return item.asMutable ? item.asMutable() : item;
    });
    _.map(props.selectors, (item) => {
      newFields.push(item.asMutable ? item.asMutable() : item);
    });
    _.map(props.clauses, (item) => {
      newFields.push(item.asMutable ? item.asMutable() : item);
    });
    if (
      this.state.currentItem &&
      this.state.currentItem.contentCodeOrder &&
      this.state.currentItem.contentCodeOrder !== "" &&
      this.state.currentItem.contentCodeOrder !== "null"
    ) {
      let codeOrders;
      try {
        codeOrders = JSON.parse(this.state.currentItem.contentCodeOrder);
      } catch (error) {}
      _.map(newFields, (item) => {
        if (typeof codeOrders[item.name] !== "undefined") {
          item.index = codeOrders[item.name].order;
        }
      });
      newFields = _.sortBy(newFields, "index");
    }
    newFields = this.orderFieldsByStep(newFields);
    this.setState({
      fieldsLoaded: true,
      fields: newFields,
    });
  }

  loadSteps = (props = this.props) => {
    if (_.size(props.stepsFromValidation) === 0 && this.state.stepsLoaded) {
      this.setState({
        stepsLoaded: false,
      });
    }
    let items = _.map(props.stepsFromValidation, (step) => {
      return step;
    });
    let current_step_id = 0;
    if (items.length && items[0].id) {
      current_step_id = items[0].id;
    }
    this.setState({
      stepsLoaded: true,
      steps: items,
      currentStepId: current_step_id,
    });
  };

  orderFieldsByStep(arr) {
    let y = [];
    _.map(arr, (field) => {
      if (!y[field["stepId"] - 1]) y[field["stepId"] - 1] = [];
      y[field["stepId"] - 1].push(field);
    });

    let newArr = [];
    y = _.map(y, (item) => {
      if (typeof item !== "undefined") newArr.push(item);
    });

    return newArr;
  }

  getCurrentSteps(props = this.props) {
    let { visibleSteps, visibleStepsLoading } = this.state;
    if (_.size(props.steps) && visibleSteps) {
      let newItems = this.state.items;
      let y = [];
      newItems.map((item, index) => {
        if (!y[item["stepId"] - 1]) y[item["stepId"] - 1] = [];
        item.index = index;
        y[item["stepId"] - 1].push(item);
      });
      newItems = [];
      y.map((item) => {
        if (typeof item !== "undefined") newItems.push(item);
      });
      newItems.map((items, i) => {
        let stepOrder =
          _.size(props.steps) && props.steps["_" + items[0].stepId]
            ? props.steps["_" + items[0].stepId].order
            : null;
        items.stepOrder = stepOrder;
      });
      newItems = _.orderBy(newItems, ["stepOrder"], ["asc"]);
      newItems.map((item, i) => {
        visibleSteps["item-" + "selector-" + i] = item;
      });
      this.setState({
        visibleSteps,
        visibleStepsLoading: true,
      });
    }
  }

  validate() {
    let item = this.state.tmpItem;
    let duplicate = this.isDuplicated(item);
    let errors = {};
    if (_.size(item.options)) {
      item.options.forEach((option, index) => {
        if (!option.name || option.name.trim() === "") {
          errors[`option ${index + 1}`] = strings.get("Exceptions.required");
          errors[`name ${index + 1}`] = strings.get("Exceptions.required");
        }
        if (option.isClause) {
          if (!option.clauseId || option.clauseId.trim() === "") {
            errors[`option ${index + 1}`] = strings.get("Exceptions.required");
            errors[`clauseId ${index + 1}`] = strings.get(
              "Exceptions.required"
            );
          } else if (!/^[0-9]+$/.test(option.clauseId)) {
            errors[`clauseId ${index + 1}`] = strings.get("Exceptions.invalid");
          }
        } else {
          if (!option.content || option.content.trim() === "") {
            errors[`option ${index + 1}`] = strings.get("Exceptions.required");
            errors[`content ${index + 1}`] = strings.get("Exceptions.required");
          }
        }
      });
    }
    if (item.name === "") {
      errors["name"] = strings.get("Exceptions.required");
    } else if (!/^[a-z0-9_]+$/.test(item.name)) {
      errors["name"] = strings.get("Exceptions.onlyLowercase");
    }

    if (item.placeholder === "") {
      errors["placeholder"] = strings.get("Exceptions.required");
    }

    if (!_.isObject(item.description)) {
      errors["description"] = strings.get("Exceptions.required");
    } else if (
      _.size(item.description) >= 1 &&
      item.description.hasOwnProperty("default") &&
      item.description.default === ""
    ) {
      errors["description"] = strings.get("Exceptions.required");
    }

    if (duplicate) {
      if (_.isObject(duplicate)) {
        errors["name"] = strings.get("Exceptions.existsInOther", {
          itemName: duplicate.name,
        });
      } else {
        errors["name"] = strings.get("Exceptions.existsInThis");
      }
    }

    console.log("errorrr", errors);
    if (_.size(errors)) {
      this.setState({ errors });
      return false;
    }

    return true;
  }

  isDuplicated(item) {
    let duplicate;
    let currentGlossary = this.props.currentGlossary;

    // If item has been edited and name didn't change is not duplicate
    if (this.state.currentItem && item.name === this.state.currentItem.name) {
      return null;
    }

    // Check duplicates in other glossaries
    _.each(this.props.glossaries, (glossary) => {
      // Skip current glossary
      if (!currentGlossary || currentGlossary.id !== glossary.id) {
        let value = JSON.parse(glossary.value);

        _.each(value.clauses, (clause) => {
          if (clause.name === item.name) {
            duplicate = glossary;
          }
        });
      }
    });

    // Check duplicates into current glossary
    _.each(this.state.items, (clause) => {
      if (clause.name === item.name) {
        duplicate = true;
      }
    });

    return duplicate;
  }

  hasError(inputName) {
    return !!this.state.errors[inputName];
  }

  getErrorClass(inputName, defaultClasses = "") {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  }

  getErrorMessage(inputName) {
    return this.state.errors[inputName];
  }

  getSuggesions = () =>{
    let codeSuggest = [];
    let fieldSuggest = [];
    const  currentGlossary = this.props.currentGlossary;
    const glossariesValue = currentGlossary ? JSON.parse(currentGlossary.value) : {};
    _.map(glossariesValue, (elements,key) => {
      let name = "";
      if(key === "carousels") {
         name = `carousel.carousel_`;
      }
      else if(key === "clauses") {
          name = `if.clause_`;
      }
      else if(key === "selectors") {
        name = `select.select_`;
      }
      else if(key === "CodeBlocks") {
        name = `code.block_`;
      }
      else {
        name = "field_";
      }

      _.forEach(elements, (code) => {
          if(key==="fields") {
            fieldSuggest.push({name: `${name}${code.id}`, contentName: code.name});
          }
          else {
            codeSuggest.push({name: `${name}${code.id}`, contentName: code.name});
          }
      });
    });
    this.setState({
      codeSuggest,
      fieldSuggest
    });
  }

  showFormModal() {
    this.getSuggesions()
    this.refs.formModal.show();
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideFormModal() {
    this.refs.formModal.hide();
    if(this.props.activeItems.id)  window.close();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  processItems(item) {
    let description = _.isObject(item.description)
      ? item.description
      : {
          default: item.description,
        };

    return _.extend(item, {
      description,
    });
  }

  loadAllDocuments(code) {
    if (code !== this.state.code) {
      this.setState({
        documentsByCodeLoaded: false,
        documentsByCode: [],
      });
    }
    this.props.setGlossaryCode(code, "carousel");
    this.props.fetchAllDocuments(true);
  }

  handleItemClick(key) {
    // this.loadAllDocuments(code);

    this.initFormData = JSON.stringify(
      this.processItems(this.state.items[key]||{})
    );
    this.showFormModal();
    this.setState({
      currentItemKey: key,
      currentItem: JSON.parse(this.initFormData),
      tmpItem: JSON.parse(this.initFormData),
    });
  }

  handleItemRemove(key, code) {
    // this.loadAllDocuments(code);
    _.delay(() => {
      this.showDeleteModal();
    }, 500);
    this.setState({
      currentItemKey: key,
    });
  }

  handleItemDocumentList(code) {
    this.loadAllDocuments(code);
    this.setState({
      code: code,
    });
    _.delay(() => {
      this.showDocumentListModal();
    }, 500);
  }

  showDocumentListModal() {
    this.refs.documentListModal.show();
  }

  hideDocumentListModal() {
    this.refs.documentListModal.hide();
  }

  handleCodeOrderList(carouselId) {
    let { items, fieldsSize } = this.state;
    let currentCodeBlock = {};
    items.forEach((carousel) => {
      if (carousel.id === carouselId) {
        currentCodeBlock = carousel;
        if (
          carousel.contentCodeOrder &&
          carousel.contentCodeOrder !== "" &&
          carousel.contentCodeOrder !== "null"
        ) {
          try {
            fieldsSize = JSON.parse(carousel.contentCodeOrder);
          } catch (error) {
            //  console.trace(error);
          }
        }
      }
    });
    this.setState(
      {
        fields: [],
        steps: [],
        fieldsLoaded: false,
        stepsLoaded: false,
        fieldsSize,
      },
      () => {
        if (_.size(currentCodeBlock)) {
          this.props.validateTemplate(currentCodeBlock);
          this.setState({ currentItem: currentCodeBlock });
        }
        this.showCodeOrderListModal();
      }
    );
  }

  showCodeOrderListModal() {
    this.refs.orderFieldsModal.show();
  }

  hideCodeOrderListModal() {
    this.refs.orderFieldsModal.hide();
  }

  documentsList() {
    let items = _.map(this.state.documentsByCode, (item) => {
      return (
        <tr key={item.id}>
          <td>
            <a href={`/documents/${item.id}`} target="_blank">
              {item.name}
            </a>
          </td>
          <td>{item.category ? item.category.name : ""}</td>
        </tr>
      );
    });
    return items;
  }

  handleConfirmItemRemove(e) {
    e.preventDefault();
    if (this.state.documentsByCode.length) {
      this.setState({
        deleteError: true,
      });
    } else {
      let index = this.state.currentItemKey;
      let carousel_id = this.state.items[index].id;
      if (this.props.currentGlossary) {
        this.props.onDeleteCarousel(carousel_id);
      }
      let items = _.filter(this.state.items, (item, key) => {
        return key !== this.state.currentItemKey;
      });

      this.props.onChange(items);
      this.hideDeleteModal();
      _.delay(
        () =>
          this.setState({
            currentItemKey: -1,
            items,
          }),
        250
      );
    }
  }

  handleAddClick() {
    if (this.props.currentItem) this.props.addClick();
    this.showFormModal();
  }

  handleSortEnd({ oldIndex, newIndex }) {
    let items = arrayMove(this.state.items, oldIndex, newIndex);
    items.forEach((item, index) => {
      item.codeOrder = index;
    });
    this.props.onChange(items);
    this.setState({ items });
  }

  handleInputChange(e) {
    let item = { ...this.state.tmpItem };
    if (e.target.getAttribute("data-key")) {
      item[e.target.name] = {
        ...item[e.target.name],
        [e.target.getAttribute("data-key")]: e.target.value,
      };
    } else {
      item[e.target.name] = e.target.value;
    }
    this.setState({
      tmpItem: _.extend(this.state.tmpItem, item),
    });
  }

  deleteCarouselOption = (id) => {
    let options = this.state.tmpItem.options.filter((item) => item.id !== id);
    let optionsDelete =
      (this.state.tmpItem && this.state.tmpItem.optionsDelete) || [];
    this.setState({
      tmpItem: {
        ...this.state.tmpItem,
        options,
        optionsDelete: [...optionsDelete, id],
      },
    });
    // this.props.deleteCarouselOption(id);
  };

  handleChangeToRegex(contentTemplate, itemKey, isRegex) {
    let tempRegex = null;
    while (null !== (tempRegex = isRegex.exec(contentTemplate))) {
      if (tempRegex) {
        if (
          itemKey[tempRegex[1]] &&
          itemKey[tempRegex[1]] &&
          itemKey[tempRegex[1]].id
        ) {
          if (
            isRegex.toString() === new RegExp(/.*\[select_(.*)\]/g).toString()
          ) {
            contentTemplate = contentTemplate.replace(
              `_${tempRegex[1]}`,
              `.select_${itemKey[tempRegex[1]].id}`
            );
          } else {
            contentTemplate = contentTemplate.replace(
              `_${tempRegex[1]}`,
              `_${itemKey[tempRegex[1]].id}`
            );
          }
        }
      }
    }
    return contentTemplate;
  }

  onScroll = (id) => {
    var myElement = document.getElementById(id);
    if (myElement) {
      document
        .getElementById("helpbox-container")
        .querySelectorAll(".active")
        .forEach((e) => e.classList.remove("active"));
      myElement.classList.add("active");
      var topPos = myElement.offsetTop;
      document.getElementById("helpbox-container").scrollTop = topPos - 726;
    }
  };

  handleRequiredChange(e) {
    let item = {};
    item[e.target.name] = e.target.value;
    this.setState({
      tmpItem: _.extend(this.state.tmpItem, item),
    });
  }

  handleFormModalHide() {
    this.setState({
      itemsLoaded: false,
      currentItemKey: -1,
      currentItem: null,
      errors: {},
      tmpItem: {
        name: this.props.prefix,
        description: "",
      },
      items: [],

      code: "",
      documentsByCode: [],
    });
    if(this.props.activeItems.id)  window.close();
  }

  getNameCount = (name) =>{
    let overallCount,currentCount
    let currentGlossary = this.props.currentGlossary;
    _.each(this.props.glossaries, (glossary) => {
      // Skip current glossary
      if (!currentGlossary || currentGlossary.id !== glossary.id) {
        let value = JSON.parse(glossary.value);

        overallCount =  _.filter(value.carousels, (carousel) => 
          carousel.name.search(name) !== -1
        );
      }
    });
    currentCount =  _.filter(this.state.items, (carousel) => 
      carousel.name.search(name) !== -1 
    );
    return currentCount.length +  overallCount.length + 1;
  }

  getCopyName = (item) =>{

    if (item.name.search('_copy_') === -1) {
      const count = this.getNameCount(`${item.name}_copy`)
      return `${item.name}_copy_${count}`
    }
    const splitval = item.name.split('_');
    splitval.splice(splitval.length - 1, 1);
    const count = this.getNameCount(splitval.join('_'));
  
    return  `${splitval.join('_')}_${count}`
  }

  async handleSaveClick(e, isCopy) {
    e.preventDefault();
    // Validate temporary item
    if (this.validate() || (this.state.currentItemKey !== -1 && isCopy)) {
      let items = _.extend([], this.state.items);
      let tmpItem = this.state.tmpItem;
      if (this.state.currentItemKey === -1) {
        // Add item
        tmpItem.id = `${_.uniqueId("temp_")}`;
        items.push(tmpItem);
      } else {
        // Edit item
        if (!isNaN(tmpItem.id)) {
          let carousel_id = this.state.currentItem.id;
          let data = tmpItem;
          delete data.index;
          delete data.created_at;
          delete data.deleted_at;
          delete data.updated_at;
          data.copy = !!isCopy;
          if(isCopy){
            data.copy_name = this.getCopyName(tmpItem)
          }
          await this.props.onSaveCarousel(carousel_id, {
            ...data,
            description: JSON.stringify(data.description),
            options: JSON.stringify(data.options),
            optionsDelete:
              (data.optionsDelete && JSON.stringify(data.optionsDelete)) ||
              "[]",
          },
          !!this.props.activeItems.id);
        }
        items[this.state.currentItemKey] = tmpItem;
      }
      if (
        this.props.exceptions.template &&
        this.props.exceptions.template.notDefined
      ) {
        this.setState({});
      } else {
        //not exceptions then show errors
        this.props.onChange(items);
        if (!this.props.activeItems.id) this.hideFormModal();
      }
    }
  }

  resetFormModal = () => {
    this.setState({
      currentItem: JSON.parse(this.initFormData),
      tmpItem: JSON.parse(this.initFormData),
    });
  };

  handleCancelClick(e) {
    e.preventDefault();
    this.hideFormModal();
    this.hideDeleteModal();
  }

  hanldeDeleteModalHide() {
    this.setState({
      deleteError: false,
      code: "",
    });
  }

  handleAccordionToggle() {
    if (this.props.currentItem)
      this.refs.hidden.style.display =
        this.refs.hidden.style.display === "none" ? "" : "none";
  }

  buildLabel(Item, item) {
    if (!Item || Item === undefined) return Item;
    let glossariesCodeIdToName = this.props.glossariesCodeIdToName;
    let expandGlossaries = this.props.expandGlossaries;
    let _label = Item;
    // separate out all selectors and loop through
    let result = strings.getFromBetween.get(_label, "[[", "]]");
    result.forEach((e) => {
      const reg = new RegExp(e);

      //if multiple codes are responsible for name of a step
      let stepNameOptions = e.split("||");
      if (stepNameOptions.length > 1) {
        stepNameOptions.forEach((element) => {
          element = element.trim();
          // work out pattern [[*.*_*]] for selects
          if (element.indexOf(".") > -1) {
            const field = element.split(".")[0];
            if (expandGlossaries && _.size(expandGlossaries)) {
              if (
                expandGlossaries[field] &&
                expandGlossaries[field].placeholderField
              ) {
                _label = _label
                  .replace(element, expandGlossaries[field].placeholderField)
                  .replace(/\[\[|\]\]/g, "");
              } else if (
                expandGlossaries[glossariesCodeIdToName[field]] &&
                expandGlossaries[glossariesCodeIdToName[field]].placeholderField
              ) {
                _label = _label
                  .replace(
                    element,
                    expandGlossaries[glossariesCodeIdToName[field]]
                      .placeholderField
                  )
                  .replace(/\[\[|\]\]/g, "");
              } else {
                _label = _label
                  .replace(element, "< ## >")
                  .replace(/\[\[|\]\]/g, "");
              }
            }
          } else {
            if (expandGlossaries && _.size(expandGlossaries)) {
              if (
                expandGlossaries[element] &&
                expandGlossaries[element].placeholderField
              ) {
                _label = _label
                  .replace(element, expandGlossaries[element].placeholderField)
                  .replace(/\[\[|\]\]/g, "");
              } else if (
                expandGlossaries[glossariesCodeIdToName[element]] &&
                expandGlossaries[glossariesCodeIdToName[element]]
                  .placeholderField
              ) {
                _label = _label
                  .replace(
                    element,
                    expandGlossaries[glossariesCodeIdToName[element]]
                      .placeholderField
                  )
                  .replace(/\[\[|\]\]/g, "");
              } else {
                _label = _label
                  .replace(element, "< ## >")
                  .replace(/\[\[|\]\]/g, "");
              }
            }
          }
        });
      }
      //if single code is responsible for name of a step
      // work out pattern [[*.*_*]] for selects
      else if (e.indexOf(".") > -1) {
        const field = e.split(".")[0];
        if (expandGlossaries && _.size(expandGlossaries)) {
          if (
            expandGlossaries[field] &&
            expandGlossaries[field].placeholderField
          ) {
            _label = _label
              .replace(reg, expandGlossaries[field].placeholderField)
              .replace(/\[\[|\]\]/g, "");
          } else if (
            expandGlossaries[glossariesCodeIdToName[field]] &&
            expandGlossaries[glossariesCodeIdToName[field]].placeholderField
          ) {
            _label = _label
              .replace(
                reg,
                expandGlossaries[glossariesCodeIdToName[field]].placeholderField
              )
              .replace(/\[\[|\]\]/g, "");
          } else {
            _label = _label.replace(reg, "< ## >").replace(/\[\[|\]\]/g, "");
          }
        }
      } else {
        // if there's no placeholder there, then find placeholder in all glossaries
        if (expandGlossaries && _.size(expandGlossaries)) {
          if (expandGlossaries[e] && expandGlossaries[e].placeholderField) {
            _label = _label
              .replace(reg, expandGlossaries[e].placeholderField)
              .replace(/\[\[|\]\]/g, "");
          } else if (
            expandGlossaries[glossariesCodeIdToName[e]] &&
            expandGlossaries[glossariesCodeIdToName[e]].placeholderField
          ) {
            _label = _label
              .replace(
                reg,
                expandGlossaries[glossariesCodeIdToName[e]].placeholderField
              )
              .replace(/\[\[|\]\]/g, "");
          } else {
            _label = _label.replace(reg, "< ## >").replace(/\[\[|\]\]/g, "");
          }
        }
      }
    });
    return _label;
  }

  handleArticleChange = (selectedOption) => {
    if (selectedOption) {
      this.setState({
        tmpItem: _.extend(this.state.tmpItem, {
          helperLink: selectedOption.value,
        }),
      });
    } else {
      this.setState({
        tmpItem: _.extend(this.state.tmpItem, {
          helperLink: "",
        }),
      });
    }
  };

  handleTabsItemClick(id) {
    this.setState({ currentStepId: id });
  }

  handleSortEnd({ oldIndex, newIndex, index }) {
    let stepItems = _.find(this.state.fields, ["stepId", index]);
    let stepItemsIndex = _.findIndex(this.state.fields, ["stepId", index]);
    let items = arrayMove(stepItems, oldIndex, newIndex);
    let newItems = [];
    _.map(this.state.fields, (field, ind) => {
      if (ind === stepItemsIndex) {
        newItems[ind] = items;
      } else {
        newItems[ind] = field;
      }
    });
    this.setState({ fields: newItems });
  }

  carouselFieldsOrderModalConfirm() {
    let { fieldsSize } = this.state;
    let newFields = {};
    let ind = 0;
    _.map(this.state.fields, (items) => {
      _.map(items, (item) => {
        let temp = {};
        temp.order = ind;
        if (fieldsSize[item.name]) {
          temp.size = parseInt(fieldsSize[item.name].size)
            ? parseInt(fieldsSize[item.name].size) <
              this.state.fieldsPercentageStatus.minPer
              ? this.state.fieldsPercentageStatus.minPer
              : parseInt(fieldsSize[item.name].size)
            : this.state.fieldsPercentageStatus.minPer;
        } else {
          temp.size = 100;
        }
        newFields[item.name] = temp;
        ind++;
      });
    });
    // if(this.state.currentItem && _.size(newFields)){
    if (
      !this.state.fieldsPercentageStatus.error &&
      this.state.currentItem &&
      _.size(newFields)
    ) {
      let { items, currentItem } = this.state;
      items.forEach((item) => {
        if (item.id === this.state.currentItem.id) {
          item.contentCodeOrder = JSON.stringify(newFields);
        }
      });
      currentItem.contentCodeOrder = JSON.stringify(newFields);
      this.setState({ items, currentItem }, async () => {
        if (!isNaN(currentItem.id)) {
          delete currentItem.index;
          delete currentItem.created_at;
          delete currentItem.deleted_at;
          delete currentItem.updated_at;

          await this.props.onSaveCarousel(currentItem.id, {
            ...currentItem,
            description: currentItem.description,
          },
          !!this.params.activeItems.id);
          this.refs.orderFieldsModal.hide();
        }
      });
    }
  }

  handleFieldSize(data, element) {
    let { fieldsSize, fieldsPercentageStatus } = this.state;
    if (parseInt(data)) {
      if (parseInt(data) <= 100) {
        if (fieldsSize[element.name]) {
          fieldsSize[element.name].size = data;
        } else {
          let x = {};
          x.size = data;
          fieldsSize[element.name] = x;
        }
      }
    } else {
      if (fieldsSize[element.name]) {
        fieldsSize[element.name].size = data;
      } else {
        let x = {};
        x.size = data;
        fieldsSize[element.name] = x;
      }
    }
    if (parseInt(data) && parseInt(data) > fieldsPercentageStatus.minPer) {
      fieldsPercentageStatus.error = false;
    } else {
      fieldsPercentageStatus.error = true;
    }
    this.setState({ fieldsSize, fieldsPercentageStatus });
  }

  addCarouselOption = () => {
    let tmpItem = this.state.tmpItem;
    let options = tmpItem.options || [];

    this.setState(
      {
        tmpItem: {
          ...tmpItem,
          options: [
            ...options,
            {
              order: options.length + 1,
              glossary_carousel_id: this.state.currentItem
                ? this.state.currentItem.id
                : 0,
              id: Math.random(),
            },
          ],
        },
      },
      () => {}
    );
  };
  render() {
    let descriptionLabel = this.hasError("description")
      ? `${strings.get(
          "App.glossaries.glossaryForm.clauses.formModal.description"
        )} ${this.getErrorMessage("description")}`
      : strings.get(
          "App.glossaries.glossaryForm.clauses.formModal.description"
        );
    let placeholderLabel = this.hasError("placeholder")
      ? `${strings.get(
          "App.glossaries.glossaryForm.fields.placeholder"
        )} ${this.getErrorMessage("placeholder")}`
      : strings.get("App.glossaries.glossaryForm.fields.placeholder");
    let stepLabel = this.hasError("stepId")
      ? `${strings.get(
          "App.glossaries.glossaryForm.clauses.formModal.step"
        )} ${this.getErrorMessage("stepId")}`
      : strings.get("App.glossaries.glossaryForm.clauses.formModal.step");

    let nameLabel = this.hasError("name")
      ? `${strings.get(
          "App.glossaries.glossaryForm.clauses.formModal.name"
        )} ${this.getErrorMessage("name")}`
      : strings.get("App.glossaries.glossaryForm.clauses.formModal.name");

    let stepsOption = _.size(this.state.selectedCategories)
      ? this.state.stepsOptions
      : this.props.steps;
    let steps = _.map(stepsOption, (item) => {
      let newLabel = this.buildLabel(item.name);
      return (
        <option value={item.id} key={item.id}>
          {newLabel}
        </option>
      );
    });

    let deleteModalContent =
      this.state.currentItemKey !== -1 ? (
        <div>
          <div className="modal-close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.handleCancelClick}
            />
          </div>
          <h2>
            {strings.get("App.deleteModal.message", {
              itemName: this.state.items[this.state.currentItemKey].name,
            })}
          </h2>
          {/*<h2>{ strings.get('App.deleteModal.message', {itemName: this.state.currentItem.name}) }</h2>*/}
          {this.state.deleteError ? (
            <div style={{ color: "red" }}>
              {strings.get("App.deleteModal.error")}
            </div>
          ) : null}
          <div className="form-actions">
            <button
              className="btn btn-lg btn-danger"
              onClick={this.handleConfirmItemRemove}
            >
              {strings.get("App.deleteModal.delete")}
            </button>
            <button
              className="btn btn-lg cancel"
              onClick={this.handleCancelClick}
            >
              {strings.get("App.deleteModal.cancel")}
            </button>
          </div>
        </div>
      ) : null;

    let deleteModal = (
      <CustomDropModal
        className="boron-modal"
        ref="deleteModal"
        onHide={this.hanldeDeleteModalHide}
      >
        {deleteModalContent}
      </CustomDropModal>
    );

    let sortableListsBySteps;
    if (_.size(this.state.fields)) {
      let stepFields = this.state.fields;
      _.map(stepFields, (fields, ind) => {
        stepFields[ind].stepId = parseInt(fields[0].stepId);
      });
      let listItems = _.find(stepFields, ["stepId", this.state.currentStepId]);
      sortableListsBySteps = (
        <div key={`item-${this.state.currentStepId}`}>
          <SortableList
            items={listItems}
            handleFieldSize={this.handleFieldSize}
            fieldsSize={this.state.fieldsSize}
            onSortEnd={({ oldIndex, newIndex }) => {
              this.handleSortEnd({
                oldIndex,
                newIndex,
                index: this.state.currentStepId,
              });
            }}
            axis="xy"
            useDragHandle
            lockToContainerEdges
            helperClass="SortableItem Field"
          />
        </div>
      );
    } else {
      sortableListsBySteps = (
        <div className="noData">
          {strings.get("App.glossaries.glossaryForm.noData")}
        </div>
      );
    }

    let codeOrderModal = (
      <CustomDropModal
        className="boron-modal"

        ref="orderFieldsModal"
        onHide={() => this.setState({ currentItem: undefined })}
      >
        <div>
          <div className="modal-close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.hideCodeOrderListModal}
            />
          </div>
          <label>
            {strings.get("App.documents.documentForm.orderFieldsModal.title")}
          </label>
          <br />
          <StepTabs
            items={this.state.steps}
            currentItemId={this.state.currentStepId}
            fields={this.state.fields}
            onClick={this.handleTabsItemClick}
            // stepsOrder = {this.state.currentItem && this.state.currentItem.steps_order ? this.state.currentItem.steps_order : ''}
            buildLabel={this.buildLabel}
          />
          {sortableListsBySteps}
          {this.state.fieldsPercentageStatus.error ? (
            <div className="fieldSizeError">
              {strings.get(
                "App.documents.documentForm.orderFieldsModal.fieldSizeError"
              )}{" "}
              {this.state.fieldsPercentageStatus.minPer}%
            </div>
          ) : null}
          <button
            style={{ marginRight: ".4rem" }}
            className="btn btn-primary"
            onClick={this.carouselFieldsOrderModalConfirm}
          >
            {strings.get("App.documents.documentForm.orderFieldsModal.save")}
          </button>
          <button
            style={{ marginLeft: ".4rem" }}
            className="btn btn-default"
            onClick={this.hideCodeOrderListModal}
          >
            {strings.get("App.documents.documentForm.orderFieldsModal.cancel")}
          </button>
        </div>
      </CustomDropModal>
    );

    let documentListModalContent = (
      <div className="documentsList">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th> {strings.get("App.users.downloads.documentName")} </th>
              <th> {strings.get("App.users.downloads.category")} </th>
            </tr>
          </thead>
          <tbody>{this.documentsList()}</tbody>
        </table>
      </div>
    );

    let formModal = ()=> {
      return (
      <FadeModal
        className="boron-modal form-modal"
        ref="formModal"
        onHide={this.handleFormModalHide}
      >
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.hideFormModal}
          />
        </div>
        <div className="scrollablelistFields">
          <div className={this.getErrorClass("name", "form-group")}>
            <label className="control-label">{nameLabel}</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={this.state.tmpItem.name}
              onChange={this.handleInputChange}
            />
          </div>
          <div className={this.getErrorClass("placeholder", "form-group")}>
            <label className="control-label">{placeholderLabel}</label>
            <input
              className="form-control"
              type="text"
              name="placeholder"
              value={this.state.tmpItem.placeholder}
              onChange={this.handleInputChange}
            />
          </div>
          <div className={this.getErrorClass("description", "form-group")}>
            <label className="control-label">{descriptionLabel}</label>
            {["default", "me", "client"].map((key) => (
              <textarea
                key={key}
                className="form-control"
                name="description"
                data-key={key}
                value={this.state.tmpItem.description[key]}
                onChange={this.handleInputChange}
                placeholder={key}
              />
            ))}
          </div>
          <div className={this.getErrorClass("stepId", "form-group")}>
            <label className="control-label">{stepLabel} </label>
            <select
              className="form-control"
              name="stepId"
              value={this.state.tmpItem.stepId}
              onChange={this.handleInputChange}
            >
              <option value="-1" disabled>
                {strings.get("App.glossaries.glossaryForm.choose")}
              </option>
              {steps}
            </select>
          </div>

          {this.state.tmpItem.options &&
            this.state.tmpItem.options.map((item, index) => {
              return (
                <CarouselOption
                  option={item}
                  index={index}
                  key={index + 1}
                  documents={[] || this.props.documents}
                  handleChangeCarouselOption={this.handleChangeCarouselOption}
                  deleteCarouselOption={this.deleteCarouselOption}
                  sumOrder={this.state.options.length}
                  handleChangeOrder={this.handleSortOrderInForm}
                  hasError={this.hasError}
                  getErrorClass={this.getErrorClass}
                  getErrorMessage={this.getErrorMessage}
                  clauseElements={
                    this.props.itemElements &&
                    this.props.itemElements.itemKeyClauses
                      ? this.props.itemElements.itemKeyClauses
                      : {}
                  }
                  currentCodes={{fields: this.state.fieldSuggest, codes: this.state.codeSuggest}}
                />
              );
            })}
          <div className="AddCarouselOption">
            <button
              type="button"
              onClick={() => this.addCarouselOption()}
              className="btn btn-info btn-add"
            >
              {" "}
              + Add Option
            </button>
          </div>
        </div>
        <div className="form-actions">
          <button className="btn btn-primary" onClick={this.handleSaveClick}>
            {strings.get("App.glossaries.glossaryForm.clauses.formModal.save")}
          </button>
          <button className="btn btn-default cancel" onClick={this.handleCancelClick}>
            {strings.get(
              "App.glossaries.glossaryForm.clauses.formModal.cancel"
            )}
          </button>
          <button className="btn btn-default cancel" onClick={(e) => this.handleSaveClick(e, true)}>
            {strings.get(
              "App.glossaries.glossaryForm.clauses.formModal.duplicate"
            )}
          </button>
        </div>
      </FadeModal>
    )};

    //1- Add Index of the Clauses
    let newItems = this.state.items || [];
    newItems.map((item, index) => {
      item.index = index;
    });

    return (
      <div className="GlossaryFormCarousels form-group">
        {deleteModal}
        <div>{formModal()}</div>
        {codeOrderModal}
        <CustomDropModal className="boron-modal" ref="documentListModal">
          <div className="modal-close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.hideDocumentListModal}
            />
          </div>
          {documentListModalContent}
        </CustomDropModal>
        <div className="header">
          <span
            className="btn btn-primary small-add mb-10"
            onClick={this.handleAddClick}
          >
            <i className="ion-android-add" />
          </span>
        </div>
        <div ref="hidden" className="table-responsive">
          <SortableTable
            items={this.state.items}
            prefix={this.props.prefix}
            onSortEnd={this.handleSortEnd}
            axis="xy"
            useDragHandle={true}
            lockToContainerEdges={true}
            helperClass="glossarySortableRow Clause"
          />
        </div>
      </div>
    );
  }
}

GlossaryFormCarousels.propTypes = {
  items: PropTypes.array,
  glossaries: PropTypes.object.isRequired,
  currentGlossary: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default GlossaryFormCarousels;
