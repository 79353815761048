import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import strings from "../../../services/strings";

import moment from "moment";
import _ from "lodash";
import "../UserList.scss";
import Modal from "reboron/DropModal";
import { makeSEFUrl } from "../../../helpers";
import "moment/locale/nl";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";

import "./UserDraftList.scss";

class UserDraftList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      editDraftName: "",
      draftId: "",
      reminderFormFieldsLoaded: false,
      reminderForm: {
        datetime: "",
        email: "",
        message: "",
        related_documents: [],
      },
    };
  }

  handleEditDraftNameClick(item) {
    this.setState({
      editDraftName: item.name,
      draftId: item.id,
    });
    _.delay(() => {
      this.showEditDraftNameModal();
    }, 500);
  }

  showEditDraftNameModal() {
    let modal = this.refs.editDraftNameModal;
    modal.show();
  }

  handleDraftNameInputChange(e) {
    this.setState({
      editDraftName: e.target.value,
    });
  }

  editableFields() {
    return (
      <div className="editable-fields-box">
        <input
          type="text"
          className="form-control"
          name="editable_draft_name"
          value={this.state.editDraftName}
          onChange={this.handleDraftNameInputChange}
        />
      </div>
    );
  }

  editDraftNameModalConfirm() {
    let newFields = {};
    if (this.state.editDraftName) {
      newFields["name"] = this.state.editDraftName;
    }
    this.props.updateItemDraftName(this.state.draftId, newFields);
    this.refs.editDraftNameModal.hide();
  }

  editDraftNameModalCancel() {
    this.refs.editDraftNameModal.hide();
  }

  processTemplateLink(item) {
    let locale = JSON.parse(localStorage.getItem("language")).iso2 || "NL";
    return `${this.props.siteURL}${locale}/documenten/${makeSEFUrl(
      item.category.name
    )}-${item.document.category_id}/${makeSEFUrl(item.document.name)}-${
      item.document.id
    }`;
  }

  processCategoryLink(item) {
    let locale = JSON.parse(localStorage.getItem("language")).iso2 || "NL";
    return `${this.props.siteURL}${locale}/documenten/${makeSEFUrl(
      item.category.name
    )}-${item.document.category_id}`;
  }

  //Reminder Section
  componentDidUpdate() {
    if (_.size(this.props.currentItem) > 0) {
      let reminder = this.props.currentItem.reminder;
      if (reminder && !this.state.reminderFormFieldsLoaded) {
        let reminderForm = _.extend({}, this.state.reminderForm);
        _.map(this.state.reminderForm, (value, key) => {
          reminderForm[key] = reminder[key]
            ? reminder[key]
            : this.state.reminderForm[key];
        });
        this.setState({
          reminderFormFieldsLoaded: true,
          reminderForm,
        });
      }
    }
  }

  handleSetReminderClick(id) {
    this.props.unsetCurrentItemId();
    this.handleReminderFormModalHide();
    _.delay(() => {
      this.props.setCurrentItemId(id);
      if (this.state.reminderForm.email === "") {
        let item = {};
        item["email"] = this.props.currentItem.user.email;
        this.setState({
          reminderForm: _.extend(this.state.reminderForm, item),
        });
      }
      this.setState({
        draftId: id,
      },
      () => {
        this.addRelatedDocToReminder();
      });
      this.showReminderFormModal();
    }, 500);
  }

  addRelatedDocToReminder = () => {
    let { reminderForm } = this.state;
    reminderForm.related_documents = this.props.currentItem.related_documents
      ? this.props.currentItem.related_documents
      : [];
    this.setState({ reminderForm });
  };

  showReminderFormModal() {
    let modal = this.refs.reminderFormModal;
    if (
      !(
        this.props.exceptions.template &&
        this.props.exceptions.template.notDefined
      )
    )
      modal.show();
  }

  handleReminderFormModalHide() {
    this.props.clearReminderException();
    this.setState({
      draftId: "",
      reminderFormFieldsLoaded: false,
      reminderForm: {
        datetime: "",
        email: "",
        message: "",
      },
    });
  }

  handleInputChange(e) {
    let item = {};
    item[e.target.name] = e.target.value;
    this.setState({
      reminderForm: _.extend(this.state.reminderForm, item),
    });
  }

  handleDateInputChange(date, name) {
    let item = {};
    let dateOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    item[name] = new Date(date).toLocaleDateString("nl-NL", dateOptions);
    this.setState({
      reminderForm: _.extend(this.state.reminderForm, item),
    });
  }

  handleReminderFormSaveClick() {
    let updateItem = this.props.updateItemReminder(
      this.state.draftId,
      this.state.reminderForm
    );
    Promise.all([updateItem]).then(() => {
      _.delay(() => {
        if (_.size(this.props.exceptions) === 0) {
          this.handleReminderFormCancelClick();
        }
      }, 4000);
    });
  }

  handleReminderFormCancelClick() {
    this.refs.reminderFormModal.hide();
  }

  hasError(inputName) {
    return !!this.props.exceptions[inputName];
  }

  getErrorClass(inputName, defaultClasses = "") {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  }

  getErrorMessage(inputName) {
    return this.props.exceptions[inputName];
  }
  //Reminder Section

  render() {
    let datetimeError = this.hasError("datetime")
      ? `${strings.get(
          "Client.downloadsPage.reminderForm.reminder_date"
        )} ${this.getErrorMessage("datetime")}`
      : "";
    let emailError = this.hasError("email")
      ? `${strings.get(
          "Client.downloadsPage.reminderForm.email"
        )} ${this.getErrorMessage("email")}`
      : "";
    let messageError = this.hasError("message")
      ? `${strings.get(
          "Client.downloadsPage.reminderForm.message"
        )} ${this.getErrorMessage("message")}`
      : "";

    let items = _.map(this.props.items, (item) => {
      return (
        <tr key={item.id}>
          <td>
            <i
              onClick={() => this.handleEditDraftNameClick(item)}
              className="btn btn-default edit-btn ion-edit"
            />
            &nbsp; {item.name}
          </td>
          <td>
            <a href={this.processTemplateLink(item)} target="_blank">
              {item.document.name}
            </a>
          </td>
          <td>
            <a href={this.processCategoryLink(item)} target="_blank">
              {item.category.name}
            </a>
          </td>
          <td>
            <i
              className="btn btn-primary set-reminder ion-clock"
              title="Set/Edit"
              onClick={() => this.handleSetReminderClick(item.id)}
            ></i>
          </td>
        </tr>
      );
    });

    let editDraftNameModalContent = (
      <div className="jd-modal">
        <label>
          {" "}
          {strings.get("App.documents.draft.draftList.editField")}{" "}
        </label>
        <br />
        {this.editableFields()}
        <br />
        <button
          style={{ marginRight: ".4rem" }}
          className="btn btn-primary"
          onClick={this.editDraftNameModalConfirm}
        >
          {strings.get("App.users.userForm.save")}
        </button>
        <button
          style={{ marginLeft: ".4rem" }}
          className="btn btn-default"
          onClick={this.editDraftNameModalCancel}
        >
          {strings.get("App.users.userForm.cancel")}
        </button>
      </div>
    );

    let reminderFormModalContent = this.props.currentItem ? (
      <div className="reminder-form">
        <div className="form-group text-left">
          <label className="control-label">
            {strings.get("Client.downloadsPage.reminderForm.reminder_date")}
          </label>
          <DatePicker
            fixedHeight
            locale="nl"
            // placeholderText={strings.get(
            //   "Client.downloadsPage.reminderForm.date_placeholder"
            // )}
            dateFormat="d MMMM yyyy"
            minDate={new Date()}
            className={this.getErrorClass("datetime", "form-control")}
            // selected={
            //   this.state.reminderForm.datetime
            //     ? moment(this.state.reminderForm.datetime, "YYYY-MM-DD")
            //     : null
            // }
            selected={
              this.state.reminderForm.datetime
                ? parseISO(
                    moment(this.state.reminderForm.datetime).toISOString()
                  )
                : null
            }
            onChange={(date) => this.handleDateInputChange(date, "datetime")}
          />
          <small className="text-danger">{datetimeError}</small>
        </div>
        <div className="form-group text-left">
          <label className="control-label">
            {strings.get("Client.downloadsPage.reminderForm.email")}
          </label>
          <input
            className={this.getErrorClass("email", "form-control")}
            type="text"
            name="email"
            value={this.state.reminderForm.email}
            onChange={this.handleInputChange}
          />
          <small className="text-danger">{emailError}</small>
        </div>
        <div className="form-group text-left">
          <label className="control-label">
            {strings.get("Client.downloadsPage.reminderForm.message")}
          </label>
          <textarea
            className={this.getErrorClass("message", "form-control")}
            name="message"
            placeholder={strings.get(
              "Client.downloadsPage.reminderForm.message_placeholder"
            )}
            value={this.state.reminderForm.message}
            onChange={this.handleInputChange}
          ></textarea>
          <small className="text-danger">{messageError}</small>
        </div>
        <div className="form-actions">
          <button
            className="btn btn-primary"
            onClick={this.handleReminderFormSaveClick}
          >
            {strings.get("Client.documentContent.downloadModal.btnSave")}
          </button>
          <button
            className="btn btn-default"
            onClick={this.handleReminderFormCancelClick}
          >
            {strings.get("Client.documentContent.downloadModal.btnCencel")}
          </button>
        </div>
      </div>
    ) : null;

    return (
      <span className="UserDraftList">
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-body">
                <Modal className="boron-modal" ref="editDraftNameModal">
                  <div className="modal-close-btn">
                    <i
                      className="ion-android-cancel clickable"
                      onClick={this.editDraftNameModalCancel}
                    ></i>
                  </div>
                  {editDraftNameModalContent}
                </Modal>
                <Modal
                  className="boron-modal"
                  ref="reminderFormModal"
                  onHide={this.handleReminderFormModalHide}
                >
                  <div className="modal-close-btn">
                    <i
                      className="ion-android-cancel clickable"
                      onClick={this.handleReminderFormCancelClick}
                    ></i>
                  </div>
                  {reminderFormModalContent}
                </Modal>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>{strings.get("App.users.drafts.draftName")}</th>
                      <th>{strings.get("App.users.drafts.docName")}</th>
                      <th>{strings.get("App.users.drafts.category")}</th>
                      <th>{strings.get("App.users.downloads.allert")}</th>
                    </tr>
                  </thead>
                  {items.length > 0 ? (
                    <tbody>{items}</tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="4">
                          {strings.get("App.settings.documents.noFiles")}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  }
}

UserDraftList.propTypes = {
  items: PropTypes.array.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
};

export default UserDraftList;
