import React, { Component } from "react";
import _ from "lodash";
// import strings from '../../../../services/strings'

import Dropzone from "react-dropzone";
import ReactAvatarEditor from "react-avatar-editor";
import strings from "../../../services/strings";
import settings from "../../../services/settings";

class ImageUpload extends Component {
  state = {
    image: null,
    cropImageData: null,
    scale: 0.2,

    headerFileRejected: false,

    headerFile: null,

    cropClass: false,
  };

  setEditorRef = (editor) => (this.editor = editor);

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  removeImage = (image, file) => () => {
    this.setState({
      [image]: null,
      ...this.state[image],
      [file]: null,
      ...this.state[file],
      cropImageData: null,
    });
  };

  cropImage = () => {
    this.setState({
      image: null,
      cropImageData: this.editor.getImageScaledToCanvas().toDataURL(),
    });
    // var contentType = 'image/png'

    let crooperD = this.editor.getImageScaledToCanvas().toDataURL();
    // console.log('++++++', crooperD.split(',')[1])

    // let form = _.extend(this.state.form, {});
    // form[settings.keys.HEADER_LOGO] = form[settings.keys.HEADER_LOGO].asMutable
    //   ? form[settings.keys.HEADER_LOGO].asMutable()
    //   : form[settings.keys.HEADER_LOGO];
    // form[settings.keys.HEADER_LOGO].file.preview = crooperD;

    // this.setState({
    //   headerFile: this.editor.getImageScaledToCanvas().toDataURL()
    // });
  };

  onLoadSuccess(e) {
    console.log("e + e", e);
  }
  componentDidMount() {
    this.tryLoadCurrentSettings();
  }

  componentDidUpdate() {
    this.tryLoadCurrentSettings();
  }

  constructor(props) {
    super(props);
  }

  tryLoadCurrentSettings() {
    if (_.size(this.props.settings) && !this.state.currentSettingsLoaded) {
      this.setState({
        currentSettingsLoaded: true,
        form: {
          contact_phone: this.props.settings[settings.keys.CONTACT_PHONE],
          contact_fax: this.props.settings[settings.keys.CONTACT_FAX],
          contact_email: this.props.settings[settings.keys.CONTACT_EMAIL],
          contact_address: this.props.settings[settings.keys.CONTACT_ADDRESS],
          contact_site_name: this.props.settings[
            settings.keys.CONTACT_SITE_NAME
          ],
          header_logo: this.props.settings[settings.keys.HEADER_LOGO],
          footer_logo: this.props.settings[settings.keys.FOOTER_LOGO],
          banner_img: this.props.settings[settings.keys.BANNER_IMG],
          bottom_banner_img: this.props.settings[
            settings.keys.BOTTOM_BANNER_IMG
          ],
        },
      });
    }
  }

  handleInputChange(e) {
    let form = _.extend(this.state.form, {});
    form[e.target.name] = form[e.target.name].asMutable
      ? form[e.target.name].asMutable()
      : form[e.target.name];
    form[e.target.name].value = e.target.value;

    this.setState({ form });
  }

  handleSaveClick(e) {
    e.preventDefault();
    console.log("this.state +++", this.state);
    this.props.saveSettings(this.state);
  }

  handleCancelClick(e) {
    e.preventDefault();
    this.setState({
      form: {
        contact_phone: this.props.settings[settings.keys.CONTACT_PHONE],
        contact_fax: this.props.settings[settings.keys.CONTACT_FAX],
        contact_email: this.props.settings[settings.keys.CONTACT_EMAIL],
        contact_address: this.props.settings[settings.keys.CONTACT_ADDRESS],
        contact_site_name: this.props.settings[settings.keys.CONTACT_SITE_NAME],
        header_logo: this.props.settings[settings.keys.HEADER_LOGO],
        footer_logo: this.props.settings[settings.keys.FOOTER_LOGO],
        banner_img: this.props.settings[settings.keys.BANNER_IMG],
        bottom_banner_img: this.props.settings[settings.keys.BOTTOM_BANNER_IMG],
      },
    });
  }

  getPreviewImage(file, position) {
    console.log(this.state[file], "this.state[file]");
    console.log("this.state.form[position]", this.state.form[position]);
    if (this.state[file]) {
      return <img src={this.state[file].preview} />;
    } else {
      return this.state.form[position] && this.state.form[position].imageURL ? (
        <img src={this.state.form[position].imageURL} />
      ) : null;
    }
  }

  isImage(file, position) {
    if (this.state.form[position] && this.state.form[position].imageURL) {
      return true;
    } else {
      return false;
    }
  }

  setImage = (fileName, formKey) => () => {
    this.setState({
      form: {
        ...this.state.form,
        [formKey]: {
          // ...this.state.form[formKey],
          imageURL: null,
        },
      },
      [fileName]: null,
    });
  };

  handleFileDrop = (dropped) => {
    this.props.handleFileDrop();
    // console.log("drop", dropped);
    // let form = _.extend(this.state.form, {});
    // form[settings.keys.HEADER_LOGO] = form[settings.keys.HEADER_LOGO].asMutable
    //   ? form[settings.keys.HEADER_LOGO].asMutable()
    //   : form[settings.keys.HEADER_LOGO];
    // form[settings.keys.HEADER_LOGO].file = dropped[0];
    // console.log(this.state.form);
    // this.setState({ image: dropped[0], cropClass: false });
    // console.log(this.state.form);
  };

  render() {
    // const {image, headerFile, footerImg, footerFile} = this.state;
    const { label, dropzoneContent } = this.props;
    // let dropzoneContent = this.getPreviewImage("headerFile", "header_logo") ? (
    //   this.getPreviewImage("headerFile", "header_logo")
    // ) : (
    //   <img src={"https://dummyimage.com/142x20/000/fff.jpg"} />
    // );

    return (
      <div className="form-group">
        <label className="control-label">{label}</label>

        {this.state.image ||
        this.state.cropImageData ||
        this.state.headerFile ? (
          <input
            type="button"
            className="close-btn"
            onClick={this.removeImage("image", "headerFile", "header_logo")}
            value="X"
          />
        ) : this.isImage("headerFile", "header_logo") ? (
          <input
            type="button"
            className="close-btn"
            onClick={this.setImage("headerFile", "header_logo")}
            value="X"
          />
        ) : (
          ""
        )}

        <Dropzone
          className="dropzone dropzone-first"
          onDrop={this.handleHeaderFileDrop}
          multiple={false}
          maxSize={4096000}
          accept="image/*"
        >
          {/* {dropzoneContent}
              {this.state.image ? (
                <ReactAvatarEditor
                  scale={this.state.scale}
                  rotate={0}
                  className="image-placeholder"
                  image={this.state.image}
                  width={320}
                  height={150}
                  ref={ref => this.setEditorRef(ref)}
                />
              ) : this.state.headerFile ? (
                <img src={this.state.headerFile} />
              ) : (
                ""
              )} */}
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              {dropzoneContent}
              {this.state.image ? (
                <ReactAvatarEditor
                  scale={this.state.scale}
                  rotate={0}
                  className="image-placeholder"
                  image={this.state.image}
                  width={320}
                  height={150}
                  ref={(ref) => this.setEditorRef(ref)}
                />
              ) : this.state.headerFile ? (
                <img src={this.state.headerFile} />
              ) : (
                ""
              )}
            </div>
          )}
        </Dropzone>
        {this.state.cropClass === false ? (
          <div className="help-block">
            <div className="help-block">
              {/* (Suggestie: breedte 142px, hoogte 20px) */}
              {strings.get("App.commonImage.sizeSuggestion")}
            </div>
            <small className="text-danger headerErrorUpload" />
            {this.state.image !== null ? (
              <input
                type="range"
                step="0.01"
                min="0.1"
                max="2"
                name="scale"
                value={this.state.scale}
                onChange={this.handleChange}
              />
            ) : (
              ""
            )}

            {this.state.image !== null ? (
              <input
                type="button"
                className="crop-image-btn"
                onClick={this.cropImage}
                value="Crop"
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default ImageUpload;
