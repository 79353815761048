import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import { Link } from "react-router-dom";
import _ from "lodash";
import "../settings.scss";
import "../../Page.scss";

import * as translationsActions from "../../../store/translations/actions";
import * as translationsSelectors from "../../../store/translations/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";

import Topbar from "../../../components/Topbar";
import SubTopbar from "../../../components/SubTopbar";
import SearchBar from "../../../components/SearchBar";
import SettingsTabs from "../../../components/settings/SettingsTabs";
import TranslationForm from "../../../components/settings/translations/TranslationForm";

class TranslationEditPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchTranslation(this.props.match.params.id);
    this.props.setCurrentTranslationId(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.unsetCurrentTranslationId();
    this.props.clearExceptions();
  }

  handleSubmit(data) {
    if (data.file) {
      this.props.updateTranslationWithLogo(
        this.props.match.params.id,
        data.form,
        data.file
      );
    } else {
      this.props.updateTranslation(this.props.match.params.id, data.form);
    }
  }

  render() {
    return (
      <div className="TranslationEditPage">
        <Topbar>
          <div className="title">
            <Link to="/settings">{strings.get("App.settings.title")}</Link>
          </div>
        </Topbar>

        <div className="content settings-container">
          <SubTopbar>
            <SettingsTabs />
          </SubTopbar>
          <div className="setting-content">
            <TranslationForm
              exceptions={this.props.exceptions}
              handleSubmit={this.handleSubmit}
              currentItem={this.props.currentTranslation}
              formHeading={strings.get(
                "App.settings.translations.editTranslation"
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentTranslation: translationsSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTranslation: (id) => {
      dispatch(translationsActions.fetchItem(id));
    },
    updateTranslation: (id, data) => {
      dispatch(translationsActions.updateItem(id, data));
    },
    setCurrentTranslationId: (id) => {
      dispatch(translationsActions.setCurrentItemId(id));
    },
    unsetCurrentTranslationId: () => {
      dispatch(translationsActions.unsetCurrentItemId());
    },
    updateTranslationWithLogo: (id, data, file) => {
      dispatch(translationsActions.updateItemWithLogo(id, data, file));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationEditPage);
