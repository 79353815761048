import React, { Component } from "react";
import autoBind from "react-autobind";
import PropTypes from "prop-types";
import strings from "../../services/strings";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import _ from "lodash";
import "./CompanyCategoryList.scss";

import { browserHistory } from "../../helpers";


import CustomDropModal from "../common/Popup";

let componentInstance = null;

const SortableDragger = SortableHandle(() => {
  return <div className="item-logo default-logo ion-checkmark" />;
});

const SortableItem = SortableElement(({ value }) => {
  return (
    <tr key={value.id}>
      <td>
        <SortableDragger />
      </td>
      <td>
        <div className="details">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => componentInstance.handleEditClick(value.id)}
            className="name"
          >
            {value.tree_name}
          </div>
        </div>
      </td>
      <td>
        <div className="details">{value.slug}</div>
      </td>
      <td>
        <i
          onClick={() => componentInstance.handleEditClick(value.id)}
          className="btn btn-default edit-btn ion-edit"
        />
        <i
          onClick={() => componentInstance.handleDeleteClick(value.id)}
          className="btn btn-default delete-btn ion-trash-b"
        />
      </td>
    </tr>
  );
});

const SortableList = SortableContainer(({ items }) => {
  let rows = _.map(items, (value, index) => {
    return (
      <SortableItem
        key={`item-${value.tree_name}`}
        index={index}
        value={value}
      />
    );
  });

  return <tbody>{rows}</tbody>;
});

class CompanyCategoryList extends Component {
  state = {
    items: [],
    deleteStatus: "",
  };

  constructor(props) {
    super(props);
    autoBind(this);
    componentInstance = this;
  }

  componentDidMount() {
    this.tryLoadCompanyCategory();
  }

  componentDidUpdate() {
    this.tryLoadCompanyCategory();
  }

  tryLoadCompanyCategory() {
    let reload = false;

    // Reload items if size is different
    if (_.size(this.props.items) !== _.size(this.state.items)) {
      reload = true;
    }

    // Reload items if any step is different
    if (!reload) {
      _.each(this.props.items, (item) => {
        if (!_.find(this.state.items, { name: item.name })) {
          reload = true;
        }
        if (!_.find(this.state.items, { slug: item.slug })) {
          reload = true;
        }
      });
    }

    if (reload) {
      let items = [];
      _.each(this.props.items, (item) => {
        items.push(item);
      });
      this.setState({
        items: items,
      });
    }
  }

  // getLogo(item) {
  //     return (
  //         <div className="item-logo default-logo ion-android-list"></div>
  //     );
  // }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  handleDeleteClick(id) {
    this.props.setCurrentItemId(id);
    this.showDeleteModal();
  }

  handleConfirmDeleteClick() {
    this.props.deleteItem(
      this.props.currentItem.id,
      this.handleCatDeleteResponse
    );
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleCancelDeleteClick() {
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleEditClick(id) {
    browserHistory.push(`/glossaries/companies/categories/${id}`);
  }

  handleSortEnd({ oldIndex, newIndex }) {
    if (oldIndex !== newIndex) {
      let item = {};
      item.source = this.state.items[oldIndex];
      item.target = this.state.items[newIndex];
      item.direction = oldIndex > newIndex ? "before" : "after";

      if (item.source.parent_id !== item.target.parent_id) {
        this.showParentMisMatchModal();
      } else {
        let items = arrayMove(this.state.items, oldIndex, newIndex);
        this.setState({ items });

        this.props.orderItems(item);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  }

  showParentMisMatchModal() {
    this.refs.parentMisMatchModal.show();
  }

  hideParentMisMatchModal() {
    this.refs.parentMisMatchModal.hide();
  }

  handleConfirmMsgClick() {
    this.hideParentMisMatchModal();
  }

  showDeleteMsgModal() {
    this.refs.deleteMsgModal.show();
  }

  hideDeleteMsgModal() {
    this.refs.deleteMsgModal.hide();
    this.setState({ deleteStatus: "" });
  }

  handleCatDeleteResponse(deleteStatus) {
    this.setState({ deleteStatus }, () => {
      this.showDeleteMsgModal();
    });
  }

  handleCancelDeleteMsgClick() {
    this.hideDeleteMsgModal();
  }

  render() {
    let deleteModalContent = this.props.currentItem ? (
      <span>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleCancelDeleteClick}
          />
        </div>
        <h2>
          {strings.get("App.deleteModal.message", {
            itemName: this.props.currentItem.name,
          })}
        </h2>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger"
            onClick={this.handleConfirmDeleteClick}
          >
            {strings.get("App.deleteModal.delete")}
          </button>
          <button
            className="btn btn-lg btn-default cancel cancel"
            onClick={this.handleCancelDeleteClick}
          >
            {strings.get("App.deleteModal.cancel")}
          </button>
        </div>
      </span>
    ) : null;

    let parentMisMatchContent = (
      <span>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.hideParentMisMatchModal}
          />
        </div>
        <h2>
          {/* Same Parent Level only allow */}
          {strings.get("App.articleCategoryList.sameLevel")}
        </h2>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger"
            onClick={this.handleConfirmMsgClick}
          >
            {/* Ok */}
            {strings.get("App.articleCategoryList.ok")}
          </button>
        </div>
      </span>
    );

    let deleteMsgModalContent =
      this.state.deleteStatus !== "" ? (
        <span>
          <div className="modal-close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.handleCancelDeleteMsgClick}
            />
          </div>
          <div className="form-actions">
            <h2>
              {this.state.deleteStatus === "success"
                ? strings.get("App.companyCategoryPages.deleteSuccessMsg")
                : strings.get("App.companyCategoryPages.deleteErrorMsg")}
            </h2>
          </div>
        </span>
      ) : null;

    return (
      <span className="CompanyCategoryList">
        <CustomDropModal className="boron-modal" ref="deleteModal">
          {deleteModalContent}
        </CustomDropModal>
        <CustomDropModal className="boron-modal" ref="deleteMsgModal">
          {deleteMsgModalContent}
        </CustomDropModal>
        <CustomDropModal className="boron-modal" ref="parentMisMatchModal">
          {parentMisMatchContent}
        </CustomDropModal>
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th />
                      <th>
                        {strings.get("App.companies.companyCategoryForm.name")}
                      </th>
                      <th>
                        {/* Code */}
                        {strings.get("App.companies.companyCategoryForm.code")}
                      </th>
                      <th>{strings.get("General.action")}</th>
                    </tr>
                  </thead>
                  <SortableList
                    items={this.state.items}
                    onSortEnd={this.handleSortEnd}
                    lockAxis="y"
                    useDragHandle={true}
                    lockToContainerEdges={true}
                    helperClass="SortableItem"
                  />
                </table>
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  }
}

CompanyCategoryList.propTypes = {
  items: PropTypes.array.isRequired,
  sorter: PropTypes.object.isRequired,
  fetchItems: PropTypes.func.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  toggleSorter: PropTypes.func.isRequired,
};

export default CompanyCategoryList;
