import React, { Component } from "react";
import _ from "lodash"
import autoBind from "react-autobind";
import strings from "../../../services/strings";
import "./CarouselOption.scss";
import Modal from "reboron/DropModal";
import Editor from "../../Editor";
import Switch from "../../Switch";
import SelectSearch from "react-select";

class CarouselOption extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      showDetailCarouselOption: false,
      form: props.option,
      tmpItem: [],
      setOrder: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.option) {
      let form = _.extend({}, this.state.form);
      _.map(this.state.form, (value, key) => {
        form[key] = nextProps.option[key]
          ? nextProps.option[key]
          : this.state.form[key];
      });
      this.setState({
        form,
      });
    }
  }

  getErrorClass(inputName, defaultClasses = "") {
    return defaultClasses;
  }

  handleInputChange(e) {
    let form = { ...this.state.form };
    form[e.target.name] = e.target.value;
    this.setState(
      {
        form: _.extend(this.state.form, form),
      },
      () => {
        this.handleChangeCarouselOption(this.state.form);
      }
    );
  }

  handleClauseSwitchChange(enabled) {
    let form = { ...this.state.form };
    form["isClause"] = enabled ? 1 : 0;
    this.setState(
      {
        form: _.extend(this.state.form, form),
      },
      () => {
        this.handleChangeCarouselOption(this.state.form);
      }
    );
  }

  handleChangeCarouselOption = (data) => {
    this.props.handleChangeCarouselOption(data);
  };

  getCategoryTree(items, item, arr, depth = 0) {
    if (_.size(items) && _.find(items, item)) {
      if (item.parent_id && depth === 0) return;
      arr[0].push(item);
      arr[1].push(depth);
      _.map(items, (it) => {
        if (item.id === it.parent_id) {
          this.getCategoryTree(items, it, arr, depth + 1);
        }
      });
    }
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  handleDeleteClick() {
    // this.props.setCurrentItemId(id);
    this.showDeleteModal();
  }

  handleConfirmDeleteClick(id) {
    this.props.deleteCarouselOption(id);
    _.delay(() => 250);
    this.hideDeleteModal();
  }

  handleCancelDeleteClick() {
    _.delay(() => 250);
    this.hideDeleteModal();
  }

  handleEditorChangeContent(content) {
    this.setState(
      {
        form: _.extend(this.state.form, {
          content,
        }),
      },
      () => {
        this.handleChangeCarouselOption(this.state.form);
      }
    );
  }

  handleChangeOrder = (e) => {
    let selectedOrder = parseInt(e.target.value);
    let curOrder = this.state.form.order;
    this.props.handleChangeOrder(selectedOrder, curOrder);
  };

  handleEditorChange(content) {
    this.setState(
      {
        form: _.extend(this.state.form, {
          helperText: content,
        }),
      },
      () => {
        this.handleChangeCarouselOption(this.state.form);
      }
    );
  }

  handleClauseSelect = (event) => {
    let form = this.state.form;
    form.clauseId = `${event.value}`;
    this.setState({ form },()=>{
      this.handleChangeCarouselOption(this.state.form)
    });
  };

  render() {
    let allClausesId = [];
    let allClauses = [];
    _.map(this.props.clauseElements, (item) => {
      if (!allClausesId.includes(item.id)) {
        allClausesId.push(item.id);
        let temp = {};
        temp.value = item.id;
        temp.label = item.name;
        allClauses.push(temp);
      }
    });
    let autoSuggestAllClauses = (
      <div className="mb-10">
        <label className={"control-label mb-10"}>
          <b>
            {strings.get(
              "App.glossaries.glossaryForm.carouselForm.options.clauseIdAutoSuggest"
            )}
          </b>
        </label>
        <SelectSearch
          options={allClauses}
          placeholder={strings.get(
            "App.glossaries.glossaryForm.carouselForm.options.clauseIdAutoSuggest"
          )}
          value={allClauses.value}
          name="allClauses"
          onChange={this.handleClauseSelect}
        />
      </div>
    );

    let optionLabel = this.props.hasError(`option ${this.props.index + 1}`)
      ? `${strings.get(
          "App.glossaries.glossaryForm.carouselForm.options.title"
        )} ${this.props.getErrorMessage(`option ${this.props.index + 1}`)}`
      : `${strings.get(
          "App.glossaries.glossaryForm.carouselForm.options.title"
        )} ${this.props.index + 1}`;

    let nameLabel = this.props.hasError(`name ${this.props.index + 1}`)
      ? `${strings.get(
          "App.glossaries.glossaryForm.carouselForm.options.name"
        )} ${this.props.getErrorMessage(`name ${this.props.index + 1}`)}`
      : strings.get("App.glossaries.glossaryForm.carouselForm.options.name");

    let contentLabel = this.props.hasError(`content ${this.props.index + 1}`)
      ? `${strings.get(
          "App.glossaries.glossaryForm.carouselForm.options.content"
        )} ${this.props.getErrorMessage(`content ${this.props.index + 1}`)}`
      : strings.get("App.glossaries.glossaryForm.carouselForm.options.content");

    let helperTextLabel = this.props.hasError(
      `helperText ${this.props.index + 1}`
    )
      ? `${strings.get(
          "App.glossaries.glossaryForm.carouselForm.options.helperText"
        )} ${this.props.getErrorMessage(`helperText ${this.props.index + 1}`)}`
      : strings.get(
          "App.glossaries.glossaryForm.carouselForm.options.helperText"
        );

    let helperLinkLabel = this.props.hasError(
      `helperLink ${this.props.index + 1}`
    )
      ? `${strings.get(
          "App.glossaries.glossaryForm.carouselForm.options.helperLink"
        )} ${this.props.getErrorMessage(`helperLink ${this.props.index + 1}`)}`
      : strings.get(
          "App.glossaries.glossaryForm.carouselForm.options.helperLink"
        );

    let { showDetailCarouselOption } = this.state;
    let arr = [[], []];
    _.map(this.props.documents, (item) => {
      this.getCategoryTree(this.props.documents, item, arr);
    });

    let deleteModalContent = this.props.option ? (
      <span>
        <div className="modal-close-btn" onClick={this.handleCancelDeleteClick}>
          <i className="ion-android-cancel clickable" />
        </div>
        <h2>
          {strings.get("App.deleteModal.message", {
            itemName: this.props.option.name,
          })}
        </h2>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger"
            onClick={() => this.handleConfirmDeleteClick(this.props.option.id)}
          >
            {strings.get("App.deleteModal.delete")}
          </button>
          <div
            className="btn btn-lg btn-default cancel cancel"
            onClick={this.handleCancelDeleteClick}
          >
            {strings.get("App.deleteModal.cancel")}
          </div>
        </div>
      </span>
    ) : null;

    let optionItem = (
      <div className="form-group">
        <div className="ml-0">
          <div
            className="col-sm-12 col-md-12 titleDiv"
            onClick={() =>
              this.setState({
                showDetailCarouselOption: !this.state.showDetailCarouselOption,
              })
            }
          >
            <div className="title-name">
              <div className="arrowLeft ml-10">
                <i className="ion-android-exit" />
                {/* Option {this.props.index + 1} */}
                <b
                  className={this.props.getErrorClass(
                    `option ${this.props.index + 1}`
                  )}
                >
                  {this.state.form.name && this.state.form.name.trim()
                    ? this.state.form.name
                    : optionLabel}
                </b>
              </div>
              <div
                className="arrowLeft buttonDelete"
                onClick={this.handleDeleteClick}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </div>
            </div>

            <div className="col-sm-1 col-md-1 col-xs-1 arrowDiv">
              {showDetailCarouselOption ? (
                <i
                  className="fa fa-arrow-down arrow-icon"
                  aria-hidden="true"
                ></i>
              ) : (
                <i className="fa fa-arrow-up arrow-icon" aria-hidden="true"></i>
              )}
            </div>
          </div>
        </div>

        {showDetailCarouselOption ? (
          <div>
            <div className="col-sm-12">
              <div className="mb-10">
                <label
                  className={this.props.getErrorClass(
                    `name ${this.props.index + 1}`,
                    "control-label mb-10"
                  )}
                >
                  {/* <b>Name</b> */}
                  <b>{nameLabel}</b>
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={this.state.form.name}
                  onChange={this.handleInputChange}
                ></input>
              </div>
            </div>

            <div className="col-sm-12">
              <div
                className="mb-10"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  className={this.props.getErrorClass(
                    `isClause ${this.props.index + 1}`,
                    ""
                  )}
                >
                  <b>
                    {strings.get(
                      "App.glossaries.glossaryForm.carouselForm.options.isClause"
                    )}
                  </b>
                </label>
                <Switch
                  enabled={!!this.state.form.isClause}
                  onChange={this.handleClauseSwitchChange}
                />
              </div>
            </div>
            <div className="col-sm-12">
              {this.state.form.isClause ? (
                <div
                  className={this.props.getErrorClass(
                    `clauseId ${this.props.index + 1}`,
                    "mb-10"
                  )}
                >
                  {autoSuggestAllClauses}
                  <label
                    className={this.props.getErrorClass(
                      `clauseId ${this.props.index + 1}`,
                      "mb-10"
                    )}
                  >
                    <b>
                      {strings.get(
                        "App.glossaries.glossaryForm.carouselForm.options.clauseIdLabel"
                      )}
                    </b>
                  </label>
                  <input
                    type="text"
                    name="clauseId"
                    className="form-control"
                    value={this.state.form.clauseId ? this.state.form.clauseId : ""}
                    onChange={this.handleInputChange}
                    placeholder={strings.get(
                      "App.glossaries.glossaryForm.carouselForm.options.clauseIdInput"
                    )}
                  />
                </div>
              ) : (
                <div className="mb-10">
                  <label
                    className={this.props.getErrorClass(
                      `content ${this.props.index + 1}`,
                      "control-label mb-10"
                    )}
                  >
                    {/* <b>Content</b> */}
                    <b>{contentLabel}</b>
                  </label>
                  <Editor
                    name="content"
                    content={this.state.form.content}
                    handleChange={this.handleEditorChangeContent}
                    height={200}
                    isHasHelpbox
                    fieldSuggest={this.props.currentCodes.fields}
                    codeSuggest={this.props.currentCodes.codes}
                  />
                </div>
              )}
            </div>

            <div className="col-sm-12">
              <div className="mb-10">
                {/* <label className="control-label mb-10">Helper Text</label> */}
                <label
                  className={this.props.getErrorClass(
                    `helperText ${this.props.index + 1}`,
                    "control-label mb-10"
                  )}
                >
                  <b>{helperTextLabel}</b>
                </label>
                <Editor
                  name="helperText"
                  content={this.state.form.helperText}
                  handleChange={this.handleEditorChange}
                  height={115}
                />
              </div>
            </div>

            <div className="col-sm-12 mb-10">
              <div>
                <label
                  className={this.props.getErrorClass(
                    `helperLink ${this.props.index + 1}`,
                    "control-label mb-10"
                  )}
                >
                  {/* <b>Helper Link</b> */}
                  <b>{helperLinkLabel}</b>
                </label>
                <input
                  type="text"
                  name="helperLink"
                  className="form-control"
                  value={this.state.form.helperLink}
                  onChange={this.handleInputChange}
                ></input>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
    return (
      <div className="CarouselOption row">
        <div className="col-sm-12">
          <Modal className="boron-modal" ref="deleteModal">
            {deleteModalContent}
          </Modal>
          {optionItem}
        </div>
      </div>
    );
  }
}

export default CarouselOption;
