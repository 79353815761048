import React, { Component } from 'react';
import PropTypes from "prop-types";
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import strings from '../../../services/strings';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { browserHistory } from '../../../helpers';


class IpAddressesForm extends Component {

    state = {
        currentItemLoaded: false,
        form: {
            name: '',
            ip_address: '',
            type: 'A',
        }
    }

    componentDidMount() {
        this.tryLoadCurrentItem();
    }

    componentDidUpdate() {
        this.tryLoadCurrentItem();
    }

    constructor(props) {
        super(props);
        autoBind(this);
    }

    tryLoadCurrentItem() {
        if (this.props.currentItem && !this.state.currentItemLoaded) {
            let form = _.extend({}, this.state.form);
            _.map(this.state.form, (value, key) => {
                form[key] = (this.props.currentItem[key]) ? this.props.currentItem[key] : this.state.form[key];
            });

            this.setState({
                currentItemLoaded: true,
                form
            });
        }
    }

    hasError(inputName) {
        return !!this.props.exceptions[inputName];
    }

    getErrorClass(inputName, defaultClasses = '') {
        return this.hasError(inputName) ? defaultClasses + ' has-error' : defaultClasses;
    }

    getErrorMessage(inputName) {
        return this.props.exceptions[inputName];
    }

    handleInputChange(e) {
        let form = {};
        form[e.target.name] = e.target.value;
        this.setState({
            form: _.extend(this.state.form, form)
        });
    }

    handleSaveClick(e) {
        e.preventDefault();
        let form = {};
        this.setState(_.extend(this.state.form, form), ()=>{
            this.props.saveItem(this.state);
        });
    }

    handleCancelClick(e) {
        e.preventDefault();
        browserHistory.push('/settings/ipAddresses');

    }

    render() {
        let nameLabel = this.hasError('name') ? `${strings.get('App.ipAddressForm.name')} ${this.getErrorMessage('name')}` : strings.get('App.ipAddressForm.name');
        let ipAddressLabel = this.hasError('ip_address') ? `${strings.get('App.ipAddressForm.ipAddress')} ${this.getErrorMessage('ip_address')}` : strings.get('App.ipAddressForm.ipAddress');
        let typeLabel = this.hasError('type') ? `${strings.get('App.ipAddressForm.type')} ${this.getErrorMessage('type')}` : strings.get('App.ipAddressForm.type');

        return (
            <div className="IpAddressesForm col-xs-12 col-sm-12 col-md-9">
                <form>
                    <div className={ this.getErrorClass('name', 'form-group') }>
                        <label className="control-label">{ nameLabel }</label>
                        <input className="form-control" type="text" name="name" value={ this.state.form.name } onChange={ this.handleInputChange } />
                    </div>
                    <div className={ this.getErrorClass('ip_address', 'form-group') }>
                        <label className="control-label">{ ipAddressLabel }</label>
                        <input className="form-control" type="text" name="ip_address" value={ this.state.form.ip_address } onChange={ this.handleInputChange } />
                    </div>
                    <div className={ this.getErrorClass('typeLabel', 'form-group') }>
                        <label className="control-label">{ typeLabel }</label>
                        <select className="form-control" name="type" value={ this.state.form.type } onChange={ this.handleInputChange }>
                            <option value="A">{strings.get('App.ipAddressForm.allowed')}</option>
                            <option value="B">{strings.get('App.ipAddressForm.Blocked')}</option>
                        </select>
                    </div>
                    <div className="form-actions">
                        <button className="btn btn-primary" onClick={ this.handleSaveClick }>{strings.get('App.partnerForm.save')}</button>
                        <button className="btn btn-default" onClick={ this.handleCancelClick }>{strings.get('App.partnerForm.cancel')}</button>
                    </div>
                </form>
            </div>
        );
    }
}

IpAddressesForm.propTypes = {
    exceptions: PropTypes.object.isRequired,
    saveItem: PropTypes.func.isRequired,
}

export default IpAddressesForm;
