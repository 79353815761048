import React, { Component } from "react";
import autoBind from "react-autobind";
import PropTypes from "prop-types";
import strings from "../../services/strings";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from "react-sortable-hoc";
import _ from "lodash";
import "./StepList.scss";

import Modal from "reboron/DropModal";
import { browserHistory } from "../../helpers";



let componentInstance = null;

const SortableDragger = SortableHandle(() => {
  return <div className="item-logo default-logo ion-checkmark" />;
});

const SortableItem = SortableElement(({ value }) => {
  return (
    <tr>
      <td>
        <SortableDragger />
      </td>
      <td>
        <div className="details">
          <div className="name">{value.name}</div>
        </div>
      </td>
      <td>
        <i
          onClick={() => componentInstance.handleEditClick(value.id)}
          className="btn btn-default edit-btn ion-edit"
        />
      </td>
      <td>
        <i
          onClick={() => componentInstance.handleDeleteClick(value.id)}
          className="btn btn-default delete-btn ion-trash-b"
        />
      </td>
      <td>
        <i
          onClick={() => componentInstance.handleViewClick(value.id)}
          className="btn btn-default btn-color-change ion-eye"
        />
      </td>
    </tr>
  );
});

const SortableList = SortableContainer(({ items }) => {
  let rows = _.map(items, (value, index) => {
    return <SortableItem key={`item-${index}`} index={index} value={value} />;
  });

  return <tbody>{rows}</tbody>;
});

class StepList extends Component {
  state = {
    items: [],
    expandGlossaries: {},
    glossariesCodeIdToName: {}
  };

  componentDidMount() {
    this.loadGlossaries();
    this.tryLoadSteps();
  }

  componentDidUpdate() {
    this.tryLoadSteps();
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.glossaries && nextProps.glossaries !== this.props.glossaries && !_.size(this.state.expandGlossaries)){
      this.loadGlossaries(nextProps);
    }
  }

  constructor(props) {
    super(props);
    autoBind(this);
    componentInstance = this;
  }

  tryLoadSteps() {
    let reload = false;

    // Reload items if size is different
    if (_.size(this.props.items) !== _.size(this.state.items)) {
      reload = true;
    }

    // Reload items if any step is different
    if (!reload) {
      _.each(this.props.items, item => {
        if (!_.find(this.state.items, { name: item.name })) {
          reload = true;
        }
      });
    }

    if (reload) {
      let items = [];
      _.each(this.props.items, item => {
        items.push(item);
      });
      this.setState({
        items: items
      });
    }
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  handleSortEnd({ oldIndex, newIndex }) {
    let items = arrayMove(this.state.items, oldIndex, newIndex);
    this.props.orderItems(items);
    this.setState({ items });
  }

  handleDeleteClick(id) {
    this.props.setCurrentItemId(id);
    this.showDeleteModal();
  }

  handleConfirmDeleteClick() {
    this.props.deleteItem(this.props.currentItem.id);
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleCancelDeleteClick() {
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleEditClick(id) {
    browserHistory.push(`/glossaries/steps/${id}`);
  }

  handleViewClick(id) {
    browserHistory.push(`/glossaries/steps/${id}/view`);
  }

  loadGlossaries = (props = this.props) => {
    if(props.glossaries && _.size(props.glossaries) && !_.size(this.state.expandGlossaries)){
      let temp = {};
      let tempCodeIdToName= {};
      let _glossaries = props.glossaries;
      _.map(_glossaries, element => {
        if(element.clauses && element.clauses.length){
          element.clauses.forEach(clause => {
            temp[`${clause.name}`] = clause
            tempCodeIdToName[`clause_${clause.id}`] = `${clause.name}`;
          });
        }
        if(element.fields && element.fields.length){
          element.fields.forEach(field => {
            temp[`${field.name}`] = field;
            tempCodeIdToName[`field_${field.id}`] = `${field.name}`;
          });
        }
        if(element.selectors && element.selectors.length){
          element.selectors.forEach(selector => {
            temp[`${selector.name}`] = selector;
            tempCodeIdToName[`selector_${selector.id}`] = `${selector.name}`;
          });
        }
      });
      this.setState({
        expandGlossaries : temp,
        glossariesCodeIdToName: tempCodeIdToName
      })
    }
  }

  buildLabel(Items, item) {
    let data = [];
    let {expandGlossaries, glossariesCodeIdToName} = this.state;
    Items.forEach(function(Item) {
      let _label = Item.name;
      // separate out all selectors and loop through
      let result = strings.getFromBetween.get(_label, "[[", "]]");
      result.forEach(e => {
        const reg = new RegExp(e);

        //if multiple codes are responsible for name of a step
        let stepNameOptions = e.split("||");
        if(stepNameOptions.length > 1){
          stepNameOptions.forEach(element => {
            element = element.trim();
            // work out pattern [[*.*_*]] for selects
            if (element.indexOf(".") > -1) {
              const field = element.split(".")[0];
              if (expandGlossaries && _.size(expandGlossaries)) {
                if (
                  expandGlossaries[field] && expandGlossaries[field].placeholderField
                ) {
                  _label = _label.replace(element, expandGlossaries[field].placeholderField).replace(/\[\[|\]\]/g, "");
                } else if (
                  expandGlossaries[glossariesCodeIdToName[field]] &&
                  expandGlossaries[glossariesCodeIdToName[field]].placeholderField
                ) {
                  _label = _label.replace(element, expandGlossaries[glossariesCodeIdToName[field]].placeholderField).replace(/\[\[|\]\]/g, "");
                }
                else {
                  _label = _label.replace(element, "< ## >").replace(/\[\[|\]\]/g, "");
                }
              }
            } else {
              if (expandGlossaries && _.size(expandGlossaries)) {
                if (
                  expandGlossaries[element] &&
                  expandGlossaries[element].placeholderField
                ) {
                  _label = _label.replace(element, expandGlossaries[element].placeholderField).replace(/\[\[|\]\]/g, "");
                } else if (
                  expandGlossaries[glossariesCodeIdToName[element]] &&
                  expandGlossaries[glossariesCodeIdToName[element]].placeholderField
                ) {
                  _label = _label.replace(element, expandGlossaries[glossariesCodeIdToName[element]].placeholderField).replace(/\[\[|\]\]/g, "");
                }
                else {
                  _label = _label.replace(element, "< ## >").replace(/\[\[|\]\]/g, "");
                }
              }
            }
          });
        }
        //if single code is responsible for name of a step
        // work out pattern [[*.*_*]] for selects
        else if (e.indexOf(".") > -1) {
          const field = e.split(".")[0];
          if (expandGlossaries && _.size(expandGlossaries)) {
            if (
              expandGlossaries[field] && expandGlossaries[field].placeholderField
            ) {
              _label = _label.replace(reg, expandGlossaries[field].placeholderField).replace(/\[\[|\]\]/g, "");
            } else if (
              expandGlossaries[glossariesCodeIdToName[field]] &&
              expandGlossaries[glossariesCodeIdToName[field]].placeholderField
            ) {
              _label = _label.replace(reg, expandGlossaries[glossariesCodeIdToName[field]].placeholderField).replace(/\[\[|\]\]/g, "");
            }
            else {
              _label = _label.replace(reg, "< ## >").replace(/\[\[|\]\]/g, "");
            }
          }
        } else {
          // if there's no placeholder there, then find placeholder in all glossaries
          if (expandGlossaries && _.size(expandGlossaries)) {
            if (
              expandGlossaries[e] && expandGlossaries[e].placeholderField
            ) {
              _label = _label.replace(reg, expandGlossaries[e].placeholderField).replace(/\[\[|\]\]/g, "");
            } else if (
              expandGlossaries[glossariesCodeIdToName[e]] &&
              expandGlossaries[glossariesCodeIdToName[e]].placeholderField
            ) {
              _label = _label.replace(reg, expandGlossaries[glossariesCodeIdToName[e]].placeholderField).replace(/\[\[|\]\]/g, "");
            }
            else {
              _label = _label.replace(reg, "< ## >").replace(/\[\[|\]\]/g, "");
            }
          }
        }
      });
      data.push({
        id: Item.id,
        name: _label
      });
    });
    return data;
  }

  render() {
    let deleteModalContent = this.props.currentItem ? (
      <span>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleCancelDeleteClick}
          />
        </div>
        <h2>
          {strings.get("App.deleteModal.message", {
            itemName: this.props.currentItem.name
          })}
        </h2>
        <p>{strings.get("App.steps.unavailableFields")}</p>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger"
            onClick={this.handleConfirmDeleteClick}
          >
            {strings.get("App.deleteModal.delete")}
          </button>
          <button
            className="btn btn-lg btn-default cancel"
            onClick={this.handleCancelDeleteClick}
          >
            {strings.get("App.deleteModal.cancel")}
          </button>
        </div>
      </span>
    ) : null;

    return (
      <span className="StepList">
        <Modal className="boron-modal" ref="deleteModal">
          {deleteModalContent}
        </Modal>
        <table className="table step-list">
          {_.size(this.state.items) ? (
            <SortableList
              items={this.buildLabel(this.state.items)}
              onSortEnd={this.handleSortEnd}
              lockAxis="y"
              useDragHandle={true}
              lockToContainerEdges={true}
              helperClass="SortableItem"
            />
          ) : (
            <thead>
              <tr>
                <td style={{textAlign: 'center'}}>
                  {strings.get("App.glossaries.glossaryForm.noData")}
                </td>
              </tr>
            </thead>
          )}
        </table>
      </span>
    );
  }
}

StepList.propTypes = {
  items: PropTypes.object.isRequired,
  fetchItems: PropTypes.func.isRequired,
  orderItems: PropTypes.func.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired
};

export default StepList;
