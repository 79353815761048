import Immutable from 'seamless-immutable';
import { types } from './actions';
import moment from 'moment';
import _ from 'lodash';

const initialState = Immutable({
  filters: {
		searchTerm: '',
		categoryId: '',
	},
  pagination: {
			currentPage: 1,
			previousPage: 1,
			pageSize: 10,
			totalPages: 1,
	},
  sorter: {
		column: 'created_at',
		descending: true,
		pageLimit: 10
	},
  currentUserId: null,
  currentItemId: null,
  itemsById: {
	},
	idsByPage: {
			_1: []
	},
});

function setCurrentUserId(state, payload){
  return state.merge({
    currentUserId: payload.id
  });
}

function setCurrentItemId(state, payload){
  return state.merge({
    currentItemId: payload.id
  });
}

// Set category id filter
function setCategoryId(state, payload) {
    return state.merge({
        filters: {
            categoryId: payload.id
        }
    }, {deep: true});
}

// Save all Downloads to store
function fetchAllDownloadsDone(state, payload) {
	let newState = {
			pagination: {
					totalPages: payload.totalPages
			},
		itemsById: {},
		idsByPage: {},
  }
	newState['idsByPage']['_' + state.pagination.currentPage] = [];
	_.map(payload.items, (item) => {
			newState['itemsById']['_' + item.id] = item;
			newState['idsByPage']['_' + state.pagination.currentPage].push(item.id);
	});
	return state.merge(newState, { deep: true });
}

// Clear cached info
function clearCache(state) {
    return state.merge({
			filters: state.filters,
			sorter: {
				column: 'created_at',
				descending: true,
				pageLimit: 10
			},
			pagination: {
					currentPage: 1,
					previousPage: 1,
					pageSize: 10,
					totalPages: 1,
			},
			itemsById: {},
			idsByPage: {
					_1: []
			},
    });
}

function setSorter(state, payload) {
	return state.merge({
		sorter: payload.sorter
	}); 
}

// Set current page for items list
function setCurrentPage(state, payload) {
	return state.merge({
		pagination: {
			currentPage: payload.page,
			previousPage: state.pagination.currentPage
		}
	}, {deep: true})
}

export default function reduce(state = initialState, action = {}) {
  switch(action.type) {
    case types.SET_CURRENT_USER_ID:
      return setCurrentUserId(state, action.payload);

    case types.SET_CURRENT_ITEM_ID:
      return setCurrentItemId(state, action.payload);

    case types.CLEAR_CACHE:
				return clearCache(state);

    case types.FETCH_ALL_DOWNLOADS_DONE:
			return fetchAllDownloadsDone(state, action.payload);

    case types.SET_CURRENT_PAGE:
			return setCurrentPage(state, action.payload);

	case types.SET_SORTER:
		return setSorter(state, action.payload);

	case types.SET_CATEGORY_ID:
		return setCategoryId(state, action.payload);
  
    default:
        return state;
  }
}
