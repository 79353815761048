import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import PropTypes from "prop-types";

import _ from "lodash";
import ReactAvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { browserHistory } from "../../helpers";

class CompanyForm extends Component {
  state = {
    currentItemLoaded: false,
    file: null,
    image: null,
    scale: 0.2,
    currItem: null,
    fileRejected: false,
    form: {
      company_category_id: "",
      name: "",
      section: "",
      street: "",
      place: "",
      postal_code: "",
      mailing_section: "",
      mailing_street: "",
      mailing_place: "",
      mailing_postal_code: "",
      fax: "",
      email: "",
    },
  };

  setEditorRef = (editor) => (this.editor = editor);

  // handleChange(e) {
  //   this.setState({ [e.target.name]: e.target.value });
  // }

  removeImage = (image, file) => () => {
    this.setState({
      image: null,
      file: null,
    });
  };

  cropImage = () => {
    let crooperD = this.editor.getImageScaledToCanvas().toDataURL();
    // console.log('this.editor.getImageScaledToCanvas()')
    // let form = _.extend(this.state.form, {});
    // form.image = form.image.asMutable
    //   ? form.image.asMutable()
    //   : form.image
    // form.image.preview = crooperD;
    this.setState({
      image: null,
      file: this.editor.getImageScaledToCanvas().toDataURL(),
      currItem: null,
    });
  };
  componentDidMount() {
    this.tryLoadCurrentItem();
  }

  componentDidUpdate() {
    this.tryLoadCurrentItem();
  }

  constructor(props) {
    super(props);
    autoBind(this);
  }

  tryLoadCurrentItem() {
    if (this.props.currentItem && !this.state.currentItemLoaded) {
      let form = _.extend({}, this.state.form);
      _.map(this.state.form, (value, key) => {
        form[key] = this.props.currentItem[key]
          ? this.props.currentItem[key]
          : this.state.form[key];
      });
      this.setState({
        currentItemLoaded: true,
        form,
        currItem: this.props.currentItem,
      });
    }
  }

  hasError(inputName) {
    return !!this.props.exceptions[inputName];
  }

  getErrorClass(inputName, defaultClasses = "") {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  }

  getErrorMessage(inputName) {
    return this.props.exceptions[inputName];
  }

  // getPreviewImage() {
  //     if (this.state.file) {
  //         return (<img src={ this.state.file.preview } />);
  //     } else {
  //         return (this.props.currentItem && this.props.currentItem.imageURL) ? (
  //             <img src={ this.props.currentItem.imageURL } />
  //         ) : null;
  //     }
  // }

  handleInputChange(e) {
    let form = {};
    form[e.target.name] = e.target.value;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  }

  // handleFileDrop(acceptedFiles, rejectedFiles) {
  //     if (rejectedFiles.length) {
  //         this.setState({
  //             fileRejected: true,
  //         })
  //     } else {
  //         this.setState({
  //             file: _.first(acceptedFiles),
  //             fileRejected: false,
  //         })
  //     }
  // }

  setEditorRef = (editor: any) => {
    if (editor) {
      this.editor = editor;
      // console.log('this.editor', this.editor)
      const img = this.editor.getImageScaledToCanvas().toDataURL();
      // console.log('hello i am here', img)
    }
  };

  getPreviewImage(file) {
    if (this.state.file) {
      return <img src={this.state.file} />;
    } else {
      return this.state.currItem && this.state.currItem.imageURL ? (
        <img src={this.state.currItem.imageURL} />
      ) : null;
    }
  }

  isImage(file) {
    // console.log('this.state.currItem', this.state.currItem)
    if (this.state.currItem && this.state.currItem.imageURL) {
      return true;
    } else {
      return false;
    }
  }

  setImage = (fileName, formKey) => () => {
    this.setState({
      [fileName]: null,
      currItem: {
        imageURL: null,
      },
    });
    // if(this.props.currentItem && this.props.currentItem.imageURL) {
    //   this.props.currentItem.imageURL = null
    // }
  };

  handleFileDrop = (dropped) => {
    // console.log("drop", dropped);
    let form = _.extend(this.state.form, {});
    // form.image = form.image.asMutable
    //   ? form.image.asMutable()
    //   : form.image;
    // form.image = dropped[0];
    // console.log("handleFileDrop", this.state.form, dropped[0]);
    this.setState({ image: dropped[0], cropClass: false, file: dropped[0] });
  };

  handleSaveClick(e) {
    e.preventDefault();
    this.props.saveItem(this.state);
  }

  handleCancelClick(e) {
    e.preventDefault();
    browserHistory.push("/glossaries/companies");
  }

  render() {
    let nameLabel = this.hasError("name")
      ? `${strings.get(
          "App.companies.companyForm.name"
        )} ${this.getErrorMessage("name")}`
      : strings.get("App.companies.companyForm.name");
     
      let sectionLabel = this.hasError("section")
      ? `${strings.get(
          "App.companies.companyForm.section"
        )} ${this.getErrorMessage("section")}`
      : strings.get("App.companies.companyForm.section");
      let placeLabel = this.hasError("place")
      ? `${strings.get(
          "App.companies.companyForm.place"
        )} ${this.getErrorMessage("place")}`
      : strings.get("App.companies.companyForm.place");
      let streetLabel = this.hasError("street_number")
      ? `${strings.get(
          "App.companies.companyForm.street_number"
        )} ${this.getErrorMessage("street_number")}`
      : strings.get("App.companies.companyForm.street_number");
    let postalCodeLabel = this.hasError("postal_code")
      ? `${strings.get(
          "App.companies.companyForm.postalCode"
        )} ${this.getErrorMessage("postal_code")}`
      : strings.get("App.companies.companyForm.postalCode");

      let mailingSectionLabel = this.hasError("mailing_section")
      ? `${strings.get(
          "App.companies.companyForm.section"
        )} ${this.getErrorMessage("mailing_section")}`
      : strings.get("App.companies.companyForm.section");
      let mailingPlaceLabel = this.hasError("mailing_place")
      ? `${strings.get(
          "App.companies.companyForm.place"
        )} ${this.getErrorMessage("mailing_place")}`
      : strings.get("App.companies.companyForm.place");
      let mailingStreetLabel = this.hasError("mailing_street")
      ? `${strings.get(
          "App.companies.companyForm.street_number"
        )} ${this.getErrorMessage("mailing_street")}`
      : strings.get("App.companies.companyForm.street_number");
    let mailingPostalCodeLabel = this.hasError("mailing_postal_code")
      ? `${strings.get(
          "App.companies.companyForm.postalCode"
        )} ${this.getErrorMessage("mailing_postal_code")}`
      : strings.get("App.companies.companyForm.postalCode");
    let faxLabel = this.hasError("fax")
      ? `${strings.get("App.companies.companyForm.fax")} ${this.getErrorMessage(
          "fax"
        )}`
      : strings.get("App.companies.companyForm.fax");
    let emailLabel = this.hasError("email")
      ? `${strings.get(
          "App.companies.companyForm.email"
        )} ${this.getErrorMessage("email")}`
      : strings.get("App.companies.companyForm.email");
    let companyCategoryLabel = this.hasError("company_category_id")
      ? `${strings.get(
          "App.companies.companyForm.companyCategory"
        )} ${this.getErrorMessage("company_category_id")}`
      : strings.get("App.companies.companyForm.companyCategory");
    let imageLabel = this.hasError("image")
      ? strings.get("Exceptions.imageTooBig")
      : strings.get("App.companies.companyForm.image");

    let companyCategories = _.map(this.props.companyCategories, (item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.tree_name}
        </option>
      );
    });

    let dropzoneContent = this.getPreviewImage() ? (
      this.getPreviewImage()
    ) : (
      <img src={"https://dummyimage.com/150x150/000/fff.jpg"} />
    );

    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="CompanyForm">
            <form className="form-horizontal">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <b>{this.props.formHeading}</b>
                </div>
                <div className="panel-body">
                  <div className={this.getErrorClass("name", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {nameLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={this.state.form.name}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div
                    className={this.getErrorClass(
                      "company_category_id",
                      "form-group"
                    )}
                  >
                    <label className="col-sm-3 control-label">
                      {companyCategoryLabel}
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="company_category_id"
                        value={this.state.form.company_category_id}
                        onChange={this.handleInputChange}
                      >
                        <option value="" disabled>
                          {strings.get("App.companies.companyForm.choose")}
                        </option>
                        {companyCategories}
                      </select>
                    </div>
                  </div>
                  {/* <div className={ this.getErrorClass('image', 'form-group') }>
                              <label className="col-sm-3 control-label">{ imageLabel }</label>
                              <div className="col-sm-9">
                                <Dropzone
                                    className="dropzone"
                                    onDrop={ this.handleFileDrop }
                                    multiple={ false }
                                    maxSize={ 4096000 }
                                    accept="image/*">
                                    { dropzoneContent }aaaaaaaa
                                </Dropzone>
                              </div>
                          </div> */}

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {imageLabel}
                    </label>

                    <div className="col-sm-9">
                      {this.state.image || this.state.file ? (
                        <input
                          type="button"
                          className="close-btn"
                          style={{ marginTop: -10 }}
                          onClick={this.removeImage("image", "file")}
                          value="X"
                        />
                      ) : this.isImage("file") ? (
                        <input
                          type="button"
                          className="close-btn"
                          style={{ marginTop: -10 }}
                          onClick={this.setImage("file")}
                          value="X"
                        />
                      ) : (
                        ""
                      )}
                      <Dropzone
                        className="dropzone"
                        onDrop={this.handleFileDrop}
                        multiple={false}
                        maxSize={4096000}
                        accept="image/*"
                      >
                        {/* {dropzoneContent}

                      {this.state.image ? (
                        <ReactAvatarEditor
                          scale={this.state.scale}
                          rotate={0}
                          className="image-placeholder"
                          image={this.state.image}
                          width={150}
                          height={150}
                          ref={ref => this.setEditorRef(ref)}
                        />
                      ) : this.state.file && this.state.currItem ? (
                        <img src={this.state.file} />
                      ) : (
                        ""
                      )} */}
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {dropzoneContent}

                            {this.state.image ? (
                              <ReactAvatarEditor
                                scale={this.state.scale}
                                rotate={0}
                                className="image-placeholder"
                                image={this.state.image}
                                width={150}
                                height={150}
                                ref={(ref) => this.setEditorRef(ref)}
                              />
                            ) : this.state.file && this.state.currItem ? (
                              <img src={this.state.file} />
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </Dropzone>

                      {this.state.cropClass === false ? (
                        <div className="help-block">
                          <div className="help-block">
                            {strings.get("App.companyForm.suggestDimension")}
                          </div>
                          {this.state.image !== null ? (
                            <input
                              type="range"
                              step="0.01"
                              min="0.1"
                              max="2"
                              name="scale"
                              value={this.state.scale}
                              onChange={(e) =>
                                this.setState({
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          ) : (
                            ""
                          )}

                          {this.state.image !== null ? (
                            <input
                              type="button"
                              className="crop-image-btn"
                              onClick={this.cropImage}
                              value="Crop"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      <b>{strings.get('App.companies.companyForm.visiting_address')}</b>
                    </label>
                  </div>

                  <div className={this.getErrorClass("section", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {sectionLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        name="section"
                        value={this.state.form.section}
                        onChange={this.handleInputChange}
                     />
                    </div>
                  </div> 
                  <div className={this.getErrorClass("street", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {streetLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        name="street"
                        value={this.state.form.street}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div
                    className={this.getErrorClass("postal_code", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {postalCodeLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="postal_code"
                        value={this.state.form.postal_code}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div> 
                  <div className={this.getErrorClass("place", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {placeLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        name="place"
                        value={this.state.form.place}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div> 

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      <b>{strings.get('App.companies.companyForm.mailing_address')}</b>
                    </label>
                  </div>

                  <div className={this.getErrorClass("mailing_section", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {mailingSectionLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        name="mailing_section"
                        value={this.state.form.mailing_section}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div> 
                  <div className={this.getErrorClass("mailing_street", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {mailingStreetLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        name="mailing_street"
                        value={this.state.form.mailing_street}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div> 
                  <div className={this.getErrorClass("mailing_postal_code", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {mailingPostalCodeLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        name="mailing_postal_code"
                        value={this.state.form.mailing_postal_code}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div> 
                  <div className={this.getErrorClass("mailing_place", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {mailingPlaceLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        name="mailing_place"
                        value={this.state.form.mailing_place}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div> 

                  <div className={this.getErrorClass("fax", "form-group")}>
                    <label className="col-sm-3 control-label">{faxLabel}</label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="fax"
                        value={this.state.form.fax}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className={this.getErrorClass("email", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {emailLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        value={this.state.form.email}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="panel-footer text-right form-actions">
                  <button
                    className="btn btn-primary"
                    onClick={this.handleSaveClick}
                  >
                    {strings.get("App.companies.companyForm.save")}
                  </button>
                  <button
                    className="btn btn-default btn-color-change"
                    onClick={this.handleCancelClick}
                  >
                    {strings.get("App.companies.companyForm.cancel")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

CompanyForm.propTypes = {
  exceptions: PropTypes.object.isRequired,
  companyCategories: PropTypes.object.isRequired,
  saveItem: PropTypes.func.isRequired,
};

export default CompanyForm;
