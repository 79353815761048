import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import autoBind from "react-autobind";
import config from "../../../config";
import strings from "../../../services/strings";

import ReactAvatarEditor from "react-avatar-editor";

import Switch from "../../Switch";
import "./TranslationForm.scss";

import Dropzone from "react-dropzone";
import { browserHistory } from "../../../helpers";

class TranslationForm extends Component {
  state = {
    currentItemLoaded: false,
    image: null,
    file: null,
    scale: 0.2,
    fileRejected: false,
    form: {
      name: "",
      iso2: "",
      status: true,
      image: "",
    },
    currItem: null,
  };

  setEditorRef = (editor) => (this.editor = editor);

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  removeImage = (image, file) => () => {
    this.setState({
      image: null,
      file: null,
    });
  };

  cropImage = () => {
    this.setState({});

    let crooperD = this.editor.getImageScaledToCanvas().toDataURL();
    // console.log('this.editor.getImageScaledToCanvas()')
    let form = _.extend(this.state.form, {});
    form.image = form.image.asMutable ? form.image.asMutable() : form.image;
    form.image.preview = crooperD;
    this.setState({
      image: null,
      file: this.editor.getImageScaledToCanvas().toDataURL(),
      currItem: null,
    });
  };

  componentDidMount() {
    this.tryLoadCurrentItem();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentItem && this.props.currentItem) {
      this.tryLoadCurrentItem();
    }
  }

  tryLoadCurrentItem() {
    if (this.props.currentItem && !this.state.currentItemLoaded) {
      let form = _.extend({}, this.state.form);
      _.map(this.state.form, (value, key) => {
        form[key] = this.props.currentItem[key]
          ? this.props.currentItem[key]
          : this.state.form[key];
      });

      this.setState({
        currentItemLoaded: true,
        form,
        currItem: this.props.currentItem,
      });
    }
  }

  handleInputChange(e) {
    let form = {};
    form[e.target.name] = e.target.value;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  }

  handleSwitchChange(status) {
    this.setState({ status });
  }

  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleCancelClick(e) {
    e.preventDefault();
    browserHistory.push("/settings/translations");
  }

  handleSaveClick(e) {
    e.preventDefault();
    let form = {};
    form.status = Number(this.state.form.status);
    this.setState(_.extend(this.state.form, form), () => {
      this.props.handleSubmit(this.state);
    });
  }

  // getPreviewImage() {
  //     if (this.state.file) {
  //         return (<img src={ this.state.file.preview } />);
  //     } else {
  //         return (this.props.currentItem && this.props.currentItem.imageURL) ? (
  //             <img src={ this.props.currentItem.imageURL } />
  //         ) : null;
  //     }
  // }

  setEditorRef = (editor: any) => {
    if (editor) {
      this.editor = editor;
      // console.log('this.editor', this.editor)
      const img = this.editor.getImageScaledToCanvas().toDataURL();
      // console.log('hello i am here', img)
    }
  };

  getPreviewImage(file) {
    if (this.state.file) {
      return <img src={this.state.file} />;
    } else {
      return this.state.currItem && this.state.currItem.imageURL ? (
        <img src={this.state.currItem.imageURL} />
      ) : null;
    }
  }

  isImage(file) {
    // console.log('this.state.currItem', this.state.currItem)
    if (this.state.currItem && this.state.currItem.imageURL) {
      return true;
    } else {
      return false;
    }
  }

  setImage = (fileName, formKey) => () => {
    this.setState({
      [fileName]: null,
      currItem: {
        imageURL: null,
      },
    });
    // if(this.props.currentItem && this.props.currentItem.imageURL) {
    //   this.props.currentItem.imageURL = null
    // }
  };

  handleFileDrop = (dropped) => {
    // console.log("drop", dropped);
    let form = _.extend(this.state.form, {});
    form.image = form.image.asMutable ? form.image.asMutable() : form.image;
    form.image = dropped[0];
    // console.log("handleFileDrop", this.state.form, dropped[0]);
    this.setState({ image: dropped[0], cropClass: false, file: dropped[0] });
  };
  // handleFileDrop(acceptedFiles, rejectedFiles) {
  //     if (rejectedFiles.length) {
  //         this.setState({
  //             fileRejected: true,
  //         })
  //     } else {
  //         this.setState({
  //             file: _.first(acceptedFiles),
  //             fileRejected: false,
  //         })
  //     }
  // }

  hasError(inputName) {
    return !!this.props.exceptions[inputName];
  }

  getErrorClass(inputName, defaultClasses = "") {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  }

  getErrorMessage(inputName) {
    return this.props.exceptions[inputName];
  }

  render() {
    let nameError = this.hasError("name")
      ? `${strings.get("App.settings.addLanguage.name")} ${this.getErrorMessage(
          "name"
        )}`
      : "";
    let iso2Error = this.hasError("iso2")
      ? `iso2 ${this.getErrorMessage("iso2")}`
      : "";
    let imageError = this.hasError("file")
      ? `${strings.get("App.articleForm.image")} ${this.getErrorMessage(
          "file"
        )}`
      : "";

    let dropzoneContent = this.getPreviewImage("file") ? (
      this.getPreviewImage("file")
    ) : (
      <img src={"https://dummyimage.com/150x150/000/fff.jpg"} />
    );
    return (
      <div className="translation-content row">
        <div className="col-sm-12">
          <form className="form-horizontal">
            <div className="panel panel-default">
              <div className="panel-heading">
                <b>{this.props.formHeading}</b>
              </div>
              <div className="panel-body">
                <div className={this.getErrorClass("name", "form-group")}>
                  <label className="col-sm-3 control-label" htmlFor="name">
                    {strings.get("App.settings.addLanguage.name")}
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      id="name"
                      type="text"
                      name="name"
                      value={this.state.form.name}
                      onChange={this.handleInputChange}
                    />
                    <small className="text-danger">{nameError}</small>
                  </div>
                </div>
                <div className={this.getErrorClass("iso2", "form-group")}>
                  <label className="col-sm-3 control-label" htmlFor="iso2">
                    {strings.get("App.settings.addLanguage.iso2")}
                  </label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      id="iso2"
                      type="text"
                      name="iso2"
                      value={this.state.form.iso2}
                      onChange={this.handleInputChange}
                    />
                    <small className="text-danger">{iso2Error}</small>
                  </div>
                </div>
                {/* <div className="form-group">
                          <label className="col-sm-3 control-label">{ strings.get('App.articleForm.image') }</label>
                          <div className="col-sm-9">
                          <Dropzone
                              className="dropzone"
                              onDrop={ this.handleFileDrop }
                              multiple={ false }
                              maxSize={ 4096000 }
                              accept="image/*">
                              { dropzoneContent }
                          </Dropzone>
                              <div className="help-block">(Suggest Dimension: 150px X 150px)</div>
                              <small className="text-danger">{ imageError }</small>
                          </div>
                      </div> */}

                <div className="form-group">
                  <label className="col-sm-3 control-label">
                    {strings.get("App.articleForm.image")}
                  </label>

                  <div className="col-sm-9">
                    {this.state.image || this.state.file ? (
                      <input
                        type="button"
                        className="close-btn"
                        style={{ marginTop: -10 }}
                        onClick={this.removeImage("image", "file")}
                        value="X"
                      />
                    ) : this.isImage("file") ? (
                      <input
                        type="button"
                        className="close-btn"
                        style={{ marginTop: -10 }}
                        onClick={this.setImage("file")}
                        value="X"
                      />
                    ) : (
                      ""
                    )}
                    {/* <Dropzone
                      className="dropzone"
                      onDrop={this.handleFileDrop}
                      multiple={false}
                      maxSize={4096000}
                      accept="image/*"
                    >
                      {dropzoneContent}

                      {this.state.image ? (
                        <ReactAvatarEditor
                          scale={this.state.scale}
                          rotate={0}
                          className="image-placeholder"
                          image={this.state.image}
                          width={150}
                          height={150}
                          ref={ref => this.setEditorRef(ref)}
                        />
                      ) : this.state.file && this.state.currItem ? (
                        <img src={this.state.file} />
                      ) : (
                        ""
                      )}
                    </Dropzone> */}

                    <Dropzone
                      className="dropzone"
                      onDrop={this.handleFileDrop}
                      multiple={false}
                      maxSize={4096000}
                      accept="image/*"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <>
                            {dropzoneContent}

                            {this.state.image ? (
                              <ReactAvatarEditor
                                scale={this.state.scale}
                                rotate={0}
                                className="image-placeholder"
                                image={this.state.image}
                                width={150}
                                height={150}
                                ref={(ref) => this.setEditorRef(ref)}
                              />
                            ) : this.state.file && this.state.currItem ? (
                              <img src={this.state.file} />
                            ) : (
                              ""
                            )}
                          </>
                        </div>
                      )}
                    </Dropzone>

                    {this.state.cropClass === false ? (
                      <div className="help-block">
                        <div className="help-block">
                          {/* (Suggest Dimension: 150px X 150px) */}
                          {strings.get("App.companyForm.suggestDimension")}
                        </div>
                        <small className="text-danger">{imageError}</small>
                        {this.state.image !== null ? (
                          <input
                            type="range"
                            step="0.01"
                            min="0.1"
                            max="2"
                            name="scale"
                            value={this.state.scale}
                            onChange={this.handleChange}
                          />
                        ) : (
                          ""
                        )}

                        {this.state.image !== null ? (
                          <input
                            type="button"
                            className="crop-image-btn"
                            onClick={this.cropImage}
                            value="Crop"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/*<div className="form-group">
                        <Switch
                            enabled={ this.state.status }
                            onChange={ (enabled) => this.handleSwitchChange(enabled) }
                        />
                      </div>*/}
              </div>
              <footer className="panel-footer text-right bg-light lter">
                <button
                  className="btn btn-primary"
                  onClick={this.handleSaveClick}
                >
                  {strings.get("App.settings.addLanguage.submit")}
                </button>
                <button
                  className="btn btn-default"
                  onClick={this.handleCancelClick}
                >
                  {strings.get("App.articleForm.cancel")}
                </button>
              </footer>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

TranslationForm.propTypes = {
  exceptions: PropTypes.object.isRequired,
};

export default TranslationForm;
