import React, { Component } from "react";
import autoBind from "react-autobind";
import strings from "../../services/strings";
import PropTypes from "prop-types";

import _ from "lodash";
import './PartnerList.scss'

import Modal from "reboron/DropModal";
import Switch from "../Switch";
import { browserHistory } from "../../helpers";

let componentInstance = null;

class PartnerList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    componentInstance = this;
  }

  getLogo(item) {
    if (item.imageURL) {
      return (
        <div
          className="item-logo"
          style={{ backgroundImage: `url('${item.imageURL}')` }}
        />
      );
    }

    return <div className="item-logo default-logo ion-android-list" />;
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  handleDeleteClick(id) {
    this.props.setCurrentItemId(id);
    this.showDeleteModal();
  }

  handleChange(id, item, enabled) {
    this.props.updateItem(id, {
      name: item.name,
      url: item.url,
      image: item.image,
      visible: enabled ? 1 : 0
    });
  }

  handleConfirmDeleteClick() {
    this.props.deleteItem(this.props.currentItem.id);
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleCancelDeleteClick() {
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleEditClick(id) {
    browserHistory.push(`/modules/partners/${id}`);
    
  }

  render() {
    let items = _.map(this.props.items, (item, i) => {
      return (
        <tr key={item.id}>
          <td
            style={{ cursor: "pointer" }}
            onClick={() => this.handleEditClick(item.id)}
          >
            {this.getLogo(item)}
          </td>
          <td
            style={{ cursor: "pointer" }}
            onClick={() => this.handleEditClick(item.id)}
          >
            <div className="details">
              <div className="name">{item.name}</div>
            </div>
          </td>
          <td>
            <Switch
              enabled={!!item.visible}
              onChange={enabled =>
                componentInstance.handleChange(item.id, item, enabled)
              }
            />
          </td>
          <td>
            <i
              onClick={() => this.handleEditClick(item.id)}
              className="btn btn-default edit-btn ion-edit"
            />
            &nbsp;&nbsp;
            <i
              onClick={() => this.handleDeleteClick(item.id)}
              className="btn btn-default delete-btn ion-trash-b"
            />
          </td>
        </tr>
      );
    });

    let deleteModalContent = this.props.currentItem ? (
      <span>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleCancelDeleteClick}
          />
        </div>
        <h2>
          {strings.get("App.deleteModal.message", {
            itemName: this.props.currentItem.name
          })}
        </h2>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger"
            onClick={this.handleConfirmDeleteClick}
          >
            {strings.get("App.deleteModal.delete")}
          </button>
          <button
            className="btn btn-lg btn-default cancel"
            onClick={this.handleCancelDeleteClick}
          >
            {strings.get("App.deleteModal.cancel")}
          </button>
        </div>
      </span>
    ) : null;

    return (
      <span className="PartnerList">
        <Modal className="boron-modal" ref="deleteModal">
          {deleteModalContent}
        </Modal>
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>{strings.get("App.partnerForm.image")}</th>
                      <th>{strings.get("App.partnerForm.name")}</th>
                      <th>{strings.get("App.partnerForm.visible")}</th>
                      <th>{strings.get("General.action")}</th>
                    </tr>
                  </thead>
                  <tbody>{items}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/*<table className="table">*/}
        {/*<tbody>*/}
        {/*{ items }*/}
        {/*</tbody>*/}
        {/*</table>*/}
      </span>
    );
  }
}

PartnerList.propTypes = {
  items: PropTypes.array.isRequired,
  fetchItems: PropTypes.func.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired
};

export default PartnerList;
