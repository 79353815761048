import Immutable from "seamless-immutable";
import { types } from "./actions";
import moment from "moment";
import _ from "lodash";
import language from "../../services/language";

const initialState = Immutable({
  itemsByKey: {
    // key: { item }
  },
  screenLoading: false,
  currentModal: "",
  lang: {},
});

// Clear cached info
function clearCache(state) {
  return state.merge({
    itemsByKey: {},
  });
}

// Clear extra settings
function clearExtraSettings(state) {
  return state.merge({
    // screenLoading: false,
    currentModal: "",
  });
}

//set screen loading status
function setScreenLoading(state, payload) {
const newState = state.merge({
  screenLoading: payload.status,
});

  return newState 
}

//set active modal
function setCurrentModal(state, payload) {
  return state.merge({
    currentModal: payload.currentModal,
  });
}
// Save items to store
function fetchAllItemsDone(state, payload) {
  _.map(payload.items, (item) => {
    if (item.imageURL) {
      item.imageURL += `?t=${moment().unix()}`;
    }
    return item;
  });
  return state.merge({
    itemsByKey: _.keyBy(payload.items, (item) => item.key),
  });
}

function handleLanguageChange(state) {
  let lang = {};
  lang["id"] = language.get();
  lang["iso2"] = language.getIso2();
  return state.merge({
    lang: lang,
  });
}

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.CLEAR_CACHE:
      return clearCache(state);

    case types.CLEAR_EXTRA_SETTINGS:
      return clearExtraSettings(state);

    case types.FETCH_ALL_ITEMS_DONE:
      return fetchAllItemsDone(state, action.payload);

    case types.SET_LOADING_SCREEN:
      return setScreenLoading(state, action.payload);

    case types.SET_CURRENT_MODAL:
      return setCurrentModal(state, action.payload);

    case types.HANDLE_LANGUAGE_CHANGE:
      return handleLanguageChange(state);

    default:
      return state;
  }
}
