import _ from 'lodash';

export function getItems(state) {
    return state.ipAddresses.itemsById;
}

export function getItemById(state, id) {
    return state.ipAddresses.itemsById['_' + id];
}

export function getCurrentItem(state) {
    return state.ipAddresses.currentItemId ? getItemById(state, state.ipAddresses.currentItemId) : null;
}

export function getPagination(state) {
    return state.ipAddresses.pagination;
}

export function getItemsByPage(state, page) {
    if (!state.ipAddresses.idsByPage['_' + page]) {
        page = (getPagination(state)).previousPage;
    }
    return _.map(state.ipAddresses.idsByPage['_' + page], (itemId) => {
        return state.ipAddresses.itemsById['_' + itemId]
    })
}

export function getSorter(state) {
    return state.ipAddresses.sorter;
}
