import _ from 'lodash';

export function getItems(state) {
    return state.workflowCategories.itemsById;
}

export function getItemById(state, id) {
    return state.workflowCategories.itemsById['_' + id];
}

export function getCurrentItem(state) {
    return state.workflowCategories.currentItemId ? getItemById(state, state.workflowCategories.currentItemId) : null;
}

export function getPagination(state) {
    return state.workflowCategories.pagination;
}

export function getItemsByPage(state, page) {
    if (!state.workflowCategories.idsByPage['_' + page]) {
        page = (getPagination(state)).previousPage;
    }
    return _.map(state.workflowCategories.idsByPage['_' + page], (itemId) => {
        return state.workflowCategories.itemsById['_' + itemId]
    })
}

export function getSorter(state) {
	return state.workflowCategories.sorter;
}
