import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import strings from "../../services/strings";
import Topbar from "../../components/Topbar";
import ArticleCategoryForm from "../../components/articleCategory/ArticleCategoryForm";

import "../Page.scss";

import * as articleCategoriesActions from "../../store/articleCategories/actions";
import * as articleCategoriesSelectors from "../../store/articleCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

class ArticleCategoriesEditPage extends Component {
  componentDidMount() {
    this.props.fetchAllArticleCategories();
    this.props.fetchArticleCategory(this.props.match.params.id);
    this.props.setCurrentArticleCategoryId(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.unsetCurrentArticleCategoryId();
    this.props.clearExceptions();
  }

  saveArticleCategory = (data) => {
    this.props.updateArticleCategory(this.props.match.params.id, data.form, true);
    if (data.bgFile) {
      this.props.uploadArticleCategoryLogo(
        this.props.match.params.id,
        data.bgFile
      );
    }
  };

  render() {
    return (
      <div className="ArticleCategoriesEditPage">
        <Topbar>
          <div className="title">
            <Link to="/articles/categories">
              {strings.get("App.articleCategoryPages.edit.title")}
            </Link>
            <span className="xs-hidden">
              <span className="divider">/</span>
              <Link to={`/articles/categories/${this.props.match.params.id}`}>
                {strings.get("App.articleCategoryPages.edit.edit")}
              </Link>
            </span>
          </div>
          <div className="main-btns">
            <Link to={`/articles/add`} className="btn btn-primary">
              <span>
                {strings.get("App.articleCategoryPages.add.addArticle")}
              </span>{" "}
              <i className="ion-plus"></i>
            </Link>
          </div>
        </Topbar>

        <div className="content">
          <ArticleCategoryForm
            exceptions={this.props.exceptions}
            articleCategories={this.props.articleCategories}
            currentItem={this.props.currentArticleCategory}
            saveItem={this.saveArticleCategory}
            formHeading={strings.get("App.articleCategoryForm.editFormHeading")}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    exceptions: exceptionsSelectors.getItems(state),
    currentArticleCategory: articleCategoriesSelectors.getCurrentItem(state),
    articleCategories: articleCategoriesSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllArticleCategories: (deleteCache) => {
      dispatch(articleCategoriesActions.fetchAllItems(deleteCache));
    },
    fetchArticleCategory: (id) => {
      dispatch(articleCategoriesActions.fetchItem(id));
    },
    setCurrentArticleCategoryId: (id) => {
      dispatch(articleCategoriesActions.setCurrentItemId(id));
    },
    unsetCurrentArticleCategoryId: () => {
      dispatch(articleCategoriesActions.unsetCurrentItemId());
    },
    updateArticleCategory: (id, data, message) => {
      dispatch(articleCategoriesActions.updateItem(id, data, message));
    },
    createArticleCategory: (data) => {
      dispatch(articleCategoriesActions.createItem(data));
    },
    uploadArticleCategoryLogo: (id, bgFile) => {
      dispatch(articleCategoriesActions.uploadItemLogo(id, bgFile));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleCategoriesEditPage);
