import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import _ from "lodash";
import Switch from "../Switch.js";
import ReactAvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { browserHistory } from "../../helpers";
import PropTypes from "prop-types";

class PartnerForm extends Component {
  state = {
    currentItemLoaded: false,
    file: null,
    image: null,
    scale: 0.2,
    currItem: null,
    fileRejected: false,
    form: {
      name: "",
      image: "",
      url: "",
      visible: false,
    },
  };

  setEditorRef = (editor) => (this.editor = editor);

  // handleChange(e) {
  //   this.setState({ [e.target.name]: e.target.value });
  // }

  removeImage = (image, file) => () => {
    this.setState({
      image: null,
      file: null,
    });
  };

  cropImage = () => {
    let crooperD = this.editor.getImageScaledToCanvas().toDataURL();
    // console.log('this.editor.getImageScaledToCanvas()')
    // let form = _.extend(this.state.form, {});
    // form.image = form.image.asMutable
    //   ? form.image.asMutable()
    //   : form.image
    // form.image.preview = crooperD;
    this.setState({
      image: null,
      file: this.editor.getImageScaledToCanvas().toDataURL(),
      currItem: null,
    });
  };

  componentDidMount() {
    this.tryLoadCurrentItem();
  }

  componentDidUpdate() {
    this.tryLoadCurrentItem();
  }

  constructor(props) {
    super(props);
    autoBind(this);
  }

  tryLoadCurrentItem() {
    if (this.props.currentItem && !this.state.currentItemLoaded) {
      let form = _.extend({}, this.state.form);
      _.map(this.state.form, (value, key) => {
        form[key] = this.props.currentItem[key]
          ? this.props.currentItem[key]
          : this.state.form[key];
      });

      this.setState({
        currentItemLoaded: true,
        form,
        currItem: this.props.currentItem,
      });
    }
  }

  hasError(inputName) {
    return !!this.props.exceptions[inputName];
  }

  getErrorClass(inputName, defaultClasses = "") {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  }

  getErrorMessage(inputName) {
    return this.props.exceptions[inputName];
  }

  // getPreviewImage() {
  //     if (this.state.file) {
  //         return (<img src={ this.state.file.preview } />);
  //     } else {
  //         return (this.props.currentItem && this.props.currentItem.imageURL) ? (
  //             <img src={ this.props.currentItem.imageURL } />
  //         ) : null;
  //     }
  // }

  handleInputChange(e) {
    let form = {};
    form[e.target.name] = e.target.value;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  }

  // handleFileDrop(acceptedFiles, rejectedFiles) {
  //     if (rejectedFiles.length) {
  //         this.setState({
  //             fileRejected: true,
  //         })
  //     } else {
  //         this.setState({
  //             file: _.first(acceptedFiles),
  //             fileRejected: false,
  //         })
  //     }
  // }

  setEditorRef = (editor: any) => {
    if (editor) {
      this.editor = editor;
      // console.log('this.editor', this.editor)
      const img = this.editor.getImageScaledToCanvas().toDataURL();
      // console.log('hello i am here', img)
    }
  };

  getPreviewImage(file) {
    if (this.state.file) {
      return <img src={this.state.file} />;
    } else {
      return this.state.currItem && this.state.currItem.imageURL ? (
        <img src={this.state.currItem.imageURL} />
      ) : null;
    }
  }

  isImage(file) {
    // console.log('this.state.currItem', this.state.currItem)
    if (this.state.currItem && this.state.currItem.imageURL) {
      return true;
    } else {
      return false;
    }
  }

  setImage = (fileName, formKey) => () => {
    this.setState({
      [fileName]: null,
      currItem: {
        imageURL: null,
      },
    });
    // if(this.props.currentItem && this.props.currentItem.imageURL) {
    //   this.props.currentItem.imageURL = null
    // }
  };

  handleFileDrop = (dropped) => {
    // console.log("drop", dropped);
    // let form = _.extend(this.state.form, {});
    // form.image = form.image.asMutable
    //   ? form.image.asMutable()
    //   : form.image;
    // form.image = dropped[0];
    // console.log("handleFileDrop", this.state.form, dropped[0]);
    this.setState({ image: dropped[0], cropClass: false, file: dropped[0] });
  };

  handleSaveClick(e) {
    e.preventDefault();
    let form = {};
    form.visible = Number(this.state.form.visible);

    this.setState(_.extend(this.state.form, form), () => {
      this.props.saveItem(this.state);
    });
  }

  handleCancelClick(e) {
    e.preventDefault();
    browserHistory.push("/modules/partners");
  }

  handleCheckboxChange(e) {
    let form = {};
    form[e.target.name] = e.target.checked;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  }

  handleChange(name, enabled) {
    let { form } = this.state;
    form[name] = enabled;
    this.setState({ form });
  }

  render() {
    let nameLabel = this.hasError("name")
      ? `${strings.get("App.partnerForm.name")} ${this.getErrorMessage("name")}`
      : strings.get("App.partnerForm.name");
    let imageLabel = this.hasError("image")
      ? strings.get("Exceptions.imageTooBig")
      : strings.get("App.partnerForm.image");
    let urlLabel = this.hasError("url")
      ? `${strings.get("App.partnerForm.url")} ${this.getErrorMessage("url")}`
      : strings.get("App.partnerForm.url");

    let dropzoneContent = this.getPreviewImage() ? (
      this.getPreviewImage()
    ) : (
      <img src={"https://dummyimage.com/150x150/000/fff.jpg"} />
    );
    return (
      <div className="PartnerForm row">
        <form className="col-sm-12 col-md-6">
          <div className={this.getErrorClass("name", "form-group")}>
            <label className="control-label">{nameLabel}</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={this.state.form.name}
              onChange={this.handleInputChange}
            />
          </div>
          <div className={this.getErrorClass("url", "form-group")}>
            <label className="control-label">{urlLabel}</label>
            <input
              className="form-control"
              type="text"
              name="url"
              value={this.state.form.url}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="visibility">
            <label className="col-sm-3 control-label">
              {strings.get("App.partnerForm.visible")}
            </label>
            {/* <input type="checkbox" name="visible" checked={ this.state.form.visible } onChange={ this.handleCheckboxChange }/> */}
            <div className="col-sm-9">
              <Switch
                enabled={this.state.form.visible}
                onChange={(enabled) => {
                  this.handleChange("visible", enabled);
                }}
              />
            </div>
          </div>
          {/* <div className={ this.getErrorClass('image', 'form-group') }>
                        <label className="control-label">{ imageLabel }</label>
                        <Dropzone
                            className="dropzone"
                            onDrop={ this.handleFileDrop }
                            multiple={ false }
                            maxSize={ 4096000 }
                            accept="image/*">
                            { dropzoneContent }
                        </Dropzone>
                    </div> */}

          <div className="form-group">
            <label className="col-sm-3 control-label">{imageLabel}</label>

            <div className="col-sm-9">
              {this.state.image || this.state.file ? (
                <input
                  type="button"
                  className="close-btn"
                  style={{ marginTop: -10 }}
                  onClick={this.removeImage("image", "file")}
                  value="X"
                />
              ) : this.isImage("file") ? (
                <input
                  type="button"
                  className="close-btn"
                  style={{ marginTop: -10 }}
                  onClick={this.setImage("file")}
                  value="X"
                />
              ) : (
                ""
              )}
              <Dropzone
                className="dropzone"
                onDrop={this.handleFileDrop}
                multiple={false}
                maxSize={4096000}
                accept="image/*"
              >
                {/* {dropzoneContent}

                      {this.state.image ? (
                        <ReactAvatarEditor
                          scale={this.state.scale}
                          rotate={0}
                          className="image-placeholder"
                          image={this.state.image}
                          width={150}
                          height={150}
                          ref={ref => this.setEditorRef(ref)}
                        />
                      ) : this.state.file && this.state.currItem ? (
                        <img src={this.state.file} />
                      ) : (
                        ""
                      )} */}
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {dropzoneContent}
                    {this.state.image ? (
                      <ReactAvatarEditor
                        scale={this.state.scale}
                        rotate={0}
                        className="image-placeholder"
                        image={this.state.image}
                        width={150}
                        height={150}
                        ref={(ref) => this.setEditorRef(ref)}
                      />
                    ) : this.state.file && this.state.currItem ? (
                      <img src={this.state.file} />
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Dropzone>

              {this.state.cropClass === false ? (
                <div className="help-block">
                  <div className="help-block">
                    ( {strings.get("App.companyForm.suggestDimension")} )
                  </div>
                  {this.state.image !== null ? (
                    <input
                      type="range"
                      step="0.01"
                      min="0.1"
                      max="2"
                      name="scale"
                      value={this.state.scale}
                      onChange={(e) =>
                        this.setState({ [e.target.name]: e.target.value })
                      }
                    />
                  ) : (
                    ""
                  )}

                  {this.state.image !== null ? (
                    <input
                      type="button"
                      className="crop-image-btn"
                      onClick={this.cropImage}
                      value="Crop"
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="form-actions">
            <button className="btn btn-primary" onClick={this.handleSaveClick}>
              {strings.get("App.partnerForm.save")}
            </button>
            <button
              className="btn btn-default btn-color-change"
              onClick={this.handleCancelClick}
            >
              {strings.get("App.partnerForm.cancel")}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

PartnerForm.propTypes = {
  exceptions: PropTypes.object.isRequired,
  saveItem: PropTypes.func.isRequired,
};

export default PartnerForm;
