import React, { Component } from 'react';
import PropTypes from "prop-types";
import autoBind from 'react-autobind';
import strings from '../../services/strings';
import language from '../../services/language';
import _ from 'lodash';

import './ArticlePagesize.scss';

class ArticlePagesize extends Component {

    constructor(props) {
        super(props);
        this.state={
            column : "name",
            pageLimit: 10,
            descending: true
        }
        autoBind(this);
    }

    getSorter() {
        if (this.props.sorter) {
            return this.props.sorter.column;
        }
        return '';
    }

    getPageLimit = () => {
        if (this.props.sorter) {
            return this.props.sorter.pageLimit;
        }

        return '';
    }


    handleSortChange = (e) => {
        let sorter = {
            pageLimit: this.state.pageLimit,
            column: e.target.value,
            descending: this.state.descending
        };
        if (sorter.column ==="name"){
            sorter.descending= false;
        }
        this.setState({
            column : sorter.column,
            descending: sorter.descending,
            pageLimit : sorter.pageLimit
        });
        this.props.setSorter(sorter);
        this.props.fetchItems(true);
    }

    handleChange(e) {
        let sorter = {
            pageLimit: e.target.value,
            column: this.state.column,
            descending: this.state.descending
        }
        this.setState({ pageLimit: sorter.pageLimit })
        this.props.setSorter(sorter);
        this.props.fetchItems(true);
    }

  handleOrder = () => {
    let sorter = {
      descending: !this.props.sorter.descending
    }

    this.props.setSorter(sorter);
    this.props.fetchItems(true);
}

    render() {
        return (
            <div className="Articlesize">
                 <label>{strings.get("App.companies.sorter.title")}</label>
                <select
                    className="form-control"
                    name="sorter"
                    value={this.getSorter()}
                    onChange={this.handleSortChange}
                >
                    <option value="name">
                        {strings.get("App.companies.sorter.name")}
                    </option>
                    <option value="visible">
                        {strings.get("App.companies.sorter.visibility")}
                    </option>
                    <option value="created_at">
                        {strings.get("App.companies.sorter.lastCreate")}
                    </option>
                    <option value="updated_at">
                        {strings.get("App.companies.sorter.lastUpdate")}
                    </option>
                </select>

                <span className="ml-5" style={{color:'#000000'}} onClick={this.handleOrder}>
                    {this.props.sorter.descending ?
                        (<i title="Set to Ascending Direction" className="fa fa-arrow-down"></i>)
                         : 
                         (<i title="Set to Descending Direction" className="fa fa-arrow-up"></i>)
                    }
                </span>
                
                <select className="form-control" name="sorter" value={ this.getPageLimit() } onChange={ this.handleChange }>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
        );
    }

}

ArticlePagesize.propTypes = {
    sorter: PropTypes.object,
    setSorter: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
}

export default ArticlePagesize;
