import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import strings from "../../services/strings";
import "../Page.scss";
import "./UserSettings.scss";

/* TODO: will be changed */

import * as usersActions from "../../store/users/actions";
import * as usersSelectors from "../../store/users/selectors";
import * as groupsActions from "../../store/groups/actions";
import * as groupsSelectors from "../../store/groups/selectors";
import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as scopesActions from "../../store/scopes/actions";
import * as scopesSelectors from "../../store/scopes/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
import UserFrom from "../../components/user/UserForm";
import SubTopbar from "../../components/SubTopbar";
import UserSettingsTabs from "../../components/user/UserSettingsTabs";

class UserEditPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchUser(this.props.match.params.id);
    this.props.setCurrentUserId(this.props.match.params.id);

    this.props.fetchRoles();

    this.props.fetchGroups();
    this.props.fetchCategories();
  }

  componentWillUnmount() {
    this.props.unsetCurrentUserId();
    this.props.clearExceptions();
  }

  saveUser(data) {
    this.props.updateUser(this.props.match.params.id, data.form);
  }

  render() {
    return (
      <div className="CategoryEditPage">
        <Topbar>
          <div className="title">
            <Link to="/users">{strings.get("App.userPages.title")}</Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to={`/users/${this.props.match.params.id}/profile`}>
                {strings.get("App.userPages.edit")}
              </Link>
            </span>
          </div>
        </Topbar>
        <div className="content user-settings-container">
          <SubTopbar>
            <UserSettingsTabs userId={this.props.match.params.id} />
          </SubTopbar>
          <div className="user-setting-content">
            <UserFrom
              exceptions={this.props.exceptions}
              currentItem={this.props.currentUser}
              saveItem={this.saveUser}
              changeUserRole={this.changeUserRole}
              groups={this.props.groups}
              categories={this.props.categories}
              roles={this.props.roles}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: usersSelectors.getCurrentItem(state),
    groups: groupsSelectors.getItems(state),
    categories: categoriesSelectors.getItems(state),
    roles: scopesSelectors.getScopes(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
    fetchAllUsers: (deleteCache) => {
      dispatch(usersActions.fetchAllItems(deleteCache));
    },
    fetchUsers: (deleteCache) => {
      dispatch(usersActions.fetchItems(deleteCache));
    },
    setCurrentUserId: (id) => {
      dispatch(usersActions.setCurrentItemId(id));
    },
    fetchUser: (id) => {
      dispatch(usersActions.fetchItem(id));
    },
    unsetCurrentUserId: () => {
      dispatch(usersActions.unsetCurrentItemId());
    },
    updateUser: (id, data) => {
      dispatch(usersActions.updateItem(id, data));
    },
    // updateUserScope: (id, data) => {
    //   dispatch(usersActions.updateItem(id, data))
    // },
    fetchGroups: (deleteCache) => {
      dispatch(groupsActions.fetchItems(deleteCache));
    },
    fetchCategories: (deleteCache) => {
      dispatch(categoriesActions.fetchItems(deleteCache));
    },
    fetchRoles: () => {
      dispatch(scopesActions.fetchAllRoles());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditPage);
