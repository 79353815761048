import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import settings from "../../../services/settings";
import language from "../../../services/language";
import { Link } from "react-router-dom";
import ImageUpload from "../../../components/common/ImageUpload";
import ReactAvatarEditor from "react-avatar-editor";

import _ from "lodash";
import Dropzone from "react-dropzone";

import Switch from "react-switch";

class MaintenanceSettingForm extends Component {
  state = {
    currentSettingsLoaded: false,
    fileRejected: false,
    image: null,
    cropImageData: null,
    maintenanceFile: null,
    scale: 0.2,
    form: {
      maintenance_mode: { value: 0 },
      maintenance_heading: { value: "" },
      maintenance_desc: { value: "" },
      maintenance_img: { value: "" },
      glossary_time_out: { value: "30" },
    },
    glossary_time_out_error: false,
  };
  setEditorRef = (editor) => (this.editor = editor);

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  removeImage = (image, file, formKey) => () => {
    this.setState({
      [image]: null,
      ...this.state[image],
      [file]: null,
      ...this.state[file],
      cropImageData: null,
    });
  };

  cropImage = () => {
    this.setState({
      image: null,
      cropImageData: this.editor.getImageScaledToCanvas().toDataURL(),
    });

    let crooperD = this.editor.getImageScaledToCanvas().toDataURL();
    let form = _.extend(this.state.form, {});
    form[settings.keys.MAINTENANCE_IMG] = form[settings.keys.MAINTENANCE_IMG]
      .asMutable
      ? form[settings.keys.MAINTENANCE_IMG].asMutable()
      : form[settings.keys.MAINTENANCE_IMG];
    form[settings.keys.MAINTENANCE_IMG].file.preview = crooperD;
    this.setState({
      maintenanceFile: this.editor.getImageScaledToCanvas().toDataURL(),
    });
  };

  componentDidMount() {
    this.tryLoadCurrentSettings();
  }

  componentDidUpdate() {
    this.tryLoadCurrentSettings();
  }

  constructor(props) {
    super(props);
    autoBind(this);
  }

  tryLoadCurrentSettings() {
    if (_.size(this.props.settings) && !this.state.currentSettingsLoaded) {
      this.setState({
        currentSettingsLoaded: true,
        form: {
          maintenance_mode: this.props.settings[settings.keys.MAINTENANCE_MODE],
          maintenance_heading: this.props.settings[
            settings.keys.MAINTENANCE_HEADING
          ],
          maintenance_desc: this.props.settings[settings.keys.MAINTENANCE_DESC],
          maintenance_img: this.props.settings[settings.keys.MAINTENANCE_IMG],
          glossary_time_out: this.props.settings[
            settings.keys.GLOSSARY_TIME_OUT
          ],
        },
      });
    }
  }

  handleInputChange(e) {
    let form = _.extend(this.state.form, {});
    let glossary_time_out_error = this.state.glossary_time_out_error;
    form[e.target.name] = form[e.target.name].asMutable
      ? form[e.target.name].asMutable()
      : form[e.target.name];
    if (e.target.name === "glossary_time_out") {
      if (e.target.value > 5) {
        form[e.target.name].value = e.target.value;
        glossary_time_out_error = false;
      } else {
        glossary_time_out_error = true;
      }
    } else {
      form[e.target.name].value = e.target.value;
    }
    this.setState({ form, glossary_time_out_error });
  }

  handleCheckboxChange(e) {
    let form = _.extend(this.state.form, {});
    form["maintenance_mode"] = form["maintenance_mode"].asMutable
      ? form["maintenance_mode"].asMutable()
      : form["maintenance_mode"];
    form["maintenance_mode"].value = Number(e);
    this.setState({ form });
  }

  handleSaveClick(e) {
    e.preventDefault();
    this.props.saveSettings(this.state);
  }

  handleCancelClick(e) {
    e.preventDefault();
    this.setState({
      form: {
        maintenance_mode: this.props.settings[settings.keys.MAINTENANCE_MODE],
        maintenance_heading: this.props.settings[
          settings.keys.MAINTENANCE_HEADING
        ],
        maintenance_desc: this.props.settings[settings.keys.MAINTENANCE_DESC],
        maintenance_img: this.props.settings[settings.keys.MAINTENANCE_IMG],
      },
    });
  }

  isChecked() {
    if (this.state.form.maintenance_mode.value === 1) {
      return true;
    }
    return false;
  }

  setEditorRef = (editor: any) => {
    if (editor) {
      this.editor = editor;
      // console.log('this.editor', this.editor)
      const img = this.editor.getImageScaledToCanvas().toDataURL();
      // console.log('hello i am here', img)
    }
  };

  getPreviewImage(file, position) {
    if (this.state[file]) {
      return <img src={this.state[file].preview} />;
    } else {
      return this.state.form[position] && this.state.form[position].imageURL ? (
        <img src={this.state.form[position].imageURL} />
      ) : null;
    }
  }

  isImage(file, position) {
    if (this.state.form[position] && this.state.form[position].imageURL) {
      return true;
    } else {
      return false;
    }
  }

  setImage = (fileName, formKey) => () => {
    this.setState({
      form: {
        ...this.state.form,
        [formKey]: {
          ...this.state.form[formKey],
          imageURL: null,
        },
      },
      [fileName]: null,
    });
  };

  // handleFileDrop(acceptedFiles, rejectedFiles) {
  //     if (rejectedFiles.length) {
  //         this.setState({
  //             fileRejected: true,
  //         })
  //     } else {
  //       let form = _.extend(this.state.form, {});
  //       form[settings.keys.MAINTENANCE_IMG] = form[settings.keys.MAINTENANCE_IMG].asMutable ? form[settings.keys.MAINTENANCE_IMG].asMutable() : form[settings.keys.MAINTENANCE_IMG];
  //       form[settings.keys.MAINTENANCE_IMG].file = _.first(acceptedFiles);
  //       this.setState({ form });
  //       this.setState({
  //           file: _.first(acceptedFiles),
  //           fileRejected: false,
  //       })
  //     }
  // }

  handleFileDrop = (dropped) => {
    let form = _.extend(this.state.form, {});
    form[settings.keys.MAINTENANCE_IMG] = form[settings.keys.MAINTENANCE_IMG]
      .asMutable
      ? form[settings.keys.MAINTENANCE_IMG].asMutable()
      : form[settings.keys.MAINTENANCE_IMG];
    form[settings.keys.MAINTENANCE_IMG].file = dropped[0];
    this.setState({ image: dropped[0], cropClass: false });
  };

  render() {
    let dropzoneContent = this.getPreviewImage(
      "maintenanceFile",
      "maintenance_img"
    ) ? (
      this.getPreviewImage("maintenanceFile", "maintenance_img")
    ) : (
      <img src={"https://dummyimage.com/350x250/000/fff.jpg"} />
    );

    return (
      <div className="EmailForm row">
        <form className="col-sm-12 col-md-8">
          <div className="form-group">
            <label className="control-label">
              {strings.get(
                "App.settings.maintenanceSetting.maintenanceHeading"
              )}
            </label>
            <input
              className="form-control"
              type="text"
              name="maintenance_heading"
              value={this.state.form.maintenance_heading.value}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.maintenanceSetting.maintenanceDesc")}
            </label>
            <textarea
              className="form-control"
              name="maintenance_desc"
              value={this.state.form.maintenance_desc.value}
              onChange={this.handleInputChange}
            ></textarea>
          </div>
          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.maintenanceSetting.glossaryTimeOut")}
            </label>
            <input
              type="number"
              className="form-control"
              name="glossary_time_out"
              value={parseInt(this.state.form.glossary_time_out.value)}
              onChange={this.handleInputChange}
            />
            {this.state.glossary_time_out_error ? (
              <label
                className="control-label"
                style={{ color: "red", fontSize: "13px" }}
              >
                {strings.get(
                  "App.settings.maintenanceSetting.glossaryTimeOutHelperText"
                )}
              </label>
            ) : null}
          </div>
          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.maintenanceSetting.maintenanceMode")}
            </label>
            &nbsp;&nbsp;
            <Switch
              onChange={this.handleCheckboxChange}
              checked={this.isChecked()}
              id="normal-switch"
              offColor="#A10021"
              onColor="#009832"
              className="maintenance_switch"
            />
          </div>
          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.maintenanceSetting.maintenanceImg")}
            </label>

            {this.state.image ||
            this.state.cropImageData ||
            this.state.maintenanceFile ? (
              <input
                type="button"
                className="close-btn"
                onClick={this.removeImage(
                  "image",
                  "maintenanceFile",
                  "maintenance_img"
                )}
                value="X"
              />
            ) : this.isImage("maintenanceFile", "maintenance_img") ? (
              <input
                type="button"
                className="close-btn"
                onClick={this.setImage("maintenanceFile", "maintenance_img")}
                value="X"
              />
            ) : (
              ""
            )}
            <Dropzone
              className="dropzone"
              onDrop={this.handleFileDrop}
              multiple={false}
              maxSize={4096000}
              accept="image/*"
            >
              {/* {dropzoneContent}

              {this.state.image ? (
                <ReactAvatarEditor
                  scale={this.state.scale}
                  rotate={0}
                  className="image-placeholder"
                  image={this.state.image}
                  width={350}
                  height={150}
                  ref={(ref) => this.setEditorRef(ref)}
                />
              ) : this.state.maintenanceFile ? (
                <img src={this.state.maintenanceFile} />
              ) : (
                ""
              )} */}
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  {dropzoneContent}

                  {this.state.image ? (
                    <ReactAvatarEditor
                      scale={this.state.scale}
                      rotate={0}
                      className="image-placeholder"
                      image={this.state.image}
                      width={350}
                      height={150}
                      ref={(ref) => this.setEditorRef(ref)}
                    />
                  ) : this.state.maintenanceFile ? (
                    <img src={this.state.maintenanceFile} />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </Dropzone>

            {this.state.cropClass === false ? (
              <div className="help-block">
                {/* <div className='help-block'>(Suggestie: breedte 142px, hoogte 20px)</div> */}
                {/* <small className='text-danger headerErrorUpload' /> */}
                {this.state.image !== null ? (
                  <input
                    type="range"
                    step="0.01"
                    min="0.1"
                    max="2"
                    name="scale"
                    value={this.state.scale}
                    onChange={this.handleChange}
                  />
                ) : (
                  ""
                )}

                {this.state.image !== null ? (
                  <input
                    type="button"
                    className="crop-image-btn"
                    onClick={this.cropImage}
                    value="Crop"
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="form-actions">
            <button className="btn btn-primary" onClick={this.handleSaveClick}>
              {strings.get("App.email.save")}
            </button>
            <button
              className="btn btn-default"
              onClick={this.handleCancelClick}
            >
              {strings.get("App.email.cancel")}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

MaintenanceSettingForm.propTypes = {
  settings: PropTypes.object,
  saveSettings: PropTypes.func.isRequired,
};

export default MaintenanceSettingForm;
