import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import strings from "../../services/strings";

import "../Page.scss";
import Topbar from "../../components/Topbar";
import ArticleForm from "../../components/article/ArticleForm";

import * as articlesActions from "../../store/articles/actions";
// import * as articlesSelectors from "../../store/articles/selectors";
import * as articleCategoriesActions from "../../store/articleCategories/actions";
import * as articleCategoriesSelectors from "../../store/articleCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

class ArticlesAddPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchArticleCategories();
  }

  componentWillUnmount() {
    this.props.clearExceptions();
  }

  saveArticle(data) {
    if (data.file) {
      this.props.createArticleWithLogo(data.form, data.file);
    } else {
      this.props.createArticle(data.form);
    }
  }

  render() {
    return (
      <div className="ArticlesAddPage">
        <Topbar>
          <div className="title">
            <Link to="/articles">
              {strings.get("App.articlePages.add.title")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/articles/add">
                <span>{strings.get("App.articlePages.add.add")}</span>{" "}
                <i className="ion-plus"></i>
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content">
          <ArticleForm
            exceptions={this.props.exceptions}
            saveItem={this.saveArticle}
            articleCategories={this.props.articleCategories}
            formHeading={
              strings.get("App.articlePages.add.add") +
              " " +
              strings.get("App.articlePages.add.title")
            }
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    exceptions: exceptionsSelectors.getItems(state),
    articleCategories: articleCategoriesSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createArticle: (data) => {
      dispatch(articlesActions.createItem(data));
    },
    createArticleWithLogo: (data, file) => {
      dispatch(articlesActions.createItemWithLogo(data, file));
    },
    fetchArticleCategories: () => {
      dispatch(articleCategoriesActions.fetchAllItems());
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesAddPage);
