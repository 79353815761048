import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";

import "../Page.scss";
import * as documentsActions from "../../store/documents/actions";
import * as documentsSelectors from "../../store/documents/selectors";
import * as workflowsActions from "../../store/workflows/actions";
import * as workflowssSelectors from "../../store/workflows/selectors";
import * as workflowCategoriesSelectors from "../../store/workflowCategories/selectors";
import * as workflowCategoriesActions from "../../store/workflowCategories/actions";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
import WorkflowForm from "../../components/workflow/WorkflowForm";

class WorkflowsAddPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  componentDidMount() {
    this.props.fetchDocuments();
    this.props.fetchWorkflows();
    this.props.fetchAllCategories();

    // this.props.fetchWorkflow(this.props.match.params.id);
  }
  componentWillUnmount() {
    this.props.clearExceptions();
  }

  saveWorkflow(data) {
    if (data.file) {
      this.props.createWorkflowWithLogo(data.form, data.file);
    } else {
      this.props.createWorkflow(data.form);
    }
  }
  render() {
    return (
      <div className="WorkflowsAddPage">
        <Topbar>
          <div className="title">
            <Link to="/workflows">
              {strings.get("App.workflowPages.title")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/workflows/add">
                {strings.get("App.workflowPages.add")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content">
          <WorkflowForm
            documents={this.props.documents}
            categories={this.props.categories}
            saveItem={this.saveWorkflow}
            formHeading={strings.get("App.workflowPages.add")}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    // documents: documentsSelectors.getItemsByPage(state, (documentsSelectors.getPagination(state)).currentPage),
    documents: documentsSelectors.getItems(state),
    currentWorkflow: workflowssSelectors.getCurrentItem(state),
    categories: workflowCategoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDocuments: (deleteCache) => {
      dispatch(documentsActions.fetchItemAllPages(deleteCache));
    },
    fetchWorkflows: (deleteCache) => {
      dispatch(workflowsActions.fetchItems(deleteCache));
    },
    fetchAllCategories: (deleteCache) => {
      dispatch(workflowCategoriesActions.fetchItemAllPages(deleteCache));
    },
    createWorkflow: (data) => {
      dispatch(workflowsActions.createItem(data));
    },
    createWorkflowWithLogo: (data, file) => {
      dispatch(workflowsActions.createItemWithLogo(data, file));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowsAddPage);
