import React from "react";
import _ from "lodash";
import "./Helpbox.scss";
import ReactLoading from "react-loading";

class Helpbox extends React.Component {
  constructor(props) {
    super(props);
    let helpbox = [];
    if (props.form && props.form.template && props.itemElements) {
      let template = props.form.template;
      helpbox = this.getHelpbox(template, props.itemElements);
    }
    this.state = {
      form: props.form,
      itemElements: props.itemElements,
      helpbox
    };
  }

  getHelpbox = (template, itemElements = {}) => {
    var helpbox = null;
    let regexSelect = /\[select.(select_\d+)\]/g;
    let regexClause = /\[if.(clause_\d+)\]/g;
    let regexCodeBlock = /\[code.(block_\d+)\]/g;
    let regexCarousel = /\[carousel.(carousel_\d+)\]/g;
    let regexField = /\[\[(field_\d+)\]\]/g;
    let regexField2 = /\[\[(field_\d+)\.[^\.\[\]]*\]\]/g;
    let regexUnknown = /\[([^\[\]]*)\]/g;

    let match = null;
    match = regexSelect.exec(template);

    let selectors = [];
    let clauses = [];
    let codeBlocks = [];
    let carousels = [];
    let fields = [];
    let unknown = [];

    while (match !== null) {
      if (!selectors.includes(match[1])) selectors.push(match[1]);
      match = regexSelect.exec(template);
    }

    match = regexClause.exec(template);
    while (match !== null) {
      if (!clauses.includes(match[1])) clauses.push(match[1]);
      match = regexClause.exec(template);
    }

    match = regexCodeBlock.exec(template);
    while (match !== null) {
      if (!codeBlocks.includes(match[1])) codeBlocks.push(match[1]);
      match = regexCodeBlock.exec(template);
    }

    match = regexCarousel.exec(template);
    while (match !== null) {
      if (!carousels.includes(match[1])) carousels.push(match[1]);
      match = regexCarousel.exec(template);
    }

    match = regexField.exec(template);
    while (match !== null) {
      if (!fields.includes(match[1])) fields.push(match[1]);
      match = regexField.exec(template);
    }

    match = regexField2.exec(template);
    while (match !== null) {
      if (!fields.includes(match[1])) fields.push(match[1]);
      match = regexField2.exec(template);
    }

    match = regexUnknown.exec(template);
    while (match !== null) {
      let t1 = !regexSelect.test(match[0]);
      let t2 = !regexClause.test(match[0]);
      let t3 = !regexCodeBlock.test(match[0]);
      let t4 = !regexField.test(`[${match[0]}]`);
      let t5 = !regexField2.test(`[${match[0]}]`);
      let t6 = !regexCarousel.test(`[${match[0]}]`);
      // console.log('#######', regexClause.test(match[0]));
      // console.log('match---', match[0], t1, t2, t3, t4, t5, '=>',  t1 && t2 && t3 && t4 && t5,'=>>', !unknown.includes(match[1]) && t1 && t2 && t3 && t4 && t5);
      if (!unknown.includes(match[1]) && t1 && t2 && t3 && t4 && t5 && t6) {
        unknown.push(match[1]);
      }
      match = regexUnknown.exec(template);
    }

    let data = {
      selectors,
      clauses,
      fields,
      codeBlocks,
      carousels,
      unknown
    };

    let {
      itemKeySelectors,
      itemKeyClauses,
      itemKeyFields,
      itemKeyCodeBlocks,
      itemKeyCarousels
    } = itemElements;
    if (
      itemKeySelectors ||
      itemKeyClauses ||
      itemKeyFields ||
      itemKeyCodeBlocks || 
      itemKeyCarousels
    ) {
      helpbox = [];

      itemKeySelectors &&
        data.selectors.forEach(item => {
          helpbox.push(
            <div
              className={`helpbox-item ${
                itemKeySelectors[item] && itemKeySelectors[item].name
                  ? ""
                  : "disabled"
              }`}
              id={`${item}`}
              key={_.uniqueId()}
            >
              <b>{item}</b>
              <br />
              <h6>
                Name: {itemKeySelectors[item] && itemKeySelectors[item].name}
              </h6>
              <h6>
                Description:{" "}
                {itemKeySelectors[item] &&
                  JSON.parse(itemKeySelectors[item].description).default}
              </h6>
              <h6>
                Glossary: {itemKeySelectors[item] && itemKeySelectors[item].glossary.name}
              </h6>
            </div>
          );
        });
      itemKeyClauses &&
        data.clauses.forEach(item =>
          helpbox.push(
            <div
              className={`helpbox-item ${
                itemKeyClauses[item] && itemKeyClauses[item].name
                  ? ""
                  : "disabled"
              }`}
              id={`${item}`}
              key={_.uniqueId()}
            >
              <b>{item}</b>
              <br />
              <h6>Name: {itemKeyClauses[item] && itemKeyClauses[item].name}</h6>
              <h6>
                Description:{" "}
                {itemKeyClauses[item] &&
                  JSON.parse(itemKeyClauses[item].description).default}
              </h6>
              <h6>
                Glossary: {itemKeyClauses[item] && itemKeyClauses[item].glossary.name}
              </h6>
            </div>
          )
        );
      itemKeyCodeBlocks &&
        data.codeBlocks.forEach(item =>
          helpbox.push(
            <div
              className={`helpbox-item ${
                itemKeyCodeBlocks[item] && itemKeyCodeBlocks[item].name
                  ? ""
                  : "disabled"
              }`}
              id={`${item}`}
              key={_.uniqueId()}
            >
              <b>{item}</b>
              <br />
              <h6>
                Name: {itemKeyCodeBlocks[item] && itemKeyCodeBlocks[item].name}
              </h6>
              <h6>
                Description:{" "}
                {itemKeyCodeBlocks[item] &&
                  JSON.parse(itemKeyCodeBlocks[item].description).default}
              </h6>
              <h6>
                Glossary: {itemKeyCodeBlocks[item] && itemKeyCodeBlocks[item].glossary.name}
              </h6>
            </div>
          )
        );
      itemKeyCarousels &&
        data.carousels.forEach(item =>
          helpbox.push(
            <div
              className={`helpbox-item ${
                itemKeyCarousels[item] && itemKeyCarousels[item].name
                  ? ""
                  : "disabled"
              }`}
              id={`${item}`}
              key={_.uniqueId()}
            >
              <b>{item}</b>
              <br />
              <h6>
                Name: {itemKeyCarousels[item] && itemKeyCarousels[item].name}
              </h6>
              <h6>
                Description:{" "}
                {itemKeyCarousels[item] &&
                  JSON.parse(itemKeyCarousels[item].description).default}
              </h6>
              <h6>
                Glossary: {itemKeyCarousels[item] && itemKeyCarousels[item].glossary.name}
              </h6>
            </div>
          )
        );
      itemKeyFields &&
        data.fields.forEach(item =>
          helpbox.push(
            <div
              className={`helpbox-item ${
                itemKeyFields[item] && itemKeyFields[item].name
                  ? ""
                  : "disabled"
              }`}
              id={`${item}`}
              key={_.uniqueId()}
            >
              <b>{item}</b>
              <br />
              <h6>Name: {itemKeyFields[item] && itemKeyFields[item].name}</h6>
              <h6>
                Label:{" "}
                {itemKeyFields[item] &&
                  JSON.parse(itemKeyFields[item].label).default}
              </h6>
              <h6>
                Glossary: {itemKeyFields[item] && itemKeyFields[item].glossary.name}
              </h6>
            </div>
          )
        );
      data.unknown.forEach(item =>
        helpbox.push(
          <div
            className={`helpbox-item disabled`}
            id={`${item}`}
            key={_.uniqueId()}
          >
            <b>{item}</b>
            <br />
            <h6>Name: {itemKeyFields[item] && itemKeyFields[item].name}</h6>
            <h6>
              Label:{" "}
              {itemKeyFields[item] &&
                JSON.parse(itemKeyFields[item].label).default}
            </h6>
          </div>
        )
      );
    }

    return helpbox;
  };

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(
      {
        form: props.form,
        itemElements: props.itemElements
      },
      () => {
        var helpbox = null;
        if (
          this.state.form &&
          this.state.form.template &&
          this.state.itemElements
        ) {
          let template = this.state.form.template;
          helpbox = this.getHelpbox(template, this.state.itemElements);
        }
        this.setState({ helpbox });
      }
    );
  }

  render() {
    return (
      <div id="helpbox-container" className="helpbox-container">
        {(this.state.helpbox &&
          this.state.helpbox.map((item, index) => item)) || (
          <div>
            <ReactLoading
              className="react-loading"
              type="spinningBubbles"
              color="#CCC"
            />
          </div>
        )}
      </div>
    );
  }
}

export default Helpbox;
