import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as glossariesActions from "../../store/glossaries/actions";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
import GlossaryImport from "../../components/glossary/GlossaryImport";
// import * as glossariesSelectors from "../../store/glossaries/selectors";
import { browserHistory } from "../../helpers";

class GlossaryImportPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  async importGlossary(data) {
    await this.props.importGlossary(data.form);
    browserHistory.push(`/glossaries`);
  }

  render() {
    return (
      <div className="GlossaryImportPage">
        <Topbar>
          <div className="title">
            <Link to="/glossaries">
              {strings.get("App.glossaryPages.title")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/glossaries/add">
                {strings.get("App.glossaryPages.add")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content">
          <GlossaryImport
            exceptions={this.props.exceptions}
            importItem={this.importGlossary}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { exceptions: exceptionsSelectors.getItems(state) };
}

function mapDispatchToProps(dispatch) {
  return {
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
    importGlossary: (data) => {
      dispatch(glossariesActions.importItem(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GlossaryImportPage);
