import Immutable from 'seamless-immutable';
import { types } from './actions';
import moment from 'moment';
import _ from 'lodash';

const initialState = Immutable({
	opened: false,
	// siteURL: 'https://documentmaken.nl/'
	siteURL: ''
});

// Toggle sidebar
function toggle(state) {
	return state.merge({
		opened: !state.opened,
	})
}
//set client side url
function setClientSiteUrl(state, payload){
	return state.merge({
		siteURL: payload.clientSiteUrl,
	})
}

export default function reduce(state = initialState, action = {}) {
  	switch (action.type) {
  		case types.TOGGLE:
  			return toggle(state);
      case types.SET_CLIENT_SITE_URL:
        return setClientSiteUrl(state, action.payload);
    	default:
      		return state;
  	}
}
