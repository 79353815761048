import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as workflowsActions from "../../store/workflows/actions";
import * as workflowsSelectors from "../../store/workflows/selectors";
import * as categoriesActions from "../../store/workflowCategories/actions";
import * as categoriesSelectors from "../../store/workflowCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";
import * as articleCategoriesSelectors from "../../store/articleCategories/selectors";

import Topbar from "../../components/Topbar";
// import SubTopbar from "../../components/SubTopbar";
import SearchBar from "../../components/SearchBar";
import WorkflowList from "../../components/workflow/WorkflowList";
import WorkflowCategoryFilter from "../../components/workflow/WorkflowCategoryFilter";
import WorkflowSorter from "../../components/workflow/WorkflowSorter";
// import WorkflowPagesize from '../../components/workflow/WorkflowPagesize';

import Pagination from "../../components/Pagination";

class WorkflowListPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchWorkflows();

    this.props.fetchAllCategories();
  }

  render() {
    return (
      <div className="WorkflowListPage">
        <Topbar>
          <div className="title">
            <Link to="/workflows">
              {strings.get("App.workflowPages.title")}
            </Link>
          </div>
          <SearchBar
            placeholder={strings.get("App.searchPlaceholders.workflow")}
            searchTerm={this.props.filters.searchTerm}
            fetchItems={this.props.fetchWorkflows}
            setSearchTerm={this.props.setSearchTerm}
          />
          <div className="main-btns">
            <Link to="/workflows/add" className="btn btn-primary">
              <span>{strings.get("App.workflowPages.addWorkflow")}</span>{" "}
              <i className="ion-plus"></i>
            </Link>
          </div>
        </Topbar>

        <div className="content">
          <div className="row">
            <div className="col-xs-6">
              <WorkflowCategoryFilter
                filters={this.props.filters}
                categories={this.props.categories}
                fetchItems={this.props.fetchWorkflows}
                setCategoryId={this.props.setCategoryId}
              />
            </div>
            <div className="col-xs-6">
              <WorkflowSorter
                sorter={this.props.sorter}
                fetchItems={this.props.fetchWorkflows}
                setSorter={this.props.setSorter}
              />
            </div>
          </div>
          <WorkflowList
            items={this.props.workflows}
            categories={this.props.categories}
            sorter={this.props.sorter}
            currentItem={this.props.currentWorkflow}
            fetchItems={this.props.fetchWorkflows}
            setCurrentItemId={this.props.setCurrentWorkflowId}
            unsetCurrentItemId={this.props.unsetCurrentWorkflowId}
            deleteItem={this.props.deleteWorkflow}
            toggleSorter={this.props.toggleSorter}
            updateColumn={this.props.updateColumn}
          />

          <Pagination
            pagination={this.props.pagination}
            setCurrentPage={this.props.setCurrentPage}
            fetchItems={this.props.fetchWorkflows}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    workflows: workflowsSelectors.getItemsByPage(
      state,
      workflowsSelectors.getPagination(state).currentPage
    ),
    categories: categoriesSelectors.getItems(state),
    sorter: workflowsSelectors.getSorter(state),
    filters: workflowsSelectors.getFilters(state),
    pagination: workflowsSelectors.getPagination(state),
    currentWorkflow: workflowsSelectors.getCurrentItem(state),
    articleCategories: articleCategoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
    fetchAllCategories: () => {
      dispatch(categoriesActions.fetchAllItems());
    },
    fetchWorkflows: (deleteCache) => {
      dispatch(workflowsActions.fetchItems(deleteCache));
    },
    setSearchTerm: (searchTerm) => {
      dispatch(workflowsActions.setSearchTerm(searchTerm));
    },
    toggleSorter: (searchTerm) => {
      dispatch(workflowsActions.toggleSorter(searchTerm));
    },
    setSorter: (sorter) => {
      dispatch(workflowsActions.setSorter(sorter));
    },
    setCurrentPage: (page) => {
      dispatch(workflowsActions.setCurrentPage(page));
    },
    setCategoryId: (id) => {
      dispatch(workflowsActions.setCategoryId(id));
    },
    setCurrentWorkflowId: (id) => {
      dispatch(workflowsActions.setCurrentItemId(id));
    },
    unsetCurrentWorkflowId: () => {
      dispatch(workflowsActions.unsetCurrentItemId());
    },
    deleteWorkflow: (id) => {
      dispatch(workflowsActions.deleteItem(id));
    },
    updateColumn: (id, column, value) => {
      dispatch(workflowsActions.updateColumn(id, column, value));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowListPage);
