import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import strings from "../../services/strings";

import _ from "lodash";
import Dropzone from "react-dropzone";
import Switch from "../Switch.js";
import ReactAvatarEditor from "react-avatar-editor";
import { browserHistory } from "../../helpers";

class WorkflowCategoryForm extends Component {
  state = {
    currentItemLoaded: false,
    bgFile: null,
    image: null,
    scale: 0.2,
    currItem: null,
    bgFileRejected: false,
    form: {
      name: "",
      url: "",
      visible: false,
      parent_id: "",
      header: 0,
      footer: 0,
      meta_title: "",
      meta_description: "",
      meta_keyword: "",
    },
  };

  setEditorRef = (editor) => (this.editor = editor);

  // handleChange(e) {
  //   this.setState({ [e.target.name]: e.target.value });
  // }

  removeImage = (image, bgFile) => () => {
    this.setState({
      image: null,
      bgFile: null,
    });
  };

  cropImage = () => {
    let crooperD = this.editor.getImageScaledToCanvas().toDataURL();
    // console.log('this.editor.getImageScaledToCanvas()')
    // let form = _.extend(this.state.form, {});
    // form.image = form.image.asMutable
    //   ? form.image.asMutable()
    //   : form.image
    // form.image.preview = crooperD;
    this.setState({
      image: null,
      bgFile: this.editor.getImageScaledToCanvas().toDataURL(),
      currItem: null,
    });
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.tryLoadCurrentItem();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentItem && this.props.currentItem) {
      this.tryLoadCurrentItem();
    }
  }

  tryLoadCurrentItem() {
    if (this.props.currentItem && !this.state.currentItemLoaded) {
      let form = _.extend({}, this.state.form);
      _.map(this.state.form, (value, key) => {
        form[key] = this.props.currentItem[key]
          ? this.props.currentItem[key]
          : this.state.form[key];
      });

      this.setState({
        currentItemLoaded: true,
        form,
        currItem: this.props.currentItem,
      });
    }
  }

  hasError(inputName) {
    return !!this.props.exceptions[inputName];
  }

  getErrorClass(inputName, defaultClasses = "") {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  }

  getErrorMessage(inputName) {
    return this.props.exceptions[inputName];
  }

  handleInputChange(e) {
    let form = {};
    form[e.target.name] = e.target.value;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  }

  handleSaveClick(e) {
    e.preventDefault();
    let form = {};
    form.visible = Number(this.state.form.visible);
    form.header = Number(this.state.form.header);
    form.footer = Number(this.state.form.footer);

    this.setState(_.extend(this.state.form, form), () => {
      this.props.saveItem(this.state);
    });
  }

  handleCancelClick(e) {
    e.preventDefault();
    browserHistory.push("/workflows/categories");
  }

  handleCheckboxChange(e) {
    let form = {};
    form[e.target.name] = e.target.checked;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  }

  getWorkflowCategories() {
    return _.map(this.props.workflowCategories, (item) => {
      return (
        <option key={`item-${item.id}`} value={item.id}>
          {item.tree_name}
        </option>
      );
    });
  }

  // getBgPreviewImage() {
  //     if (this.state.bgFile) {
  //         return (<img src={ this.state.bgFile.preview } />);
  //     } else {
  //         return (this.props.currentItem && this.props.currentItem.bgImageURL) ? (
  //             <img src={ this.props.currentItem.bgImageURL } />
  //         ) : null;
  //     }
  // }

  // handleBgImgFileDrop(acceptedFiles, rejectedFiles){
  //   $(".bgErrorUpload").html("");
  //   if(rejectedFiles.length){
  //     this.state({
  //       bgFileRejected: true,
  //     })
  //   } else {
  //     let imageErrorMsg =  `${strings.get('Client.settings.errorlogo')}`;
  //     let i = new Image();
  //     i.src = _.first(acceptedFiles).preview;
  //     i.onload = () => {
  //       if (i.width !== 2200 || i.height !== 1200 ) {
  //           $(".bgErrorUpload").html(imageErrorMsg);
  //           return;
  //       }
  //       this.setState({
  //           bgFile: _.first(acceptedFiles),
  //           bgFileRejected: false,
  //       })
  //     }
  //   }
  // }

  setEditorRef = (editor: any) => {
    if (editor) {
      this.editor = editor;
      // console.log('this.editor', this.editor)
      const img = this.editor.getImageScaledToCanvas().toDataURL();
      // console.log('hello i am here', img)
    }
  };

  getBgPreviewImage(bgFile) {
    if (this.state.bgFile) {
      return <img src={this.state.bgFile} />;
    } else {
      return this.state.currItem && this.state.currItem.bgImageURL ? (
        <img src={this.state.currItem.bgImageURL} />
      ) : null;
    }
  }

  isImage(bgFile) {
    // console.log('this.state.currItem', this.state.currItem)
    if (this.state.currItem && this.state.currItem.bgImageURL) {
      return true;
    } else {
      return false;
    }
  }

  setImage = (fileName, formKey) => () => {
    this.setState({
      [fileName]: null,
      currItem: {
        bgImageURL: null,
      },
    });
    // if(this.props.currentItem && this.props.currentItem.bgImageURL) {
    //   this.props.currentItem.bgImageURL = null
    // }
  };

  handleBgImgFileDrop = (dropped) => {
    // console.log("drop", dropped);
    // let form = _.extend(this.state.form, {});
    // form.image = form.image.asMutable
    //   ? form.image.asMutable()
    //   : form.image;
    // form.image = dropped[0];
    console.log("handleFileDrop", this.state.form, dropped[0]);
    this.setState({ image: dropped[0], cropClass: false, bgFile: dropped[0] });
  };

  handleChange(name, enabled, value) {
    let { form } = this.state;
    typeof value === "boolean"
      ? (form[name] = enabled)
      : (form[name] = enabled === true ? 1 : 0);
    this.setState({ form });
  }

  render() {
    let nameLabel = this.hasError("name")
      ? `${strings.get("App.workflowCategoryForm.name")} ${this.getErrorMessage(
          "name"
        )}`
      : strings.get("App.workflowCategoryForm.name");
    let urlLabel = this.hasError("url")
      ? `${strings.get("App.workflowCategoryForm.url")} ${this.getErrorMessage(
          "url"
        )}`
      : strings.get("App.workflowCategoryForm.url");
    let metaTitleLabel = this.hasError("meta_title")
      ? `${strings.get("App.workflowForm.meta_title")} ${this.getErrorMessage(
          "meta_title"
        )}`
      : strings.get("App.workflowForm.meta_title");
    let metaDescLabel = this.hasError("meta_description")
      ? `${strings.get(
          "App.workflowForm.meta_description"
        )} ${this.getErrorMessage("meta_description")}`
      : strings.get("App.workflowForm.meta_description");

    let dropzoneBgImgContent = this.getBgPreviewImage() ? (
      this.getBgPreviewImage()
    ) : (
      <img src={"https://dummyimage.com/2200x1200/000/fff.jpg"} />
    );
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="WorkflowCategoryForm">
            <form className="form-horizontal">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <b>{this.props.formHeading}</b>
                </div>
                <div className="panel-body">
                  <div className={this.getErrorClass("name", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {nameLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={this.state.form.name}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="form-group workflowCategories">
                    <label className="col-sm-3 control-label">
                      {strings.get("App.workflowCategoryForm.parent")}
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="parent_id"
                        value={this.state.form.parent_id}
                        onChange={this.handleInputChange}
                      >
                        <option value="">
                          {strings.get(
                            "App.workflowCategoryForm.parent_choose"
                          )}
                        </option>
                        {this.getWorkflowCategories()}
                      </select>
                    </div>
                  </div>

                  <div className="form-group visibility">
                    <label className="col-sm-3 control-label">
                      {strings.get("App.workflowCategoryForm.visible")}
                    </label>
                    <div className="col-sm-9">
                      {/* <input type="checkbox" name="visible" checked={ this.state.form.visible } onChange={ this.handleCheckboxChange }/> */}
                      <Switch
                        enabled={!!this.state.form.visible}
                        onChange={(enabled) => {
                          this.handleChange(
                            "visible",
                            enabled,
                            this.state.form.visible
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group visibility">
                    <label className="col-sm-3 control-label">
                      {strings.get("App.workflowCategoryForm.header")}
                    </label>
                    <div className="col-sm-9">
                      {/* <input type="checkbox" name="header" checked={ this.state.form.header } onChange={ this.handleCheckboxChange }/> */}
                      <Switch
                        enabled={!!this.state.form.header}
                        onChange={(enabled) => {
                          this.handleChange(
                            "header",
                            enabled,
                            this.state.form.header
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group visibility">
                    <label className="col-sm-3 control-label">
                      {strings.get("App.workflowCategoryForm.footer")}
                    </label>
                    <div className="col-sm-9">
                      {/* <input type="checkbox" name="footer" checked={ this.state.form.footer } onChange={ this.handleCheckboxChange }/> */}
                      <Switch
                        enabled={!!this.state.form.footer}
                        onChange={(enabled) => {
                          this.handleChange(
                            "footer",
                            enabled,
                            this.state.form.footer
                          );
                        }}
                      />
                    </div>
                  </div>

                  {/* <div className="form-group">
                            <label className="col-sm-3 control-label">{ strings.get('App.workflowCategoryForm.headerImage') }</label>
                            <div className="col-sm-9">
                              <Dropzone
                                  className="dropzone"
                                  onDrop={ this.handleBgImgFileDrop }
                                  multiple={ false }
                                  maxSize={ 4096000 }
                                  accept="image/*">
                                  { dropzoneBgImgContent }
                              </Dropzone>
                              <div className="help-block">(Suggestie: breedte 2200px, hoogte 1200px)</div>
                              <small className="text-danger bgErrorUpload"></small>
                            </div>
                        </div> */}

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {strings.get("App.workflowCategoryForm.headerImage")}
                    </label>

                    <div className="col-sm-9">
                      {this.state.image || this.state.bgFile ? (
                        <input
                          type="button"
                          className="close-btn"
                          style={{ marginTop: -10 }}
                          onClick={this.removeImage("image", "bgFile")}
                          value="X"
                        />
                      ) : this.isImage("bgFile") ? (
                        <input
                          type="button"
                          className="close-btn"
                          style={{ marginTop: -10 }}
                          onClick={this.setImage("bgFile")}
                          value="X"
                        />
                      ) : (
                        ""
                      )}
                      <Dropzone
                        className="dropzone"
                        onDrop={this.handleBgImgFileDrop}
                        multiple={false}
                        maxSize={4096000}
                        accept="image/*"
                      >
                        {/* {dropzoneBgImgContent}

                        {this.state.image ? (
                          <ReactAvatarEditor
                            scale={this.state.scale}
                            rotate={0}
                            className="image-placeholder"
                            image={this.state.image}
                            width={2200}
                            height={1200}
                            ref={(ref) => this.setEditorRef(ref)}
                          />
                        ) : this.state.bgFile && this.state.currItem ? (
                          <img src={this.state.bgFile} />
                        ) : (
                          ""
                        )} */}
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {dropzoneBgImgContent}

                            {this.state.image ? (
                              <ReactAvatarEditor
                                scale={this.state.scale}
                                rotate={0}
                                className="image-placeholder"
                                image={this.state.image}
                                width={2200}
                                height={1200}
                                ref={(ref) => this.setEditorRef(ref)}
                              />
                            ) : this.state.bgFile && this.state.currItem ? (
                              <img src={this.state.bgFile} />
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </Dropzone>

                      {this.state.cropClass === false ? (
                        <div className="help-block">
                          <div className="help-block">
                            {strings.get(
                              "App.workflowCategoryForm.suggestDimension"
                            )}
                          </div>
                          {this.state.image !== null ? (
                            <input
                              type="range"
                              step="0.01"
                              min="0.1"
                              max="2"
                              name="scale"
                              value={this.state.scale}
                              onChange={(e) =>
                                this.setState({
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          ) : (
                            ""
                          )}

                          {this.state.image !== null ? (
                            <input
                              type="button"
                              className="crop-image-btn"
                              onClick={this.cropImage}
                              value="Crop"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass("meta_title", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {metaTitleLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="meta_title"
                        value={this.state.form.meta_title}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass(
                      "meta_description",
                      "form-group"
                    )}
                  >
                    <label className="col-sm-3 control-label">
                      {metaDescLabel}
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        name="meta_description"
                        value={this.state.form.meta_description}
                        onChange={this.handleInputChange}
                      ></textarea>
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass("meta_keyword", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.workflowForm.meta_keyword")}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="meta_keyword"
                        value={this.state.form.meta_keyword}
                        onChange={this.handleInputChange}
                      />
                      <div className="help-block">
                        {strings.get("App.workflowForm.meta_keyword_example")}
                      </div>
                    </div>
                  </div>
                </div>

                <footer className="panel-footer text-right bg-light lter">
                  <button
                    className="btn btn-success"
                    onClick={this.handleSaveClick}
                  >
                    {strings.get("App.workflowCategoryForm.save")}
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-default btn-color-change"
                    onClick={this.handleCancelClick}
                  >
                    {strings.get("App.workflowCategoryForm.cancel")}
                  </button>
                </footer>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

WorkflowCategoryForm.propTypes = {
  exceptions: PropTypes.object.isRequired,
  saveItem: PropTypes.func.isRequired,
};

export default WorkflowCategoryForm;
