class SettingsService {
  keys = {
    EMAIL_SUBJECT: "download_email_subject",
    EMAIL_MESSAGE: "download_email_message",
    SHARE_EMAIL_SUBJECT: "share_email_subject",
    SHARE_EMAIL_MESSAGE: "share_email_message",
    SHARE_DRAFT_INSTRUCTION_MESSAGE: "share_draft_instruction_message",
    SHARE_CONCEPT_EMAIL_SUBJECT: "share_concept_email_subject",
    SHARE_CONCEPT_EMAIL_MESSAGE: "share_concept_email_message",
    SMTP_EMAIL_HOST: "smtp_email_host",
    SMTP_EMAIL_PORT: "smtp_email_port",
    SMTP_EMAIL_USERNAME: "smtp_email_username",
    SMTP_EMAIL_PASSWORD: "smtp_email_password",
    SMTP_EMAIL_FROM_ADDRESS: "smtp_email_from_address",
    SEARCH_PLACEHOLDER: "search_placeholder",
    DENY_MESSAGE: "deny_message",
    CONTACT_PHONE: "contact_phone",
    CONTACT_FAX: "contact_fax",
    CONTACT_EMAIL: "contact_email",
    CONTACT_ADDRESS: "contact_address",
    CONTACT_SITE_NAME: "contact_site_name",
    SITE_META_DESCRIPTION: "site_meta_description",
    SITE_META_KEYWORDS: "site_meta_keywords",
    FACEBOOK_LINK:"facebook_link",
    INSAGRAM_LINK:"instagram_link",
    LINKEDIN_LINK:"linkedin_link",
    MAINTENANCE_MODE: "maintenance_mode",
    MAINTENANCE_HEADING: "maintenance_heading",
    MAINTENANCE_DESC: "maintenance_desc",
    MAINTENANCE_IMG: "maintenance_img",
    MAINTENANCE_COOKIE_MSG: "maintenance_cookie_msg",
    GOOGLE_ANALYTICS_ID: "google_analytics_id",
    GOOGLE_ANALYTICS_STATUS: "google_analytics_status",
    GLOSSARY_TIME_OUT: "glossary_time_out",
    DOWNLOAD_REMINDER_EMAIL_SUBJECT: "download_reminder_email_subject",
    DOWNLOAD_REMINDER_EMAIL_MESSAGE: "download_reminder_email_message",
    NEW_USER_WELCOME_EMAIL_SUBJECT: "new_user_welcome_email_subject",
    NEW_USER_WELCOME_EMAIL_MESSAGE: "new_user_welcome_email_message",
    NEW_USER_WELCOME_ADMIN_EMAIL_SUBJECT:
      "new_user_welcome_admin_email_subject",
    NEW_USER_WELCOME_ADMIN_EMAIL_MESSAGE:
      "new_user_welcome_admin_email_message",
    DRAFT_REMINDER_EMAIL_SUBJECT: "draft_reminder_email_subject",
    DRAFT_REMINDER_EMAIL_MESSAGE: "draft_reminder_email_message",
    HEADER_LOGO: "header_logo",
    FOOTER_LOGO: "footer_logo",
    BANNER_IMG: "banner_img",
    BOTTOM_BANNER_IMG: "bottom_banner_img",
    MILESTONE_REMINDER_EXPIRY_EMAIL_SUBJECT:
      "milestone_reminder_expiry_email_subject",
    MILESTONE_REMINDER_EXPIRY_EMAIL_MESSAGE:
      "milestone_reminder_expiry_email_message",
    CLIENT_TITLE_FAVICON: "client_title_favicon",
    ADMIN_TITLE_FAVICON: "admin_title_favicon",
    CODE_BLOCK_CONTENT_NOTES: "code_block_content_notes",
    TEMPLATE_COMMON_NOTES: "template_common_notes",
    EMAIL_ADDRESS_VERIFICATION_EMAIL_SUBJECT:
      "email_address_verification_email_subject",
    EMAIL_ADDRESS_VERIFICATION_EMAIL_MESSAGE:
      "email_address_verification_email_message",
    OTP_EMAIL_SUBJECT:
      "otp_email_subject",
    OTP_EMAIL_MESSAGE:
      "otp_email_message",
      FORGOTPASSWORD_EMAIL_MESSAGE:
      "forgotpassword_email_message",
      FORGOTPASSWORD_EMAIL_SUBJECT:
      "forgotpassword_email_subject",
      FACEBOOK_KEY: "facebook_key",
      LINKEDIN_KEY: "linkedin_key",
      LINKEDIN_SECRET: "linkedin_secret"

  };
}

export default new SettingsService();
