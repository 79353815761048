import React, { useEffect } from "react";

import PropTypes from "prop-types";
import strings from "../../../services/strings";
import _ from "lodash";
function DraftTypeFilter(props) {
  useEffect(() => {
    return () => {
      props.setDraftsType();
    };
  }, []);

  // return true means draft, and false means roles
  const getDraftsType = () => {
    if (props.filters && props.filters.type && props.filters.type === "roles") {
      return false;
    }
    return true;
  };

  const handleDraftsTypeChange = async (ev) => {
    if (ev.target.value === "roles") {
      props.setDraftsType(ev.target.value);
    } else {
      props.setDraftsType("drafts");
    }
    await props.fetchItems(true);
  };

  const draftTypeOptions = [
    {
      value: "drafts",
      label: strings.get("Client.profilePage.drafts"),
    },
    {
      value: "roles",
      label: strings.get("Client.profilePage.roles"),
    },
  ];

  let categories = _.map(draftTypeOptions, (category, i) => (
      <option
        className={`depth-${i}`}
        value={category.value}
        key={category.i}
      >
        {`${category.label}`}
      </option>
    ));

  return (
    <div className="DraftTypeFilter col-sm-3 col-md-3 col-xs-6 top-margin-10">
        <select
          className="form-control cate-select"
          name="groups"
          value={getDraftsType() ? draftTypeOptions[0].value : draftTypeOptions[1].value}
          onChange={handleDraftsTypeChange}
        >
          {categories}
        </select>
      </div>
  );
}

DraftTypeFilter.propTypes = {
  filters: PropTypes.object.isRequired,
  setDraftsType: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
};

export default DraftTypeFilter;
