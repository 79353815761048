import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import thunk from "redux-thunk";
import Immutable from "seamless-immutable";

import App from "./App";
import LoginPage from "./containers/auth/LoginPage";

import cacheManager from "./services/cacheManager";

import rootReducer from "./store/reducers";
import { browserHistory } from "./helpers";
//for persist store : START
const loadState = Immutable(
  JSON.parse(
    !localStorage.getItem("state") ? "{}" : localStorage.getItem("state")
  )
);
const saveState = (state = {}) => {
  try {
    localStorage.setItem("state", JSON.stringify(state));
  } catch (error) {
    if (localStorage.getItem("state")) {
      localStorage.removeItem("state");
    }
    try {
      localStorage.setItem("state", JSON.stringify(state));
    } catch (error) {
      console.log(
        "Error occured related to storage. Please check for persist storage"
      );
    }
  }
};
const persistedState = loadState;
//for persist store : END

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  persistedState, //for persist store
  composeEnhancers(applyMiddleware(thunk))
);

//for persist store : START
store.subscribe(() => {
  saveState(store.getState());
});
//for persist store : END

cacheManager.setVersion("0.0.2");

ReactDOM.render(
  <Provider store={store}>
    <Router history={browserHistory}>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route path="/" component={App} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
