import React, { Component } from "react";
import _ from "lodash";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "./UserSettings.scss";
import "../Page.scss";

import Topbar from "../../components/Topbar";
import SubTopbar from "../../components/SubTopbar";
import UserSettingsTabs from "../../components/user/UserSettingsTabs";
import UserDraftList from "../../components/user/draft/UserDraftList";
import DraftTypeFilter from "../../components/user/draft/DraftTypeFilter";
import DraftCategoryFilter from "../../components/user/draft/DraftCategoryFilter";
import SearchBar from "../../components/user/draft/SearchBar";
import WorkflowFilter from "../../components/user/draft/WorkflowFilter";
import Pagination from "../../components/Pagination";

import * as draftsActions from "../../store/drafts/actions";
import * as draftsSelectors from "../../store/drafts/selectors";
import * as sidebarSelectors from "../../store/sidebar/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";
import * as categoriesSelectors from '../../store/categories/selectors'
import * as workflowSelectors from '../../store/workflows/selectors'
import * as workflowActions from '../../store/workflows/actions'
import * as settingsSelectors from '../../store/settings/selectors'

class UserDraftPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.setCurrentUserId(this.props.match.params.id);
    this.props.fetchAllDrafts();
    this.props.fetchUserWorkflows();
  }

  componentWillUnmount() {
    this.props.unsetCurrentUserId();
  }

  updateItemDraftName(draftId, data) {
    this.props.updateDraftName(draftId, data);
    _.delay(() => {
      this.props.fetchAllDrafts();
    }, 500);
  }

  clearReminderException() {
    this.props.clearExceptions();
  }

  updateItemReminder(draftId, data) {
    this.props.updateDraftReminder(draftId, data);
    _.delay(() => {
      this.props.fetchAllDrafts();
    }, 500);
  }

  render() {
    const {loader} = this.props
    return (
      <div className="UserDraftPage">
        <Topbar>
          <div className="title">
            <Link to="/users">{strings.get("App.userPages.title")}</Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to={`/users/${this.props.match.params.id}/profile`}>
                {strings.get("App.userPages.edit")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content user-settings-container">
          <SubTopbar>
            <UserSettingsTabs userId={this.props.match.params.id} />
          </SubTopbar>
          <div className="row">
              {!loader && 
                <div className="col-sm-12 col-md-12 col-xs-12 cate-filter1 draft-filters">
                  <DraftTypeFilter
                    filters={this.props.filters}
                    fetchItems={this.props.fetchAllDrafts}
                    setDraftsType={this.props.setDraftsType}
                  />
                  <DraftCategoryFilter
                    filters={this.props.filters}
                    categories={this.props.categories}
                    fetchItems={this.props.fetchAllDrafts}
                    setCategoryId={this.props.setCategoryId}
                  />
                  <SearchBar
                    placeholder={strings.get("Client.draftsPage.draftName")}
                    searchTerm={this.props.filters.searchTerm}
                    fetchItems={this.props.fetchAllDrafts}
                    setSearchTerm={this.props.setSearchTerm}
                  />
                  <WorkflowFilter
                    filters={this.props.filters}
                    categories={this.props.categories}
                    fetchItems={this.props.fetchAllDrafts}
                    setSavedWorkflowId={this.props.setSavedWorkflowId}
                    setFetchOnlyWorkflowDraftStatus={
                      this.props.setFetchOnlyWorkflowDraftStatus
                    }
                    currentUserWorkflows={
                      this.props.currentUserWorkflows
                        ? this.props.currentUserWorkflows
                        : []
                    }
                  />
                </div>
              }
            </div>
          <div className="user-setting-content">
            <UserDraftList
              items={this.props.drafts}
              updateItemDraftName={this.updateItemDraftName}
              siteURL={this.props.siteURL}
              currentItem={this.props.currentDraft}
              setCurrentItemId={this.props.setCurrentDraftId}
              unsetCurrentItemId={this.props.unsetCurrentDraftId}
              clearReminderException={this.clearReminderException}
              exceptions={this.props.exceptions}
              updateItemReminder={this.updateItemReminder}
            />
            <Pagination
              pagination={this.props.pagination}
              setCurrentPage={this.props.setCurrentPage}
              fetchItems={this.props.fetchAllDrafts}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    drafts: draftsSelectors.getItemsByPage(
      state,
      draftsSelectors.getPagination(state).currentPage
    ),
    categories: categoriesSelectors.getItems(state),
    filters: draftsSelectors.getFilters(state),
    pagination: draftsSelectors.getPagination(state),
    siteURL: sidebarSelectors.getSiteURL(state),
    exceptions: exceptionsSelectors.getItems(state),
    currentDraft: draftsSelectors.getCurrentItem(state),
    currentUserWorkflows: workflowSelectors.getUserWorkflows(state),
    loader: settingsSelectors.getScreenLoadingStatus(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
    setCurrentUserId: (id) => {
      dispatch(draftsActions.setCurrentUserId(id));
    },
    unsetCurrentUserId: (id) => {
      dispatch(draftsActions.unsetCurrentUserId());
    },
    fetchAllDrafts: (deleteCache) => {
      dispatch(draftsActions.fetchAllDrafts(deleteCache));
    },
    setCurrentPage: (page) => {
      dispatch(draftsActions.setCurrentPage(page));
    },
    setCurrentDraftId: (id) => {
      dispatch(draftsActions.setCurrentItemId(id));
    },
    unsetCurrentDraftId: () => {
      dispatch(draftsActions.unsetCurrentItemId());
    },
    updateDraftName: (id, data) => {
      dispatch(draftsActions.updateDraftName(id, data));
    },
    updateDraftReminder: (id, data) => {
      dispatch(draftsActions.updateItemReminder(id, data));
    },
    setDraftsType: (type = "drafts") => {
      dispatch(draftsActions.setDraftType(type));
    },
    setCategoryId: (id) => {
      dispatch(draftsActions.setCategoryId(id));
    },
    setSearchTerm: (searchTerm) => {
      dispatch(draftsActions.setSearchTerm(searchTerm));
    },    
    setFetchOnlyWorkflowDraftStatus: (status) => {
      dispatch(draftsActions.setFetchOnlyWorkflowDraftStatus(status));
    },
    fetchUserWorkflows: (deleteCache) => {
      dispatch(workflowActions.getUserWorkflows(deleteCache));
    },
    setSavedWorkflowId: (id) => {
      dispatch(draftsActions.setSavedWorkflowId(id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDraftPage);
