import api from "../../services/api";
import language from "../../services/language";
import _ from "lodash";

import * as exceptionsActions from "../exceptions/actions";
import * as workflowCategoriesSelectors from "./selectors";
import { browserHistory } from "../../helpers";

export const types = {
  FETCH_ALL_ITEMS_DONE: "workflowCategories.FETCH_ALL_ITEMS_DONE",
  FETCH_ITEMS_DONE: "workflowCategories.FETCH_ITEMS_DONE",
  FETCH_ITEM_DONE: "workflowCategories.FETCH_ITEM_DONE",
  SET_SEARCH_TERM: "workflowCategories.SET_SEARCH_TERM",
  SET_CURRENT_PAGE: "workflowCategories.SET_CURRENT_PAGE",
  SET_CURRENT_ITEM_ID: "workflowCategories.SET_CURRENT_ITEM_ID",
  TOGGLE_SORTER: "workflowCategories.TOGGLE_SORTER",
  CLEAR_CACHE: "workflowCategories.CLEAR_CACHE",
  SET_SORTER: "workflowCategories.SET_SORTER",
};

export function toggleSorter(pageLimit) {
  return {
    type: types.TOGGLE_SORTER,
    payload: {
      pageLimit,
    },
  };
}

export function setSorter(sorter) {
  return {
    type: types.SET_SORTER,
    payload: {
      sorter,
    },
  };
}

export function setCurrentPage(page) {
  return {
    type: types.SET_CURRENT_PAGE,
    payload: {
      page,
    },
  };
}

export function setCurrentItemId(id) {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id,
    },
  };
}

export function unsetCurrentItemId() {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id: null,
    },
  };
}

export function clearCache() {
  return {
    type: types.CLEAR_CACHE,
  };
}

export function fetchAllItems() {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("expand", "workflow");
      params.set("language_id", language.get());
      let items = await api.get(
        "/workflowCategories/buildWorkflowCategories",
        params
      );
      // dispatch(clearCache());
      dispatch({
        type: types.FETCH_ALL_ITEMS_DONE,
        payload: {
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItem(id) {
  return async (dispatch) => {
    try {
      // GET request from API
      let item = await api.get(`/workflowCategories/${id}`);
      dispatch({
        type: types.FETCH_ITEM_DONE,
        payload: {
          item,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItems(deleteCache = false) {
  return async (dispatch, getState) => {
    let state = getState();
    try {
      // Set additional params
      let params = new Map();
      params.set("language_id", language.get());
      let pagination = workflowCategoriesSelectors.getPagination(state);
      params.set("page_size", pagination.pageSize);
      params.set("page_number", deleteCache ? 1 : pagination.currentPage);
      let sorter = workflowCategoriesSelectors.getSorter(state);
      params.set("page_size", sorter.pageLimit);

      // GET request from API
      let [response, items] = await api.get(
        "/workflowCategories/getAppList",
        params,
        true
      );

      // Clear cache if deleteCache is enabled
      if (deleteCache) {
        dispatch(clearCache());
      }

      dispatch({
        type: types.FETCH_ITEMS_DONE,
        payload: {
          totalPages: parseInt(response.headers.get("X-Total-Pages")),
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItemAllPages(deleteCache = false) {
  return async (dispatch, getState) => {
    // let state = getState();
    try {
      // Set additional params
      let params = new Map();
      params.set("language_id", language.get());
      let [response, items] = await api.get(
        "/workflowCategories/getAllPagesWorkflowCategories",
        params,
        true
      );
      // Clear cache if deleteCache is enabled
      if (deleteCache) {
        dispatch(clearCache());
      }

      dispatch({
        type: types.FETCH_ITEMS_DONE,
        payload: {
          totalPages: parseInt(response.headers.get("X-Total-Pages")),
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function createItem(data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      params.set("language_id", language.get());
      await api.post(`/workflowCategories`, params);
      browserHistory.push("/workflows/categories");

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateItem(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // PUT request to API
      params.set("language_id", language.get());
      await api.put(`/workflowCategories/${id}`, params);
      dispatch(fetchItems());
      browserHistory.push(`/workflows/categories`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function uploadItemLogo(id, file) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("file", file);
      params.set("column", "background_image");
      // POST request to API
      params.set("language_id", language.get());
      await api.postFiles(`/workflowCategories/${id}/image`, params);

      dispatch(fetchItem(id));
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function createItemWithLogo(data, file) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      params.set("language_id", language.get());
      let item = await api.post("/workflowCategories", params);
      browserHistory.push(`/workflows/categories`);

      params = new Map();
      params.set("file", file);
      params.set("column", "background_image");
      // POST request to API for Upload
      await api.postFiles(`/workflowCategories/${item.id}/image`, params);

      dispatch(fetchItem(item.id));
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function deleteItem(id, handleCatDeleteResponse) {
  return async (dispatch) => {
    try {
      await api.delete(`/workflowCategories/${id}`).then((response) => {
        handleCatDeleteResponse(response);
        if (response === "success") {
          dispatch(fetchItems());
          dispatch(exceptionsActions.clear());
        }
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function orderItems(items) {
  return async (dispatch) => {
    try {
      let params = new Map();
      let parent_id = items.source.parent_id;
      if (parent_id === null && typeof parent_id === "object") {
        parent_id = "";
      }
      params.set("id", items.source.id);
      params.set("to", items.target.id);
      params.set("direction", items.direction);
      params.set("parent_id", parent_id);
      // PUT request to API
      await api.put("/workflowCategories/order", params);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}
