import React, { Component } from 'react';
import autoBind from 'react-autobind';
import strings from '../../../services/strings';

import _ from 'lodash';
import { Link } from 'react-router-dom';
import './TranslationList.scss';

// import Switch from '../../Switch.js';

let eFrontStr = {};
let eBackStr = {};
let cFrontStr = {};
let cBackStr = {};

class TranslationView extends Component {

    state = {
        currentItemLoaded: false,
        form: {
          name : "",
        },
        lang : "",
        secreenLoaded : false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount = () => {
      // let string = strings
      //   .getAllStringsJson()
      //   .then( data =>
      //     this.setState( { allStrings: data }, () => { this.fetchUniversalStrings() } ) )
      //   .catch( error => console.log(error));
    }

    fetchUniversalStrings = () =>{
      // console.log("All Lang String", this.state.allStrings);

      if(this.state.allStrings){
        let englishStrings = this.state.allStrings["GB"];
        let frontEndEnglishStrings = englishStrings["Client"];
        let backEndEnglishStrings = englishStrings["App"];

        eFrontStr  = this.flattenObject(frontEndEnglishStrings);
        eBackStr  = this.flattenObject(backEndEnglishStrings);
      }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) =>{
      // console.log('nextProps', nextProps);
      // if(nextProps !== this.props && nextProps && nextProps.currentItem && nextProps.currentItem.iso2){
      //   let {allStrings} = this.state;
      //   let temp_lang = nextProps.currentItem.iso2;
      //   this.setState({
      //     lang : temp_lang
      //   }, ()=>{
      //     allStrings[nextProps.currentItem.iso2] ? null : this.addLang(allStrings, temp_lang)
      //   });

      //   let currentLangStrings = allStrings[nextProps.currentItem.iso2] ? this.state.allStrings[nextProps.currentItem.iso2] : this.state.allStrings["DEFAULT"] ;
      //   let frontC_Strings = currentLangStrings["Client"];
      //   let backC_Strings = currentLangStrings["App"];

      //   cFrontStr  = this.flattenObject(frontC_Strings);
      //   cBackStr  = this.flattenObject(backC_Strings);

      //   this.checkSecreenLoading();
      // }
    }

    checkSecreenLoading= () =>{
        this.setState({
          secreenLoaded : true
        });
      }

    flattenObject (ob) {
      var toReturn = {};
      for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) === 'object') {
          var flatObject = this.flattenObject(ob[i]);
          for (var x in flatObject) {
            if (!flatObject.hasOwnProperty(x)) continue;

            toReturn[i + '.' + x] = flatObject[x];
          }
        } else {
          toReturn[i] = ob[i];
        }
      }
      return toReturn;
    };

    // unflattenObject(data) {
    //   var result = {}
    //   for (var i in data) {
    //     var keys = i.split('.')
    //     keys.reduce(function(r, e, j) {
    //       return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? data[i] : {}) : [])
    //     }, result)
    //   }
    //   return result
    // }


    componentDidMount() {
        // this.tryLoadCurrentItem();
    }

    componentDidUpdate(prevProps, prevState) {
      // if(prevProps.currentItem && this.props.currentItem){
      //   this.tryLoadCurrentItem();
      // }
    }

    tryLoadCurrentItem() {
        if (this.props.currentItem && !this.state.currentItemLoaded) {
            let form = _.extend({}, this.state.form);
            _.map(this.state.form, (value, key) => {
                form[key] = (this.props.currentItem[key]) ? this.props.currentItem[key] : this.state.form[key];
            });

            this.setState({
                currentItemLoaded: true,
                form
            });
        }
    }

    addLang = (allStrings , lang) => {
      // let default_obj = allStrings["DEFAULT"];
      // allStrings[lang] = default_obj;
      // this.setState({
      //   allStrings
      // }, ()=>{
      //   strings.setAllStringsJson(this.state.allStrings, this.state.lang + "-New");
      // });

    }

    handleChange = (event) =>{
      // if(event.target.value.trim()){
      //   console.log('pure string', event.target.getAttribute("data-name"));
        if(event.target.getAttribute("data-name") === "Client"){
          cFrontStr[event.target.name] = event.target.value.trim();
        }
        if(event.target.getAttribute("data-name") === "App"){
          cBackStr[event.target.name] = event.target.value.trim();
        }
      // }
      // else{
      // console.log("Error");
      // }
    }

    handleSave = (event) => {
      // let {allStrings, lang} = this.state;
      // if(event.target.name === "front"){
      //   let unflattenResult = this.unflattenObject(cFrontStr);
      //   allStrings[lang]["Client"] = unflattenResult;
      //   // let data = strings.setAllStringsJson(allStrings, this.state.lang + "-Client");

      //   // data.then(
      //   //   (resolve) => {
      //   //   this.showMsg("successfront");
      //   //   // console.log("Success in Response", resolve);
      //   //   },
      //   //   (error) => {
      //   //   console.log("Error in Response", error);
      //   //   }
      //   // );

      // }
      // if(event.target.name === "back"){
      //   let unflattenResult = this.unflattenObject(cBackStr);
      //   allStrings[lang]["App"] = unflattenResult;
      //   // let data = strings.setAllStringsJson(allStrings, this.state.lang + "-App");
      //   // data.then(
      //   //   (resolve) => {
      //   //     this.showMsg("successback");
      //   //     // console.log("Success in Response", resolve);
      //   //   },
      //   //   (error) => {
      //   //     console.log("Error in Response", error);
      //   //   }
      //   // );
      // }
    }

    saveButton = (type) => (
      <div className="text-right"> <button className="btn btn-success" style={{marginBottom : '10px', backgroundColor : '#4caf50 !important'}} onClick={this.handleSave} name={type} >Save</button> </div>
    )

    showMsg = (string) => {
      const msg = document.getElementById(string);
      msg.style.display = 'block';
      setTimeout(() => {
        msg.style.display = 'none';
      }, 3000);
    }

    render() {
      let {secreenLoaded} = this.state;
      const frontValues = Object.keys(cFrontStr).map((key, value)=>{
        return <tr style={{fontSize : '12px'}} key={key}>
                <td style={{width : "45%"}}>
                  {eFrontStr[key]}
                </td>
                <td style={{width : "55%"}}>
                  <textarea className="form-control" defaultValue = {cFrontStr[key]} name={key} onChange={this.handleChange} data-name="Client" style={{width : "100%", borderRadius : "5px", resize : "none"}}/>
                </td>
              </tr>
      })

      const backValues = Object.keys(cBackStr).map((key, value)=>{
        return <tr style={{fontSize : '12px'}} key={key}>
                <td style={{width : "45%"}}>
                  {eBackStr[key]}
                </td>
                <td style={{width : "55%"}}>
                  <textarea className="form-control" defaultValue = {cBackStr[key]} name={key} onChange={this.handleChange} data-name="App" style={{width : "100%", borderRadius : "5px", resize : "none"}}/>
                </td>
              </tr>
      })

        return (
            <span className="TranslationView">
              <div className="row">
                <div className="col-sm-8">
                  <h3>{this.state.form.name}</h3>
                </div>
                <div className="col-sm-4">
                  <Link to="/settings/translations" className="btn btn-default" style={{float:'right'}}>{strings.get('App.settings.translations.back')}</Link>
                </div>
                <div className="col-sm-6">
                  <div className="panel panel-default">
                    <div className="panel-body">
                      <div className="text-center">
                      {strings.get('App.settings.translations.frontEnd')}
                      </div>
                    </div>
                  </div>
                  <div className="alert alert-success" role="alert" id="successFront" style={{display : 'none'}}>
                  {strings.get('App.settings.translations.dataUpdateMsg')}
                  </div>
                  {  secreenLoaded ? this.saveButton("front") : null }

                  {  secreenLoaded ?
                        <table className="table">
                          <thead>
                            <tr>
                              <th>GB</th><th>{this.state.lang}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {frontValues}
                          </tbody>
                        </table>
                    : null  }

                  { secreenLoaded ? this.saveButton("front") : null }

                </div>

                <div className="col-sm-6">
                  <div className="panel panel-default">
                    <div className="panel-body">
                      <div className="text-center">
                      {strings.get('App.settings.translations.backEnd')}
                      </div>
                    </div>
                  </div>
                  <div className="alert alert-success" role="alert" id="successBack" style={{display : 'none'}}>
                  {strings.get('App.settings.translations.dataUpdateMsg')}
                  </div>

                  { secreenLoaded ? this.saveButton("back") : null }

                  { secreenLoaded ?
                      <table className="table">
                        <thead>
                          <tr>
                            <th>GB</th><th>{this.state.lang}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {backValues}
                        </tbody>
                      </table>
                    : null  }

                  { secreenLoaded ? this.saveButton("back") : null }

                </div>

              </div>
            </span>
        );
    }
}

export default TranslationView;
