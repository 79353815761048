
import api from '../../services/api';
import _ from 'lodash';

import * as exceptionsActions from '../exceptions/actions';
import * as userAlertsSelector from './selectors';

export const types = {
	FETCH_ITEMS_DONE: 'userAlerts.FETCH_ITEMS_DONE',
	FETCH_ALL_ITEMS_DONE: 'userAlerts.FETCH_ALL_ITEMS_DONE',
	SET_SEARCH_TERM: 'userAlerts.SET_SEARCH_TERM',
	SET_CURRENT_PAGE: 'userAlerts.SET_CURRENT_PAGE',
  SET_SORTER: 'userAlerts.SET_SORTER',
	CLEAR_CACHE: 'userDocuments.CLEAR_CACHE',
  SET_CATEGORY_ID: 'userDocuments.SET_CATEGORY_ID',
  SET_CURRENT_ITEM_ID: 'userAlerts.SET_CURRENT_ITEM_ID',
  SET_USER_ID: 'userAlerts.SET_USER_ID'
}

export function setCurrentPage(page) {
	return {
		type: types.SET_CURRENT_PAGE,
		payload: {
			page
		}
	}
}

export function setSorter(sorter) {
    return {
        type: types.SET_SORTER,
        payload: {
            sorter
        }
    }
}
export function setSearchTerm(searchTerm) {
	return {
		type: types.SET_SEARCH_TERM,
		payload: {
			searchTerm
		}
	}
}

export function setCategoryId(id) {
    return {
        type: types.SET_CATEGORY_ID,
        payload: {
            id
        }
    }
}

export function setUserId(id) {
  return {
      type: types.SET_USER_ID,
      payload: {
          id
      }
  }
}

export function fetchItems(deleteCache = false) {
	return async (dispatch, getState) => {
		let state = getState();
		try {
			// Set additional params
			let params = new Map();
			let filters = userAlertsSelector.getFilters(state);
			let sorter = userAlertsSelector.getSorter(state);
      let pagination = userAlertsSelector.getPagination(state);
      let currentUserId = userAlertsSelector.getUserId(state);
			params.set('expand', 'document,user,reminder');
			params.set('category_id',filters.categoryId);
			params.set('dname', filters.searchTerm);
			params.set('page_size', pagination.pageSize);
			params.set('page_number', deleteCache ? 1 : pagination.currentPage);
			params.set('sort_by', sorter.column);
			params.set('sort_desc', sorter.descending);
      params.set('key', 'sorter');
      params.set('user_id',currentUserId);
			// GET request from API
			let [response, items] = await api.get('/userAlerts', params, true);

			// Clear cache if deleteCache is enabled
			if (deleteCache) {
				dispatch(clearCache());
			}

			dispatch({
				type: types.FETCH_ITEMS_DONE,
				payload: {
					totalPages: parseInt(response.headers.get('X-Total-Pages')),
					items
				}
			});
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function clearCache() {
	return {
		type: types.CLEAR_CACHE
	}
}
