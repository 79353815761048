import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import { Link } from "react-router-dom";
import "../../Page.scss";

import * as genderStringsActions from "../../../store/genderStrings/actions";
import * as genderStringsSelectors from "../../../store/genderStrings/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";

import Topbar from "../../../components/Topbar";
import SubTopbar from "../../../components/SubTopbar";
import GrammarTabs from "../../../components/grammar/GrammarTabs";
import GenderStringForm from "../../../components/grammar/genderString/GenderStringForm";

class GenderStringEditPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchGenderString(this.props.match.params.id);
    this.props.setCurrentGenderStringId(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.unsetCurrentGenderStringId();
    this.props.clearExceptions();
  }

  saveGenderString(data) {
    this.props.updateGenderString(this.props.match.params.id, data.form);
  }

  render() {
    return (
      <div className="GenderStringEditPage">
        <Topbar>
          <div className="title">
            <Link to="/grammar/gender-strings">
              {strings.get("App.genderStringPages.title")}
            </Link>
          </div>
        </Topbar>
        <SubTopbar>
          <GrammarTabs />
        </SubTopbar>

        <div className="content">
          <GenderStringForm
            exceptions={this.props.exceptions}
            currentItem={this.props.currentGenderString}
            saveItem={this.saveGenderString}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentGenderString: genderStringsSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchGenderString: (id) => {
      dispatch(genderStringsActions.fetchItem(id));
    },
    setCurrentGenderStringId: (id) => {
      dispatch(genderStringsActions.setCurrentItemId(id));
    },
    unsetCurrentGenderStringId: () => {
      dispatch(genderStringsActions.unsetCurrentItemId());
    },
    updateGenderString: (id, data) => {
      dispatch(genderStringsActions.updateItem(id, data));
    },
    createGenderString: (data) => {
      dispatch(genderStringsActions.createItem(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenderStringEditPage);
