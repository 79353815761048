import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import { Link } from "react-router-dom";
import "../settings.scss";
import "../../Page.scss";

import * as ipAddressesActions from "../../../store/ipAddresses/actions";
import * as ipAddressesSelectors from "../../../store/ipAddresses/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";

import Topbar from "../../../components/Topbar";
import SubTopbar from "../../../components/SubTopbar";
// import SearchBar from "../../../components/SearchBar";
import SettingsTabs from "../../../components/settings/SettingsTabs";
import SmtpSettingsLeftTabs from "../../../components/settings/SmtpSettingsLeftTabs";
import IpAddressesList from "../../../components/settings/ipAddresses/IpAddressesList";
import Pagination from "../../../components/Pagination";

class IpAddressesListPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchIpAddresses();
  }

  componentWillUnmount() {
    this.props.unsetCurrentIpAddressId();
    this.props.clearExceptions();
  }

  render() {
    return (
      <div className="IpAddressesListPage">
        <Topbar>
          <div className="title">
            <Link to="/settings/ipAddresses">
              {strings.get("App.settings.title")}
            </Link>
          </div>
        </Topbar>

        <div className="content settings-container">
          <SubTopbar>
            <SettingsTabs />
          </SubTopbar>
          <div className="setting-content row">
            <SmtpSettingsLeftTabs currentItemId={1} />
            <div className="col-xs-12 col-sm-12 col-md-9">
              <div className="clearfix">
                <Link
                  to="/settings/ipAddresses/add"
                  className="btn btn-primary"
                  style={{ float: "right" }}
                >
                  {strings.get("App.ipAddressForm.addIPAddress")}
                </Link>
              </div>
              <IpAddressesList
                items={this.props.ipAddresses}
                currentItem={this.props.currentIpAddress}
                fetchItems={this.props.fetchIpAddresses}
                setCurrentItemId={this.props.setCurrentIpAddressId}
                unsetCurrentItemId={this.props.unsetCurrentIpAddressId}
                deleteItem={this.props.deleteIpAddress}
                updateItem={this.props.updateIpAddress}
              />

              <Pagination
                pagination={this.props.pagination}
                setCurrentPage={this.props.setCurrentPage}
                fetchItems={this.props.fetchIpAddresses}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sorter: ipAddressesSelectors.getSorter(state),
    ipAddresses: ipAddressesSelectors.getItemsByPage(
      state,
      ipAddressesSelectors.getPagination(state).currentPage
    ),
    pagination: ipAddressesSelectors.getPagination(state),
    currentIpAddress: ipAddressesSelectors.getCurrentItem(state),exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchIpAddresses: (deleteCache) => {
      dispatch(ipAddressesActions.fetchItems(deleteCache));
    },
    setCurrentPage: (page) => {
      dispatch(ipAddressesActions.setCurrentPage(page));
    },
    setCurrentIpAddressId: (id) => {
      dispatch(ipAddressesActions.setCurrentItemId(id));
    },
    unsetCurrentIpAddressId: () => {
      dispatch(ipAddressesActions.unsetCurrentItemId());
    },
    deleteIpAddress: (id) => {
      dispatch(ipAddressesActions.deleteItem(id));
    },
    updateIpAddress: (id, data) => {
      dispatch(ipAddressesActions.updateItem(id, data));
    },
    setSorter: (sorter) => {
      dispatch(ipAddressesActions.setSorter(sorter));
    },clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IpAddressesListPage);
