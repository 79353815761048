import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "./UserSettings.scss";
import "../Page.scss";

import Topbar from "../../components/Topbar";
import SubTopbar from "../../components/SubTopbar";
import UserSettingsTabs from "../../components/user/UserSettingsTabs";
import NavigationLeftTabs from "./NavigationLeftTabs";
import DocumentCategoryFilter from "../../components/document/DocumentCategoryFilter";
import SearchBar from "../../components/SearchBar";
import Pagination from "../../components/Pagination";
import AlertList from "../../components/user/alerts/AlertList";

import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as userAlertsActions from "../../store/userAlerts/actions";
import * as userAlertsSelectors from "../../store/userAlerts/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

class UserAlertPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.setUserId(this.props.match.params.id);
  }

  componentDidMount() {
    this.props.fetchUserAlerts();
    this.props.fetchAllCategories();
  }

  saveUserSettings(data) {
    // console.log('*',data.form)
    this.props.updateUser(this.props.match.params.id, data.form);
  }

  render() {
    return (
      <div className="UserDownloadPage">
        <Topbar>
          <div className="title">
            <Link to="/users">{strings.get("App.userPages.title")}</Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to={`/users/${this.props.match.params.id}/profile`}>
                {strings.get("App.userPages.edit")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content user-settings-container">
          <SubTopbar>
            <UserSettingsTabs userId={this.props.match.params.id} />
          </SubTopbar>
          <div className="user-setting-content">
            <div className="container-row">
              <div className="row">
                <NavigationLeftTabs
                  currentItemId={2}
                  currentLang={this.props.currentLang}
                  currentUserId={this.props.match.params.id}
                />

                <div className="col-xs-12 col-sm-9 col-md-9 inner-page-cont AlertList">
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h4>
                        {strings.get("Client.settings.messages.alerts.title")}
                      </h4>
                    </div>
                    <div className="panel-body">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12">
                          <DocumentCategoryFilter
                            filters={this.props.filters}
                            categories={this.props.categories}
                            fetchItems={this.props.fetchUserAlerts}
                            setCategoryId={this.props.setCategoryId}
                          />
                          <SearchBar
                            placeholder={strings.get(
                              "Client.downloadsPage.documentName"
                            )}
                            searchTerm={
                              this.props.filters
                                ? this.props.filters.searchTerm
                                : ""
                            }
                            fetchItems={this.props.fetchUserAlerts}
                            setSearchTerm={this.props.setSearchTerm}
                          />
                        </div>
                      </div>
                      <AlertList
                        items={this.props.userAlerts}
                        sorter={this.props.sorter}
                      />
                      <Pagination
                        pagination={this.props.pagination}
                        setCurrentPage={this.props.setCurrentPage}
                        fetchItems={this.props.fetchUserAlerts}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: categoriesSelectors.getItems(state),
    userAlerts: userAlertsSelectors.getItemsByPage(
      state,
      userAlertsSelectors.getPagination(state).currentPage
    ),
    sorter: userAlertsSelectors.getSorter(state),
    filters: userAlertsSelectors.getFilters(state),
    pagination: userAlertsSelectors.getPagination(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
    fetchAllCategories: () => {
      dispatch(categoriesActions.fetchAllItems());
    },
    setSorter: (sorter) => {
      dispatch(userAlertsActions.setSorter(sorter));
    },
    setCurrentPage: (page) => {
      dispatch(userAlertsActions.setCurrentPage(page));
    },
    setSearchTerm: (searchTerm) => {
      dispatch(userAlertsActions.setSearchTerm(searchTerm));
    },
    fetchUserAlerts: (deleteCache) => {
      dispatch(userAlertsActions.fetchItems(deleteCache));
    },
    setCategoryId: (id) => {
      dispatch(userAlertsActions.setCategoryId(id));
    },
    setUserId: (id) => {
      dispatch(userAlertsActions.setUserId(id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAlertPage);
