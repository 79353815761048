import React, { Component } from "react";
import PropTypes from "prop-types";
import strings from "../services/strings";

import { connect } from "react-redux";

import * as settingsActions from "../store/settings/actions";
import * as settingsSelectors from "../store/settings/selectors";

import "./Topbar.scss";

import LanguageSelector from "../containers/LanguageSelector";
import { viewModals } from "../helpers";
import settings from "../services/settings";
import CustomDropModal from "./common/Popup";

class Topbar extends Component {
  hideTemplateCommonNotesModal = () => {
    this.refs.TEMPLATE_COMMON_NOTES.hide();
  };

  showTemplateCommonNotesModal = () => {
    this.refs.TEMPLATE_COMMON_NOTES.show();
  };

  getTemplateCommonNotesModal = () => {
    return (
      <CustomDropModal
        className="boron-modal template-common-notes"
        // style={{textAlign:'center'}}
        ref={viewModals.TEMPLATE_COMMON_NOTES}
      >
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.hideTemplateCommonNotesModal}
          />
        </div>
        <h2>
          {strings.get(
            "App.settings.templateNotes.templateCommonNotesModal.title"
          )}
        </h2>
        <div
          className="scrollable"
          dangerouslySetInnerHTML={{
            __html:
              this.props.settings &&
              this.props.settings[settings.keys.TEMPLATE_COMMON_NOTES]
                ? this.props.settings[settings.keys.TEMPLATE_COMMON_NOTES].value
                : "",
          }}
        />
      </CustomDropModal>
    );
  };

  render() {
    return (
      <nav className="Topbar navbar navbar-default">
        {this.getTemplateCommonNotesModal()}
        <div className="container-fluid">
          <LanguageSelector />
          {this.props.children}
          {this.props.templateGuide ? (
            <div className="main-btns">
              <button
                className="btn btn-default btn-color-change"
                onClick={this.showTemplateCommonNotesModal}
              >
                Template Guide
              </button>
            </div>
          ) : null}
        </div>
      </nav>
    );
  }
}

Topbar.propTypes = {
  templateGuide: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    settings: settingsSelectors.getItems(state),
    currentModal: settingsSelectors.getCurrentModal(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentModal: (modal) => {
      dispatch(settingsActions.setCurrentModal(modal));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
