import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import strings from "../../services/strings";
import language from "../../services/language";
import _ from "lodash";

import "./WorkflowSorter.scss";

class WorkflowSorter extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  getSorter() {
    if (this.props.sorter) {
      return this.props.sorter.column;
    }

    return "";
  }

  handleChange(e) {
    let sorter = {
      column: e.target.value,
      descending: true,
      pageLimit: this.getSorterPageSize(),
    };

    if (sorter.column === "name") {
      sorter.descending = false;
    }

    this.props.setSorter(sorter);
    this.props.fetchItems(true);
  }

  getSorterPageSize() {
    if (this.props.sorter) {
      return this.props.sorter.pageLimit;
    }

    return "";
  }

  handleChangePageSize(e) {
    let sorter = {
      pageLimit: e.target.value,
    };

    this.props.setSorter(sorter);
    this.props.fetchItems(true);
  }

  handleOrder = () => {
    let sorter = {
      descending: !this.props.sorter.descending
    }
    this.props.setSorter(sorter);
    this.props.fetchItems(true);
}

  render() {
    return (
      <div className="WorkflowSorter">
        <label>{strings.get("App.workflows.sorter.title")}</label>
        <select
          className="form-control"
          name="sorter"
          value={this.getSorter()}
          onChange={this.handleChange}
        >
          <option value="name">
            {strings.get("App.workflows.sorter.name")}
          </option>
           <option value="created_at">
            {strings.get("App.workflows.sorter.newest")}
          </option>
          <option value="validDoc">
            {strings.get("App.workflows.sorter.brokenDocs")}
          </option>
          <option value="published">
            {strings.get("App.workflows.sorter.published")}
          </option>
          <option value="depublished">
            {strings.get("App.workflows.sorter.depublished")}
          </option>
          <option value="paid">
            {strings.get("App.workflows.sorter.paid")}
          </option>
          <option value="free">
            {strings.get("App.workflows.sorter.free")}
          </option>
        </select>

        <span className="ml-5" style={{color:'#000000'}} onClick={this.handleOrder}>
          {this.props.sorter.descending ?
            (<i title="Set to Ascending Direction" className="fa fa-arrow-down"></i>) 
              : 
            (<i title="Set to Descending Direction" className="fa fa-arrow-up"></i>)
          }
        </span>

        <select
          className="form-control"
          name="sorter"
          value={this.getSorterPageSize()}
          onChange={this.handleChangePageSize}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
    );
  }
}

WorkflowSorter.propTypes = {
  sorter: PropTypes.object,
  setSorter: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
};

export default WorkflowSorter;
