import React, { Component } from "react";
import _ from "lodash";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as stepsActions from "../../store/steps/actions";
import * as stepsSelectors from "../../store/steps/selectors";
import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
// import SubTopbar from "../../components/SubTopbar";
import StepForm from "../../components/step/StepForm";

class StepEditPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchStep(this.props.match.params.id);
    this.props.setCurrentStepId(this.props.match.params.id);
    if (!_.size(this.props.categories)) {
      this.props.fetchAllCategories();
    }
  }

  componentWillUnmount() {
    this.props.unsetCurrentStepId();
    this.props.clearExceptions();
  }

  saveStep(data) {
    this.props.updateStep(this.props.match.params.id, data.form);
  }

  render() {
    return (
      <div className="StepEditPage">
        <Topbar>
          <div className="title">
            <Link to="/glossaries">
              {strings.get("App.stepPages.glossaries")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/glossaries/steps">
                {strings.get("App.stepPages.title")}
              </Link>
              <span className="divider">/</span>
              <Link to={`/glossaries/steps/${this.props.match.params.id}`}>
                {strings.get("App.stepPages.edit")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content">
          <StepForm
            exceptions={this.props.exceptions}
            currentItem={this.props.currentStep}
            saveItem={this.saveStep}
            categories={this.props.categories}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentStep: stepsSelectors.getCurrentItem(state),
    categories: categoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchStep: (id) => {
      dispatch(stepsActions.fetchItem(id));
    },
    setCurrentStepId: (id) => {
      dispatch(stepsActions.setCurrentItemId(id));
    },
    unsetCurrentStepId: () => {
      dispatch(stepsActions.unsetCurrentItemId());
    },
    updateStep: (id, data) => {
      dispatch(stepsActions.updateItem(id, data));
    },
    fetchAllCategories: (deleteCache) => {
      dispatch(categoriesActions.fetchAllItems(deleteCache));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepEditPage);
