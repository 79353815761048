import React, { Component } from "react";
import autoBind from "react-autobind";
import ReactLoading from "react-loading";
import './LoadingScreen.scss'
class LoadingScreen extends Component {
  state = {
    enabled: true,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return (
      <div className="LoadingScreen">
        <ReactLoading
          className="react-loading"
          type="spinningBubbles"
          color="#fff"
        />
      </div>
    );
  }
}

export default LoadingScreen;
