import api from "../../services/api";
import language from "../../services/language";
import _ from "lodash";

import * as exceptionsActions from "../exceptions/actions";

export const types = {
  FETCH_ALL_ITEMS_DONE: "settings.FETCH_ALL_ITEMS_DONE",
  CLEAR_CACHE: "settings.CLEAR_CACHE",
  CLEAR_EXTRA_SETTINGS: "settings.CLEAR_EXTRA_SETTINGS",
  SET_LOADING_SCREEN: "settings.SET_LOADING_SCREEN",
  SET_CURRENT_MODAL: "settings.SET_CURRENT_MODAL",
  HANDLE_LANGUAGE_CHANGE: "settings.HANDLE_LANGUAGE_CHANGE",
};

export function clearCache() {
  return {
    type: types.CLEAR_CACHE,
  };
}

export function clearExtraSettings() {
  return {
    type: types.CLEAR_EXTRA_SETTINGS,
  };
}

export function handleLanguageChange() {
  return {
    type: types.HANDLE_LANGUAGE_CHANGE,
  };
}
var timer;
export function setLoadingScreen(status = false) {
  return  (dispatch, getState) => {
    if (timer) clearTimeout(timer)

    if(status)
      dispatch({
        type: types.SET_LOADING_SCREEN,
        payload: {
          status,
        },
      });
    timer = setTimeout(() => {
        dispatch( {
          type: types.SET_LOADING_SCREEN,
          payload: {
            status,
          },
        });
      },1500)
  }
}

export function setCurrentModal(currentModal = "") {
  return {
    type: types.SET_CURRENT_MODAL,
    payload: {
      currentModal,
    },
  };
}

export function fetchAllItems() {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingScreen(true));
      let params = new Map();
      params.set("language_id", language.get());

      let items = await api.get("/settings", params);
      dispatch(clearCache());
      dispatch({
        type: types.FETCH_ALL_ITEMS_DONE,
        payload: {
          items,
        },
      });
      dispatch(setLoadingScreen());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
      dispatch(setLoadingScreen());
    }
  };
}

export function updateItem(data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("value", data.value);
      params.set("language_id", language.get());
      // PUT request to API

      await api.put(`/settings/${data.id}`, params);

      fetchAllItems();
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateAnalytics(data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("value", data.value);
      params.set("language_id", 2);
      // PUT request to API

      await api.put(`/settings/${data.id}`, params);

      fetchAllItems();
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateImageItem(data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      if (data.file) {
        params.set("file", data.file);
        params.set("header_logo", data.file.preview);

        params.set("language_id", language.get());
        // await api.postFiles(`http://127.0.0.1/dm-web-server/public/api/settings/${data.id}/image`, params);

        await api.postFiles(`/settings/${data.id}/image`, params);
      }
      fetchAllItems();
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}
