import React, { Component } from "react";
import _ from "lodash";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import settings from "../../../services/settings";
import { Link } from "react-router-dom";
import "../settings.scss";
import "../../Page.scss";

import * as settingsActions from "../../../store/settings/actions";
import * as settingsSelectors from "../../../store/settings/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";

import Modal from "reboron/DropModal";
import Topbar from "../../../components/Topbar";
import SubTopbar from "../../../components/SubTopbar";
import SearchBar from "../../../components/SearchBar";
import SettingsTabs from "../../../components/settings/SettingsTabs";
import SettingsLeftTabs from "../../../components/settings/SettingsLeftTabs";
import ShareEmailForm from "../../../components/settings/email/ShareEmailForm";
import "./LanguageListPage.scss";

class LanguageListPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchAllSettings();
  }

  showSaveModal() {
    this.refs.saveModal.show();
  }

  hideSaveModal() {
    this.refs.saveModal.hide();
  }

  saveSettings(data) {
    let subjectPromise = this.props.updateSetting(
      data.form[settings.keys.SHARE_EMAIL_SUBJECT]
    );
    let messagePromise = this.props.updateSetting(
      data.form[settings.keys.SHARE_EMAIL_MESSAGE]
    );

    Promise.all([subjectPromise, messagePromise]).then(() => {
      this.showSaveModal();
    });
  }

  handleShowModal() {
    _.delay(() => {
      this.hideSaveModal();
    }, 500);
  }

  render() {
    return (
      <div className="LanguageListPage">
        <Modal
          className="boron-modal no-body"
          ref="saveModal"
          onShow={this.handleShowModal}
        >
          <span>
            <div className="modal-close-btn">
              <i
                className="ion-android-cancel clickable"
                onClick={this.hideSaveModal}
              />
            </div>
            <h2>{strings.get("App.settings.settingsSaved")}</h2>
          </span>
        </Modal>
        <Topbar>
          <div className="title">
            <Link to="/settings/shareEmail">
              {strings.get("App.settings.title")}
            </Link>
          </div>
        </Topbar>

        <div className="content settings-container">
          <SubTopbar>
            <SettingsTabs />
          </SubTopbar>
          <div className="setting-content row">
            <SettingsLeftTabs currentItemId={2} />
            <ShareEmailForm
              settings={this.props.settings}
              saveSettings={this.saveSettings}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: settingsSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllSettings: () => {
      dispatch(settingsActions.fetchAllItems());
    },
    updateSetting: (data) => {
      return dispatch(settingsActions.updateItem(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageListPage);
