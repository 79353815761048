import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as groupsActions from "../../store/groups/actions";
import * as groupsSelectors from "../../store/groups/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
import SearchBar from "../../components/SearchBar";
import GroupList from "../../components/group/GroupList";
import GroupListSorter from "../../components/group/GroupListSorter";
import Pagination from "../../components/Pagination";

class GroupListPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchGroups();
  }

  render() {
    return (
      <div className="GroupListPage">
        <Topbar>
          <div className="title">
            <Link to="/users">{strings.get("App.groupPages.users")}</Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/users/groups">
                {strings.get("App.groupPages.title")}
              </Link>
            </span>
          </div>
          <SearchBar
            placeholder={strings.get("App.searchPlaceholders.group")}
            searchTerm={this.props.filters.searchTerm}
            fetchItems={this.props.fetchGroups}
            setSearchTerm={this.props.setSearchTerm}
          />
          <div className="main-btns">
            <Link to="/users" className="btn btn-default btn-color-change">
              {strings.get("App.groupPages.manage")}
            </Link>
          </div>
          <div className="main-btns">
            <Link to="/users/groups/add" className="btn btn-primary">
              <span>{strings.get("App.groupPages.addGroup")}</span>{" "}
              <i className="ion-plus"></i>
            </Link>
          </div>
          <div className="main-btns">
            <Link to="/users/groups/blockedGroups" className="btn btn-danger">
              <span>{strings.get("App.groupPages.blockedGroups")}</span>{" "}
              <i className="ion-locked"></i>
            </Link>
          </div>
        </Topbar>

        <div className="content">
          <div className="row">
            <div className="col-xs-6" />
            <div className="col-xs-6">
              <GroupListSorter 
                  sorter={this.props.sorter}
                  fetchItems={this.props.fetchGroups}
                  setSorter={this.props.setSorter}
              />
            </div>
          </div>
          <GroupList
            items={this.props.groups}
            sorter={this.props.sorter}
            currentItem={this.props.currentGroup}
            fetchItems={this.props.fetchGroups}
            setCurrentItemId={this.props.setCurrentGroupId}
            unsetCurrentItemId={this.props.unsetCurrentGroupId}
            deleteItem={this.props.deleteGroup}
            blockItem={this.props.blockGroup}
            toggleSorter={this.props.toggleSorter}
          />

          <Pagination
            pagination={this.props.pagination}
            setCurrentPage={this.props.setCurrentPage}
            fetchItems={this.props.fetchGroups}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    groups: groupsSelectors.getItemsByPage(
      state,
      groupsSelectors.getPagination(state).currentPage
    ),
    sorter: groupsSelectors.getSorter(state),
    filters: groupsSelectors.getFilters(state),
    pagination: groupsSelectors.getPagination(state),
    currentGroup: groupsSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchGroups: (deleteCache) => {
      dispatch(groupsActions.fetchItems(deleteCache));
    },
    setSearchTerm: (searchTerm) => {
      dispatch(groupsActions.setSearchTerm(searchTerm));
    },
    setSorter: (sorter) => {
      dispatch(groupsActions.setSorter(sorter));
    },
    toggleSorter: (searchTerm) => {
      dispatch(groupsActions.toggleSorter(searchTerm));
    },
    setCurrentPage: (page) => {
      dispatch(groupsActions.setCurrentPage(page));
    },
    setCurrentGroupId: (id) => {
      dispatch(groupsActions.setCurrentItemId(id));
    },
    unsetCurrentGroupId: () => {
      dispatch(groupsActions.unsetCurrentItemId());
    },
    deleteGroup: (id, handleGroupDeleteResponse) => {
      dispatch(groupsActions.deleteItem(id, handleGroupDeleteResponse));
    },
    blockGroup: (id, data) => {
      dispatch(groupsActions.blockItem(id, data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupListPage);
