import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import language from "../../services/language";
import PropTypes from "prop-types";

import _ from "lodash";
import "./CategoryForm.scss";
import Switch from "../Switch.js";
import ReactAvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import CategoryFormUsers from "./CategoryFormUsers";
import CategoryFormGroups from "./CategoryFormGroups";
import CategoryFormTeamMembers from "./CategoryFormTeamMembers";
import { browserHistory } from "../../helpers";


class CategoryForm extends Component {
  state = {
    currentItemLoaded: false,
    file: null,
    bgFile: null,
    image: null,
    bgImage: null,
    scale: 0.2,
    bgScale: 0.2,
    currItem: null,
    imageUrl: null,
    bgImageUrl: null,
    fileRejected: false,
    bgFileRejected: false,
    users: [],
    groups: [],
    checkedReadMore: false,
    form: {
      language_id: language.get(),
      parent_id: "",
      users_ids: "",
      groups_ids: "",
      teamMembers_ids: "",
      name: "",
      // prefix : '',
      description: "",
      read_more_link: "",
      image: "",
      visibility: 0,
      meta_title: "",
      meta_description: "",
      meta_keyword: "",
    },
  };

  setEditorRef = (editor) => (this.editor = editor);
  setEditorBgRef = (editor) => (this.editorBg = editor);

  handleScaleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  removeImage = (image, file) => () => {
    this.setState({
      [image]: null,
      [file]: null,
    });
  };

  cropImage = () => {
    let crooperD = this.editor.getImageScaledToCanvas().toDataURL();
    // console.log('this.editor.getImageScaledToCanvas()')
    // let form = _.extend(this.state.form, {});
    // form.image = form.image.asMutable
    //   ? form.image.asMutable()
    //   : form.image
    // form.image.preview = crooperD;
    this.setState({
      image: null,
      file: this.editor.getImageScaledToCanvas().toDataURL(),
      // currItem: {
      // ...this.state.currItem,
      imageUrl: null,
      // }
    });
  };

  bgCropImage = () => {
    let crooperD = this.editorBg.getImageScaledToCanvas().toDataURL();

    this.setState({
      bgImage: null,
      bgFile: this.editorBg.getImageScaledToCanvas().toDataURL(),
      // currItem: null,
      bgimageUrl: null,
    });
  };

  componentDidMount() {
    this.tryLoadCurrentItem();
  }

  componentDidUpdate() {
    this.tryLoadCurrentItem();
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  tryLoadCurrentItem() {
    if (this.props.currentItem && !this.state.currentItemLoaded) {
      let form = _.extend({}, this.state.form);
      _.map(this.state.form, (value, key) => {
        form[key] = this.props.currentItem[key]
          ? this.props.currentItem[key]
          : this.state.form[key];
      });

      form["users_ids"] = _.map(this.props.currentItem.users, (item) => {
        return item.id;
      }).join(",");

      form["groups_ids"] = _.map(this.props.currentItem.groups, (item) => {
        return item.id;
      }).join(",");

      form["teamMembers_ids"] = _.map(
        this.props.currentItem.selectedUsers,
        (item) => {
          return item.id;
        }
      ).join(",");

      this.setState({
        currentItemLoaded: true,
        users: this.props.currentItem.users.asMutable(),
        groups: this.props.currentItem.groups.asMutable(),
        teamMembers: this.props.currentItem.assigned_users,
        form,
        // currItem: this.props.currentItem,
        imageUrl: this.props.currentItem.imageURL,
        bgimageUrl: this.props.currentItem.bgImageURL,
      });
    }
  }
  // handleChange() {
  //     if (!this.state.form.read_more_link)
  //     {
  //         this.setState({
  //             checkedReadMore: !this.state.checkedReadMore
  //         })
  //     }
  // }

  hasError(inputName) {
    return !!this.props.exceptions[inputName];
  }

  getErrorClass(inputName, defaultClasses = "") {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  }

  getErrorMessage(inputName) {
    return this.props.exceptions[inputName];
  }

  // getPreviewImage() {
  //     if (this.state.file) {
  //         return (<img src={ this.state.file.preview } />);
  //     } else {
  //         return (this.props.currentItem && this.props.currentItem.imageUrl) ? (
  //             <img src={ this.props.currentItem.imageUrl } />
  //         ) : null;
  //     }
  // }

  // getBgPreviewImage() {
  //     if (this.state.bgFile) {
  //         return (<img src={ this.state.bgFile.preview } />);
  //     } else {
  //         return (this.props.currentItem && this.props.currentItem.bgimageUrl) ? (
  //             <img src={ this.props.currentItem.bgimageUrl } />
  //         ) : null;
  //     }
  // }

  getPreviewImage(file) {
    if (this.state.file) {
      return <img src={this.state.file} />;
    } else {
      return this.state.imageUrl ? <img src={this.state.imageUrl} /> : null;
    }
  }

  getBgPreviewImage(file) {
    if (this.state.bgFile) {
      return <img src={this.state.bgFile} />;
    } else {
      return this.state.bgimageUrl ? <img src={this.state.bgimageUrl} /> : null;
    }
  }

  isImage(file) {
    // if (this.state.currItem && this.state.currItem.imageUrl) {
    //   return true;
    // } else {
    //   return false;
    // }
    if (this.state[file]) {
      return true;
    } else {
      return false;
    }
  }

  setImage = (fileName, formKey) => () => {
    this.setState({
      [fileName]: null,
      [formKey]: null,
      // currItem: {
      //   ...this.state.currItem,
      //   [formKey]: null
      // }
    });
  };

  handleFileDrop = (dropped) => {
    // let form = _.extend(this.state.form, {})
    // form[settings.keys.HEADER_LOGO] = form[settings.keys.HEADER_LOGO].asMutable
    //   ? form[settings.keys.HEADER_LOGO].asMutable()
    //   : form[settings.keys.HEADER_LOGO]
    // form[settings.keys.HEADER_LOGO].file = dropped[0]
    // console.log(this.state.form, 'handleHeaderFileDrop')
    this.setState({ image: dropped[0], cropClass: false, file: dropped[0] });
  };

  handleBgImgFileDrop = (dropped) => {
    // let form = _.extend(this.state.form, {})
    // form[settings.keys.HEADER_LOGO] = form[settings.keys.HEADER_LOGO].asMutable
    //   ? form[settings.keys.HEADER_LOGO].asMutable()
    //   : form[settings.keys.HEADER_LOGO]
    // form[settings.keys.HEADER_LOGO].file = dropped[0]
    // console.log(this.state.form, 'handleHeaderFileDrop')
    this.setState({
      bgImage: dropped[0],
      cropClass: false,
      bgFile: dropped[0],
    });
  };

  getVisibilityIcon() {
    if (this.state.form.visibility === 0) {
      return <i className="visibility-icon ion-locked"></i>;
    } else if (this.state.form.visibility === 1) {
      return <i className="visibility-icon ion-earth"></i>;
    } else if (this.state.form.visibility === 2) {
      return <i className="visibility-icon ion-android-people"></i>;
    } else if (this.state.form.visibility === 3) {
      return <i className="visibility-icon ion-android-settings"></i>;
    }
  }

  handleInputChange(e) {
    let form = {};
    form[e.target.name] = e.target.value;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  }

  handleUsersChange(items) {
    let users_ids = _.map(items, (item) => {
      return item.id;
    }).join(",");

    this.setState({
      users: items,
      form: _.extend(this.state.form, {
        users_ids,
      }),
    });
  }

  handleGroupsChange(items) {
    let groups_ids = _.map(items, (item) => {
      return item.id;
    }).join(",");

    this.setState({
      groups: items,
      form: _.extend(this.state.form, {
        groups_ids,
      }),
    });
  }

  handleTeamMembersChange(items) {
    let teamMembers_ids = _.map(items, (item) => {
      return item.id;
    }).join(",");

    this.setState({
      teamMembers: items,
      form: _.extend(this.state.form, {
        teamMembers_ids,
      }),
    });
  }

  // handleFileDrop(acceptedFiles, rejectedFiles) {
  //     if (rejectedFiles.length) {
  //         this.setState({
  //             fileRejected: true,
  //         })
  //     } else {
  //         this.setState({
  //             file: _.first(acceptedFiles),
  //             fileRejected: false,
  //         })
  //     }
  // }

  // handleBgImgFileDrop(acceptedFiles, rejectedFiles){
  //   $(".bgErrorUpload").html("");
  //   if(rejectedFiles.length){
  //     this.state({
  //       bgFileRejected: true,
  //     })
  //   } else {
  //     let imageErrorMsg =  `${strings.get('Client.settings.errorlogo')}`;
  //     let i = new Image();
  //     i.src = _.first(acceptedFiles).preview;
  //     i.onload = () => {
  //       if (i.width !== 2200 || i.height !== 1200 ) {
  //           $(".bgErrorUpload").html(imageErrorMsg);
  //           return;
  //       }
  //       this.setState({
  //           bgFile: _.first(acceptedFiles),
  //           bgFileRejected: false,
  //       })
  //     }
  //   }
  // }

  handleSaveClick(e) {
    e.preventDefault();
    this.props.saveItem(
      _.extend(this.state, {
        form: _.extend(this.state.form, {
          language_id: language.get(),
        }),
      })
    );
  }

  handleCancelClick(e) {
    e.preventDefault();
    browserHistory.push("/documents/categories");
  }

  getCategoryTree(items, item, arr, depth = 0) {
    if (_.size(items) && _.find(items, item)) {
      if (item.parent_id && depth === 0) return;
      arr[0].push(item);
      arr[1].push(depth);
      _.map(items, (it) => {
        if (item.id === it.parent_id) {
          this.getCategoryTree(items, it, arr, depth + 1);
        }
      });
    }
  }

  // handleChange(name, enabled, value) {
  //   let { form } = this.state;
  //   typeof value === 'boolean' ? form[name] = enabled : form[name] = enabled === true ? 1 : 0;
  //   this.setState({form});
  // }

  handleChange(enabled) {
    if (!this.state.form.read_more_link) {
      this.setState({
        checkedReadMore: !this.state.checkedReadMore,
      });
    }
  }

  render() {
    const readMoreLink =
      this.state.checkedReadMore || this.state.form.read_more_link ? (
        <div className={this.getErrorClass("readMoreLabel", "form-group")}>
          <label className="col-sm-3 control-label">
            {strings.get("App.categoryForm.readMoreLink")}
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              name="read_more_link"
              value={this.state.form.read_more_link}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
      ) : null;
    let nameLabel = this.hasError("name")
      ? `${strings.get("App.categoryForm.name")} ${this.getErrorMessage(
          "name"
        )}`
      : strings.get("App.categoryForm.name");
    // let prefixLabel = this.hasError('prefix') ? `${strings.get('App.categoryForm.prefix')} ${this.getErrorMessage('prefix')}` : strings.get('App.categoryForm.prefix');
    let descriptionLabel = this.hasError("description")
      ? `${strings.get("App.categoryForm.description")} ${this.getErrorMessage(
          "description"
        )}`
      : strings.get("App.categoryForm.description");
    let parentLabel = this.hasError("parent_id")
      ? `${strings.get(
          "App.categoryForm.categoryParent"
        )} ${this.getErrorMessage("parent_id")}`
      : strings.get("App.categoryForm.categoryParent");
    let visibilityLabel = this.hasError("visibility")
      ? `${strings.get(
          "App.categoryForm.visibility.title"
        )} ${this.getErrorMessage("visibility")}`
      : strings.get("App.categoryForm.visibility.title");
    let imageLabel = this.hasError("image")
      ? strings.get("Exceptions.imageTooBig")
      : strings.get("App.categoryForm.image");
    let metaTitleLabel = this.hasError("meta_title")
      ? `${strings.get("App.articleForm.meta_title")} ${this.getErrorMessage(
          "meta_title"
        )}`
      : strings.get("App.articleForm.meta_title");
    let metaDescLabel = this.hasError("meta_description")
      ? `${strings.get(
          "App.articleForm.meta_description"
        )} ${this.getErrorMessage("meta_description")}`
      : strings.get("App.articleForm.meta_description");

    let arr = [[], []];
    _.map(this.props.categories, (item) => {
      this.getCategoryTree(this.props.categories, item, arr);
    });

    let categories = _.map(arr[0], (category, i) => {
      let sub = arr[1][i]
        ? " ".repeat(Math.round(arr[1][i] * 3 + arr[1][i] * 3)) + "|" + "_"
        : " ";
      return (
        <option
          className={`depth-${arr[1][i]}`}
          value={category.id}
          key={category.id}
        >
          {`${sub} ${category.name}`}
        </option>
      );
    });

    let usersFormGroup =
      this.state.form.visibility === 3 ? (
        <CategoryFormUsers
          users={this.props.users}
          selected={this.state.users}
          onChange={this.handleUsersChange}
        />
      ) : null;

    let groupsFormGroup =
      this.state.form.visibility === 3 ? (
        <CategoryFormGroups
          groups={this.props.groups}
          selected={this.state.groups}
          onChange={this.handleGroupsChange}
        />
      ) : null;
    let teamMembersFormGroup =
      this.props.currentItem && this.state.currentItemLoaded ? (
        <CategoryFormTeamMembers
          teamMembers={this.props.currentItem.assigned_users}
          selected={this.props.currentItem.selectedUsers}
          onChange={this.handleTeamMembersChange}
        />
      ) : null;

    let dropzoneContent = this.getPreviewImage() ? (
      this.getPreviewImage()
    ) : (
      <img src={"https://dummyimage.com/2200x1200/000/fff.jpg"} />
    );
    let dropzoneBgImgContent = this.getBgPreviewImage() ? (
      this.getBgPreviewImage()
    ) : (
      <img src={"https://dummyimage.com/2200x1200/000/fff.jpg"} />
    );
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="CategoryForm">
            <form className="form-horizontal">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <b>{this.props.formHeading}</b>
                </div>
                <div className="panel-body">
                  <div className={this.getErrorClass("name", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {nameLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={this.state.form.name}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  {/* <div className={ this.getErrorClass('prefix', 'form-group') }>
                            <label className="col-sm-3 control-label">{ prefixLabel }</label>
                            <div className="col-sm-9">
                              <input className="form-control" type="text" name="prefix" value={ this.state.form.prefix } onChange={ this.handleInputChange } />
                            </div>
                        </div> */}
                  <div
                    className={this.getErrorClass("parent_id", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {parentLabel}
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="parent_id"
                        value={this.state.form.parent_id}
                        onChange={this.handleInputChange}
                      >
                        <option value="">
                          {strings.get("App.categoryForm.noParent")}
                        </option>
                        {categories}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {visibilityLabel}
                      {this.getVisibilityIcon()}
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="visibility"
                        value={this.state.form.visibility}
                        onChange={this.handleInputChange}
                      >
                        <option value="1">
                          {strings.get("App.categoryForm.visibility.public")}
                        </option>
                        <option value="2">
                          {strings.get("App.categoryForm.visibility.loggedIn")}
                        </option>
                        <option value="0">
                          {strings.get("App.categoryForm.visibility.hidden")}
                        </option>
                        <option value="3">
                          {strings.get("App.categoryForm.visibility.custom")}
                        </option>
                      </select>
                    </div>
                  </div>
                  {usersFormGroup}
                  {groupsFormGroup}
                  {/* { teamMembersFormGroup } */}
                  <div
                    className={this.getErrorClass("description", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {descriptionLabel}
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        name="description"
                        value={this.state.form.description}
                        onChange={this.handleInputChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {strings.get("App.categoryForm.addReadMoreLink")}
                    </label>
                    <div className="col-sm-9">
                      {/* <input
                                  type="checkbox"
                                  checked={ this.state.checkedReadMore || this.state.form.read_more_link }
                                  onChange={ this.handleChange } /> */}
                      <Switch
                        enabled={
                          this.state.checkedReadMore ||
                          !!this.state.form.read_more_link
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  {readMoreLink}
                  {/* <div className={ this.getErrorClass('image', 'form-group') }>
                            <label className="col-sm-3 control-label">{ imageLabel }</label>
                            <div className="col-sm-9">
                              <Dropzone
                                  className="dropzone"
                                  onDrop={ this.handleFileDrop }
                                  multiple={ false }
                                  maxSize={ 4096000 }
                                  accept="image/*">
                                  { dropzoneContent }
                              </Dropzone>
                            </div>
                        </div> */}

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {imageLabel}
                    </label>

                    <div className="col-sm-9">
                      {this.state.image || this.state.file ? (
                        <input
                          type="button"
                          className="close-btn"
                          style={{ marginTop: -10 }}
                          onClick={this.removeImage("image", "file")}
                          value="X"
                        />
                      ) : this.isImage("imageUrl") ? (
                        <input
                          type="button"
                          className="close-btn"
                          style={{ marginTop: -10 }}
                          onClick={this.setImage("file", "imageUrl")}
                          value="X"
                        />
                      ) : (
                        ""
                      )}
                      <Dropzone
                        className="dropzone"
                        onDrop={this.handleFileDrop}
                        multiple={false}
                        maxSize={4096000}
                        accept="image/*"
                      >
                        {/* {dropzoneContent}

                        {this.state.image ? (
                          <ReactAvatarEditor
                            scale={this.state.scale}
                            rotate={0}
                            className="image-placeholder"
                            image={this.state.image}
                            width={2200}
                            height={1200}
                            ref={(ref) => this.setEditorRef(ref)}
                          />
                        ) : (
                          // this.state.file && this.state.currItem && this.state.currItem.imageUrl ? (
                          //   <img src={this.state.file} />
                          // ) :
                          ""
                        )} */}
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {dropzoneContent}
                            {this.state.image ? (
                              <ReactAvatarEditor
                                scale={parseFloat(this.state.scale)}
                                rotate={0}
                                className="image-placeholder"
                                image={this.state.image}
                                width={2200}
                                height={1200}
                                ref={(ref) => this.setEditorRef(ref)}
                              />
                            ) : (
                              // this.state.file && this.state.currItem && this.state.currItem.imageUrl ? (
                              //   <img src={this.state.file} />
                              // ) :
                              ""
                            )}
                          </div>
                        )}
                      </Dropzone>

                      {this.state.cropClass === false ? (
                        <div className="help-block">
                          <div className="help-block">
                            {/* (Suggest Dimension: 2200px X 1200px) */}
                            {strings.get(
                              "App.articleCategoryForm.suggestDimension"
                            )}
                          </div>
                          {this.state.image !== null ? (
                            <input
                              type="range"
                              step="0.01"
                              min="0.1"
                              max="2"
                              name="scale"
                              value={this.state.scale}
                              onChange={(e) =>
                                this.setState({
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          ) : (
                            ""
                          )}

                          {this.state.image !== null ? (
                            <input
                              type="button"
                              className="crop-image-btn"
                              onClick={this.cropImage}
                              value="Crop"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {/* <div className="form-group">
                            <label className="col-sm-3 control-label">{strings.get('App.categoryForm.headerImage')}</label>
                            <div className="col-sm-9">
                              <Dropzone
                                  className="dropzone"
                                  onDrop={ this.handleBgImgFileDrop }
                                  multiple={ false }
                                  maxSize={ 4096000 }
                                  accept="image/*">
                                  { dropzoneBgImgContent }
                              </Dropzone>
                              <div className="help-block">(Suggestie: breedte 2200px, hoogte 1200px)</div>
                              <small className="text-danger bgErrorUpload"></small>
                            </div>
                        </div> */}

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {strings.get("App.categoryForm.headerImage")}
                    </label>

                    <div className="col-sm-9">
                      {this.state.bgImage || this.state.bgFile ? (
                        <input
                          type="button"
                          className="close-btn"
                          style={{ marginTop: -10 }}
                          onClick={this.removeImage("bgImage", "bgFile")}
                          value="X"
                        />
                      ) : this.isImage("bgimageUrl") ? (
                        <input
                          type="button"
                          className="close-btn"
                          style={{ marginTop: -10 }}
                          onClick={this.setImage("bgFile", "bgimageUrl")}
                          value="X"
                        />
                      ) : (
                        ""
                      )}
                      <Dropzone
                        className="dropzone"
                        onDrop={this.handleBgImgFileDrop}
                        multiple={false}
                        maxSize={4096000}
                        accept="image/*"
                      >
                        {/* {dropzoneBgImgContent}

                        {this.state.bgImage ? (
                          <ReactAvatarEditor
                            scale={this.state.bgScale}
                            rotate={0}
                            className="image-placeholder"
                            image={this.state.bgImage}
                            width={2200}
                            height={1200}
                            ref={(ref) => this.setEditorBgRef(ref)}
                          />
                        ) : (
                          // : this.state.bgFile && this.state.currItem ? (
                          //   <img src={this.state.bgFile} />
                          // )
                          ""
                        )} */}
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {dropzoneBgImgContent}

                            {this.state.bgImage ? (
                              <ReactAvatarEditor
                                scale={parseFloat(this.state.bgScale)}
                                rotate={0}
                                className="image-placeholder"
                                image={this.state.bgImage}
                                width={2200}
                                height={1200}
                                ref={(ref) => this.setEditorBgRef(ref)}
                              />
                            ) : (
                              // : this.state.bgFile && this.state.currItem ? (
                              //   <img src={this.state.bgFile} />
                              // )
                              ""
                            )}
                          </div>
                        )}
                      </Dropzone>

                      {this.state.cropClass === false ? (
                        <div className="help-block">
                          <div className="help-block">
                            {/* (Suggest Dimension: 2200px X 1200px) */}
                            {strings.get(
                              "App.articleCategoryForm.suggestDimension"
                            )}
                          </div>
                          {this.state.bgImage !== null ? (
                            <input
                              type="range"
                              step="0.01"
                              min="0.1"
                              max="2"
                              name="bgScale"
                              value={this.state.bgScale}
                              onChange={(e) =>
                                this.setState({
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          ) : (
                            ""
                          )}

                          {this.state.bgImage !== null ? (
                            <input
                              type="button"
                              className="crop-image-btn"
                              onClick={this.bgCropImage}
                              value="Crop"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass("meta_title", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {metaTitleLabel}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="meta_title"
                        value={this.state.form.meta_title}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass(
                      "meta_description",
                      "form-group"
                    )}
                  >
                    <label className="col-sm-3 control-label">
                      {metaDescLabel}
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        name="meta_description"
                        value={this.state.form.meta_description}
                        onChange={this.handleInputChange}
                      ></textarea>
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass("meta_keyword", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.meta_keyword")}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="meta_keyword"
                        value={this.state.form.meta_keyword}
                        onChange={this.handleInputChange}
                      />
                      <div className="help-block">
                        {" "}
                        {strings.get(
                          "App.articleForm.meta_keyword_example"
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <footer className="panel-footer text-right bg-light lter">
                  <button
                    className="btn btn-primary"
                    onClick={this.handleSaveClick}
                  >
                    {strings.get("App.categoryForm.save")}
                  </button>
                  <button
                    className="btn btn-color-change"
                    onClick={this.handleCancelClick}
                  >
                    {strings.get("App.categoryForm.cancel")}
                  </button>
                </footer>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

CategoryForm.propTypes = {
  exceptions: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  groups: PropTypes.object.isRequired,
  saveItem: PropTypes.func.isRequired,
};

export default CategoryForm;
