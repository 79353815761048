import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import { Link } from "react-router-dom";
import "../settings.scss";
import "../../Page.scss";

import * as translationsActions from "../../../store/translations/actions";
import * as translationsSelectors from "../../../store/translations/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";

import Topbar from "../../../components/Topbar";
import SubTopbar from "../../../components/SubTopbar";
// import SearchBar from "../../../components/SearchBar";
import SettingsTabs from "../../../components/settings/SettingsTabs";
import TranslationList from "../../../components/settings/translations/TranslationList";

class TranslationListPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchAllTranslations();
  }

  render() {
    return (
      <div className="TranslationListPage">
        <Topbar>
          <div className="title">
            <Link to="/settings/translations">
              {strings.get("App.settings.title")}
            </Link>
          </div>
        </Topbar>

        <div className="content settings-container">
          <SubTopbar>
            <SettingsTabs />
          </SubTopbar>
          <div className="setting-content">
            <div className="row">
              <div className="col-xs-12">
                <Link
                  to="/settings/translations/add"
                  className="btn btn-primary"
                  style={{ float: "right" }}
                >
                  {strings.get("App.settings.languages.addLanguage")}
                </Link>
              </div>
            </div>
            <TranslationList
              items={this.props.translations}
              currentItem={this.props.currentTrans}
              updateItem={this.props.updateTranslation}
              setCurrentItemId={this.props.setCurrentTranslationId}
              unsetCurrentItemId={this.props.unsetCurrentTranslationId}
              deleteItem={this.props.deleteTranslation}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    translations: translationsSelectors.getItems(state),
    currentTrans: translationsSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllTranslations: () => {
      dispatch(translationsActions.fetchAllItems());
    },
    updateTranslation: (id, data) => {
      dispatch(translationsActions.updateItem(id, data));
    },
    setCurrentTranslationId: (id) => {
      dispatch(translationsActions.setCurrentItemId(id));
    },
    unsetCurrentTranslationId: () => {
      dispatch(translationsActions.unsetCurrentItemId());
    },
    deleteTranslation: (id) => {
      dispatch(translationsActions.deleteItem(id));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationListPage);
