import React, { Component } from "react";
import { connect } from "react-redux";
import { NotificationContainer } from "react-notifications";
import _ from "lodash";
import strings from "../../services/strings";
import { loadIdentifier } from "../../helpers/index";

import LoadingScreen from "../../components/LoadingScreen";

import * as authActions from "../../store/auth/actions";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";
import * as settingsSelectors from "../../store/settings/selectors";
import "../../App.scss";
import "../Page.scss";
import "react-notifications/lib/notifications.css";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        email: "",
        password: "",
        otp: "",
        remember_me: false,
        identifier: loadIdentifier(),
      },
      otpSent: false,
      preview: false,
    };
  }

  componentDidMount() {
    strings.getJson().then(() => {
      this.setState({ preview: true });
    });
  }

  componentWillUnmount() {
    this.props.clearExceptions();
  }

  hasError = (inputName) => {
    return !!this.props.exceptions[inputName];
  };

  getErrorClass = (inputName, defaultClasses = "") => {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  };

  getErrorMessage = (inputName) => {
    return this.props.exceptions[inputName];
  };

  handleInputChange = (e) => {
    let form = {};
    form[e.target.name] = e.target.value;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  };

  handleRememberMeChange = (e) => {
    let form = {};
    form[e.target.name] = e.target.checked ? true : false;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  };

  handleLoginClick = (e) => {
    e.preventDefault();
    this.props.login(this.state.form, this.handleOtpSent);
  };

  handleOtpSubmit = (e) => {
    e.preventDefault();
    this.props.otpSubmit(this.state.form);
  };

  handleOtpSent = () => {
    this.setState({ otpSent: true });
  };

  render() {
    let emailLabel = this.hasError("email")
      ? `Email ${this.getErrorMessage("email")}`
      : strings.get("App.loginPage.login.emailPlaceholder");
    let passwordLabel = this.hasError("password")
      ? `Password ${this.getErrorMessage("password")}`
      : strings.get("App.loginPage.login.passwordPlaceholder");

    let alert = null;
    if (this.props.exceptions["invalid_credentials"]) {
      alert = (
        <div className="alert alert-danger">
          {this.props.exceptions["invalid_credentials"]}
        </div>
      );
    }

    let loginForm = (
      <div>
        <div className="form-group">
          <input
            className="form-control"
            type="text"
            name="email"
            placeholder={emailLabel}
            value={this.state.form.email}
            onChange={this.handleInputChange}
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            type="password"
            name="password"
            placeholder={passwordLabel}
            value={this.state.form.password}
            onChange={this.handleInputChange}
          />
          <div className="help-block other-actions"></div>
        </div>
        <div className="form-actions">
          <input
            type="hidden"
            name="share_draft_id"
            value={this.state.form.share_draft_id}
          />
          <button
            className="btn btn-primary"
            onClick={this.handleLoginClick}
          >
            {/* Inloggen */}
            {strings.get("App.loginPage.login.submitButton")}
          </button>
        </div>
      </div>
    );

    let otpForm = (
      <div className="otp-form">
        <div>
          <h5>{`${strings.get("App.loginPage.otp.subTitle")} ${
            this.state.form.email
          }`}</h5>
        </div>
        <div className="form-group">
          <input
            className="form-control"
            type="number"
            name="otp"
            placeholder={strings.get("App.loginPage.otp.inputPlaceholder")}
            value={this.state.form.otp}
            onChange={this.handleInputChange}
          />
        </div>
        <div className="form-group remember-me">
          <input
            type="checkbox"
            name="remember_me"
            value={this.state.form.remember_me}
            onChange={this.handleRememberMeChange}
          />
          <p>{strings.get("App.loginPage.otp.rememberMeLabel")}</p>
        </div>
        <div className="form-actions">
          <button className="btn btn-primary" onClick={this.handleOtpSubmit}>
            {strings.get("App.loginPage.otp.submitButton")}
          </button>
        </div>
      </div>
    );
    return (
      <div className="container login-page">
        <NotificationContainer />
        {this.props.screenLoading ? <LoadingScreen /> : null}
        {this.state.preview ? (
          <div className="row">
            <div
              className={
                this.state.otpSent
                  ? "wrapper col-sm-6 col-sm-offset-3 otp-form-container"
                  : "wrapper col-sm-6 col-sm-offset-3"
              }
            >
              <h2>
                {this.state.otpSent
                  ? strings.get("App.loginPage.otp.title")
                  : strings.get("App.loginPage.login.title")}
              </h2>
              <br />
              {alert}
              {this.state.otpSent ? otpForm : loginForm}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    exceptions: exceptionsSelectors.getItems(state),
    screenLoading: settingsSelectors.getScreenLoadingStatus(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (data, handleOtpSent) => {
      dispatch(authActions.login(data, handleOtpSent));
    },
    otpSubmit: (data) => {
      dispatch(authActions.otpSubmit(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
