import React, { Component } from "react";
import autoBind from "react-autobind";

import PropTypes from "prop-types";

import strings from "../../services/strings";

import "./DocumentSorter.scss";

class DocumentSorter extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = {
          column : true,
          pageLimit: 10,
          descending: true
        };
      }

  getSorter = () => {
    if (this.props.sorter) {
      return this.props.sorter.column;
    }
    return "";
  };

  getDescending = () => {
    if (this.props.sorter) {
      return this.props.sorter.descending;
    }

    return "";
  };

  handleChangePageSize(e) {
    let sorter = {
      column: this.state.column,
      descending: this.state.descending,
      pageLimit: e.target.value
    };
    this.setState({
        pageLimit : sorter.pageLimit
    });
    this.props.setSorter(sorter);
    this.props.fetchItems();
  }

  handleChangeDescending = (e) => {
    let sorter = {
      column: this.props.sorter.column,
      descending: e.target.value === "true",
      pageLimit: this.getSorterPageSize()
    };
    this.setState({
        column : sorter.column,
        descending: sorter.descending,
        pageLimit : sorter.pageLimit
      });
    this.props.setSorter(sorter);
    this.props.fetchItems();
  };

  getSorterPageSize() {
    if (this.props.sorter) {
      return this.props.sorter.pageLimit;
    }
    return "";
  }

  render() {
    return (
      <div className="DocumentSorter ">
        <select
          className="form-control cate-name"
          name="sorter"
          value={this.getDescending()}
          onChange={this.handleChangeDescending}
        >
          <option value={false}>
            {strings.get("Client.downloadsPage.sorter.asc")}
          </option>
          <option value={true}>
            {strings.get("Client.downloadsPage.sorter.desc")}
          </option>
        </select>

        <select
          className="form-control"
          name="sorter"
          value={this.props.sorter.pageLimit}
          onChange={this.handleChangePageSize}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
    );
  }
}

DocumentSorter.propTypes = {
  sorter: PropTypes.object,
  setSorter: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
};

export default DocumentSorter;
