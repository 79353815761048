import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import strings from "../../services/strings";
import language from "../../services/language";
import _ from "lodash";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import ActiveLabel from "../ActiveLabel";

const SortableDragger = SortableHandle(() => {
  return <div className="fieldOptionsDrag" />;
});

const SortableItem = SortableElement(({ value, handleRemove }) => {
  return (
    <div style={{ padding: "2px", zIndex: "1100", minWidth: "0px" }}>
      <div className="activeFields">
        <SortableDragger />
        <span />
        <ActiveLabel
          name={value}
          value={value}
          onRemove={handleRemove}
          key={_.uniqueId()}
          id="selector-options-drag"
        />
      </div>
    </div>
  );
});

const SortableList = SortableContainer(({ items, handleRemove }) => {
  let rows = _.map(items, (option, index) => {
    return (
      <SortableItem
        key={`item-${index}`}
        index={index}
        value={option}
        handleRemove={handleRemove}
      />
    );
  });
  return <div className="fieldWrap">{rows}</div>;
});

class GlossaryFormFieldsOptions extends Component {
  state = {
    addedLoaded: false,
    added: [],
    option: "",
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.tryLoadAdded();
  }

  componentDidUpdate() {
    this.tryLoadAdded();
  }

  tryLoadAdded() {
    if (_.size(this.props.added) && !this.state.addedLoaded) {
      this.setState({
        addedLoaded: true,
        added: this.props.added,
      });
    }
  }

  handleChange(e) {
    this.setState({ option: e.target.value });
  }

  handleAdd() {
    let added =
      _.findIndex(this.state.added, (item) => {
        return item === this.state.option;
      }) > -1;

    if (!added) {
      let added = _.extend([], this.state.added);
      added.push(this.state.option);

      this.props.onChange(added);
      this.setState({ added, option: "" });
    }
  }

  handleRemove(value) {
    let added = _.filter(this.state.added, (item) => {
      return item !== value;
    });

    this.props.onChange(added);
    this.setState({ added });
  }

  handleSortEnd({ oldIndex, newIndex }) {
    let added = _.extend([], this.state.added);
    added = arrayMove(added, oldIndex, newIndex);
    this.setState({ added }, () => {
      this.props.onChange(added);
    });
  }

  render() {
    let options = _.map(this.props.options, (item) => {
      return (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      );
    });

    let added = _.extend([], this.state.added);
    let addedOptions = (
      <SortableList
        items={added}
        onSortEnd={({ oldIndex, newIndex }) => {
          this.handleSortEnd({
            oldIndex,
            newIndex,
          });
        }}
        handleRemove={this.handleRemove}
        axis="xy"
        useDragHandle
        lockToContainerEdges
        helperClass="SortableItem Field"
      />
    );

    return (
      <div className="GlossaryFormFieldsOptions">
        <div className="form-group">
          <label className="control-label">
            {strings.get("App.glossaries.glossaryForm.fieldOptions.title")}
          </label>
          <div>{addedOptions}</div>
          <div className="row col-sm-12">
            <div className="col-sm-9" style={{ paddingLeft: 0 }}>
              <input
                className="form-control"
                type="text"
                name="option"
                placeholder={strings.get(
                  "App.glossaries.glossaryForm.fieldOptions.placeholder"
                )}
                value={this.state.option}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-sm-3">
              <div className="btn btn-default" onClick={this.handleAdd}>
                {strings.get("App.glossaries.glossaryForm.fieldOptions.add")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GlossaryFormFieldsOptions.propTypes = {
  added: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default GlossaryFormFieldsOptions;
