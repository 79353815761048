import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import strings from "../../services/strings";
import _ from "lodash";

import ActiveLabel from "../ActiveLabel";

class AddUserCategory extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      selectedLoaded: false,
      selected: [],
    };
  }

  componentDidMount() {
    this.tryLoadSelected();
  }

  componentDidUpdate() {
    this.tryLoadSelected();
  }

  tryLoadSelected() {
    if (_.size(this.props.selected) && !this.state.selectedLoaded) {
      this.setState({
        selectedLoaded: true,
        selected: this.props.selected,
      });
    }
  }

  handleAdd(e) {
    let id = e.target.value;
    let added =
      _.findIndex(this.state.selected, (item) => {
        return item.id === id;
      }) > -1;

    if (!added) {
      let selected = _.extend([], this.state.selected);
      selected.push(this.props.categories[`_${id}`]);

      this.props.onChange(selected);
      this.setState({ selected });
    }
  }

  handleRemove(value) {
    let selected = _.filter(this.state.selected, (item) => {
      return item.id !== value;
    });

    this.props.onChange(selected);
    this.setState({ selected });
  }

  getCategoryTree(items, item, arr, depth = 0) {
    if (_.size(items) && _.find(items, item)) {
      if (item.parent_id && depth === 0) return;
      arr[0].push(item);
      arr[1].push(depth);
      _.map(items, (it) => {
        if (item.id === it.parent_id) {
          this.getCategoryTree(items, it, arr, depth + 1);
        }
      });
    }
  }

  render() {
    let selectedCategories = _.map(this.props.selected, (category) => {
      return (
        <ActiveLabel
          name={`${category.name}`}
          value={category.id}
          onRemove={this.handleRemove}
          key={category.id}
        />
      );
    });

    let arr = [[], []];
    _.map(this.props.categories, (item) => {
      this.getCategoryTree(this.props.categories, item, arr);
    });

    let categories = _.map(arr[0], (category, i) => {
      let sub = arr[1][i]
        ? " ".repeat(Math.round(arr[1][i] * 3 + arr[1][i] * 3)) + "|" + "_"
        : " ";
      return (
        <option
          className={`depth-${arr[1][i]}`}
          value={category.id}
          key={category.id}
        >
          {`${sub} ${category.name}`}
        </option>
      );
    });

    return (
      <div className="GroupFormUsers" style={this.props.style}>
        <div className="form-category">
          <label className="control-label">
            {strings.get("App.users.addUserCategory.title")}
          </label>
          <div>{selectedCategories}</div>
          <select
            className="form-control"
            name="users"
            value=""
            onChange={this.handleAdd}
          >
            <option value="" disabled>
              {strings.get("App.users.addUserCategory.choose")}
            </option>
            {categories}
          </select>
        </div>
      </div>
    );
  }
}

AddUserCategory.propTypes = {
  categories: PropTypes.object.isRequired,
  selected: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AddUserCategory;
