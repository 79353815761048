import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import settings from "../../../services/settings";
import language from "../../../services/language";
import { Link } from "react-router-dom";
import _ from "lodash";
import Dropzone from "react-dropzone";
import ReactAvatarEditor from "react-avatar-editor";
import "../SettingsTabs.scss";
import Editor from "../../Editor";
// import b64toBlob from "b64-to-blob";
import * as settingsActions from "../../../store/settings/actions";
import * as settingsSelectors from "../../../store/settings/selectors";
import HeaderLogo from "./ImageComponent/HeaderLogo";
import PropTypes from "prop-types";

class ContactSettingForm extends Component {
  state = {
    image: null,
    footerImg: null,
    bannerImg: null,
    btmbannerImg: null,
    clientFavicon: null,
    adminFavicon: null,
    active: false,
    cropImageData: null,
    cropImageFooterData: null,
    cropImageBannerData: null,
    cropImageBtmBannerData: null,
    cropClientTitleFavicon: null,
    cropAdminTitleFavicon: null,
    scale: 0.2,
    scaleFooter: 0.2,
    scaleBanner: 0.2,
    scaleBtmBanner: 0.2,
    scaleClientTitleFavicon: 0.2,
    scaleAdminTitleFavicon: 0.2,
    currentSettingsLoaded: false,
    headerFileRejected: false,
    footerFileRejected: false,
    headerFile: null,
    footerFile: null,
    bannerFile: null,
    btmBannerFile: null,
    clientFaviconFile: null,
    adminFaviconFile: null,
    cropClass: false,
    form: {
      contact_phone: { value: "" },
      contact_fax: { value: "" },
      contact_email: { value: "" },
      contact_address: { value: "" },
      contact_site_name: { value: "" },
      header_logo: { value: "" },
      footer_logo: { value: "" },
      client_title_favicon: { value: "" },
      admin_title_favicon: { value: "" },
      site_meta_description: { value: "" },
      site_meta_keywords: { value: "" },
      facebook_link:{value: ""},
      instagram_link:{value: ""},
      linkedin_link:{value: ""},
    },
  };
  // setState({ scale:0 })
  setEditorRef = (editor) => (this.editor = editor);
  setEditorRefFooter = (editor) => (this.editorFooter = editor);
  setEditorRefBanner = (editor) => (this.editorBanner = editor);
  setEditorRefBtmBanner = (editor) => (this.editorBtmBanner = editor);
  setEditorRefClientFavicon = (editor) => (this.editorClientFavicon = editor);
  setEditorRefAdminFavicon = (editor) => (this.editorAdminFavicon = editor);

  // width: 20rem;
  // left: 14px;
  // height: 12rem;
  // position: absolute;
  // cursor: grab;
  // touch-action: none;

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  removeImage = (image, file, formKey) => () => {
    this.setState({
      [image]: null,
      ...this.state[image],
      [file]: null,
      ...this.state[file],
      cropImageData: null,
      cropImageFooterData: null,
      cropImageBannerData: null,
      cropImageBtmBannerData: null,
    });
  };

  cropImage = () => {
    this.setState({
      image: null,
      cropImageData: this.editor.getImageScaledToCanvas().toDataURL(),
    });
    // var contentType = 'image/png'

    let crooperD = this.editor.getImageScaledToCanvas().toDataURL();
    // console.log('++++++', crooperD.split(',')[1])

    // var blob = b64toBlob(crooperD.split(',')[1], contentType)
    // console.log('b64toBlob', blob)
    // var blobUrl = URL.createObjectURL(blob)

    let form = _.extend(this.state.form, {});
    form[settings.keys.HEADER_LOGO] = form[settings.keys.HEADER_LOGO].asMutable
      ? form[settings.keys.HEADER_LOGO].asMutable()
      : form[settings.keys.HEADER_LOGO];
    form[settings.keys.HEADER_LOGO].file.preview = crooperD;
    console.log(" ++++ ", form[settings.keys.HEADER_LOGO].file.preview);
    this.setState({
      // header_logo: this.editor.getImageScaledToCanvas().toDataURL(),
      headerFile: this.editor.getImageScaledToCanvas().toDataURL(),
    });
  };

  cropImageFooter = () => {
    this.setState({
      footerImg: null,
      cropImageFooterData: this.editorFooter
        .getImageScaledToCanvas()
        .toDataURL(),
    });
    // var contentType = 'image/png'
    let crooperDFooter = this.editorFooter.getImageScaledToCanvas().toDataURL();

    let form = _.extend(this.state.form, {});

    form[settings.keys.FOOTER_LOGO] = form[settings.keys.FOOTER_LOGO].asMutable
      ? form[settings.keys.FOOTER_LOGO].asMutable()
      : form[settings.keys.FOOTER_LOGO];

    form[settings.keys.FOOTER_LOGO].file.preview = crooperDFooter;

    // console.log(this.state.form)
    this.setState({
      // footer_logo: this.editorFooter.getImageScaledToCanvas().toDataURL(),
      footerFile: this.editorFooter.getImageScaledToCanvas().toDataURL(),
    });
  };

  cropImageBanner = () => {
    this.setState({
      bannerImg: null,
      cropImageBannerData: this.editorBanner
        .getImageScaledToCanvas()
        .toDataURL(),
    });
    let crooperDBanner = this.editorBanner.getImageScaledToCanvas().toDataURL();

    let form = _.extend(this.state.form, {});

    form[settings.keys.BANNER_IMG] = form[settings.keys.BANNER_IMG].asMutable
      ? form[settings.keys.BANNER_IMG].asMutable()
      : form[settings.keys.BANNER_IMG];

    form[settings.keys.BANNER_IMG].file.preview = crooperDBanner;

    // console.log(this.state.form)
    this.setState({
      bannerFile: this.editorBanner.getImageScaledToCanvas().toDataURL(),
    });
  };

  cropImageBtmBanner = () => {
    this.setState({
      btmbannerImg: null,
      cropImageBtmBannerData: this.editorBtmBanner
        .getImageScaledToCanvas()
        .toDataURL(),
    });
    let crooperDBtmBanner = this.editorBtmBanner
      .getImageScaledToCanvas()
      .toDataURL();

    let form = _.extend(this.state.form, {});

    form[settings.keys.BOTTOM_BANNER_IMG] = form[
      settings.keys.BOTTOM_BANNER_IMG
    ].asMutable
      ? form[settings.keys.BOTTOM_BANNER_IMG].asMutable()
      : form[settings.keys.BOTTOM_BANNER_IMG];

    form[settings.keys.BOTTOM_BANNER_IMG].file.preview = crooperDBtmBanner;

    // console.log(this.state.form)
    this.setState({
      btmBannerFile: this.editorBtmBanner.getImageScaledToCanvas().toDataURL(),
    });
  };

  cropClientTitleFavicon = () => {
    this.setState({
      clientFavicon: null,
      cropClientTitleFavicon: this.editorClientFavicon
        .getImageScaledToCanvas()
        .toDataURL(),
    });
    let croperDBtmClientFavicon = this.editorClientFavicon
      .getImageScaledToCanvas()
      .toDataURL();

    let form = _.extend(this.state.form, {});

    form[settings.keys.CLIENT_TITLE_FAVICON] = form[
      settings.keys.CLIENT_TITLE_FAVICON
    ].asMutable
      ? form[settings.keys.CLIENT_TITLE_FAVICON].asMutable()
      : form[settings.keys.CLIENT_TITLE_FAVICON];

    form[
      settings.keys.CLIENT_TITLE_FAVICON
    ].file.preview = croperDBtmClientFavicon;

    this.setState({
      clientFaviconFile: this.editorClientFavicon
        .getImageScaledToCanvas()
        .toDataURL(),
    });
  };

  cropAdminTitleFavicon = () => {
    this.setState({
      adminFavicon: null,
      cropAdminTitleFavicon: this.editorAdminFavicon
        .getImageScaledToCanvas()
        .toDataURL(),
    });
    let croperDBtmAdminFavicon = this.editorAdminFavicon
      .getImageScaledToCanvas()
      .toDataURL();

    let form = _.extend(this.state.form, {});

    form[settings.keys.ADMIN_TITLE_FAVICON] = form[
      settings.keys.ADMIN_TITLE_FAVICON
    ].asMutable
      ? form[settings.keys.ADMIN_TITLE_FAVICON].asMutable()
      : form[settings.keys.ADMIN_TITLE_FAVICON];

    form[
      settings.keys.ADMIN_TITLE_FAVICON
    ].file.preview = croperDBtmAdminFavicon;

    this.setState({
      adminFaviconFile: this.editorAdminFavicon
        .getImageScaledToCanvas()
        .toDataURL(),
    });
  };

  onLoadSuccess(e) {
    // console.log('e + e', e)
  }
  componentDidMount() {
    this.tryLoadCurrentSettings();
  }

  componentDidUpdate() {
    this.tryLoadCurrentSettings();
  }

  constructor(props) {
    super(props);
    autoBind(this);
  }

  tryLoadCurrentSettings() {
    if (_.size(this.props.settings) && !this.state.currentSettingsLoaded) {
      this.setState({
        currentSettingsLoaded: true,
        form: {
          contact_phone: this.props.settings[settings.keys.CONTACT_PHONE],
          contact_fax: this.props.settings[settings.keys.CONTACT_FAX],
          contact_email: this.props.settings[settings.keys.CONTACT_EMAIL],
          contact_address: this.props.settings[settings.keys.CONTACT_ADDRESS],
          contact_site_name: this.props.settings[
            settings.keys.CONTACT_SITE_NAME
          ],
          header_logo: this.props.settings[settings.keys.HEADER_LOGO],
          footer_logo: this.props.settings[settings.keys.FOOTER_LOGO],
          banner_img: this.props.settings[settings.keys.BANNER_IMG],
          bottom_banner_img: this.props.settings[
            settings.keys.BOTTOM_BANNER_IMG
          ],
          client_title_favicon: this.props.settings[
            settings.keys.CLIENT_TITLE_FAVICON
          ],
          admin_title_favicon: this.props.settings[
            settings.keys.ADMIN_TITLE_FAVICON
          ],
          site_meta_description: this.props.settings[
            settings.keys.SITE_META_DESCRIPTION
          ],
          site_meta_keywords: this.props.settings[
            settings.keys.SITE_META_KEYWORDS
          ],
          facebook_link:this.props.settings[
            settings.keys.FACEBOOK_LINK
          ],
          instagram_link:this.props.settings[
            settings.keys.INSAGRAM_LINK
          ],
          linkedin_link:this.props.settings[
            settings.keys.LINKEDIN_LINK
          ]
        },
      });
    }
  }

  handleInputChange(e) {
    let form = _.extend(this.state.form, {});
    form[e.target.name] = form[e.target.name].asMutable
      ? form[e.target.name].asMutable()
      : form[e.target.name];
    form[e.target.name].value = e.target.value;

    this.setState({ form });
  }

  handleSaveClick(e) {
    e.preventDefault();
    // console.log('this.state +++', this.state)
    this.props.saveSettings(this.state);
  }
  setEditorRef = (editor: any) => {
    if (editor) {
      this.editor = editor;
      // console.log('this.editor', this.editor)
      const img = this.editor.getImageScaledToCanvas().toDataURL();
      // console.log('hello i am here', img)
    }
  };
  setEditorRefFooter = (editor) => {
    if (editor) {
      this.editorFooter = editor;
      const img = this.editorFooter.getImageScaledToCanvas().toDataURL();
      // console.log('hello i am here for footer', img)
    }
  };

  setEditorRefBanner = (editor: any) => {
    if (editor) {
      this.editorBanner = editor;
      // console.log('this.editor', this.editor)
      const img = this.editorBanner.getImageScaledToCanvas().toDataURL();
      // console.log('hello i am here', img)
    }
  };

  setEditorRefBtmBanner = (editor: any) => {
    if (editor) {
      this.editorBtmBanner = editor;
      // console.log('this.editor', this.editor)
      const img = this.editorBtmBanner.getImageScaledToCanvas().toDataURL();
      // console.log('hello i am here', img)
    }
  };

  setEditorRefClientFavicon = (editor: any) => {
    if (editor) {
      this.editorClientFavicon = editor;
      // console.log('this.editor', this.editor)
      const img = this.editorClientFavicon.getImageScaledToCanvas().toDataURL();
      // console.log('hello i am here', img)
    }
  };

  setEditorRefAdminFavicon = (editor: any) => {
    if (editor) {
      this.editorAdminFavicon = editor;
      // console.log('this.editor', this.editor)
      const img = this.editorAdminFavicon.getImageScaledToCanvas().toDataURL();
      // console.log('hello i am here', img)
    }
  };

  handleCancelClick(e) {
    e.preventDefault();
    this.setState({
      form: {
        contact_phone: this.props.settings[settings.keys.CONTACT_PHONE],
        contact_fax: this.props.settings[settings.keys.CONTACT_FAX],
        contact_email: this.props.settings[settings.keys.CONTACT_EMAIL],
        contact_address: this.props.settings[settings.keys.CONTACT_ADDRESS],
        contact_site_name: this.props.settings[settings.keys.CONTACT_SITE_NAME],
        header_logo: this.props.settings[settings.keys.HEADER_LOGO],
        footer_logo: this.props.settings[settings.keys.FOOTER_LOGO],
        banner_img: this.props.settings[settings.keys.BANNER_IMG],
        bottom_banner_img: this.props.settings[settings.keys.BOTTOM_BANNER_IMG],
        client_title_favicon: this.props.settings[
          settings.keys.CLIENT_TITLE_FAVICON
        ],
        admin_title_favicon: this.props.settings[
          settings.keys.ADMIN_TITLE_FAVICON
        ],
        site_meta_description: this.props.settings[
          settings.keys.SITE_META_DESCRIPTION
        ],
        site_meta_keywords: this.props.settings[
          settings.keys.SITE_META_KEYWORDS
        ],
        facebook_link:this.props.settings[
          settings.keys.FACEBOOK_LINK
        ],
        instagram_link:this.props.settings[
          settings.keys.INSAGRAM_LINK
        ],
        linkedin_link:this.props.settings[
          settings.keys.LINKEDIN_LINK
        ]
      },
    });
  }

  getPreviewImage(file, position) {
    if (this.state[file]) {
      return <img src={this.state[file].preview} />;
    } else {
      return this.state.form[position] && this.state.form[position].imageURL ? (
        <img src={this.state.form[position].imageURL} />
      ) : null;
    }
  }

  isImage(file, position) {
    if (this.state.form[position] && this.state.form[position].imageURL) {
      return true;
    } else {
      return false;
    }
  }

  setImage = (fileName, formKey) => () => {
    this.setState({
      form: {
        ...this.state.form,
        [formKey]: {
          ...this.state.form[formKey],
          imageURL: null,
        },
      },
      [fileName]: null,
    });
  };

  handleHeaderFileDrop = (dropped) => {
    let form = _.extend(this.state.form, {});
    form[settings.keys.HEADER_LOGO] = form[settings.keys.HEADER_LOGO].asMutable
      ? form[settings.keys.HEADER_LOGO].asMutable()
      : form[settings.keys.HEADER_LOGO];
    form[settings.keys.HEADER_LOGO].file = dropped[0];
    // console.log(this.state.form, 'handleHeaderFileDrop')
    this.setState({ image: dropped[0], cropClass: false });
  };

  handleFooterFileDrop = (dropped) => {
    let form = _.extend(this.state.form, {});
    form[settings.keys.FOOTER_LOGO] = form[settings.keys.FOOTER_LOGO].asMutable
      ? form[settings.keys.FOOTER_LOGO].asMutable()
      : form[settings.keys.FOOTER_LOGO];
    form[settings.keys.FOOTER_LOGO].file = dropped[0];
    this.setState({ footerImg: dropped[0], cropClass: false });
  };

  handleBannerFileDrop = (dropped) => {
    let form = _.extend(this.state.form, {});
    form[settings.keys.BANNER_IMG] = form[settings.keys.BANNER_IMG].asMutable
      ? form[settings.keys.BANNER_IMG].asMutable()
      : form[settings.keys.BANNER_IMG];
    form[settings.keys.BANNER_IMG].file = dropped[0];
    this.setState({ bannerImg: dropped[0], cropClass: false });
  };

  handleBottomBannerFileDrop = (dropped) => {
    let form = _.extend(this.state.form, {});
    form[settings.keys.BOTTOM_BANNER_IMG] = form[
      settings.keys.BOTTOM_BANNER_IMG
    ].asMutable
      ? form[settings.keys.BOTTOM_BANNER_IMG].asMutable()
      : form[settings.keys.BOTTOM_BANNER_IMG];
    form[settings.keys.BOTTOM_BANNER_IMG].file = dropped[0];
    this.setState({ btmbannerImg: dropped[0], cropClass: false });
  };

  handleClientFaviconFileDrop = (dropped) => {
    let form = _.extend(this.state.form, {});
    form[settings.keys.CLIENT_TITLE_FAVICON] = form[
      settings.keys.CLIENT_TITLE_FAVICON
    ].asMutable
      ? form[settings.keys.CLIENT_TITLE_FAVICON].asMutable()
      : form[settings.keys.CLIENT_TITLE_FAVICON];
    form[settings.keys.CLIENT_TITLE_FAVICON].file = dropped[0];
    this.setState({ clientFavicon: dropped[0], cropClass: false });
  };

  handleAdminFaviconFileDrop = (dropped) => {
    let form = _.extend(this.state.form, {});
    form[settings.keys.ADMIN_TITLE_FAVICON] = form[
      settings.keys.ADMIN_TITLE_FAVICON
    ].asMutable
      ? form[settings.keys.ADMIN_TITLE_FAVICON].asMutable()
      : form[settings.keys.ADMIN_TITLE_FAVICON];
    form[settings.keys.ADMIN_TITLE_FAVICON].file = dropped[0];
    this.setState({ adminFavicon: dropped[0], cropClass: false });
  };

  render() {
    // const {image, headerFile, footerImg, footerFile} = this.state;
    let dropzoneContent = this.getPreviewImage("headerFile", "header_logo") ? (
      this.getPreviewImage("headerFile", "header_logo")
    ) : (
      <img src={"https://dummyimage.com/142x20/000/fff.jpg"} />
    );
    let dropzoneFooterContent = this.getPreviewImage(
      "footerFile",
      "footer_logo"
    ) ? (
      this.getPreviewImage("footerFile", "footer_logo")
    ) : (
      <img src={"https://dummyimage.com/142x20/000/fff.jpg"} />
    );
    let dropzoneBannerContent = this.getPreviewImage(
      "bannerFile",
      "banner_img"
    ) ? (
      this.getPreviewImage("bannerFile", "banner_img")
    ) : (
      <img src={"https://dummyimage.com/350x250/000/fff.jpg"} />
    );
    let dropzoneBottomBannerContent = this.getPreviewImage(
      "btmBannerFile",
      "bottom_banner_img"
    ) ? (
      this.getPreviewImage("btmBannerFile", "bottom_banner_img")
    ) : (
      <img src={"https://dummyimage.com/350x250/000/fff.jpg"} />
    );
    let dropzoneClientFaviconContent = this.getPreviewImage(
      "clientFaviconFile",
      "client_title_favicon"
    ) ? (
      this.getPreviewImage("clientFaviconFile", "client_title_favicon")
    ) : (
      <img src={"https://dummyimage.com/32x32/000/fff.jpg"} />
    );
    let dropzoneAdminFaviconContent = this.getPreviewImage(
      "adminFaviconFile",
      "admin_title_favicon"
    ) ? (
      this.getPreviewImage("adminFaviconFile", "admin_title_favicon")
    ) : (
      <img src={"https://dummyimage.com/32x32/000/fff.jpg"} />
    );

    let clientFavicon = (
      <div className="form-group">
        <label className="control-label">
          {strings.get("App.settings.contactSetting.clientTitleFavicon")}
        </label>

        {this.state.clientFavicon ||
        this.state.cropClientTitleFavicon ||
        this.state.clientFaviconFile ? (
          <input
            type="button"
            className="close-btn"
            onClick={this.removeImage(
              "clientFavicon",
              "clientFaviconFile",
              "client_title_favicon"
            )}
            value="X"
          />
        ) : this.isImage("clientFaviconFile", "client_title_favicon") ? (
          <input
            type="button"
            className="close-btn"
            onClick={this.setImage("clientFaviconFile", "client_title_favicon")}
            value="X"
          />
        ) : (
          ""
        )}

        <Dropzone
          className="dropzone dropzone-banner-bottom"
          onDrop={this.handleClientFaviconFileDrop}
          multiple={false}
          maxSize={4096000}
          accept="image/*"
        >
          {/* {dropzoneClientFaviconContent}
          {this.state.clientFavicon ? (
            <ReactAvatarEditor
              scale={this.state.scaleClientTitleFavicon}
              rotate={0}
              className="image-placeholder"
              image={this.state.clientFavicon}
              width={16}
              height={16}
              ref={(ref) => this.setEditorRefClientFavicon(ref)}
            />
          ) : this.state.clientFaviconFile ? (
            <img src={this.state.clientFaviconFile} />
          ) : (
            ""
          )} */}
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              {dropzoneClientFaviconContent}
              {this.state.clientFavicon ? (
                <ReactAvatarEditor
                  scale={this.state.scaleClientTitleFavicon}
                  rotate={0}
                  className="image-placeholder"
                  image={this.state.clientFavicon}
                  width={16}
                  height={16}
                  ref={(ref) => this.setEditorRefClientFavicon(ref)}
                />
              ) : this.state.clientFaviconFile ? (
                <img src={this.state.clientFaviconFile} />
              ) : (
                ""
              )}
            </div>
          )}
        </Dropzone>

        {this.state.cropClass === false ? (
          <div className="help-block">
            <div className="help-block">
              ( {strings.get("App.commonImage.faviconSizeSuggestions")} )
            </div>
            <small className="text-danger clientFaviconErrorUpload" />
            {this.state.clientFavicon !== null ? (
              <input
                type="range"
                step="0.01"
                min="0.1"
                max="2"
                name="scaleClientTitleFavicon"
                value={this.state.scaleClientTitleFavicon}
                onChange={this.handleChange}
              />
            ) : (
              ""
            )}

            {this.state.clientFavicon !== null ? (
              <input
                type="button"
                className="crop-image-btn"
                onClick={this.cropClientTitleFavicon}
                value="Crop"
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
    let adminFavicon = (
      <div className="form-group">
        <label className="control-label">
          {strings.get("App.settings.contactSetting.adminTitleFavicon")}
        </label>

        {this.state.adminFavicon ||
        this.state.cropAdminTitleFavicon ||
        this.state.adminFaviconFile ? (
          <input
            type="button"
            className="close-btn"
            onClick={this.removeImage(
              "adminFavicon",
              "adminFaviconFile",
              "admin_title_favicon"
            )}
            value="X"
          />
        ) : this.isImage("adminFaviconFile", "admin_title_favicon") ? (
          <input
            type="button"
            className="close-btn"
            onClick={this.setImage("adminFaviconFile", "admin_title_favicon")}
            value="X"
          />
        ) : (
          ""
        )}

        <Dropzone
          className="dropzone dropzone-banner-bottom"
          onDrop={this.handleAdminFaviconFileDrop}
          multiple={false}
          maxSize={4096000}
          accept="image/*"
        >
          {/* {dropzoneAdminFaviconContent}
          {this.state.adminFavicon ? (
            <ReactAvatarEditor
              scale={this.state.scaleAdminTitleFavicon}
              rotate={0}
              className="image-placeholder"
              image={this.state.adminFavicon}
              width={16}
              height={16}
              ref={(ref) => this.setEditorRefAdminFavicon(ref)}
            />
          ) : this.state.adminFaviconFile ? (
            <img src={this.state.adminFaviconFile} />
          ) : (
            ""
          )} */}
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              {dropzoneAdminFaviconContent}
              {this.state.adminFavicon ? (
                <ReactAvatarEditor
                  scale={this.state.scaleAdminTitleFavicon}
                  rotate={0}
                  className="image-placeholder"
                  image={this.state.adminFavicon}
                  width={16}
                  height={16}
                  ref={(ref) => this.setEditorRefAdminFavicon(ref)}
                />
              ) : this.state.adminFaviconFile ? (
                <img src={this.state.adminFaviconFile} />
              ) : (
                ""
              )}
            </div>
          )}
        </Dropzone>

        {this.state.cropClass === false ? (
          <div className="help-block">
            <div className="help-block">
              ( {strings.get("App.commonImage.faviconSizeSuggestions")} )
            </div>
            <small className="text-danger adminFaviconErrorUpload" />
            {this.state.adminFavicon !== null ? (
              <input
                type="range"
                step="0.01"
                min="0.1"
                max="2"
                name="scaleAdminTitleFavicon"
                value={this.state.scaleAdminTitleFavicon}
                onChange={this.handleChange}
              />
            ) : (
              ""
            )}

            {this.state.adminFavicon !== null ? (
              <input
                type="button"
                className="crop-image-btn"
                onClick={this.cropAdminTitleFavicon}
                value="Crop"
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
    let actionButtons = (
      <div className="form-actions">
        <button className="btn btn-primary" onClick={this.handleSaveClick}>
          {strings.get("App.email.save")}
        </button>
        <button className="btn btn-default" onClick={this.handleCancelClick}>
          {strings.get("App.email.cancel")}
        </button>
      </div>
    );
    let bottomBannerImage = (
      <div className="form-group">
        <label className="control-label">
          {strings.get("App.settings.contactSetting.bottomBannerImg")}
        </label>

        {this.state.btmbannerImg ||
        this.state.cropImageBtmBannerData ||
        this.state.btmBannerFile ? (
          <input
            type="button"
            className="close-btn"
            onClick={this.removeImage(
              "btmbannerImg",
              "btmBannerFile",
              "bottom_banner_img"
            )}
            value="X"
          />
        ) : this.isImage("btmBannerFile", "bottom_banner_img") ? (
          <input
            type="button"
            className="close-btn"
            onClick={this.setImage("btmBannerFile", "bottom_banner_img")}
            value="X"
          />
        ) : (
          ""
        )}

        <Dropzone
          className="dropzone dropzone-banner-bottom"
          onDrop={this.handleBottomBannerFileDrop}
          multiple={false}
          maxSize={4096000}
          accept="image/*"
        >
          {/* {dropzoneBottomBannerContent}

          {this.state.btmbannerImg ? (
            <ReactAvatarEditor
              scale={this.state.scaleBtmBanner}
              rotate={0}
              className="image-placeholder"
              image={this.state.btmbannerImg}
              width={350}
              height={150}
              ref={(ref) => this.setEditorRefBtmBanner(ref)}
            />
          ) : this.state.btmBannerFile ? (
            <img src={this.state.btmBannerFile} />
          ) : (
            ""
          )} */}
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              {dropzoneBottomBannerContent}

              {this.state.btmbannerImg ? (
                <ReactAvatarEditor
                  scale={this.state.scaleBtmBanner}
                  rotate={0}
                  className="image-placeholder"
                  image={this.state.btmbannerImg}
                  width={350}
                  height={150}
                  ref={(ref) => this.setEditorRefBtmBanner(ref)}
                />
              ) : this.state.btmBannerFile ? (
                <img src={this.state.btmBannerFile} />
              ) : (
                ""
              )}
            </div>
          )}
        </Dropzone>

        {this.state.cropClass === false ? (
          <div className="help-block">
            <div className="help-block">
              ( {strings.get("App.commonImage.bannerSizeSuggestion")} )
            </div>
            <small className="text-danger bottomBannerErrorUpload" />
            {this.state.btmbannerImg !== null ? (
              <input
                type="range"
                step="0.01"
                min="0.1"
                max="2"
                name="scaleBtmBanner"
                value={this.state.scaleBtmBanner}
                onChange={this.handleChange}
              />
            ) : (
              ""
            )}

            {this.state.btmbannerImg !== null ? (
              <input
                type="button"
                className="crop-image-btn"
                onClick={this.cropImageBtmBanner}
                value="Crop"
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );

    return (
      <div className="EmailForm row">
        <form className="col-sm-12 col-md-8">
          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.contactSetting.phone")}
            </label>
            <input
              className="form-control"
              type="text"
              name="contact_phone"
              value={this.state.form.contact_phone.value}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.contactSetting.fax")}
            </label>
            <inputtext-danger
              headerErrorUpload
              className="form-control"
              type="text"
              name="contact_fax"
              value={this.state.form.contact_fax.value}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.contactSetting.email")}
            </label>
            <input
              className="form-control"
              type="text"
              name="contact_email"
              value={this.state.form.contact_email.value}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.contactSetting.address")}
            </label>
            <input
              className="form-control"
              type="text"
              name="contact_address"
              value={this.state.form.contact_address.value}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.contactSetting.siteName")}
            </label>
            <input
              className="form-control"
              type="text"
              name="contact_site_name"
              value={
                this.state.form.contact_site_name
                  ? this.state.form.contact_site_name.value
                  : ""
              }
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.contactSetting.siteMetaDescription")}
            </label>
            <input
              className="form-control"
              type="text"
              name="site_meta_description"
              value={
                this.state.form.site_meta_description
                  ? this.state.form.site_meta_description.value
                  : ""
              }
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.contactSetting.siteMetaKeywords")}
            </label>
            <input
              className="form-control"
              type="text"
              name="site_meta_keywords"
              value={
                this.state.form.site_meta_keywords
                  ? this.state.form.site_meta_keywords.value
                  : ""
              }
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.contactSetting.facebookLink")}
            </label>
            <input
              className="form-control"
              type="text"
              name="facebook_link"
              value={
                this.state.form.facebook_link
                  ? this.state.form.facebook_link.value
                  : ""
              }
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.contactSetting.linkeInLink")}
            </label>
            <input
              className="form-control"
              type="text"
              name="linkedin_link"
              value={
                this.state.form.linkedin_link
                  ? this.state.form.linkedin_link.value
                  : ""
              }
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.contactSetting.InstagramLink")}
            </label>
            <input
              className="form-control"
              type="text"
              name="instagram_link"
              value={
                this.state.form.instagram_link
                  ? this.state.form.instagram_link.value
                  : ""
              }
              onChange={this.handleInputChange}
            />
          </div>

          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.contactSetting.headerLogo")}
            </label>

            {this.state.image ||
            this.state.cropImageData ||
            this.state.headerFile ? (
              <input
                type="button"
                className="close-btn"
                onClick={this.removeImage("image", "headerFile", "header_logo")}
                value="X"
              />
            ) : this.isImage("headerFile", "header_logo") ? (
              <input
                type="button"
                className="close-btn"
                onClick={this.setImage("headerFile", "header_logo")}
                value="X"
              />
            ) : (
              ""
            )}

            {/* <input type='button' className={this.state.active ? 'your_className': null}  onClick={this.handleImage} value='X' /> */}

            <Dropzone
              className="dropzone dropzone-first"
              onDrop={this.handleHeaderFileDrop}
              multiple={false}
              maxSize={4096000}
              accept="image/*"
            >
              {/* {dropzoneContent}

              {this.state.image ? (
                <ReactAvatarEditor
                  scale={this.state.scale}
                  rotate={0}
                  className="image-placeholder"
                  image={this.state.image}
                  width={142}
                  height={20}
                  ref={(ref) => this.setEditorRef(ref)}
                />
              ) : this.state.headerFile ? (
                <img src={this.state.headerFile} />
              ) : (
                ""
              )} */}
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  {dropzoneContent}

                  {this.state.image ? (
                    <ReactAvatarEditor
                      scale={this.state.scale}
                      rotate={0}
                      className="image-placeholder"
                      image={this.state.image}
                      width={142}
                      height={20}
                      ref={(ref) => this.setEditorRef(ref)}
                    />
                  ) : this.state.headerFile ? (
                    <img src={this.state.headerFile} />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </Dropzone>

            {this.state.cropClass === false ? (
              <div className="help-block">
                <div className="help-block">
                  (Suggestie: breedte 142px, hoogte 20px)
                </div>
                <small className="text-danger headerErrorUpload" />
                {this.state.image !== null ? (
                  <input
                    type="range"
                    step="0.01"
                    min="0.1"
                    max="2"
                    name="scale"
                    value={this.state.scale}
                    onChange={this.handleChange}
                  />
                ) : (
                  ""
                )}

                {this.state.image !== null ? (
                  <input
                    type="button"
                    className="crop-image-btn"
                    onClick={this.cropImage}
                    value="Crop"
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.contactSetting.footerLogo")}
            </label>
            {this.state.footerImg ||
            this.state.cropImageFooterData ||
            this.state.footerFile ? (
              <input
                type="button"
                className="close-btn"
                onClick={this.removeImage(
                  "footerImg",
                  "footerFile",
                  "footer_logo"
                )}
                value="X"
              />
            ) : this.isImage("footerFile", "footer_logo") ? (
              <input
                type="button"
                className="close-btn"
                onClick={this.setImage("footerFile", "footer_logo")}
                value="X"
              />
            ) : (
              ""
            )}
            <Dropzone
              className="dropzone dropzone-second"
              onDrop={this.handleFooterFileDrop}
              multiple={false}
              maxSize={4096000}
              accept="image/*"
            >
              {/* {dropzoneFooterContent}

              {this.state.footerImg ? (
                <ReactAvatarEditor
                  scale={this.state.scaleFooter}
                  rotate={0}
                  className="image-placeholder"
                  image={this.state.footerImg}
                  width={142}
                  height={20}
                  ref={(ref) => this.setEditorRefFooter(ref)}
                />
              ) : this.state.footerFile ? (
                <img src={this.state.footerFile} />
              ) : (
                ""
              )} */}
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  {dropzoneFooterContent}

                  {this.state.footerImg ? (
                    <ReactAvatarEditor
                      scale={this.state.scaleFooter}
                      rotate={0}
                      className="image-placeholder"
                      image={this.state.footerImg}
                      width={142}
                      height={20}
                      ref={(ref) => this.setEditorRefFooter(ref)}
                    />
                  ) : this.state.footerFile ? (
                    <img src={this.state.footerFile} />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </Dropzone>

            {this.state.cropClass === false ? (
              <div className="help-block">
                <div className="help-block">
                  ( {strings.get("App.commonImage.sizeSuggestion")} )
                </div>
                <small className="text-danger footerErrorUpload" />
                {this.state.footerImg !== null ? (
                  <input
                    type="range"
                    step="0.01"
                    min="0.1"
                    max="2"
                    name="scaleFooter"
                    value={this.state.scaleFooter}
                    onChange={this.handleChange}
                  />
                ) : (
                  ""
                )}

                {this.state.footerImg !== null ? (
                  <input
                    type="button"
                    className="crop-image-btn"
                    onClick={this.cropImageFooter}
                    value="Crop"
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.contactSetting.bannerImg")}
            </label>

            {this.state.bannerImg ||
            this.state.cropImageBannerData ||
            this.state.bannerFile ? (
              <input
                type="button"
                className="close-btn"
                onClick={this.removeImage(
                  "bannerImg",
                  "bannerFile",
                  "banner_img"
                )}
                value="X"
              />
            ) : this.isImage("bannerFile", "banner_img") ? (
              <input
                type="button"
                className="close-btn"
                onClick={this.setImage("bannerFile", "banner_img")}
                value="X"
              />
            ) : (
              ""
            )}

            <Dropzone
              className="dropzone dropzone-banner"
              onDrop={this.handleBannerFileDrop}
              multiple={false}
              maxSize={4096000}
              accept="image/*"
            >
              {/* {dropzoneBannerContent}

              {this.state.bannerImg ? (
                <ReactAvatarEditor
                  scale={this.state.scaleBanner}
                  rotate={0}
                  className="image-placeholder"
                  image={this.state.bannerImg}
                  width={350}
                  height={150}
                  ref={(ref) => this.setEditorRefBanner(ref)}
                />
              ) : this.state.bannerFile ? (
                <img src={this.state.bannerFile} />
              ) : (
                ""
              )} */}
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  {dropzoneBannerContent}

                  {this.state.bannerImg ? (
                    <ReactAvatarEditor
                      scale={this.state.scaleBanner}
                      rotate={0}
                      className="image-placeholder"
                      image={this.state.bannerImg}
                      width={350}
                      height={150}
                      ref={(ref) => this.setEditorRefBanner(ref)}
                    />
                  ) : this.state.bannerFile ? (
                    <img src={this.state.bannerFile} />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </Dropzone>

            {this.state.cropClass === false ? (
              <div className="help-block">
                <div className="help-block">
                  ( {strings.get("App.commonImage.bannerSizeSuggestion")} )
                </div>
                <small className="text-danger bannerErrorUpload" />
                {this.state.bannerImg !== null ? (
                  <input
                    type="range"
                    step="0.01"
                    min="0.1"
                    max="2"
                    name="scaleBanner"
                    value={this.state.scaleBanner}
                    onChange={this.handleChange}
                  />
                ) : (
                  ""
                )}

                {this.state.bannerImg !== null ? (
                  <input
                    type="button"
                    className="crop-image-btn"
                    onClick={this.cropImageBanner}
                    value="Crop"
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          {bottomBannerImage}
          {clientFavicon}
          {adminFavicon}
          {actionButtons}
        </form>
      </div>
    );
  }
}

ContactSettingForm.propTypes = {
  settings: PropTypes.object,
  saveSettings: PropTypes.func.isRequired,
  // console.log('hi',settings)
};
function mapStateToProps(state) {
  return {
    settings: settingsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllSettings: () => {
      dispatch(settingsActions.fetchAllItems());
    },
    updateSetting: (data) => {
      return dispatch(settingsActions.updateItem(data));
    },
    updateImageSetting: (data) => {
      return dispatch(settingsActions.updateImageItem(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactSettingForm);
