import React, { Component } from "react";

import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as companiesActions from "../../store/companies/actions";
import * as companiesSelectors from "../../store/companies/selectors";
import * as companyCategoriesActions from "../../store/companyCategories/actions";
import * as companyCategoriesSelectors from "../../store/companyCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
// import SubTopbar from "../../components/SubTopbar";
import SearchBar from "../../components/SearchBar";
import CompanyList from "../../components/company/CompanyList";
import CompanyCategoryFilter from "../../components/company/CompanyCategoryFilter";
import CompanyPagesize from "../../components/company/CompanyPagesize";
import Pagination from "../../components/Pagination";

class CompanyListPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchCompanies();
    this.props.fetchAllCompanyCategories();
  }

  render() {
    return (
      <div className="CompanyListPage">
        <Topbar>
          <div className="title">
            <Link to="/glossaries/companies">
              {strings.get("App.companyPages.title")}
            </Link>
          </div>
          <SearchBar
            placeholder={strings.get("App.searchPlaceholders.company")}
            searchTerm={this.props.filters.searchTerm}
            fetchItems={this.props.fetchCompanies}
            setSearchTerm={this.props.setSearchTerm}
          />
          <div className="main-btns">
            <Link
              to="/glossaries/companies/categories"
              className="btn btn-default"
            >
              <span>{strings.get("App.companyPages.manage")}</span>{" "}
              <i className="ion-pricetag"></i>
            </Link>
            <Link to="/glossaries/companies/add" className="btn btn-primary">
              <span>{strings.get("App.companyPages.addCompany")}</span>{" "}
              <i className="ion-plus"></i>
            </Link>
          </div>
        </Topbar>

        <div className="content">
          <div className="row">
            <div className="col-xs-6">
              <CompanyCategoryFilter
                filters={this.props.filters}
                companyCategories={this.props.companyCategories}
                fetchItems={this.props.fetchCompanies}
                setCompanyCategoryId={this.props.setCompanyCategoryId}
              />
            </div>
            <div className="col-xs-6">
              <CompanyPagesize
                sorter={this.props.sorter}
                fetchItems={this.props.fetchCompanies}
                setSorter={this.props.setSorter}
              />
            </div>
          </div>
          <CompanyList
            items={this.props.companies}
            sorter={this.props.sorter}
            currentItem={this.props.currentCompany}
            fetchItems={this.props.fetchCompanies}
            setCurrentItemId={this.props.setCurrentCompanyId}
            unsetCurrentItemId={this.props.unsetCurrentCompanyId}
            deleteItem={this.props.deleteCompany}
            toggleSorter={this.props.toggleSorter}
            updateColumn={this.props.updateColumn}
          />

          <Pagination
            pagination={this.props.pagination}
            setCurrentPage={this.props.setCurrentPage}
            fetchItems={this.props.fetchCompanies}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    companies: companiesSelectors.getItemsByPage(
      state,
      companiesSelectors.getPagination(state).currentPage
    ),
    sorter: companiesSelectors.getSorter(state),
    filters: companiesSelectors.getFilters(state),
    pagination: companiesSelectors.getPagination(state),
    currentCompany: companiesSelectors.getCurrentItem(state),
    companyCategories: companyCategoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompanies: (deleteCache) => {
      dispatch(companiesActions.fetchItems(deleteCache));
    },
    setSearchTerm: (searchTerm) => {
      dispatch(companiesActions.setSearchTerm(searchTerm));
    },
    toggleSorter: (searchTerm) => {
      dispatch(companiesActions.toggleSorter(searchTerm));
    },
    setCurrentPage: (page) => {
      dispatch(companiesActions.setCurrentPage(page));
    },
    setCurrentCompanyId: (id) => {
      dispatch(companiesActions.setCurrentItemId(id));
    },
    unsetCurrentCompanyId: () => {
      dispatch(companiesActions.unsetCurrentItemId());
    },
    deleteCompany: (id) => {
      dispatch(companiesActions.deleteItem(id));
    },
    setCompanyCategoryId: (id) => {
      dispatch(companiesActions.setCompanyCategoryId(id));
    },
    fetchAllCompanyCategories: () => {
      dispatch(companyCategoriesActions.fetchAllItems());
    },
    setSorter: (sorter) => {
      dispatch(companiesActions.setSorter(sorter));
    },
    updateColumn: (id, column, value) => {
      dispatch(companiesActions.updateColumn(id, column, value));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyListPage);
