import React, { Component } from 'react';

import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import settings from "../../../services/settings";
import { Link } from "react-router-dom";
import "../settings.scss";
import "../../Page.scss";

import * as settingsActions from "../../../store/settings/actions";
import * as settingsSelectors from "../../../store/settings/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";

import Topbar from "../../../components/Topbar";
import SubTopbar from "../../../components/SubTopbar";

import SettingsTabs from "../../../components/settings/SettingsTabs";
import ApiSettingsForm from '../../../components/settings/apiSettings/ApiSettingsForm';
import { NotificationManager } from "react-notifications";
class ApiSettingsPage extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    base64Convert = (obj) => {
      let data = {...obj}
      data.value = window.btoa(obj.value);
      return data
    }
    saveSettings(data) {
        let facebook_key = this.props.updateSetting(
          this.base64Convert(data.form[settings.keys.FACEBOOK_KEY])
        );
    
        let linkedin_key = this.props.updateSetting(
          this.base64Convert(data.form[settings.keys.LINKEDIN_KEY])
        );
    
        let linkedin_secret = this.props.updateSetting(
          this.base64Convert(data.form[settings.keys.LINKEDIN_SECRET])
        );
    
        Promise.all([
            facebook_key,
            linkedin_key,
            linkedin_secret
        ]).then(() => {
          // this.showSaveModal();
          NotificationManager.success(
            "",
            strings.get("App.settings.settingsSaved")
          );
        });
      }

    render() {
        return (
            <div className="ApiSettingPage">
                <Topbar>
                    <div className="title">
                        <Link to="/settings/apiSettings">
                            {strings.get("App.settings.title")}
                        </Link>
                    </div>
                </Topbar>
                <div className="content settings-container">
                    <SubTopbar>
                        <SettingsTabs />
                    </SubTopbar>
                    <div className="setting-content">
                        <ApiSettingsForm 
                            saveSettings={this.saveSettings}
                        />
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
      settings: settingsSelectors.getItems(state),
      exceptions: exceptionsSelectors.getItems(state),
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      fetchAllSettings: () => {
        dispatch(settingsActions.fetchAllItems());
      },
      updateSetting: (data) => {
        return dispatch(settingsActions.updateItem(data));
      },
      updateImageSetting: (data) => {
        return dispatch(settingsActions.updateImageItem(data));
      },
      clearExceptions: () => {
        dispatch(exceptionsActions.clear());
      },
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(ApiSettingsPage)