// import React, { Component } from "react";
// import { Editor as TinyMCE } from "@tinymce/tinymce-react";
// import PropTypes from "prop-types";
// class Editor extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       content: props.content,
//     };
//   }

//   componentDidMount() {
//     this.setContent();
//     // this.editorInit();
//     // console.log(TinyMCE, this.props);
//     // if (this.props.isHasHelpbox) {
//     //   console.log("id", this.refs.editor.id, document.getElementById(this.refs.editor.id));
//     //   document.getElementById(this.refs.editor.id).addEventListener("click", () => {
//     //       this.onClickText();
//     //       console.log("click called")
//     //     });

//     // }
//     // document.getElementById(this.refs.editor.id).contentDocument.addEventListener(...)
//   }

//   // editorInit = () => {
//   //   let editor = this.refs.editor;
//   //   if (editor) {
//   //     editor.handleInit = (editor) => {
//   //       console.log("initliazation", editor);
//   //       editor.target.target.on("keydown", (e) => {
//   //         console.log("keydowndfdgdrf", e);
//   //       });
//   //     };
//   //   }
//   // };

//   setContent = () => {
//     console.log(
//       // this.refs,
//       // this.refs.editor,
//       // this.refs.editor.currentContent,
//      "Id of editor", this.refs.editor
//     );
//     let editor = TinyMCE.activeEditor.get(this.refs.editor.id);
//     if (
//       editor &&
//       this.state.content &&
//       editor.getContent() !== this.state.content
//     ) {
//       editor.setContent(this.state.content);
//       editor.on("keydown", (e) => {
//         if (e.keyCode === 9) {
//           e.preventDefault();
//           editor.insertContent(
//             "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
//           );
//         }
//       });
//     }
//     setTimeout(() => {
//     if (editor && this.props.handleKeyUp) {
//       editor.on("keyup change", (e) => {
//         var value = editor.getContent();
//         if (value !== editor._lastChange) {
//           editor._lastChange = value;
//           this.props.handleKeyUp(editor._lastChange);
//         }
//       });
//       if (this.props.isHasHelpbox) {
//         editor.on("click", () => {
//           this.onClickText();
//         });
//         editor.on("dblclick", () => {
//           this.onDoubleClickText();
//         });
//       }
//     }
//     }, 1000);
//   };

//   UNSAFE_componentWillReceiveProps(nextProps) {
//     let content = nextProps.content;
//     this.setState(
//       {
//         content,
//       },
//       () => {
//         this.setContent();
//       }
//     );
//   }

//   // onClickText = () => {
//   //   let iframe = TinyMCE.EditorManager.get(this.refs.editor.id).iframeElement;
//   //   var idoc = iframe.contentDocument || iframe.contentWindow.document; // ie compatibility
//   //   let selected = idoc.getSelection();
//   //   let arrTmp =
//   //     (selected.focusNode.wholeText &&
//   //       selected.focusNode.wholeText.split("")) ||
//   //     [];
//   //   let end = 0;
//   //   let start = 0;
//   //   for (let i = selected.focusOffset; i <= arrTmp.length - 1; i++) {
//   //     if (arrTmp[i] === "]") {
//   //       end = i + 1;
//   //       break;
//   //     }
//   //   }
//   //   for (let i = selected.focusOffset; i >= 0; i--) {
//   //     if (arrTmp[i] == "[") {
//   //       start = i;
//   //       break;
//   //     }
//   //   }
//   //   let data =
//   //     selected.focusNode.wholeText &&
//   //     selected.focusNode.wholeText.substring(start, end);
//   //   let regexSelect = /(select_\d+)/g;
//   //   let regexClause = /(clause_\d+)/g;
//   //   let regexField = /(field_\d+)/g;
//   //   let regexCodeBlock = /(block_\d+)/g;
//   //   let regexCarousel = /(carousel_\d+)/g;
//   //   let regexUnknown = /\[(.*)\]/g;

//   //   let match = null;
//   //   let result = null;
//   //   match = regexSelect.exec(data);
//   //   if (match) result = match[1];
//   //   else {
//   //     match = regexClause.exec(data);
//   //     if (match) result = match[1];
//   //     else {
//   //       match = regexField.exec(data);
//   //       if (match) result = match[1];
//   //       else {
//   //         match = regexCodeBlock.exec(data);
//   //         if (match) result = match[1];
//   //         else {
//   //           match = regexCarousel.exec(data);
//   //           if (match) {
//   //             result = match[1];
//   //           } else {
//   //             match = regexUnknown.exec(data);
//   //             if (match) result = match[1];
//   //           }
//   //         }
//   //       }
//   //     }
//   //   }

//   //   this.props.onScroll && this.props.onScroll(result);
//   // };

//   // onDoubleClickText = () => {
//   //   let iframe = TinyMCE.EditorManager.get(this.refs.editor.id).iframeElement;
//   //   var idoc = iframe.contentDocument || iframe.contentWindow.document; // ie compatibility
//   //   let selected = idoc.getSelection();
//   //   let result =
//   //     selected.focusNode.wholeText &&
//   //     selected.focusNode.wholeText.substring(
//   //       selected.baseOffset,
//   //       selected.focusOffset
//   //     );
//   //   this.props.onScroll && this.props.onScroll(result);
//   // };

//   onChange = (e) => {
//     this.props.handleChange(e);
//   };

//   render() {
//     return (
//       <TinyMCE
//         ref="editor"
//         value={this.props.content || ""}
//         initialValue={this.props.content || ""}
//         onChange={this.onChange}
//         onChange={this.props.handleChange}
//         init={{
//           plugins: "link code paste lists",
//           toolbar:
//             "undo redo | bold italic | alignleft aligncenter alignright code bullist numlist",
//           height: this.props.height || 400,
//           setup: function (editor) {
//             editor.on("keydown", (e) => {
//               console.log("keydown init in editor", e, this);
//             });
//           },
//         }}
//       />
//     );
//   }
// }

// Editor.propTypes = {
//   handleChange: PropTypes.func.isRequired,
//   height: PropTypes.number,
// };

// export default Editor;

import React, { Component } from "react";
import { Editor as TinyMCE } from "@tinymce/tinymce-react";
import _ from "lodash";
import PropTypes from "prop-types";
import $ from "jquery";   
import '../static/css/jquery.atwho.css'
import '../static/css/jquery.atwho.min.css'
import '../static/jquery/jquery.atwho.js'
import '../static/jquery/jquery.caret.js'

class Editor extends Component {
  delay= null;
  endCode= {};
  constructor(props) {
    super(props);
    this.state = {
      codeSuggest: this.props.codeSuggest,
      fieldSuggest: this.props.fieldSuggest,
      fileSelectors: []
    }
  }
  // onChange = (e) => {
  //   if(this.props.handleKeyUp){
  //     console.log("true");
  //     this.props.handleKeyUp(e.target.getContent())
  //   }else{
  //     this.props.handleChange(e);
  //   }
  // };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(JSON.stringify(nextProps.codeSuggest) !== JSON.stringify(this.state.codeSuggest) && 
      JSON.stringify(nextProps.fieldSuggest) !== JSON.stringify(this.state.fieldSuggest)) {
        this.setState({
          codeSuggest: nextProps.codeSuggest,
          fieldSuggest: nextProps.fieldSuggest,
        });
        this.reInit();
      }
      if(this.props.isHasUploadSelector && _.size(this.props.fileSelectors) && !_.size(this.state.fileSelectors)) {
       this.setState({
        fileSelectors: _.map(this.props.fileSelectors,(selectors)=>{
            let obj = {}
            obj.name=selectors.name;
            obj.id=selectors.id;
            obj.value=`field_${selectors.id}`;
            return obj;
          }) 
        })
      }
  }

  onChange = (content) => {
    if (this.props.handleKeyUp) {
      this.props.handleKeyUp(content);
    } else {
      this.props.handleChange(content);
    }
  };

  reInit = () => {  
  const editor = this.refs.editor.editor, self= this;
  if(editor && editor.contentDocument){
    $(editor.contentDocument.activeElement)
      .atwho({
        at: "[",
        data: self.state.codeSuggest || [],
        displayTpl: '<li>${name} <br> <small> ${contentName} </small> </li>',
        limit: 50,
        insertTpl: (opt) => {
          return `<span>[${opt.name}]</span>`
        },
        callbacks: {
          filter: (query, data, searchKey) =>{
            return  _.filter(data,(item=> (item.name.toLowerCase().indexOf(query.toLowerCase()) > -1 || item.contentName.toLowerCase().indexOf(query.toLowerCase()) > -1 ))).map((item,index)=> {item.atwho_order=index; return item});
          },
          beforeInsert:(value, el, e)=>{
            self.getEnteredCode(value);
            return value;
          }
        }
      })
      .atwho({
        at: "[[",
        data: self.state.fieldSuggest||[],
        displayTpl: '<li>${name}<br> <small> ${contentName} </small> </li>',
        limit: 50,
        insertTpl: (opt) => {
          return `[[${opt.name}]]`
        },
        callbacks:{
          filter: (query, data, searchKey) =>{
            return  _.filter(data,(item=> (item.name.toLowerCase().indexOf(query.toLowerCase()) > -1 || item.contentName.toLowerCase().indexOf(query.toLowerCase()) > -1 ))).map((item,index)=> {item.atwho_order=index; return item});
          },
        }
      });
    }
  }

  onClickText = (editor) => {
    let iframe = editor.iframeElement;
    var idoc = iframe.contentDocument || iframe.contentWindow.document; // ie compatibility
    let selected = idoc.getSelection();
    let offSet = {
      offsetX: selected.focusNode.parentElement.offsetLeft,
      offsetY: selected.focusNode.parentElement.offsetTop
    }
    let arrTmp =
      (selected.focusNode.wholeText &&
        selected.focusNode.wholeText.split("")) ||
      [];
    let end = 0;
    let start = 0;
    for (let i = selected.focusOffset; i <= arrTmp.length - 1; i++) {
      if (arrTmp[i] === "]") {
        end = i + 1;
        break;
      }
    }
    for (let i = selected.focusOffset; i >= 0; i--) {
      if (arrTmp[i] == "[") {
        start = i;
        break;
      }
    }
    let data =
      selected.focusNode.wholeText &&
      selected.focusNode.wholeText.substring(start, end);
    let regexSelect = /(select_\d+)/g;
    let regexClause = /(clause_\d+)/g;
    let regexField = /(field_\d+)/g;
    let regexCodeBlock = /(block_\d+)/g;
    let regexCarousel = /(carousel_\d+)/g;
    let regexUnknown = /\[(.*)\]/g;

    let match = null;
    let result = null;
    match = regexSelect.exec(data);
    if (match) result = match[1];
    else {
      match = regexClause.exec(data);
      if (match) result = match[1];
      else {
        match = regexField.exec(data);
        if (match) result = match[1];
        else {
          match = regexCodeBlock.exec(data);
          if (match) result = match[1];
          else {
            match = regexCarousel.exec(data);
            if (match) {
              result = match[1];
            } else {
              match = regexUnknown.exec(data);
              if (match) result = match[1];
            }
          }
        }
      }
    }
    this.props.onScroll && this.props.onScroll(result);
    this.props.onClick && this.props.onClick(result);
  };

  onDelayedClick = (editor) =>{
    if(this.delay) clearTimeout(this.delay);
    this.delay=_.delay(()=>{this.onClickText(editor)}, 1000)
  }

  defaultOptions = () =>`
  <br>
  <span>&nbsp;&nbsp;&nbsp; [option.aaa] example text a [/option] </span> <br/>
  <span>&nbsp;&nbsp;&nbsp; [option.bbb] example tex b [/option]</span>  <br/>
  <span>&nbsp;&nbsp;&nbsp; [option.ccc] example text c [/option] </span> <br/>
  `;

  getEnteredCode = (code) =>{
    let isSelect = /(select_\d+)/g.exec(code);
    let isClause = /(clause_\d+)/g.exec(code);
    let isOption = /(option.\d+)/g.exec(code);
    // let isField = /(field_\d+)/g.exec(code);
    // let isCodeBlock = /(block_\d+)/g.exec(code);
    // let isCarousel = /(carousel_\d+)/g.exec(code);
    // let isUnknown = /\[(.*)\]/g.exec(code);

    if (isSelect) {
      this.endCode = {code: `${this.defaultOptions()}<span>[/select]</span>`, length: 30};
    } else if (isClause) {
      this.endCode = {code: "<span>&nbsp;[/if]</span>", length: 6};
    // } else if (isCodeBlock) {
      // this.endCode = {code: "<span>&nbsp;[/code]</span>", length: -10}
    } else if (isOption) {
      this.endCode = {code: "<span>&nbsp;[/option]</span>", length: 10};
    } 
  }

    onDoubleClickText = (e, editor) => {
    if(this.delay) clearTimeout(this.delay);
    let iframe = editor.iframeElement;
    var idoc = iframe.contentDocument || iframe.contentWindow.document; // ie compatibility
    let selected = idoc.getSelection();
    let result =
      selected.focusNode.wholeText &&
      selected.focusNode.wholeText.substring(
        selected.baseOffset,
        selected.focusOffset
      );
    this.props.onScroll && this.props.onScroll(result);
  };

  render() {
    let self = this;
    return (
      <TinyMCE
        ref="editor"
        value={this.props.content || ""}
        onEditorChange={this.onChange}
        init={{
          plugins: "link code paste lists contextmenu",
          toolbar:
            "undo redo | bold italic | alignleft aligncenter alignright code bullist numlist uploadSuggests",
          height: this.props.height || 400,
          init_instance_callback: function(editor) {
            if (self.props.isHasHelpbox){
              editor.on("click", (e) => {
                if(self.delay) clearTimeout(self.delay);
                self.delay=_.delay(()=>{self.onClickText(editor)}, 700)
              });
              editor.on("dblclick", (e) => {
                self.onDoubleClickText(e, editor);
              });
            };
            $(editor.contentDocument.activeElement)
              .atwho({
                at: "[",
                data: self.props.codeSuggest || [],
                displayTpl: '<li>${name} <br> <small> ${contentName} </small> </li>',
                limit: 50,
                callbacks: {
                  filter: (query, data, searchKey) =>{
                    return   _.filter(data,(item=> (item.name.toLowerCase().indexOf(query.toLowerCase()) > -1 || item.contentName.toLowerCase().indexOf(query.toLowerCase()) > -1 ))).map((item,index)=> {item.atwho_order=index; return item});
                  },
                  beforeInsert:(value, el, e)=>{
                    self.getEnteredCode(value);
                    return value;
                  }
                },
                insertTpl: (opt) => {
                  return `<span>[${opt.name}]</span>`
                },
              })
              .atwho({
                at: "[[",
                data: self.props.fieldSuggest||[],
                displayTpl: '<li>${name}<br> <small> ${contentName} </small> </li>',
                limit: 50,
                insertTpl: (opt) => {
                  return `[[${opt.name}]]`
                },
                callbacks:{
                  filter: (query, data, searchKey) =>{
                    return  _.filter(data,(item=> (item.name.toLowerCase().indexOf(query.toLowerCase()) > -1 || item.contentName.toLowerCase().indexOf(query.toLowerCase()) > -1 ))).map((item,index)=> {item.atwho_order=index; return item});
                  },
                }
              })
          },
          setup: function(editor) {
            editor.on("keydown", function(e) {
              if (
                e.keyCode == 13 &&
                $(editor.contentDocument.activeElement).atwho("isSelecting")
              ){
                return false;
              }
            });
            editor.on('selectionchange', function (e) {
              if (self.endCode.code) {
           
                editor.editorManager.execCommand('mceInsertContent', false, self.endCode.code);
                self.endCode = {};

                var newRange = editor.selection.getRng();
                newRange.setStart(newRange.startContainer,newRange.startOffset - self.endCode.length);
                newRange.setEnd(newRange.endContainer,newRange.endOffset - self.endCode.length);

                editor.selection.setRng(newRange);
                return false;
              }
            });
            if(self.props.isHasUploadSelector){
              const menuItems = [{
                text:'Default File Selector',
                onClick: () => {
                  self.props.handleDefaultFileUploaderClicked();
                  $(editor.contentDocument)[0].scrollingElement.scrollTop = $(editor.contentDocument.activeElement).find("p").last()[0].offsetTop;

                }
              }]
              _.map(self.props.fileSelectors,(select)=> {
                  menuItems.push({
                  text: select.name,
                  onClick: (e)=>{
                    $(editor.contentDocument.activeElement).append(`<div>[[field_${select.id}]]</div>`)
                    $(editor.contentDocument)[0].scrollingElement.scrollTop = $(editor.contentDocument.activeElement).find("p").last()[0].offsetTop;
                  }
                })
              })
              editor.addButton('uploadSuggests', {
                type: 'menubutton',
                text: 'File Selectors',
                icon: false,
                menu: menuItems
             });
            }
          }
        }}
      />

    );
  }
}

Editor.propTypes = {
  handleChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  height: PropTypes.number,
  isHasHelpbox: PropTypes.bool,
  codeSuggest: PropTypes.array,
  fieldSuggest: PropTypes.array
};

Editor.defaultProps = {
  isHasHelpbox: false,
  codeSuggest: [],
  fieldSuggest: [],
};

export default Editor;
