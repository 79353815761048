import Immutable from 'seamless-immutable';
import { types } from './actions';
import moment from 'moment';
import _ from 'lodash';

const initialState = Immutable({
  filters: {
		searchTerm: '',
		categoryId: '',
	},
  pagination: {
			currentPage: 1,
			previousPage: 1,
			pageSize: 10,
			totalPages: 1,
	},
  sorter: {
		column: 'created_at',
		descending: true,
	},
  currentUserId: null,
  currentItemId: null,
  itemsById: {
	},
	idsByPage: {
			_1: []
	},
});

function setCurrentUserId(state, payload){
  return state.merge({
    currentUserId: payload.id
  });
}

function setCurrentItemId(state, payload){
  return state.merge({
    currentItemId: payload.id
  });
}

// Save all drafts to store
function fetchAllDraftsDone(state, payload) {
	let newState = {
			pagination: {
					totalPages: payload.totalPages
			},
		itemsById: {},
		idsByPage: {},
  }
	newState['idsByPage']['_' + state.pagination.currentPage] = [];
	_.map(payload.items, (item) => {
			newState['itemsById']['_' + item.id] = item;
			newState['idsByPage']['_' + state.pagination.currentPage].push(item.id);
	});
	return state.merge(newState, { deep: true });
}

// Clear cached info
function clearCache(state) {
    return state.merge({
			filters: state.filters,
			sorter: state.sorter,
			pagination: {
					currentPage: 1,
					previousPage: 1,
					pageSize: 10,
					totalPages: 1,
			},
			itemsById: {},
			idsByPage: {
					_1: []
			},
    });
}


// Set drafts type
function setDraftsType(state, payload) {
	return state.merge(
	  {
		filters: {
		  type: payload.type,
		},
	  },
	  { deep: true }
	);
  }


// Set category id filter
function setCategoryId(state, payload) {
	return state.merge(
	  {
		filters: {
		  categoryId: payload.id,
		},
	  },
	  { deep: true }
	);
  }

  // Set current search term
function setSearchTerm(state, payload) {
	return state.merge(
	  {
		filters: {
		  searchTerm: payload.searchTerm,
		},
	  },
	  { deep: true }
	);
  }

// Set user saved workflow id filter
function setFetchOnlyWorkflowDraftStatus(state, payload) {
	return state.merge(
	  {
		filters: {
		  fetchOnlyWorkflowDraft: payload.status,
		},
	  },
	  { deep: true }
	);
  }

  // Set user saved workflow id filter
function setSavedWorkflowId(state, payload) {
	return state.merge(
	  {
		filters: {
		  savedWorkflowId: payload.id,
		},
	  },
	  { deep: true }
	);
  }  

// Set current page for items list
function setCurrentPage(state, payload) {
	return state.merge({
		pagination: {
			currentPage: payload.page,
			previousPage: state.pagination.currentPage
		}
	}, {deep: true})
}

export default function reduce(state = initialState, action = {}) {
  switch(action.type) {
    case types.SET_CURRENT_USER_ID:
      return setCurrentUserId(state, action.payload);

    case types.SET_CURRENT_ITEM_ID:
      return setCurrentItemId(state, action.payload);

    case types.CLEAR_CACHE:
				return clearCache(state);

    case types.FETCH_ALL_DRAFTS_DONE:
			return fetchAllDraftsDone(state, action.payload);

    case types.SET_CURRENT_PAGE:
			return setCurrentPage(state, action.payload);

	case types.SET_DRAFTS_TYPE:
		return setDraftsType(state, action.payload);

	case types.SET_CATEGORY_ID:
		return setCategoryId(state, action.payload);

	case types.SET_SEARCH_TERM:
		return setSearchTerm(state, action.payload);

	case types.SET_FETCH_ONLY_WORKFLOW_DRAFTS:
		return setFetchOnlyWorkflowDraftStatus(state, action.payload);

	case types.SET_SAVED_WORKFLOW_ID:
		return setSavedWorkflowId(state, action.payload);

    default:
        return state;
  }
}
