
import api from '../../services/api';
import language from '../../services/language';
import _ from 'lodash';

import * as exceptionsActions from '../exceptions/actions';
import * as sharedDraftsSelectors from './selectors';

export const types = {
  SET_CURRENT_USER_ID: 'sharedDrafts.SET_CURRENT_USER_ID',
  CLEAR_CACHE: 'sharedDrafts.CLEAR_CACHE',
  FETCH_ALL_SHARED_DRAFTS_DONE : "sharedDrafts.FETCH_ALL_SHARED_DRAFTS_DONE",
  SET_CURRENT_PAGE: 'sharedDrafts.SET_CURRENT_PAGE',
  SET_SEARCH_TERM: 'sharedDrafts.SET_SEARCH_TERM',
  SET_CATEGORY_ID: 'sharedDrafts.SET_CATEGORY_ID',
};

export function clearCache() {
	return {
		type: types.CLEAR_CACHE
	}
}

export function setCurrentUserId(id) {
  return {
    type: types.SET_CURRENT_USER_ID,
    payload: {
      id
    }
  }
}

export function unsetCurrentUserId() {
  return {
    type: types.SET_CURRENT_USER_ID,
    payload: {
      id: null
    }
  }
}

export function fetchAllSharedDrafts(deleteCache = false) {
	return async (dispatch, getState) => {
		let state = getState();
		try {
			let params = new Map();
			let filters = sharedDraftsSelectors.getFilters(state);
			let pagination = sharedDraftsSelectors.getPagination(state);
      let currentUsetId = sharedDraftsSelectors.getCurrentUserId(state);

			params.set('expand', 'document');
			if(filters.categoryId)
				params.set('category_id',filters.categoryId);
			if(filters.searchTerm)
				params.set('name',filters.searchTerm);
      if(currentUsetId)
        params.set('user_id',currentUsetId);

			params.set('page_size', pagination.pageSize);
			params.set('page_number', deleteCache ? 1 : pagination.currentPage);

			// GET request from API
			let [response, items] = await api.get(`/shareddrafts`, params, true);

			// Clear cache if deleteCache is enabled
			if (deleteCache) {
				dispatch(clearCache());
			}

			dispatch({
				type: types.FETCH_ALL_SHARED_DRAFTS_DONE,
				payload: {
					totalPages: parseInt(response.headers.get('X-Total-Pages')),
					items
				}
			})
		} catch (e) {
			dispatch(exceptionsActions.process(e));
		}
	}
}

export function setCurrentPage(page) {
	return {
		type: types.SET_CURRENT_PAGE,
		payload: {
			page
		}
	}
}

export function setSearchTerm(searchTerm) {
	return {
	  type: types.SET_SEARCH_TERM,
	  payload: {
		searchTerm,
	  },
	};
  }

  export function setCategoryId(id) {
    return {
        type: types.SET_CATEGORY_ID,
        payload: {
            id
        }
    }
}
