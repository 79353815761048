import React, { Component } from "react";
import autoBind from "react-autobind";
import strings from "../../services/strings";
import { connect } from "react-redux";
import language from "../../services/language";

import _ from "lodash";
import ReactAvatarEditor from "react-avatar-editor";
import Switch from "../Switch.js";
import Editor from "../Editor";

import Modal from "reboron/DropModal";
import Dropzone from "react-dropzone";
import "./WorkflowForm.scss";
import Milestone from "./Milestone";
import ActiveLabel from "../ActiveLabel";
import { browserHistory } from "../../helpers";

class WorkflowForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  state = {
    currentItemLoaded: false,
    allCategories: true,
    // milestones: [],
    categories: [],
    form: {
      name: "",
      payed: 0,
      price: "",
      read_more_link: "",
      shareable: false,
      published: 0,
      description: "",
      meta_title: "",
      meta_description: "",
      meta_keyword: "",
      milestones: [],
      categories: [],
    },
    payed: false,
    image: null,
    scale: 0.2,
    currItem: null,
    file: null,
    fileRejected: false,
    checkedReadMore: false,
  };

  componentDidMount() {
    this.tryLoadCurrentItem();
  }

  componentDidUpdate() {
    this.tryLoadCurrentItem();
  }

  tryLoadCurrentItem() {
    if (this.props.currentItem && !this.state.currentItemLoaded) {
      let form = _.extend({}, this.state.form);
      let payed;
      let newMilestones = [];
      _.map(this.state.form, (value, key) => {
        if (key === "milestones") {
          newMilestones =
            this.props.currentItem[key] &&
            this.props.currentItem[key].map((item, index) => {
              return {
                ...item,
                duration: JSON.parse(item.duration),
              };
            });
        }
        form[key] = this.props.currentItem[key]
          ? this.props.currentItem[key]
          : this.state.form[key];
        if (key === "payed") payed = !!form[key];
        if (key === "milestones") {
          form[key] = newMilestones;
        }
        // form['milestones'].duration = this.props.currentItem['milestones'].duration && JSON.parse(this.props.currentItem['milestones'].duration);
      });
      this.setState({
        currentItemLoaded: true,
        payed,
        categories: this.props.currentItem.categories,
        form,
        currItem: this.props.currentItem,
      });
    }
  }
  handleInputChange(e) {
    let form = {};

    form[e.target.name] = e.target.value;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  }

  handleSaveClick(e) {
    e.preventDefault();
    let newMilestones =
      this.state.form.milestones &&
      this.state.form.milestones.map((item, index) => {
        return {
          ...item,
          // duration: JSON.stringify(item.duration),
          documents: item.documents && item.documents.map((item) => item.id),
        };
      });
    let newCat =
      this.state.form.categories &&
      this.state.form.categories.map((item) => item.id);
    let data = {
      ...this.state.form,
      language_id: language.get(),
      milestones: JSON.stringify(newMilestones),
      categories: JSON.stringify(newCat),
    };
    this.props.saveItem(
      _.extend(this.state, {
        form: _.extend(this.state.form, data),
      })
    );
  }

  handleCancelClick(e) {
    e.preventDefault();
    browserHistory.push("/workflows");
  }

  getErrorClass(inputName, defaultClasses = "") {
    return defaultClasses;
  }

  handlePayedChange(e) {
    this.setState({
      payed: e,
      form: _.extend(this.state.form, {
        payed: e ? 1 : 0,
      }),
    });
  }

  removeImage = (image, file) => () => {
    this.setState({
      image: null,
      file: null,
    });
  };

  setEditorRef = (editor) => (this.editor = editor);

  setEditorRef = (editor: any) => {
    if (editor) {
      this.editor = editor;
      // console.log('this.editor', th
      const img = this.editor.getImageScaledToCanvas().toDataURL();
      // console.log('hello i am here', img)
    }
  };

  getPreviewImage(file) {
    if (this.state.file) {
      return <img src={this.state.file} />;
    } else {
      return this.state.currItem && this.state.currItem.imageURL ? (
        <img src={this.state.currItem.imageURL} />
      ) : null;
    }
  }

  isImage(file) {
    if (this.state.currItem && this.state.currItem.imageURL) {
      return true;
    } else {
      return false;
    }
  }

  setImage = (fileName, formKey) => () => {
    this.setState({
      [fileName]: null,
      currItem: {
        imageURL: null,
      },
    });
    // if(this.props.currentItem && this.props.currentItem.imageURL) {
    //   this.props.currentItem.imageURL = null
    // }
  };

  handleFileDrop = (dropped) => {
    // console.log("drop", dropped);
    let form = _.extend(this.state.form, {});
    // form.image = form.image.asMutable
    //   ? form.image.asMutable()
    //   : form.image;
    // form.image = dropped[0];
    // console.log("handleFileDrop", this.state.form, dropped[0]);
    this.setState({ image: dropped[0], cropClass: false, file: dropped[0] });
  };

  cropImage = () => {
    let crooperD = this.editor.getImageScaledToCanvas().toDataURL();
    // console.log('this.editor.getImageScaledToCanvas()')
    // let form = _.extend(this.state.form, {});
    // form.image = form.image.asMutable
    //   ? form.image.asMutable()
    //   : form.image
    // form.image.preview = crooperD;
    this.setState({
      image: null,
      file: this.editor.getImageScaledToCanvas().toDataURL(),
      currItem: null,
    });
  };

  handleChangeSharability() {
    this.state.form.shareable = !this.state.form.shareable;
    this.setState(this.state);
  }

  handleChangePublished() {
    this.state.form.published = this.state.form.published === 1 ? 0 : 1;
    this.setState(this.state);
  }

  handleChange() {
    if (!this.state.form.read_more_link) {
      this.setState({
        checkedReadMore: !this.state.checkedReadMore,
      });
    }
  }

  // handleEditorChange(e) {
  //   this.setState({
  //     tmpItem: _.extend(this.state.form, {
  //       description: e.target.getContent(),
  //     }),
  //   });
  // }
  handleEditorChange(content) {
    this.setState({
      tmpItem: _.extend(this.state.form, {
        description: content,
      }),
    });
  }
  addMilestone = () => {
    let form = this.state.form;
    let milestone = {
      order: form.milestones.length + 1,
      workflow_id: this.state.currItem ? this.state.currItem.id : 0,
    };
    form.milestones = [...form.milestones, milestone];
    this.setState({
      form: _.extend(this.state.form, form),
    });
  };

  getPreviewImage(file) {
    if (this.state.file) {
      return <img src={this.state.file} />;
    } else {
      return this.state.currItem && this.state.currItem.imageURL ? (
        <img src={this.state.currItem.imageURL} />
      ) : null;
    }
  }

  getCategoryTree(items, item, arr, depth = 0) {
    if (_.size(items) && _.find(items, item)) {
      if (item.parent_id && depth === 0) return;
      arr[0].push(item);
      arr[1].push(depth);
      _.map(items, (it) => {
        if (item.id === it.parent_id) {
          this.getCategoryTree(items, it, arr, depth + 1);
        }
      });
    }
  }
  handleCategoryRemove(value) {
    let form = this.state.form;
    let categories = _.filter(this.state.form.categories, (item) => {
      return item.id !== value;
    });
    form.categories = categories;
    this.setState(
      {
        form,
        allCategories: _.size(categories) === 0,
      },
      () => {
        // this.loadPrefix();
      }
    );
  }

  handleCategoryAdd(e) {
    let id = e.target.value;
    let form = this.state.form;
    let added =
      _.findIndex(this.state.form.categories, (item) => {
        return item.id === id;
      }) > -1;
    if (!added) {
      // let categories = _.extend([], this.state.categories);
      // categories.push(this.props.categories[`_${id}`]);
      // console.log(categories);
      form.categories = [
        ...this.state.form.categories,
        this.props.categories[`_${id}`],
      ];
      this.setState({
        ...this.state.form,
        form,
      });
    }
  }

  handleChangeMilestone = (data) => {
    let form = this.state.form;
    let newMilestones =
      form.milestones &&
      form.milestones.map((item, index) => {
        if (item.order === data.order) {
          // if(_.isEmpty(data.duration)){
          //   data.duration = "";
          //   console.log(data.duration);
          item = data;
        }

        return item;
      });

    this.setState({
      form: {
        ...this.state.form,
        milestones: newMilestones,
      },
    });
  };

  deleteMilestone = (id) => {
    this.props.deleteMilestone(id);
  };
  handleSortOrderInForm = (selectedOrder, curOrder) => {
    let form = this.state.form;
    let newMilestones =
      form.milestones &&
      form.milestones.map((item, index) => {
        //TH cur > select
        if (curOrder > selectedOrder) {
          //5>2
          let idSelected = -1;
          if (item.order === curOrder) {
            //5->2
            idSelected = item.id ? item.id : null;
            let tempMilestone = {
              //temp milestone co order mile 5 chuyen thanh 2
              ...item,
              order: selectedOrder,
            };
            return tempMilestone;
          }
          if (
            item.order >= selectedOrder &&
            item.order < curOrder &&
            item.id &&
            item.id != idSelected
          ) {
            let tempMilestone = {
              //temp milestone order: 2->3,3->4,4->5
              ...item,
              order: item.order + 1,
            };
            return tempMilestone;
          }
        }
        if (curOrder < selectedOrder) {
          //5>2
          let idSelected = -1;
          if (item.order === curOrder) {
            //5->2
            idSelected = item.id ? item.id : null;
            let tempMilestone = {
              //temp milestone co order mile 5 chuyen thanh 2
              ...item,
              order: selectedOrder,
            };
            return tempMilestone;
          }
          if (
            item.order <= selectedOrder &&
            item.order > curOrder &&
            item.id &&
            item.id != idSelected
          ) {
            let tempMilestone = {
              //temp milestone order: 2->3,3->4,4->5
              ...item,
              order: item.order - 1,
            };
            return tempMilestone;
          }
        }
        return item;
      });

    this.setState({
      form: {
        ...this.state.form,
        milestones: newMilestones,
      },
    });
  };

  render() {
    //selected multi categories
    let arr = [[], []];
    _.map(this.props.categories, (item) => {
      this.getCategoryTree(this.props.categories, item, arr);
    });

    let categories = _.map(arr[0], (category, i) => {
      let sub = arr[1][i]
        ? " ".repeat(Math.round(arr[1][i] * 3 + arr[1][i] * 3)) + "|" + "_"
        : " ";
      return (
        <option
          className={`depth-${arr[1][i]}`}
          value={category.id}
          key={category.id}
        >
          {`${sub} ${category.name}`}
        </option>
      );
    });

    let selectedCategories =
      this.state.form.categories &&
      _.map(this.state.form.categories, (item) => {
        return (
          <div key={`category-${item.id}`}>
            <ActiveLabel
              name={item.name}
              value={item.id}
              onRemove={this.handleCategoryRemove}
            />
          </div>
        );
      });

    let dropzoneContent = this.getPreviewImage() ? (
      this.getPreviewImage()
    ) : (
      <img src={"https://dummyimage.com/150x150/000/fff.jpg"} />
    );

    let priceFormGroup = this.state.form.payed ? (
      <div className={this.getErrorClass("price", "form-group")}>
        <label className="col-sm-3 control-label">
          {strings.get("App.workflows.workflowForm.price")}
        </label>
        <div className="col-sm-9">
          <input
            className="form-control"
            type="text"
            name="price"
            value={this.state.form.price}
            onChange={this.handleInputChange}
          />
        </div>
      </div>
    ) : null;

    const readMoreLink =
      this.state.checkedReadMore || this.state.form.read_more_link ? (
        <div className="form-group">
          <label className="col-sm-3 control-label">
            {strings.get("App.workflows.workflowForm.readMoreLink")}
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              name="read_more_link"
              value={this.state.form.read_more_link}
              onChange={this.handleInputChange}
            />
          </div>
        </div>
      ) : null;
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="DocumentForm">
            <form className="form-horizontal">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <b>{this.props.formHeading}</b>
                </div>
                <div className="panel-body">
                  <div className={this.getErrorClass("name", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {strings.get("App.workflows.workflowForm.name")}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={this.state.form.name}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass("category_id", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.workflows.workflowForm.category")}
                    </label>
                    <div className="col-sm-9">
                      {selectedCategories}
                      <select
                        className="form-control"
                        value=""
                        onChange={this.handleCategoryAdd}
                      >
                        <option value="" disabled>
                          {strings.get("App.glossaries.glossaryForm.choose")}
                        </option>
                        {categories}
                      </select>
                      {/* <select
                        className="form-control"
                        name="category_id"
                        value={this.state.form.category_id}
                        onChange={this.handleInputChange}
                      >
                        <option value="" disabled>
                          {strings.get("App.workflows.workflowForm.choose")}
                        </option>
                      </select>
                      { selectedCategories } */}
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {strings.get("App.workflows.workflowForm.payed")}
                    </label>
                    <div className="col-sm-9">
                      <Switch
                        enabled={!!this.state.form.payed}
                        onChange={this.handlePayedChange}
                      />
                    </div>
                  </div>
                  {priceFormGroup}

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {strings.get("App.workflows.workflowForm.image")}
                    </label>

                    <div className="col-sm-9">
                      {this.state.image || this.state.file ? (
                        <input
                          type="button"
                          className="close-btn"
                          style={{ marginTop: -10 }}
                          onClick={this.removeImage("image", "file")}
                          value="X"
                        />
                      ) : this.isImage("file") ? (
                        <input
                          type="button"
                          className="close-btn"
                          style={{ marginTop: -10 }}
                          onClick={this.setImage("file")}
                          value="X"
                        />
                      ) : (
                        ""
                      )}
                      <Dropzone
                        className="dropzone"
                        onDrop={this.handleFileDrop}
                        multiple={false}
                        maxSize={4096000}
                        accept="image/*"
                      >
                        {/* {dropzoneContent}

                        {this.state.image ? (
                          <ReactAvatarEditor
                            scale={this.state.scale}
                            rotate={0}
                            className="image-placeholder"
                            image={this.state.image}
                            width={150}
                            height={150}
                            ref={ref => this.setEditorRef(ref)}
                          />
                        ) : this.state.file && this.state.currItem ? (
                          <img src={this.state.file} />
                        ) : (
                              ""
                            )} */}
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {dropzoneContent}

                            {this.state.image ? (
                              <ReactAvatarEditor
                                scale={this.state.scale}
                                rotate={0}
                                className="image-placeholder"
                                image={this.state.image}
                                width={150}
                                height={150}
                                ref={(ref) => this.setEditorRef(ref)}
                              />
                            ) : this.state.file && this.state.currItem ? (
                              <img src={this.state.file} />
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </Dropzone>

                      {this.state.cropClass === false ? (
                        <div className="help-block">
                          <div className="help-block">
                            ( {strings.get("App.companyForm.suggestDimension")}{" "}
                            )
                          </div>
                          {this.state.image !== null ? (
                            <input
                              type="range"
                              step="0.01"
                              min="0.1"
                              max="2"
                              name="scale"
                              value={this.state.scale}
                              onChange={(e) =>
                                this.setState({
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          ) : (
                            ""
                          )}

                          {this.state.image !== null ? (
                            <input
                              type="button"
                              className="crop-image-btn"
                              onClick={this.cropImage}
                              value="Crop"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {strings.get(
                        "App.workflows.workflowForm.addReadMoreLink"
                      )}
                    </label>
                    <div className="col-sm-9">
                      <Switch
                        enabled={
                          !!this.state.checkedReadMore ||
                          !!this.state.form.read_more_link
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  {readMoreLink}

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {strings.get("App.workflows.workflowForm.shareable")}
                    </label>
                    <div className="col-sm-9">
                      <Switch
                        enabled={!!this.state.form.shareable}
                        onChange={this.handleChangeSharability}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {strings.get("App.workflows.publish")}
                    </label>
                    <div className="col-sm-9">
                      <Switch
                        enabled={this.state.form.published === 1 ? true : false}
                        onChange={this.handleChangePublished}
                      />
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass(
                      "descriptionLabel",
                      "form-group"
                    )}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.workflows.workflowForm.description")}
                    </label>
                    {/* <textarea className="form-control" name="description" value={ this.state.form.description } onChange={ this.handleInputChange } /> */}
                    <div className="col-sm-9">
                      <Editor
                        content={this.state.form.description}
                        handleChange={this.handleEditorChange}
                        height={100}
                      />
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass("meta_title", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.meta_title")}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="meta_title"
                        value={this.state.form.meta_title}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass(
                      "meta_description",
                      "form-group"
                    )}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.meta_description")}
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        name="meta_description"
                        value={this.state.form.meta_description}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass("meta_keyword", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.meta_keyword")}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="meta_keyword"
                        value={this.state.form.meta_keyword}
                        onChange={this.handleInputChange}
                      />
                      <div className="help-block">
                        {strings.get("App.articleForm.meta_keyword_example")}
                      </div>
                    </div>
                  </div>
                  {this.state.form.milestones &&
                    this.state.form.milestones.map((item, index) => {
                      return (
                        <Milestone
                          milestone={item}
                          index={index}
                          key={index + 1}
                          documents={this.props.documents}
                          handleChangeMilestone={this.handleChangeMilestone}
                          deleteMilestone={this.deleteMilestone}
                          sumOrder={this.state.form.milestones.length}
                          handleChangeOrder={this.handleSortOrderInForm}
                        />
                      );
                    })}
                  <div className="col-sm-12 AddMilestone">
                    <button
                      type="button"
                      onClick={() => this.addMilestone()}
                      className="btn btn-add"
                    >
                      {" "}
                      + {strings.get("App.workflows.milestones.addMilestone")}
                    </button>
                  </div>
                </div>

                <footer className="panel-footer text-right bg-light lter">
                  <button
                    className="btn btn-primary"
                    onClick={this.handleSaveClick}
                  >
                    {strings.get("App.workflows.workflowForm.save")}
                  </button>
                  <button
                    className="btn btn-default btn-color-change"
                    onClick={this.handleCancelClick}
                  >
                    {strings.get("App.workflows.workflowForm.cancel")}
                  </button>
                </footer>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default WorkflowForm;
