import React, { Component } from "react";
import _ from "lodash";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as stepsActions from "../../store/steps/actions";
import * as stepsSelectors from "../../store/steps/selectors";
import * as glossariesActions from "../../store/glossaries/actions";
import * as glossariesSelectors from "../../store/glossaries/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Modal from "reboron/DropModal";
import Topbar from "../../components/Topbar";
// import SubTopbar from "../../components/SubTopbar";
import StepViewForm from "../../components/step/StepViewForm";

class StepViewPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchAllSteps();
    if (!_.size(this.props.glossaries)) {
      this.props.fetchAllGlossaries();
    }
    this.props.fetchStep(this.props.match.params.id);
    this.props.setCurrentStepId(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.unsetCurrentStepId();
    this.props.clearExceptions();
  }

  saveStep(data) {
    let stepPromise = this.props.updateStepInGlossary(data);
    Promise.all([stepPromise]).then(() => {
      this.showSaveModal();
    });
  }

  showSaveModal() {
    this.refs.saveModal.show();
  }

  hideSaveModal() {
    this.refs.saveModal.hide();
  }

  handleShowModal() {
    _.delay(() => {
      this.hideSaveModal();
    }, 500);
  }

  render() {
    return (
      <div className="StepViewPage">
        <Topbar>
          <div className="title">
            <Link to="/glossaries">
              {strings.get("App.stepPages.glossaries")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/glossaries/steps">
                {strings.get("App.stepPages.title")}
              </Link>
              <span className="divider">/</span>
              <Link to={`/glossaries/steps/${this.props.match.params.id}`}>
                {strings.get("App.stepPages.edit")}
              </Link>
            </span>
          </div>
        </Topbar>
        <Modal
          className="boron-modal no-body"
          ref="saveModal"
          onShow={this.handleShowModal}
        >
          <span>
            <h2>{strings.get("App.settings.settingsSaved")}</h2>
          </span>
        </Modal>

        <div className="content">
          <StepViewForm
            exceptions={this.props.exceptions}
            steps={this.props.steps}
            glossaries={this.props.glossaries}
            currentItem={this.props.currentStep}
            saveItem={this.saveStep}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentStep: stepsSelectors.getCurrentItem(state),
    steps: stepsSelectors.getItems(state),
    glossaries: glossariesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllSteps: (deleteCache) => {
      dispatch(stepsActions.fetchAllItems(deleteCache));
    },
    fetchAllGlossaries: () => {
      dispatch(glossariesActions.fetchAllItems());
    },
    fetchStep: (id) => {
      dispatch(stepsActions.fetchItem(id));
    },
    setCurrentStepId: (id) => {
      dispatch(stepsActions.setCurrentItemId(id));
    },
    unsetCurrentStepId: () => {
      dispatch(stepsActions.unsetCurrentItemId());
    },
    updateStepInGlossary: (data) => {
      dispatch(stepsActions.updateStepInGlossary(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepViewPage);
