import { createBrowserHistory } from "history";
export const browserHistory = createBrowserHistory();

export const makeSEFUrl = (title) =>
  title.toString().toLowerCase().replace(/ /g, "-");
export const makePATHUrl = (title, id) =>
  `${title} ${id}`.toString().toLowerCase().replace(/ /g, "-");

export const viewModals = {
  CODEBLOCK_CODES_ORDERING: "CODEBLOCK_CODES_ORDERING",
  TEMPLATE_COMMON_NOTES: "TEMPLATE_COMMON_NOTES",
};
//this cookie will be used to help in 2 step authentication
const identityCookieName = "JD_IDN" + "=";

export const loadIdentifier = () => {
  let cookieValue = "";
  let allCookies = decodeURIComponent(document.cookie).split(";");
  for (const element of allCookies) {
    if (element.includes(identityCookieName)) {
      cookieValue = element.substring(element.indexOf("=") + 1).toLowerCase();
    }
  }
  return cookieValue;
};

export const setIdentifier = (value) => {
  document.cookie = identityCookieName + `${value}; max-age=2592000;`; // Max age 30 days
};
