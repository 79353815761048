import React, { Component } from 'react';
import autoBind from 'react-autobind';
import strings from '../../services/strings';
import language from '../../services/language';
import _ from 'lodash';
import PropTypes from "prop-types";

import './ArticleCategoryFilter.scss';

class ArticleCategoryFilter extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    getArticleCategories() {
      return _.map(this.props.articleCategories, (item) => {
          return (
              <option key={`item-${item.id}`} value={item.id}>{item.tree_name}</option>
          );
      });
    }

    getSelectedCategory() {
        if (this.props.filters) {
            return this.props.filters.articleCategoryId;
        }

        return '';
    }

    handleChange(e) {
        this.props.setArticleCategoryId(e.target.value);
        this.props.fetchItems(true);
    }

    render() {
        return (
            <div className="ArticleCategoryFilter">
                <label>{strings.get('App.documents.categoryFilter.from')}</label>
                <select className="form-control" name="groups" value={ this.getSelectedCategory() } onChange={ this.handleChange }>
                    <option value="">{strings.get('App.documents.categoryFilter.allCategories')}</option>
                    { this.getArticleCategories() }
                </select>
            </div>
        );
    }

}

ArticleCategoryFilter.propTypes = {
    filters: PropTypes.object,
    articleCategories: PropTypes.object,
    setArticleCategoryId: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
}

export default ArticleCategoryFilter;
