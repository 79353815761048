import _ from 'lodash';

export function getFilters(state) {
	return state.sharedDrafts.filters;
}

export function getPagination(state) {
	return state.sharedDrafts.pagination;
}

export function getItemsByPage(state, page) {
	if (!state.sharedDrafts.idsByPage['_' + page]) {
		page = (getPagination(state)).previousPage;
	};
	return _.map(state.sharedDrafts.idsByPage['_' + page], (itemId) => {
		return state.sharedDrafts.itemsById['_' + itemId]
	});
}

export function getCurrentUserId(state){
  return state.sharedDrafts.currentUserId;
}
