import api from "../../services/api";
import language from "../../services/language";
import _ from "lodash";
import { NotificationManager } from "react-notifications";

import * as exceptionsActions from "../exceptions/actions";
import * as draftsSelectors from '../drafts/selectors'
import * as workflowsSelectors from "./selectors";
import { browserHistory } from "../../helpers";
import strings from '../../services/strings'

export const types = {
  SET_CURRENT_ITEM_ID: "workflows.SET_CURRENT_ITEM_ID",
  FETCH_ITEM_DONE: "workflows.FETCH_ITEM_DONE",
  FETCH_ALL_ITEMS_DONE: "workflows.FETCH_ALL_ITEMS_DONE",
  FETCH_ITEMS_DONE: "workflows.FETCH_ITEMS_DONE",
  VALIDATE_ITEM_DONE: "workflows.VALIDATE_ITEM_DONE",
  SET_SORTER: "workflows.SET_SORTER",
  SET_SEARCH_TERM: "workflows.SET_SEARCH_TERM",
  SET_CATEGORY_ID: "workflows.SET_CATEGORY_ID",
  SET_CURRENT_PAGE: "workflows.SET_CURRENT_PAGE",
  TOGGLE_SORTER: "workflows.TOGGLE_SORTER",
  CLEAR_CACHE: "workflows.CLEAR_CACHE",
  SET_GLOSSARY_CODE: "workflows.SET_GLOSSARY_CODE",
  CLEAR_ORDER_FIELDS_CACHE: "workflows.CLEAR_ORDER_FIELDS_CACHE",
  FETCH_ITEMS_ELEMENTS: "workflows.FETCH_ITEMS_ELEMENTS",
  FETCH_CURRENT_USER_WORKFLOW_DONE:
          "workflows.FETCH_CURRENT_USER_WORKFLOW_DONE",
};

export function setGlossaryCode(code, codeType) {
  return {
    type: types.SET_GLOSSARY_CODE,
    payload: {
      code,
      codeType,
    },
  };
}

export function setCategoryId(id) {
  return {
    type: types.SET_CATEGORY_ID,
    payload: {
      id,
    },
  };
}

export function setCurrentPage(page) {
  return {
    type: types.SET_CURRENT_PAGE,
    payload: {
      page,
    },
  };
}

export function setCurrentItemId(id) {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id,
    },
  };
}

export function unsetFieldsOrder() {
  return {
    type: types.VALIDATE_ITEM_DONE,
    payload: {
      fields: null,
      fieldsOrder: null,
      selectors: null,
      clauses: null,
      steps: null,
    },
  };
}

export function unsetCurrentItemId() {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id: null,
    },
  };
}

export function fetchItem(id) {
  return async (dispatch) => {
    try {
      let params = new Map();
      // params.set('expand', 'downloads');

      // GET request from API
      let item = await api.get(`/workflows/${id}`, params);
      dispatch({
        type: types.FETCH_ITEM_DONE,
        payload: {
          item,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function toggleSorter(column) {
  return {
    type: types.TOGGLE_SORTER,
    payload: {
      column,
    },
  };
}

export function setSorter(sorter) {
  return {
    type: types.SET_SORTER,
    payload: {
      sorter,
    },
  };
}

export function setSearchTerm(searchTerm) {
  return {
    type: types.SET_SEARCH_TERM,
    payload: {
      searchTerm,
    },
  };
}

export function clearCache() {
  return {
    type: types.CLEAR_CACHE,
  };
}

export function fetchAllItems() {
  return async (dispatch, getState) => {
    let state = getState();
    try {
      let params = new Map();
      params.set("language_id", language.get());
      params.set("expand", "downloads,category");

      //By Nad.
      let filters = workflowsSelectors.getFilters(state);
      params.set(
        "glossary_code",
        filters.glossaryCode ? filters.glossaryCode : ""
      );
      params.set(
        "glossary_code_type",
        filters.glossaryCodeType ? filters.glossaryCodeType : ""
      );
      //End

      let items = await api.get("/workflows", params);
      dispatch(clearCache());
      dispatch({
        type: types.FETCH_ALL_ITEMS_DONE,
        payload: {
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItems(deleteCache = false) {
  return async (dispatch, getState) => {
    let state = getState();
    try {
      // Set additional params
      let params = new Map();
      // params.set('expand', 'downloads');

      let filters = workflowsSelectors.getFilters(state);
      let sorter = workflowsSelectors.getSorter(state);
      let pagination = workflowsSelectors.getPagination(state);
      params.set("language_id", language.get());
      params.set("name~", filters.searchTerm);
      params.set("category_id", filters.categoryId ? filters.categoryId : "");
      // params.set('page_size', pagination.pageSize);
      params.set("page_number", deleteCache ? 1 : pagination.currentPage);
      params.set("sort_by", sorter.column);
      params.set("sort_desc", sorter.descending);
      params.set("page_size", sorter.pageLimit);

      // GET request from API
      let [response, items] = await api.get("/workflows", params, true);
      // Clear cache if deleteCache is enabled

      if (deleteCache) {
        dispatch(clearCache());
      }

      dispatch({
        type: types.FETCH_ITEMS_DONE,
        payload: {
          totalPages: parseInt(response.headers.get("X-Total-Pages")),
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}


export function getUserWorkflows(deleteCache = false) {
  return async (dispatch, getState) => {
    let state = getState();
    try {
      let params = new Map();
      let currentUsetId = draftsSelectors.getCurrentUserId(state);
      let pagination = workflowsSelectors.getPagination(getState());
      params.set("page_size", pagination.pageSize);
      params.set("page_number", pagination.currentPage);

      if(currentUsetId)	params.set('user_id',currentUsetId);
      if (deleteCache) {
        let filters = workflowsSelectors.getFilters(getState());
        if (filters.categoryId) params.set("catId", filters.categoryId);
        if (filters.searchTerm) params.set("name", filters.searchTerm);
      }
      let [response, items] = await api.get("/userWorkflows", params, true);

      if (deleteCache) dispatch(clearCache());
      dispatch({
        type: types.FETCH_CURRENT_USER_WORKFLOW_DONE,
        payload: {
          items,
          totalPages: parseInt(response.headers.get("X-Total-Pages")),
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}


export function deleteUserWorkflow(id) {
  return async (dispatch, getState) => {
    let state = getState();
    
    try {
      // DELETE request to API
      let params= new Map()
      let currentUsetId = draftsSelectors.getCurrentUserId(state);

      if(currentUsetId)	params.set('user_id',currentUsetId);

      let item = await api.delete("/userWorkflows/" + id, params);
      if (item.error) {
        NotificationManager.error(
          strings.get("Client.notifications.unauthorizedRequest")
        );
      } else {
        NotificationManager.success(
          strings.get("Client.notifications.deleted")
        );
      }
      dispatch(getUserWorkflows());
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function downloadUserWorkflow(id, getZipURL) {
  //getZipURL is a callback function, required when url will be used for share a workflow
  return async (dispatch,getState) => {
    let state = getState();
    try {
      let params= new Map()
      let currentUsetId = draftsSelectors.getCurrentUserId(state);

      if(currentUsetId)	params.set('user_id',currentUsetId);

      // download request to API
      let payload = await api.get(`/userWorkflows/${id}/download`, params);
      if (typeof payload === "undefined") {
        NotificationManager.warning(strings.get("Client.notifications.error"));
      } else {
        if (typeof getZipURL === "function") {
          getZipURL(payload.url);
        } else {
          NotificationManager.success(
            strings.get("Client.notifications.requestAccepted")
          );
          let a = document.createElement("a");
          a.href = payload.url;
          a.target = payload.target;
          a.click();
        }
      }

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function shareUserWorkflowByEmail (data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        if (key !== "to") {
          params.set(key, value);
        } else {
          data.to.forEach((e, i) => {
            params.set(`to[${i}]`, e);
          });
        }
      });
      // PUT request to API
      try {
        await api.post(`/userWorkflows/shareByEmail`, params);
        NotificationManager.success(
          strings.get("Client.notifications.requestAccepted")
        );
      } catch (error) {
        NotificationManager.error(strings.get("Client.notifications.error"));
      }
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateUserWorkflowName (id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // PUT request to API
      let item = await api.put(`/userWorkflows/${id}/name`, params);
      if (item.error) {
        NotificationManager.error(
          strings.get("Client.notifications.nameAlreadyExist")
        );
      } else {
        NotificationManager.success(strings.get("Client.notifications.edited"));
      }
      dispatch(getUserWorkflows());
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItemsWithoutPagination(deleteCache = false) {
  return async (dispatch, getState) => {
    try {
      // Set additional params
      let params = new Map();
      params.set("language_id", language.get());

      // GET request from API
      let [response, items] = await api.get("/workflows", params, true);
      // Clear cache if deleteCache is enabled

      if (deleteCache) {
        dispatch(clearCache());
      }

      dispatch({
        type: types.FETCH_ITEMS_DONE,
        payload: {
          totalPages: parseInt(response.headers.get("X-Total-Pages")),
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function createItem(data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      await api.post("/workflows", params);
      browserHistory.push(`/workflows`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateItem(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // PUT request to API
      await api.put(`/workflows/${id}`, params);
      browserHistory.push(`/workflows`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateItemOrder(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("fields_order", JSON.stringify(data));
      // PUT request to API
      await api.put(`/workflows/${id}/order`, params);
      // browserHistory.push(`/workflows/${id}`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateStepOrder(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("steps_order", JSON.stringify(data));
      // PUT request to API
      await api.put(`/workflows/${id}/stepOrder`, params);
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function clearOrderFieldsCache() {
  return {
    type: types.CLEAR_ORDER_FIELDS_CACHE,
  };
}

export function validateItem(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // GET request to API
      let {
        fields,
        fieldsOrder,
        selectors,
        clauses,
        steps,
        stepsOrder,
      } = await api.post(`/workflows/${id}/validate`, params);

      dispatch(clearCache());
      dispatch(clearOrderFieldsCache());

      dispatch({
        type: types.VALIDATE_ITEM_DONE,
        payload: {
          fields,
          fieldsOrder,
          selectors,
          clauses,
          steps,
          stepsOrder,
        },
      });
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function uploadItemLogo(id, file) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("file", file);
      // POST request to API
      await api.postFiles(`/workflows/${id}/image`, params);

      dispatch(fetchItem(id));
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function createItemWithLogo(data, file) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      let item = await api.post("/workflows", params);
      browserHistory.push(`/workflows`);

      params = new Map();
      params.set("file", file);
      // POST request to API for Upload
      await api.postFiles(`/workflows/${item.id}/image`, params);

      dispatch(fetchItem(item.id));
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function deleteItem(id) {
  return async (dispatch) => {
    try {
      // DELETE request to API
      await api.delete("/workflows/" + id);
      dispatch(fetchItems());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function deleteMilestone(id) {
  return async (dispatch) => {
    try {
      // DELETE request to API
      await api.delete("/workflows/deleteMilestone/" + id);
      dispatch(fetchItems());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateColumn(id, column, value) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set(column, value);
      // PUT request to API
      await api.put(`/workflows/${id}/updateColumn`, params);
      dispatch(fetchItems());
      browserHistory.push(`/workflows`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchCodesFromWorkFlows(data, success = () => {}) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("workFlowsId", JSON.stringify(data));
      // PUT request to API
      let responseData = await api.get(`/codes-from-workflows`, params);
      success(responseData);
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}
