import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import language from "../../services/language";
import { Link } from "react-router-dom";
import _ from "lodash";
 import { browserHistory } from "../../helpers";


class StepViewForm extends Component {
  state = {
    currentItemLoaded: false,
    item: {},
    form: {
      codeType: "",
      data: {},
    },
  };

  componentDidMount() {
    this.tryLoadCurrentItem();
  }

  componentDidUpdate() {
    this.tryLoadCurrentItem();
  }

  constructor(props) {
    super(props);
    autoBind(this);
  }

  tryLoadCurrentItem() {
    if (this.props.currentItem && !this.state.currentItemLoaded) {
      //Convert all parent & child objects from immutable to mutable
      let mutableObj = JSON.parse(JSON.stringify(this.props.currentItem));
      this.setState({
        currentItemLoaded: true,
        item: mutableObj,
      });
    }
  }

  handleInputChange(e, codeType, codeName) {
    let codes = this.state.item.codes;
    let result = _.find(codes[codeType], function (o) {
      return o.name === codeName;
    });
    result.stepId = e.target.value;
    let item = _.extend({}, this.state.item);
    this.setState({
      item,
    });
    //Pass form object to server for update in db.
    let form = {};
    form["codeType"] = codeType;
    form["data"] = result;
    this.setState({
      form: _.extend(this.state.form, form),
    });
    this.props.saveItem(this.state.form);
  }

  handleCancelClick(e) {
    e.preventDefault();
    browserHistory.push("/glossaries/steps");
  }

  buildLabel(Item, item) {
    if (!Item || Item === undefined) return Item;
    let _glossaries = this.props.glossaries;
    let _label = Item;
    // separate out all selectors and loop through
    let result = strings.getFromBetween.get(_label, "[[", "]]");
    result.forEach((e) => {
      const reg = new RegExp(e);
      // if there's a translation - use it
      const _value = localStorage[e + "_translation"] || localStorage[e];
      if (_value !== "" && !!_value) {
        _label = _label.replace(reg, _value).replace(/\[\[|\]\]/g, "");
      } else {
        // work out pattern [[*.*_*]] for selects
        if (e.indexOf(".") > -1) {
          const field = e.split(".")[0];
          const values = e.split(".")[1].split("_");
          const selected = localStorage[field];
          if (!!selected) {
            const _keys = ["field", "selector", "if"];
            _keys.forEach((e) => {
              if (this.paths[e] && this.paths[e][field]) {
                const path = this.paths[e][field];
                const rule = path[Object.keys(path)[0]].node;

                let i = 0;
                for (var item in rule.options) {
                  if (item === selected) break;
                  i++;
                }
                _label = _label
                  .replace(reg, values[i])
                  .replace(/\[\[|\]\]/g, "");
              }
            });
          }
        } else {
          // if there's no placeholder there, grab all glossaries
          // then concat everything and loop through to find what we're looking for
          let _value = _.map(_glossaries, (element) => {
            return JSON.parse(element.value);
          });
          if (_value !== []) {
            let _merged = [];
            _value.forEach((element) => {
              _merged = _merged.concat(
                element["fields"],
                element["clauses"],
                element["selectors"]
              );
            });
            let replacement = _.find(_merged, (element) => {
              if(element.name)
              return element.name === e;
            });
            if (!!replacement) {
              _label = _label
                .replace(reg, replacement.placeholderField)
                .replace(/\[\[|\]\]/g, "");
            }
          }
        }
      }
    });
    return _label;
  }

  codesList(codeType) {
    let items = "";
    let steps = _.map(this.props.steps, (item) => {
      let newLabel = this.buildLabel(item.name);
      return (
        <option value={item.id} key={item.id}>
          {newLabel}
        </option>
      );
    });

    if (typeof this.state.item.codes !== "undefined") {
      let code = this.state.item.codes[codeType];
      items = _.map(code, (item, key) => {
        return (
          <tr key={item.name}>
            <td>{codeType}</td>
            <td>{item.name}</td>
            <td>
              <select
                className="form-control"
                name="stepId"
                value={item.stepId}
                onChange={(e) => this.handleInputChange(e, codeType, item.name)}
              >
                <option value="-1" disabled>
                  {strings.get("App.glossaries.glossaryForm.selectors.choose")}
                </option>
                {steps}
              </select>
            </td>
          </tr>
        );
      });
    }
    return items;
  }

  render() {
    return (
      <div className="StepViewForm row">
        <form>
          <div className="col-sm-12 col-md-12">
            <div className="form-actions pull-right">
              <button
                className="btn btn-default"
                onClick={this.handleCancelClick}
              >
                {strings.get("App.glossaries.steps.cancel")}
              </button>
            </div>
            <div className="codesList">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>
                      {strings.get(
                        "App.companies.companyCategoryForm.codeType"
                      )}
                    </th>
                    <th>
                      {strings.get("App.companies.companyCategoryForm.code")}
                    </th>
                    <th>
                      {" "}
                      {strings.get(
                        "App.glossaries.glossaryForm.clauses.formModal.step"
                      )}{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.codesList("selectors")}
                  {this.codesList("clauses")}
                  {this.codesList("fields")}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

StepViewForm.propTypes = {
  exceptions: PropTypes.object.isRequired,
  saveItem: PropTypes.func.isRequired,
};

export default StepViewForm;
