import React, { Component } from "react";
import {connect} from'react-redux'
import autoBind from "react-autobind";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import strings from "../../../services/strings";
import settings from "../../../services/settings";

import * as settingsSelectors from "../../../store/settings/selectors";
import * as settingsActions from "../../../store/settings/actions";

import Topbar from "../../../components/Topbar";
import SubTopbar from "../../../components/SubTopbar";
import SettingsTabs from "../../../components/settings/SettingsTabs";
import SettingsLeftTabs from "../../../components/settings/SettingsLeftTabs";
import ForgotPasswordEmailForm from "../../../components/settings/email/ForgotPasswordEmailForm";


class ForgotPasswordEmailPage extends Component {
    constructor(props){
        super(props)
        autoBind(this);
        this.state = {
            subjectKey: settings.keys.FORGOTPASSWORD_EMAIL_SUBJECT,
            messageKey: settings.keys.FORGOTPASSWORD_EMAIL_MESSAGE,
            email_short_codes: ["[[FIRST_NAME]]", "[[LINK]]"],
          };
    }

    componentDidMount() {
        this.props.fetchAllSettings();
    }
    
    saveSettings(data) {
        let subjectPromise = this.props.updateSetting(data[this.state.subjectKey]);
        let messagePromise = this.props.updateSetting(data[this.state.messageKey]);
    
        Promise.all([subjectPromise, messagePromise]).then(() => {
          NotificationManager.success(
            "",
            strings.get("App.settings.settingsSaved")
          );
        });
      }

    render() {
        return(
            <div className="LanguageListPage">
             
                <Topbar
                    currentLanguage={this.props.currentLanguage}
                    handleLangChange={this.props.handleLangChange}
                >
                    <div className="title">
                        <Link to="/settings/email">
                            {strings.get("App.settings.title")}
                        </Link>
                    </div>
                </Topbar>

                <div className="content settings-container">
                    <SubTopbar>
                        <SettingsTabs />
                    </SubTopbar>
                    <div className="setting-content row">
                        <SettingsLeftTabs currentItemId={13} />
                        <ForgotPasswordEmailForm
                        settings={this.props.settings}
                        saveSettings={this.saveSettings}
                        subjectKey={this.state.subjectKey}
                        messageKey={this.state.messageKey}
                        email_short_codes={this.state.email_short_codes}
                        info_msg={
                            strings.get(
                            "App.settings.pagesInfo.emailAddressVerifyTemplate"
                            ) || ""
                        }
                        />
                    </div>
                </div>
            </div>
        )
    }   
}

function mapStateToProps(state) {
    return {
      settings: settingsSelectors.getItems(state),
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      fetchAllSettings: () => {
        dispatch(settingsActions.fetchAllItems());
      },
      updateSetting: (data) => {
        return dispatch(settingsActions.updateItem(data));
      },

    };
  }

export default connect(mapStateToProps,mapDispatchToProps)(ForgotPasswordEmailPage)

