import React, { Component } from "react";
import _ from "lodash";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "./UserSettings.scss";
import "../Page.scss";

import Topbar from "../../components/Topbar";
import SubTopbar from "../../components/SubTopbar";
import UserSettingsTabs from "../../components/user/UserSettingsTabs";
import Modal from "reboron/DropModal";
import RequiredOptionsList from "../../components/user/requiredOptions/RequiredOptionsList";
import UserSettingsForm from "../../components/user/requiredOptions/UserSettingsForm";

import * as usersActions from "../../store/users/actions";
import * as usersSelectors from "../../store/users/selectors";
import * as requiredOptionsActions from "../../store/requiredOptions/actions";
import * as requiredOptionsSelectors from "../../store/requiredOptions/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

class UserRequiredOptionPage extends Component {
  state = {
    user: null,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchUser(this.props.match.params.id);
    this.props.setCurrentUserId(this.props.match.params.id);
  }

  componentDidMount() {
    this.props.fetchAllRequiredOptions();
  }

  handleShowModal() {
    _.delay(() => {
      this.hideSaveModal();
    }, 500);
  }
  showSaveModal() {
    this.refs.saveModal.show();
    _.delay(() => {
      this.hideSaveModal();
    }, 1000);
  }

  hideSaveModal() {
    this.refs.saveModal.hide();
  }

  saveData(data) {
    let updateRequiredOptions = this.props.updateRequiredOptions(data);
    Promise.all([updateRequiredOptions]).then(() => {
      this.showSaveModal();
    });
  }

  saveUserSettings(data) {
    this.props.updateUserSettings(data).then(() => {
      if (_.size(this.props.exceptions) === 0) {
        this.showSaveModal();
      }
    });
  }

  render() {
    return (
      <div className="UserDownloadPage">
        <Modal
          className="boron-modal no-body"
          ref="saveModal"
          onShow={this.handleShowModal}
        >
          <span>
            <h2>{strings.get("App.settings.settingsSaved")}</h2>
          </span>
        </Modal>

        <Topbar>
          <div className="title">
            <Link to="/users">{strings.get("App.userPages.title")}</Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to={`/users/${this.props.match.params.id}/profile`}>
                {strings.get("App.userPages.edit")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content user-settings-container">
          <SubTopbar>
            <UserSettingsTabs userId={this.props.match.params.id} />
          </SubTopbar>
          <div className="user-setting-content">
            <div className="container">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="row">
                  <RequiredOptionsList
                    items={this.props.requiredOptions}
                    currentItem={this.props.currentRequiredOption}
                    updateItem={this.saveData}
                    setCurrentItemId={this.props.setCurrentRequiredOptionId}
                    unsetCurrentItemId={this.props.unsetCurrentRequiredOptionId}
                  />
                </div>
              </div>
              <div className="clearfix" />
              <UserSettingsForm
                exceptions={this.props.exceptions}
                currentItem={this.props.currentUser}
                saveItem={this.saveUserSettings}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: usersSelectors.getCurrentItem(state),
    requiredOptions: requiredOptionsSelectors.getItems(state),
    currentRequiredOption: requiredOptionsSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
    setCurrentUserId: (id) => {
      dispatch(usersActions.setCurrentItemId(id));
    },
    fetchUser: (id) => {
      dispatch(usersActions.fetchItem(id));
    },
    fetchAllRequiredOptions: () => {
      dispatch(requiredOptionsActions.fetchAllItems());
    },
    updateRequiredOptions: (data) => {
      dispatch(requiredOptionsActions.updateItem(data));
    },
    setCurrentRequiredOptionId: (id) => {
      dispatch(requiredOptionsActions.setCurrentItemId(id));
    },
    unsetCurrentRequiredOptionId: () => {
      dispatch(requiredOptionsActions.unsetCurrentItemId());
    },
    updateUserSettings: (data) => {
      return dispatch(requiredOptionsActions.updateUserSettings(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserRequiredOptionPage);
