import React, { Component } from "react";
import autoBind from "react-autobind";
import strings from "../../services/strings";
import PropTypes from "prop-types";

import Editor from "../Editor";
import _ from "lodash";
import Switch from "../Switch.js";
import ReactAvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { browserHistory } from "../../helpers";

class ArticleForm extends Component {
  state = {
    currentItemLoaded: false,
    file: null,
    image: null,
    scale: 0.2,
    currItem: null,
    fileRejected: false,
    form: {
      name: "",
      url: "",
      article_category_id: "",
      articleCategories: [],
      content: "",
      preview_content: "",
      visible: false,
      image: "",
      content_type: false, //if false, so no need to be link
      meta_title: "",
      meta_description: "",
      meta_keyword: "",
    },
  };

  setEditorRef = (editor) => (this.editor = editor);

  // handleChange(e) {
  //   this.setState({ [e.target.name]: e.target.value });
  // }

  removeImage = (image, file) => () => {
    this.setState({
      image: null,
      file: null,
    });
  };

  cropImage = () => {
    let crooperD = this.editor.getImageScaledToCanvas().toDataURL();
    // console.log('this.editor.getImageScaledToCanvas()')
    // let form = _.extend(this.state.form, {});
    // form.image = form.image.asMutable
    //   ? form.image.asMutable()
    //   : form.image
    // form.image.preview = crooperD;
    this.setState({
      image: null,
      file: this.editor.getImageScaledToCanvas().toDataURL(),
      currItem: null,
    });
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.tryLoadCurrentItem();
  }

  componentDidUpdate() {
    this.tryLoadCurrentItem();
  }

  tryLoadCurrentItem() {
    if (this.props.currentItem && !this.state.currentItemLoaded) {
      let form = _.extend({}, this.state.form);
      _.map(this.state.form, (value, key) => {
        form[key] = this.props.currentItem[key]
          ? this.props.currentItem[key]
          : this.state.form[key];
      });

      this.setState({
        currentItemLoaded: true,
        form,
        currItem: this.props.currentItem,
      });
    }
  }

  hasError(inputName) {
    return !!this.props.exceptions[inputName];
  }

  getErrorClass(inputName, defaultClasses = "") {
    return this.hasError(inputName)
      ? defaultClasses + " has-error"
      : defaultClasses;
  }

  getErrorMessage(inputName) {
    return this.props.exceptions[inputName];
  }

  // getPreviewImage() {
  //     if (this.state.file) {
  //         return (<img src={ this.state.file.preview } />);
  //     } else {
  //         return (this.props.currentItem && this.props.currentItem.imageURL) ? (
  //             <img src={ this.props.currentItem.imageURL } />
  //         ) : null;
  //     }
  // }

  handleInputChange(e) {
    let form = {};
    form[e.target.name] = e.target.value;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  }

  setEditorRef = (editor: any) => {
    if (editor) {
      this.editor = editor;
      // console.log('this.editor', this.editor)
      const img = this.editor.getImageScaledToCanvas().toDataURL();
      // console.log('hello i am here', img)
    }
  };

  getPreviewImage(file) {
    if (this.state.file) {
      return <img src={this.state.file} />;
    } else {
      return this.state.currItem && this.state.currItem.imageURL ? (
        <img src={this.state.currItem.imageURL} />
      ) : null;
    }
  }

  isImage(file) {
    // console.log("this.state.currItem", this.state.currItem);
    if (this.state.currItem && this.state.currItem.imageURL) {
      return true;
    } else {
      return false;
    }
  }

  setImage = (fileName, formKey) => () => {
    this.setState({
      [fileName]: null,
      currItem: {
        imageURL: null,
      },
    });
    // if(this.props.currentItem && this.props.currentItem.imageURL) {
    //   this.props.currentItem.imageURL = null
    // }
  };

  handleFileDrop = (dropped) => {
    // console.log("drop", dropped);
    // let form = _.extend(this.state.form, {});
    // form.image = form.image.asMutable
    //   ? form.image.asMutable()
    //   : form.image;
    // form.image = dropped[0];
    console.log("handleFileDrop", this.state.form, dropped[0]);
    this.setState({ image: dropped[0], cropClass: false, file: dropped[0] });
  };

  // handleFileDrop(acceptedFiles, rejectedFiles) {
  //     $(".errorUpload").html("");
  //     if (rejectedFiles.length) {
  //         this.setState({
  //             fileRejected: true,
  //         })
  //     } else {
  //       let imageErrorMsg =  `${strings.get('Client.settings.errorlogo')}`;
  //       let i = new Image();
  //       i.src = _.first(acceptedFiles).preview;
  //       i.onload = () => {
  //         if (i.width < 900 || i.width > 1000 || i.height < 500 ||  i.height > 600 ) {
  //             $(".errorUpload").html(imageErrorMsg);
  //             return;
  //         }
  //         this.setState({
  //             file: _.first(acceptedFiles),
  //             fileRejected: false,
  //         })
  //       };
  //     }
  // }

  handleSaveClick(e) {
    e.preventDefault();
    let form = {};
    form.visible = Number(this.state.form.visible);
    form.content_type = Number(this.state.form.content_type);

    this.setState(_.extend(this.state.form, form), () => {
      this.props.saveItem(this.state);
    });
  }

  handleCancelClick(e) {
    e.preventDefault();
    browserHistory.push("/articles");
  }

  handleCheckboxChange(e) {
    let form = {};
    form[e.target.name] = e.target.checked;
    this.setState({
      form: _.extend(this.state.form, form),
    });
  }

  // handleContentChange(e) {
  //   this.setState({
  //     form: _.extend(this.state.form, {
  //       content: e.target.getContent(),
  //     }),
  //   });
  // }

  handleContentChange(e) {
    this.setState({
      form: _.extend(this.state.form, {
        content: e,
      }),
    });
  }

  getArticleCategories() {
    return _.map(this.props.articleCategories, (item) => {
      return (
        <option key={`item-${item.id}`} value={item.id}>
          {item.tree_name}
        </option>
      );
    });
  }

  handleChange(name, enabled, value) {
    let { form } = this.state;
    form[name] = enabled;
    this.setState({ form });
  }

  render() {
    let nameError = this.hasError("name")
      ? `${strings.get("App.articleForm.name")} ${this.getErrorMessage("name")}`
      : "";
    let urlError = this.hasError("url")
      ? `${strings.get("App.articleForm.url")} ${this.getErrorMessage("url")}`
      : "";
    let articleCategoryError = this.hasError("article_category_id")
      ? `${strings.get(
          "App.articleForm.articleCategory"
        )} ${this.getErrorMessage("article_category_id")}`
      : "";
    let contentError = this.hasError("content")
      ? `${strings.get("App.articleForm.content")} ${this.getErrorMessage(
          "content"
        )}`
      : "";
    let previewContentError = this.hasError("preview_content")
      ? `${strings.get(
          "App.articleForm.preview_content"
        )} ${this.getErrorMessage("preview_content")} *(min 100 characters)`
      : "";
    let imageError = this.hasError("file")
      ? `${strings.get("App.articleForm.image")} ${this.getErrorMessage(
          "file"
        )}`
      : "";
    let metaTitleError = this.hasError("meta_title")
      ? `${strings.get("App.articleForm.meta_title")} ${this.getErrorMessage(
          "meta_title"
        )}`
      : "";
    let metaDescError = this.hasError("meta_description")
      ? `${strings.get(
          "App.articleForm.meta_description"
        )} ${this.getErrorMessage("meta_description")}`
      : "";

    let dropzoneContent = this.getPreviewImage() ? (
      this.getPreviewImage()
    ) : (
      <img src={"https://dummyimage.com/1000x600/000/fff.jpg"} />
    );
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="ArticleForm">
            <form className="form-horizontal">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <b>{this.props.formHeading}</b>
                </div>
                <div className="panel-body">
                  <div className={this.getErrorClass("name", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.name")}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={this.state.form.name}
                        onChange={this.handleInputChange}
                      />
                      <small className="text-danger">{nameError}</small>
                    </div>
                  </div>
                  <div className={this.getErrorClass("url", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.url")}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="url"
                        value={this.state.form.url}
                        onChange={this.handleInputChange}
                      />
                      <small className="text-danger">{urlError}</small>
                    </div>
                  </div>
                  <div
                    className={this.getErrorClass(
                      "article_category_id",
                      "form-group"
                    )}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.articleCategory")}
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="article_category_id"
                        value={this.state.form.article_category_id}
                        onChange={this.handleInputChange}
                      >
                        <option value="">
                          {strings.get("App.articleForm.choose")}
                        </option>
                        {this.getArticleCategories()}
                      </select>
                      <small className="text-danger">
                        {articleCategoryError}
                      </small>
                    </div>
                  </div>
                  <div
                    style={{ display: "none" }}
                    className={this.getErrorClass("visible", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.visible")}
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="checkbox"
                        name="visible"
                        checked={this.state.form.visible}
                        onChange={this.handleCheckboxChange}
                      />
                    </div>
                  </div>
                  <div
                    className={this.getErrorClass("content_type", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.content_type")}
                    </label>
                    <div className="col-sm-9">
                      {/* <input type="checkbox" name="content_type" checked={ this.state.form.content_type } onChange={ this.handleCheckboxChange }/> */}
                      <Switch
                        enabled={this.state.form.content_type}
                        onChange={(enabled) => {
                          this.handleChange(
                            "content_type",
                            enabled,
                            this.state.form.content_type
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass(
                      "preview_content",
                      "form-group"
                    )}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.preview_content")}
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        name="preview_content"
                        value={this.state.form.preview_content}
                        onChange={this.handleInputChange}
                      ></textarea>
                      <small className="text-danger">
                        {previewContentError}
                      </small>
                    </div>
                  </div>

                  <div className={this.getErrorClass("content", "form-group")}>
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.content")}
                    </label>
                    <div className="col-sm-9">
                      <Editor
                        content={this.state.form.content}
                        handleChange={this.handleContentChange}
                      />
                      <small className="text-danger">{contentError}</small>
                    </div>
                  </div>
                  {/* <div className={ this.getErrorClass('file', 'form-group') }>
                                      <label className="col-sm-3 control-label">{ strings.get('App.articleForm.image') }</label>
                                      <div className="col-sm-9">
                                          <Dropzone
                                              className="dropzone"
                                              onDrop={ this.handleFileDrop }
                                              multiple={ false }
                                              maxSize={ 4096000 }
                                              accept="image/*">
                                              { dropzoneContent }
                                          </Dropzone>
                                          <div className="help-block">(Suggestie: breedte 900px bij 1000px, hoogte 500px bij 600px)</div>
                                          <small className="text-danger">{ imageError }</small>
                                          <small className="text-danger errorUpload"></small>
                                      </div>
                                  </div> */}

                  <div className="form-group">
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.image")}
                    </label>

                    <div className="col-sm-9">
                      {this.state.image || this.state.file ? (
                        <input
                          type="button"
                          className="close-btn"
                          style={{ marginTop: -10 }}
                          onClick={this.removeImage("image", "file")}
                          value="X"
                        />
                      ) : this.isImage("file") ? (
                        <input
                          type="button"
                          className="close-btn"
                          style={{ marginTop: -10 }}
                          onClick={this.setImage("file")}
                          value="X"
                        />
                      ) : (
                        ""
                      )}
                      <Dropzone
                        className="dropzone"
                        onDrop={this.handleFileDrop}
                        multiple={false}
                        maxSize={4096000}
                        accept="image/*"
                      >
                        {/* {dropzoneContent}

                      {this.state.image ? (
                        <ReactAvatarEditor
                          scale={this.state.scale}
                          rotate={0}
                          className="image-placeholder"
                          image={this.state.image}
                          width={1000}
                          height={600}
                          ref={ref => this.setEditorRef(ref)}
                        />
                      ) : this.state.file && this.state.currItem ? (
                        <img src={this.state.file} />
                      ) : (
                        ""
                      )} */}
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {dropzoneContent}

                            {this.state.image ? (
                              <ReactAvatarEditor
                                scale={this.state.scale}
                                rotate={0}
                                className="image-placeholder"
                                image={this.state.image}
                                width={1000}
                                height={600}
                                ref={(ref) => this.setEditorRef(ref)}
                              />
                            ) : this.state.file && this.state.currItem ? (
                              <img src={this.state.file} />
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </Dropzone>

                      {this.state.cropClass === false ? (
                        <div className="help-block">
                          <div className="help-block">
                            (Suggest Dimension: 1000px X 600px)
                          </div>
                          {this.state.image !== null ? (
                            <input
                              type="range"
                              step="0.01"
                              min="0.1"
                              max="2"
                              name="scale"
                              value={this.state.scale}
                              onChange={(e) =>
                                this.setState({
                                  [e.target.name]: e.target.value,
                                })
                              }
                            />
                          ) : (
                            ""
                          )}

                          {this.state.image !== null ? (
                            <input
                              type="button"
                              className="crop-image-btn"
                              onClick={this.cropImage}
                              value="Crop"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass("meta_title", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.meta_title")}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="meta_title"
                        value={this.state.form.meta_title}
                        onChange={this.handleInputChange}
                      />
                      <small className="text-danger">{metaTitleError}</small>
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass(
                      "meta_description",
                      "form-group"
                    )}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.meta_description")}
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        name="meta_description"
                        value={this.state.form.meta_description}
                        onChange={this.handleInputChange}
                      ></textarea>
                      <small className="text-danger">{metaDescError}</small>
                    </div>
                  </div>

                  <div
                    className={this.getErrorClass("meta_keyword", "form-group")}
                  >
                    <label className="col-sm-3 control-label">
                      {strings.get("App.articleForm.meta_keyword")}
                    </label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        type="text"
                        name="meta_keyword"
                        value={this.state.form.meta_keyword}
                        onChange={this.handleInputChange}
                      />
                      <div className="help-block">
                        ( {strings.get("App.articleForm.meta_keyword_example")}{" "}
                        )
                      </div>
                    </div>
                  </div>

                  <footer className="panel-footer text-right bg-light lter">
                    <button
                      className="btn btn-primary"
                      onClick={this.handleSaveClick}
                    >
                      {strings.get("App.articleForm.save")}
                    </button>
                    <button
                      className="btn btn-default btn-color-change"
                      onClick={this.handleCancelClick}
                    >
                      {strings.get("App.articleForm.cancel")}
                    </button>
                  </footer>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ArticleForm.propTypes = {
  exceptions: PropTypes.object.isRequired,
  saveItem: PropTypes.func.isRequired,
};

export default ArticleForm;
