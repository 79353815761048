import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import strings from "../../services/strings";
import Topbar from "../../components/Topbar";
import ArticleList from "../../components/article/ArticleList";
import ArticleCategoryFilter from "../../components/article/ArticleCategoryFilter";
import ArticlePagesize from "../../components/article/ArticlePagesize";
import Pagination from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";

import "../Page.scss";

import * as articlesActions from "../../store/articles/actions";
import * as articlesSelectors from "../../store/articles/selectors";
import * as articleCategoriesActions from "../../store/articleCategories/actions";
import * as articleCategoriesSelectors from "../../store/articleCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

class ArticlesListPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchArticles();
    this.props.fetchAllCategories();
  }

  componentWillUnmount() {
    this.props.unsetCurrentArticleId();
    this.props.clearExceptions();
  }

  render() {
    return (
      <div className="ArticlesListPage">
        <Topbar>
          <div className="title">
            <Link to="/articleCategories">
              {strings.get("App.articlePages.listPage.articleCategories")}
            </Link>
            <span className="xs-hidden">
              <span className="divier">/</span>
              <Link to="/articles">
                {strings.get("App.articlePages.listPage.title")}
              </Link>
            </span>
          </div>
          <SearchBar
              placeholder={strings.get("App.searchPlaceholders.articleCategories")}
              searchTerm={this.props.filters.searchTerm}
              fetchItems={this.props.fetchArticles}
              setSearchTerm={this.props.setSearchTerm}
            />
          <div className="main-btns">
            <Link to="/articles/add" className="btn btn-primary">
              <span>{strings.get("App.articlePages.listPage.addArticle")}</span>{" "}
              <i className="ion-plus"></i>
            </Link>
          </div>
        </Topbar>

        <div className="content">
          <div className="row">
            <div className="col-xs-6">
              <ArticleCategoryFilter
                filters={this.props.filters}
                articleCategories={this.props.articleCategories}
                fetchItems={this.props.fetchArticles}
                setArticleCategoryId={this.props.setArticleCategoryId}
              />
            </div>
            <div className="col-xs-6">
              <ArticlePagesize
                sorter={this.props.sorter}
                fetchItems={this.props.fetchArticles}
                setSorter={this.props.setSorter}
              />
            </div>
          </div>
          <ArticleList
            items={this.props.articles}
            currentItem={this.props.currentArticle}
            fetchItems={this.props.fetchArticles}
            setCurrentItemId={this.props.setCurrentArticleId}
            unsetCurrentItemId={this.props.unsetCurrentArticleId}
            deleteItem={this.props.deleteArticle}
            updateItem={this.props.updateArticle}
          />

          <Pagination
            pagination={this.props.pagination}
            setCurrentPage={this.props.setCurrentPage}
            fetchItems={this.props.fetchArticles}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    articles: articlesSelectors.getItemsByPage(
      state,
      articlesSelectors.getPagination(state).currentPage
    ),
    pagination: articlesSelectors.getPagination(state),
    currentArticle: articlesSelectors.getCurrentItem(state),
    filters: articlesSelectors.getFilters(state),
    articleCategories: articleCategoriesSelectors.getItems(state),
    sorter: articlesSelectors.getSorter(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchArticles: (deleteCache) => {
      dispatch(articlesActions.fetchItems(deleteCache));
    },
    setCurrentPage: (page) => {
      dispatch(articlesActions.setCurrentPage(page));
    },
    setCurrentArticleId: (id) => {
      dispatch(articlesActions.setCurrentItemId(id));
    },
    unsetCurrentArticleId: () => {
      dispatch(articlesActions.unsetCurrentItemId());
    },
    deleteArticle: (id) => {
      dispatch(articlesActions.deleteItem(id));
    },
    updateArticle: (id, data) => {
      dispatch(articlesActions.updateItem(id, data));
    },
    setArticleCategoryId: (id) => {
      dispatch(articlesActions.setArticleCategoryId(id));
    },
    fetchAllCategories: () => {
      dispatch(articleCategoriesActions.fetchAllItems());
    },
    setSorter: (sorter) => {
      dispatch(articlesActions.setSorter(sorter));
    },
    setSearchTerm: (searchTerm) =>{
      dispatch(articlesActions.setSearchTerm(searchTerm));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesListPage);
