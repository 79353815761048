import React, { Component } from "react";

import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as companyCategoriesActions from "../../store/companyCategories/actions";
import * as companyCategoriesSelectors from "../../store/companyCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
// import SubTopbar from "../../components/SubTopbar";
import CompanyCategoryList from "../../components/companyCategory/CompanyCategoryList";
import CompanyCategoryPagesize from "../../components/companyCategory/CompanyCategoryPagesize";
import Pagination from "../../components/Pagination";

class CompanyCategoryListPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchCompanyCategories();
  }

  render() {
    return (
      <div className="CompanyCategoryListPage">
        <Topbar>
          <div className="title">
            <Link to="/glossaries/companies">
              {strings.get("App.companyCategoryPages.title")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/glossaries/companies/categories">
                {strings.get("App.companyCategoryPages.categories")}
              </Link>
            </span>
          </div>
          <div className="main-btns">
            <Link
              to="/glossaries/companies/categories/add"
              className="btn btn-primary"
            >
              <span>
                {strings.get("App.companyCategoryPages.addCompanyCategory")}
              </span>{" "}
              <i className="ion-plus"></i>
            </Link>
          </div>
        </Topbar>

        <div className="content">
          <div className="row">
            <div className="col-xs-12">
              <CompanyCategoryPagesize
                sorter={this.props.sorter}
                fetchItems={this.props.fetchCompanyCategories}
                setSorter={this.props.setSorter}
              />
            </div>
          </div>

          <CompanyCategoryList
            items={this.props.companyCategories}
            sorter={this.props.sorter}
            currentItem={this.props.currentCompanyCategory}
            fetchItems={this.props.fetchCompanyCategories}
            setCurrentItemId={this.props.setCurrentCompanyCategoryId}
            unsetCurrentItemId={this.props.unsetCurrentCompanyCategoryId}
            deleteItem={this.props.deleteCompanyCategory}
            toggleSorter={this.props.toggleSorter}
            orderItems={this.props.orderItems}
          />

          <Pagination
            pagination={this.props.pagination}
            setCurrentPage={this.props.setCurrentPage}
            fetchItems={this.props.fetchCompanyCategories}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    companyCategories: companyCategoriesSelectors.getItemsByPage(
      state,
      companyCategoriesSelectors.getPagination(state).currentPage
    ),
    sorter: companyCategoriesSelectors.getSorter(state),
    filters: companyCategoriesSelectors.getFilters(state),
    pagination: companyCategoriesSelectors.getPagination(state),
    currentCompanyCategory: companyCategoriesSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompanyCategories: (deleteCache) => {
      dispatch(companyCategoriesActions.fetchItems(deleteCache));
    },
    setSearchTerm: (searchTerm) => {
      dispatch(companyCategoriesActions.setSearchTerm(searchTerm));
    },
    toggleSorter: (searchTerm) => {
      dispatch(companyCategoriesActions.toggleSorter(searchTerm));
    },
    setCurrentPage: (page) => {
      dispatch(companyCategoriesActions.setCurrentPage(page));
    },
    setCurrentCompanyCategoryId: (id) => {
      dispatch(companyCategoriesActions.setCurrentItemId(id));
    },
    unsetCurrentCompanyCategoryId: () => {
      dispatch(companyCategoriesActions.unsetCurrentItemId());
    },
    deleteCompanyCategory: (id, handleCatDeleteResponse) => {
      dispatch(
        companyCategoriesActions.deleteItem(id, handleCatDeleteResponse)
      );
    },
    setSorter: (sorter) => {
      dispatch(companyCategoriesActions.setSorter(sorter));
    },
    orderItems: (items) => {
      dispatch(companyCategoriesActions.orderItems(items));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyCategoryListPage);
