import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as usersActions from "../../store/users/actions";
import * as usersSelectors from "../../store/users/selectors";
import * as groupsActions from "../../store/groups/actions";
import * as groupsSelectors from "../../store/groups/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
// import SubTopbar from "../../components/SubTopbar";
import CategoryForm from "../../components/category/CategoryForm";

class CategoryAddPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchAllCategories();
    this.props.fetchAllUsers();
    this.props.fetchAllGroups();
  }

  componentWillUnmount() {
    this.props.clearExceptions();
  }

  saveCategory(data) {
    if (data.file || data.bgFile) {
      this.props.createCategoryWithLogo(data.form, data.file, data.bgFile);
    } else {
      this.props.createCategory(data.form);
    }
  }

  render() {
    return (
      <div className="CategoryAddPage">
        <Topbar>
          <div className="title">
            <Link to="/documents/categories">
              {strings.get("App.categoryPages.add.title")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/documents/categories/add">
                {strings.get("App.categoryPages.add.add")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content">
          <CategoryForm
            exceptions={this.props.exceptions}
            categories={this.props.categories}
            users={this.props.users}
            groups={this.props.groups}
            saveItem={this.saveCategory}
            formHeading={strings.get("App.categoryPages.add.add")}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: categoriesSelectors.getItems(state),
    users: usersSelectors.getItems(state),
    groups: groupsSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllCategories: (deleteCache) => {
      dispatch(categoriesActions.fetchAllItems(deleteCache));
    },
    fetchAllUsers: (deleteCache) => {
      dispatch(usersActions.fetchAllItems(deleteCache));
    },
    fetchAllGroups: (deleteCache) => {
      dispatch(groupsActions.fetchAllItems(deleteCache));
    },
    createCategory: (data) => {
      dispatch(categoriesActions.createItem(data));
    },
    createCategoryWithLogo: (data, file, bgFile) => {
      dispatch(categoriesActions.createItemWithLogo(data, file, bgFile));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryAddPage);
