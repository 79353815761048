import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import strings from "../../../services/strings";

import _ from "lodash";
import "./LanguageList.scss";

import Switch from "../../Switch.js";
import Modal from "reboron/DropModal";
import { browserHistory } from "../../../helpers";


class LanguageList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleChange(id, enabled) {
    this.props.updateItem(id, {
      status: enabled ? 1 : 0,
    });
  }

  handleLanguageClick(id) {
    browserHistory.push(`/settings/languages/${id}`);
  }

  handleDeleteClick(id) {
    this.props.setCurrentItemId(id);
    this.showDeleteModal();
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  handleConfirmDeleteClick() {
    this.props.deleteItem(this.props.currentItem.id);
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleCancelDeleteClick() {
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  render() {
    let deleteModalContent = this.props.currentItem ? (
      <span>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleCancelDeleteClick}
          />
        </div>
        <h2>
          {strings.get("App.deleteModal.message", {
            itemName: this.props.currentItem.name,
          })}
        </h2>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger"
            onClick={this.handleConfirmDeleteClick}
          >
            {strings.get("App.deleteModal.delete")}
          </button>
          <button
            className="btn btn-lg btn-default cancel"
            onClick={this.handleCancelDeleteClick}
          >
            {strings.get("App.deleteModal.cancel")}
          </button>
        </div>
      </span>
    ) : null;

    let items = _.map(this.props.items, (value) => {
      return (
        <tr key={value.id}>
          <td
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.handleLanguageClick(value.id);
            }}
          >
            {strings.get(`Languages.${value.iso2}`)}
          </td>
          <td>
            <i
              onClick={() => this.handleLanguageClick(value.id)}
              className="btn btn-default edit-btn ion-edit"
            />
          </td>
          <td>
            <i
              onClick={() => this.handleDeleteClick(value.id)}
              className="btn btn-default delete-btn ion-trash-b"
            />
          </td>
          <td>
            <Switch
              enabled={!!value.status}
              onChange={(enabled) => this.handleChange(value.id, enabled)}
            />
          </td>
        </tr>
      );
    });

    return (
      <span className="LanguageList">
        <Modal className="boron-modal" ref="deleteModal">
          {deleteModalContent}
        </Modal>
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-body">
                <table className="table table-striped">
                  <tbody>{items}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  }
}

LanguageList.propTypes = {
  items: PropTypes.object.isRequired,
  updateItem: PropTypes.func.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
};

export default LanguageList;
