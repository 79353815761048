import React, { Component } from "react";
import _ from "lodash";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "./UserSettings.scss";
import "../Page.scss";

import Topbar from "../../components/Topbar";
import SubTopbar from "../../components/SubTopbar";
import UserSettingsTabs from "../../components/user/UserSettingsTabs";
import UserDownloadList from "../../components/user/UserDownloadList";
import Pagination from "../../components/Pagination";
import UserDocumentSorter from "../../components/document/UserDocumentSort";
import DocumentCategoryFilter from "../../components/document/DocumentCategoryFilter";
import UserDocumentFilter from "../../components/document/UserDocumentFilter";

import * as downloadsActions from "../../store/downloads/actions";
import * as categoriesActions from '../../store/categories/actions'
import * as categoriesSelectors from '../../store/categories/selectors'
import * as downloadsSelectors from "../../store/downloads/selectors";
import * as sidebarSelectors from "../../store/sidebar/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

class UserDownloadPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      user: null,
      filter: "all",
    };
  }

  componentDidMount() {
    this.props.setCurrentUserId(this.props.match.params.id);
    this.props.fetchAllCategories();
    this.props.fetchAllDownloads();
  }

  componentWillUnmount() {
    this.props.unsetCurrentUserId();
  }

  clearReminderException() {
    this.props.clearExceptions();
  }

  updateItemReminder(downloadId, data) {
    this.props.updateDownloadReminder(downloadId, data);
    _.delay(() => {
      this.props.fetchAllDownloads();
    }, 500);
  }

  handleFilter = () => (e) => {
    this.setState({
      filter: e.target.value,
    });
  };

  render() {
    const { filter } = this.state;
    let userDownloads;
    if (
      this.props.downloads !== undefined &&
      this.props.downloads.length
    ) {
      if (filter === "paid") {
        userDownloads = this.props.downloads.filter(
          (doc) => doc.document.payed === 1
        );
      } else if (filter === "free") {
        userDownloads = this.props.downloads.filter(
          (doc) => doc.document.payed === 0
        );
      } else {
        userDownloads = this.props.downloads;
      }
    } else {
      userDownloads = [];
    }
    return (
      <div className="UserDownloadPage">
        <Topbar>
          <div className="title">
            <Link to="/users">{strings.get("App.userPages.title")}</Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to={`/users/${this.props.match.params.id}/profile`}>
                {strings.get("App.userPages.edit")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content user-settings-container">
          <SubTopbar>
            <UserSettingsTabs userId={this.props.match.params.id} />
          </SubTopbar>
          <div className="user-setting-content">
            <div className="row">
              <div className="col-sm-5 col-md-5 col-xs-6 ">
                  <DocumentCategoryFilter
                    filters={this.props.filters}
                    categories={this.props.categories}
                    fetchItems={this.props.fetchAllDownloads}
                    setCategoryId={this.props.setCategoryId}
                  />
                </div>
                <div className="col-sm-3 col-md-3 col-xs-6 ">
                  <UserDocumentFilter
                    handleFilter={this.handleFilter()}
                    filter={this.state.filter}
                  />
                </div>
                <div className="col-sm-4 col-md-4 col-xs-6 ">
                  <UserDocumentSorter
                    sorter={this.props.sorter}
                    fetchItems={this.props.fetchAllDownloads}
                    setSorter={this.props.setSorter}
                  />
                </div>
            </div>

            <UserDownloadList
              items={userDownloads}
              siteURL={this.props.siteURL}
              currentItem={this.props.currentDownload}
              setCurrentItemId={this.props.setCurrentDownloadId}
              unsetCurrentItemId={this.props.unsetCurrentDownloadId}
              clearReminderException={this.clearReminderException}
              exceptions={this.props.exceptions}
              updateItemReminder={this.updateItemReminder}
            />
            <Pagination
              pagination={this.props.pagination}
              setCurrentPage={this.props.setCurrentPage}
              fetchItems={this.props.fetchAllDownloads}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    exceptions: exceptionsSelectors.getItems(state),
    categories: categoriesSelectors.getItems(state),
    downloads: downloadsSelectors.getItemsByPage(
      state,
      downloadsSelectors.getPagination(state).currentPage
    ),
    filters: downloadsSelectors.getFilters(state),
    sorter: downloadsSelectors.getSorter(state),
    pagination: downloadsSelectors.getPagination(state),
    siteURL: sidebarSelectors.getSiteURL(state),
    currentDownload: downloadsSelectors.getCurrentItem(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllCategories: () => {
      dispatch(categoriesActions.fetchAllItems());
    },
    setCategoryId: (id) => {
      dispatch(downloadsActions.setCategoryId(id));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
    setCurrentUserId: (id) => {
      dispatch(downloadsActions.setCurrentUserId(id));
    },
    unsetCurrentUserId: (id) => {
      dispatch(downloadsActions.unsetCurrentUserId());
    },
    fetchAllDownloads: (deleteCache) => {
      dispatch(downloadsActions.fetchAllDownloads(deleteCache));
    },
    setCurrentPage: (page) => {
      dispatch(downloadsActions.setCurrentPage(page));
    },
    setSorter: (sorter) => {
      dispatch(downloadsActions.setSorter(sorter));
    },
    setCurrentDownloadId: (id) => {
      dispatch(downloadsActions.setCurrentItemId(id));
    },
    unsetCurrentDownloadId: () => {
      dispatch(downloadsActions.unsetCurrentItemId());
    },
    updateDownloadReminder: (id, data) => {
      dispatch(downloadsActions.updateItemReminder(id, data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDownloadPage);
