import api from '../../services/api';
import _ from 'lodash';


import * as exceptionsActions from '../exceptions/actions';
import * as ipAddressesSelectors from './selectors';
import { browserHistory } from '../../helpers';

export const types = {
    FETCH_ALL_ITEMS_DONE: 'ipAddresses.FETCH_ALL_ITEMS_DONE',
    FETCH_ITEMS_DONE: 'ipAddresses.FETCH_ITEMS_DONE',
    FETCH_ITEM_DONE: 'ipAddresses.FETCH_ITEM_DONE',
    SET_SEARCH_TERM: 'ipAddresses.SET_SEARCH_TERM',
    SET_CURRENT_PAGE: 'ipAddresses.SET_CURRENT_PAGE',
    SET_CURRENT_ITEM_ID: 'ipAddresses.SET_CURRENT_ITEM_ID',
    TOGGLE_SORTER: 'ipAddresses.TOGGLE_SORTER',
    CLEAR_CACHE: 'ipAddresses.CLEAR_CACHE',
    SET_SORTER: 'ipAddresses.SET_SORTER'
}

export function setSorter(sorter) {
    return {
        type: types.SET_SORTER,
        payload: {
            sorter
        }
    }
}

export function setCurrentPage(page) {
    return {
        type: types.SET_CURRENT_PAGE,
        payload: {
            page
        }
    }
}

export function setCurrentItemId(id) {
    return {
        type: types.SET_CURRENT_ITEM_ID,
        payload: {
            id
        }
    }
}

export function unsetCurrentItemId() {
    return {
        type: types.SET_CURRENT_ITEM_ID,
        payload: {
            id: null,
        }
    }
}

export function clearCache() {
    return {
        type: types.CLEAR_CACHE
    }
}

export function fetchAllItems() {
    return async (dispatch, getState) => {
        try {
            let items = await api.get('/ipAddresses');
            dispatch(clearCache());
            dispatch({
                type: types.FETCH_ALL_ITEMS_DONE,
                payload: {
                    items
                }
            });
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}

export function fetchItem(id) {
    return async (dispatch) => {
        try {
            // GET request from API
            let item = await api.get(`/ipAddresses/${id}`);
            dispatch({
                type: types.FETCH_ITEM_DONE,
                payload: {
                    item
                }
            })
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}

export function fetchItems(deleteCache = false) {
    return async (dispatch, getState) => {
        let state = getState();
        try {
            // Set additional params
            let params = new Map();
            let pagination = ipAddressesSelectors.getPagination(state);
            params.set('page_size', pagination.pageSize);
            params.set('page_number', deleteCache ? 1 : pagination.currentPage);
            let sorter = ipAddressesSelectors.getSorter(state);
            params.set('page_size', sorter.pageLimit);

            // GET request from API
            let [response, items] = await api.get('/ipAddresses', params, true);

            // Clear cache if deleteCache is enabled
            if (deleteCache) {
                dispatch(clearCache());
            }

            dispatch({
                type: types.FETCH_ITEMS_DONE,
                payload: {
                    totalPages: parseInt(response.headers.get('X-Total-Pages')),
                    items
                }
            });
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}

export function createItem(data) {
    return async (dispatch) => {
        try {
            let params = new Map();
            _.map(data, (value, key) => {
                params.set(key, value);
            })
            // POST request to API
            await api.post(`/ipAddresses`, params);
            browserHistory.push('/settings/ipAddresses');
            
            dispatch(exceptionsActions.clear());
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}

export function updateItem(id, data) {
    return async (dispatch) => {
        try {
            let params = new Map();
            _.map(data, (value, key) => {
                params.set(key, value);
            })
            // PUT request to API
            await api.put(`/ipAddresses/${id}`, params);
            browserHistory.push('/settings/ipAddresses');

            dispatch(exceptionsActions.clear());
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}

export function deleteItem(id) {
    return async (dispatch) => {
        try {
            await api.delete(`/ipAddresses/${id}`);

            dispatch(fetchItems());
            dispatch(exceptionsActions.clear());
        } catch (e) {
            dispatch(exceptionsActions.process(e));
        }
    }
}
