import React, { Component } from "react";
import _ from "lodash";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import strings from "../../services/strings";
import "../Page.scss";

import * as groupsActions from "../../store/groups/actions";
import * as groupsSelectors from "../../store/groups/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
// import SubTopbar from "../../components/SubTopbar";
// import GroupForm from "../../components/group/GroupForm";

class BlockedGroupsEditPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchGroup(this.props.match.params.id);
    this.props.setCurrentGroupId(this.props.match.params.id);
    this.props.fetchAllGroups();
  }

  componentWillUnmount() {
    this.props.unsetCurrentGroupId();
    this.props.clearExceptions();
  }

  saveGroup(data) {
    this.props.updateGroup(this.props.match.params.id, data.form);
  }

  render() {
    return (
      <div className="GroupEditPage">
        <Topbar>
          <div className="title">
            <Link to="/users/groups">
              {strings.get("App.blockedGroups.editPage.title")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to={`/users/groups/${this.props.match.params.id}`}>
                {strings.get("App.blockedGroups.editPage.edit")}
              </Link>
            </span>
          </div>
        </Topbar>
        <div className="content">
          {/* // to do uncomment */}
          {/* <UserFrom
                    exceptions={ this.props.exceptions }
                    currentItem={ this.props.users }
                    group={ this.props.groups }
                    saveItem={ this.saveGroup }
                  /> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    groups: groupsSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchAllUsers: (deleteCache) => {
      dispatch(groupsActions.fetchAllItems(deleteCache));
    },
    setCurrentUserId: (id) => {
      dispatch(groupsActions.setCurrentItemId(id));
    },
    fetchUser: (id) => {
      dispatch(groupsActions.fetchItem(id));
    },
    unsetCurrentUserId: () => {
      dispatch(groupsActions.unsetCurrentItemId());
    },
    updateUser: (id, data) => {
      dispatch(groupsActions.updateItem(id, data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockedGroupsEditPage);
