import React, { Component } from "react";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as companyCategoriesActions from "../../store/companyCategories/actions";
import * as companyCategoriesSelectors from "../../store/companyCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
// import SubTopbar from "../../components/SubTopbar";
import CompanyCategoryForm from "../../components/companyCategory/CompanyCategoryForm";

class CompanyCategoryEditPage extends Component {
  componentDidMount() {
    this.props.fetchCompanyCategories();
    this.props.fetchCompanyCategory(this.props.match.params.id);
    this.props.setCurrentCompanyCategoryId(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.unsetCurrentCompanyCategoryId();
    this.props.clearExceptions();
  }

  saveCompanyCategory = (data) => {
    this.props.updateCompanyCategory(this.props.match.params.id, data.form);
  };

  render() {
    return (
      <div className="CompanyCategoryEditPage">
        <Topbar>
          <div className="title">
            <Link to="/glossaries/companies">
              {strings.get("App.companyCategoryPages.title")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/glossaries/companies/categories">
                {strings.get("App.companyCategoryPages.categories")}
              </Link>
              <span className="divider">/</span>
              <Link
                to={`/glossaries/companies/categories/${this.props.match.params.id}`}
              >
                {strings.get("App.companyCategoryPages.edit")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content">
          <CompanyCategoryForm
            exceptions={this.props.exceptions}
            currentItem={this.props.currentCompanyCategory}
            saveItem={this.saveCompanyCategory}
            companyCategories={this.props.companyCategories}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentCompanyCategory: companyCategoriesSelectors.getCurrentItem(state),
    companyCategories: companyCategoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompanyCategory: (id) => {
      dispatch(companyCategoriesActions.fetchItem(id));
    },
    setCurrentCompanyCategoryId: (id) => {
      dispatch(companyCategoriesActions.setCurrentItemId(id));
    },
    unsetCurrentCompanyCategoryId: () => {
      dispatch(companyCategoriesActions.unsetCurrentItemId());
    },
    updateCompanyCategory: (id, data) => {
      dispatch(companyCategoriesActions.updateItem(id, data));
    },
    createCompanyCategory: (data) => {
      dispatch(companyCategoriesActions.createItem(data));
    },
    fetchCompanyCategories: (deleteCache) => {
      dispatch(companyCategoriesActions.fetchAllItems(deleteCache));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyCategoryEditPage);
