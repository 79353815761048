import React, { Component } from 'react';
import PropTypes from "prop-types";
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import strings from '../../../services/strings';
import settings from '../../../services/settings';
import language from '../../../services/language';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import Editor from '../../Editor';

class ShareMessageForm extends Component {

  state = {
    currentSettingsLoaded: false,
    form: {
      share_draft_instruction_message: { value: '' },
    }
  }

  componentDidMount() {
    this.tryLoadCurrentSettings();
  }

  componentDidUpdate() {
    this.tryLoadCurrentSettings();
  }

  constructor(props) {
    super(props);
    autoBind(this);
  }

  tryLoadCurrentSettings() {
    if (_.size(this.props.settings) && !this.state.currentSettingsLoaded) {
      this.setState({
        currentSettingsLoaded: true,
        form: {
          share_draft_instruction_message: this.props.settings[settings.keys.SHARE_DRAFT_INSTRUCTION_MESSAGE],
        }
      });
    }
  }

  handleInputChange(e) {
    let form = _.extend(this.state.form, {});
    form[e.target.name] = form[e.target.name].asMutable ? form[e.target.name].asMutable() : form[e.target.name];
    form[e.target.name].value = e.target.value;

    this.setState({ form });
  }

  handleSaveClick(e) {
    e.preventDefault();
    this.props.saveSettings(this.state);
  }

  handleCancelClick(e) {
    e.preventDefault();
    this.setState({
      form: {
        share_draft_instruction_message: this.props.settings[settings.keys.SHARE_DRAFT_INSTRUCTION_MESSAGE],
      }
    });
  }

  render() {
    return (
      <div className="EmailForm col-xs-12 col-sm-12 col-md-9">
        <form>
          <div className="form-group">
            <label className="control-label">{strings.get('App.settings.shareInstructionMessage.title')}</label>
            <input className="form-control" type="text" name="share_draft_instruction_message" value={ this.state.form.share_draft_instruction_message.value } onChange={ this.handleInputChange } />
          </div>
          <div className="form-actions">
            <button className="btn btn-primary" onClick={ this.handleSaveClick }>{strings.get('App.email.save')}</button>
            <button className="btn btn-default" onClick={ this.handleCancelClick }>{strings.get('App.email.cancel')}</button>
          </div>
        </form>
      </div>
    );
  }

}

ShareMessageForm.propTypes = {
  settings: PropTypes.object,
  saveSettings: PropTypes.func.isRequired,
}

export default ShareMessageForm;
