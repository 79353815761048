import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import settings from "../../../services/settings";

import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import * as settingsActions from "../../../store/settings/actions";
import * as settingsSelectors from "../../../store/settings/selectors";

import Topbar from "../../../components/Topbar";
import SubTopbar from "../../../components/SubTopbar";
import SettingsTabs from "../../../components/settings/SettingsTabs";
import SettingsLeftTabs from "../../../components/settings/SettingsLeftTabs";
import EmailSubjectMessageForm from "../../../components/settings/email/EmailSubjectMessageForm";

import "../settings.scss";
import "../../Page.scss";
import "./UserWelcomeEmailPage.scss";

class OtpEmailPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      subjectKey: settings.keys.OTP_EMAIL_SUBJECT,
      messageKey: settings.keys.OTP_EMAIL_MESSAGE,
      email_short_codes: ["[[FIRST_NAME]]", "[[EMAIL]]", "[[OTP]]"],
    };
  }

  componentDidMount() {
    this.props.fetchAllSettings();
  }

  saveSettings(data) {
    let subjectPromise = this.props.updateSetting(data[this.state.subjectKey]);
    let messagePromise = this.props.updateSetting(data[this.state.messageKey]);

    Promise.all([subjectPromise, messagePromise]).then(() => {
      NotificationManager.success(
        "",
        strings.get("App.settings.settingsSaved")
      );
    });
  }

  render() {
    return (
      <div className="UserWelcomeEmailPage">
        <Topbar
          currentLanguage={this.props.currentLanguage}
          handleLangChange={this.props.handleLangChange}
        >
          <div className="title">
            <Link to="/settings/shareEmail">
              {strings.get("App.settings.title")}
            </Link>
          </div>
        </Topbar>

        <div className="content settings-container">
          <SubTopbar>
            <SettingsTabs />
          </SubTopbar>
          <div className="setting-content row">
            <SettingsLeftTabs currentItemId={12} />
            <EmailSubjectMessageForm
              settings={this.props.settings}
              saveSettings={this.saveSettings}
              subjectKey={this.state.subjectKey}
              messageKey={this.state.messageKey}
              email_short_codes={this.state.email_short_codes}
              info_msg={
                strings.get("App.settings.pagesInfo.otpEmailTemplate") || ""
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: settingsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllSettings: () => {
      dispatch(settingsActions.fetchAllItems());
    },
    updateSetting: (data) => {
      return dispatch(settingsActions.updateItem(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OtpEmailPage);
