import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import strings from "../../services/strings";
import Topbar from "../../components/Topbar";
import ArticleForm from "../../components/article/ArticleForm";

import "../Page.scss";

import * as articlesActions from "../../store/articles/actions";
import * as articlesSelectors from "../../store/articles/selectors";
import * as articleCategoriesActions from "../../store/articleCategories/actions";
import * as articleCategoriesSelectors from "../../store/articleCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

class ArticlesEditPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchArticle(this.props.match.params.id);
    this.props.fetchArticleCategories();
    this.props.setCurrentArticleId(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.unsetCurrentArticleId();
    this.props.clearExceptions();
  }

  saveArticle(data) {
    this.props.updateArticle(this.props.match.params.id, data.form);
    if (data.file) {
      this.props.uploadArticleLogo(this.props.match.params.id, data.file);
    }
  }

  render() {
    return (
      <div className="ArticlesEditPage">
        <Topbar>
          <div className="title">
            <Link to="/articles">
              {strings.get("App.articlePages.edit.title")}
            </Link>
            <span className="xs-hidden">
              <span className="divider">/</span>
              <Link to={`/articles/${this.props.match.params.id}`}>
                {strings.get("App.articlePages.edit.edit")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content">
          <ArticleForm
            exceptions={this.props.exceptions}
            articles={this.props.articles}
            currentItem={this.props.currentArticle}
            saveItem={this.saveArticle}
            articleCategories={this.props.articleCategories}
            formHeading={
              strings.get("App.articlePages.edit.edit") +
              " " +
              strings.get("App.articlePages.edit.title")
            }
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentArticle: articlesSelectors.getCurrentItem(state),
    articles: articlesSelectors.getItems(state),
    articleCategories: articleCategoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllArticles: (deleteCache) => {
      dispatch(articlesActions.fetchAllItems(deleteCache));
    },
    fetchArticle: (id) => {
      dispatch(articlesActions.fetchItem(id));
    },
    setCurrentArticleId: (id) => {
      dispatch(articlesActions.setCurrentItemId(id));
    },
    unsetCurrentArticleId: () => {
      dispatch(articlesActions.unsetCurrentItemId());
    },
    updateArticle: (id, data) => {
      dispatch(articlesActions.updateItem(id, data));
    },
    uploadArticleLogo: (id, file) => {
      dispatch(articlesActions.uploadItemLogo(id, file));
    },
    createArticle: (data) => {
      dispatch(articlesActions.createItem(data));
    },
    fetchArticleCategories: () => {
      dispatch(articleCategoriesActions.fetchAllItems());
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesEditPage);
