import React, { Component } from "react";
import autoBind from "react-autobind";
import PropTypes from "prop-types";

import strings from "../../services/strings";

import _ from "lodash";
import "./DocumentList.scss";

import Immutable from "seamless-immutable";

import Switch from "../Switch.js";
import { browserHistory } from "../../helpers";
import CustomDropModal from "../common/Popup";

class DocumentList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isToggle: false };
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      docErrorInfo: "",
      items: [],
    };
  }

  componentDidMount() {
    this.setItems(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.items && this.state.items.length === 0 && nextProps.items) {
      this.setItems(nextProps);
    }
    if (
      this.state.items &&
      this.state.items.length !== 0 &&
      nextProps.items !== this.props.items
    ) {
      this.setItems(nextProps);
    }
  }

  setItems = (props) => {
    if (props.items) {
      this.setState({
        items: props.items,
      });
    }
  };

  handleClick(e) {
    this.setState({ isToggle: !this.state.isToggle });
  }

  toggleDocDetails(itemId) {
    if (this.refs[`doc-details-${itemId}`].style.display === "none")
      this.refs[`doc-details-${itemId}`].style.display = `block`;
    else this.refs[`doc-details-${itemId}`].style.display = `none`;
  }

  getLogo(item) {
    if (item.imageURL) {
      return (
        <div
          className="item-logo"
          style={{ backgroundImage: `url('${item.imageURL}')` }}
        />
      );
    }

    return <div className="item-logo default-logo far fa-file-alt file-icon" />;
  }

  getPrice(item) {
    return parseFloat(item.price) && item.payed ? (
      `${strings.get("App.documents.price.currencySign")}${item.price}`
    ) : (
      <span className="label label-success">
        {strings.get("App.documents.price.free")}
      </span>
    );
  }

  getCategory(item) {
    if (this.props.categories[`_${item.category_id}`]) {
      return this.props.categories[`_${item.category_id}`].name;
    }
  }

  handleChange = (id, column, enabled, index) => {
    let value = +enabled;
    //update current state
    let items = Immutable.asMutable(this.state.items);
    let oldItem = Immutable.asMutable(items[index]);
    oldItem.published = value;
    items[index] = oldItem;
    this.setState({ items });

    //update pops state
    this.props.updateColumn(id, column, value);
  };

  checkValidDocument(item) {
    if (!_.size(item.broken_fields)) {
      // if (item.validDoc === true) {
      return true;
    } else {
      return (
        <i
          className="btn btn-danger ion-alert"
          onClick={() => this.handleDocErrorInfoClick(item)}
        />
      );
    }
  }

  handleDocErrorInfoClick(item) {
    let content = "";
    if (_.size(item.broken_fields) > 0) {
      let errors = JSON.parse(item.broken_fields);
      if (errors.selectors) {
        // let selector = `<h5>Selectors</h5><ul>`;
        let selector = `<h5>${strings.get(
          "App.glossaries.glossaryForm.selectors.title"
        )}</h5><ul>`;
        _.forEach(_.uniq(errors.selectors), function (value, key) {
          selector += `<li>${value}</li>`;
        });
        selector += "</ul>";
        content += selector;
      }
      if (errors.clauses) {
        // let clauses = `<h5>Clauses</h5><ul>`;
        let clauses = `<h5>${strings.get(
          "App.glossaries.glossaryForm.clauses.title"
        )}</h5><ul>`;
        _.forEach(_.uniq(errors.clauses), function (value, key) {
          clauses += `<li>${value}</li>`;
        });
        clauses += "</ul>";
        content += clauses;
      }
      if (errors.fields) {
        // let fields = `<h5>Fields</h5><ul>`;
        let fields = `<h5>${strings.get(
          "App.glossaries.glossaryForm.fields.title"
        )}</h5><ul>`;
        _.forEach(_.uniq(errors.fields), function (value, key) {
          fields += `<li>${value}</li>`;
        });
        fields += "</ul>";
        content += fields;
      }
    }
    this.setState({
      docErrorInfo: content,
    });
    this.showDocErrorInfoModal();
  }

  showDocErrorInfoModal() {
    this.refs.docErrorInfoModal.show();
  }

  hideDocErrorInfoModal() {
    this.refs.docErrorInfoModal.hide();
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  handleDeleteClick(id) {
    this.props.setCurrentItemId(id);
    this.showDeleteModal();
  }

  handleConfirmDeleteClick() {
    this.props.deleteItem(this.props.currentItem.id);
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleCancelDeleteClick() {
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleEditClick(id) {
    browserHistory.push(`/documents/${id}`);
  }

  render() {
    let items = _.map(this.state.items, (item, index) => {
      return (
        <div className="main_doc_rw" key={item.id}>
          <div className="document-box">
            <div
              className="docname"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleEditClick(item.id)}
            >
              <i className="far fa-file-alt file-icon" />
              <div className="details">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handleEditClick(item.id)}
                  className="name"
                >
                  {item.name}
                </div>
              </div>
            </div>
            <div className="description">{this.getCategory(item)}</div>
            <div
              className="btn btn-details-doc"
              onClick={() => this.toggleDocDetails(item.id)}
            >
              <span className="details-text">
                {strings.get("App.documents.documentForm.details")}
              </span>
              <span className="details-icon">
                <i className="fa fa-chevron-down" style={{ color: "white" }} />
              </span>
            </div>
          </div>
          <div
            className="document_detail"
            ref={`doc-details-${item.id}`}
            style={{ display: "none" }}
          >
            {/* <div className="document_detail" style={{display: this.state.isToggle ? 'block': 'none'}}> */}
            <div className="item">
              <span>{strings.get("App.documents.price.title")}</span>
              <span> {this.getPrice(item)}</span>
            </div>
            <div className="item">
              <span>{strings.get("App.documents.downloads")} </span>
              <span>{_.size(item.downloads)}</span>
            </div>
            <div className="item">
              <span>{strings.get("App.documents.version")}</span>
              <span>{item.version} </span>
            </div>
            <div className="item">
              <span>{strings.get("App.documents.publish")} </span>
              <span>
                <Switch
                  enabled={!!item.published}
                  onChange={(enabled) =>
                    this.handleChange(item.id, "published", enabled, index)
                  }
                />
              </span>
            </div>
            <div className="item">
              <span>{strings.get("App.documents.tested")} </span>
              <span>
                <Switch
                  enabled={!!item.tested}
                  onChange={(enabled) =>
                    this.handleChange(item.id, "tested", enabled, index)
                  }
                />
              </span>
            </div>
            <div className="item">
              <span>{strings.get("General.action")} </span>
              <span>
                <i
                  onClick={() => this.handleEditClick(item.id)}
                  className="btn btn-default edit-btn btn-color-change ion-edit"
                />
                <i
                  onClick={() => this.handleDeleteClick(item.id)}
                  className="btn btn-default delete-btn btn-color-change ion-trash-b"
                />
                {this.checkValidDocument(item)}
              </span>
            </div>
          </div>
        </div>
      );
    });

    let deleteModalContent = this.props.currentItem ? (
      <span>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleCancelDeleteClick}
          />
        </div>
        <h2>
          {strings.get("App.deleteModal.message", {
            itemName: this.props.currentItem.name,
          })}
        </h2>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger"
            onClick={this.handleConfirmDeleteClick}
          >
            {strings.get("App.deleteModal.delete")}
          </button>
          <button
            className="btn btn-lg btn-default cancel cancel"
            onClick={this.handleCancelDeleteClick}
          >
            {strings.get("App.deleteModal.cancel")}
          </button>
        </div>
      </span>
    ) : null;

    return (
      <span className="DocumentList">
        <CustomDropModal className="boron-modal" ref="deleteModal">
          {deleteModalContent}
        </CustomDropModal>
        <CustomDropModal
          className="boron-modal error-info-modal"
          ref="docErrorInfoModal"
        >
          <div className="modal-close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.hideDocErrorInfoModal}
            />
          </div>
          <h4>{strings.get("App.documents.documentForm.wrongCodeHeading")}</h4>
          <div dangerouslySetInnerHTML={{ __html: this.state.docErrorInfo }} />
        </CustomDropModal>
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="tablet_mob">{items}</div>
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  }
}

DocumentList.propTypes = {
  items: PropTypes.array.isRequired,
  sorter: PropTypes.object.isRequired,
  fetchItems: PropTypes.func.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  toggleSorter: PropTypes.func.isRequired,
};

export default DocumentList;
