import React, { Component } from 'react';
import PropTypes from "prop-types";
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import strings from '../../../services/strings';
import settings from '../../../services/settings';
import language from '../../../services/language';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import Editor from '../../Editor';

class SmtpSettingForm extends Component {

  state = {
    currentSettingsLoaded: false,
    form: {
      smtp_email_host: { value: '' },
      smtp_email_port: { value: '' },
      smtp_email_username: { value: '' },
      smtp_email_password: { value: '' },
      smtp_email_from_address: { value: '' },
    }
  }
  componentDidMount() {
    this.tryLoadCurrentSettings();
  }

  componentDidUpdate() {
    this.tryLoadCurrentSettings();
  }

  constructor(props) {
    super(props);
    autoBind(this);
  }

  tryLoadCurrentSettings() {
    if (_.size(this.props.settings) && !this.state.currentSettingsLoaded) {
      this.setState({
        currentSettingsLoaded: true,
        form: {
          smtp_email_host: this.props.settings[settings.keys.SMTP_EMAIL_HOST],
          smtp_email_port: this.props.settings[settings.keys.SMTP_EMAIL_PORT],
          smtp_email_username: this.props.settings[settings.keys.SMTP_EMAIL_USERNAME],
          smtp_email_password: this.props.settings[settings.keys.SMTP_EMAIL_PASSWORD],
          smtp_email_from_address: this.props.settings[settings.keys.SMTP_EMAIL_FROM_ADDRESS],
        }
      });
    }
  }

  handleInputChange(e) {
    let form = _.extend(this.state.form, {});
    form[e.target.name] = form[e.target.name].asMutable ? form[e.target.name].asMutable() : form[e.target.name];
    form[e.target.name].value = e.target.value;

    this.setState({ form });
  }

  handleSaveClick(e) {
    e.preventDefault();
    this.props.saveSettings(this.state);
  }

  handleCancelClick(e) {
    e.preventDefault();
    this.setState({
      form: {
        smtp_email_host: this.props.settings[settings.keys.SMTP_EMAIL_HOST],
        smtp_email_port: this.props.settings[settings.keys.SMTP_EMAIL_PORT],
        smtp_email_username: this.props.settings[settings.keys.SMTP_EMAIL_USERNAME],
        smtp_email_password: this.props.settings[settings.keys.SMTP_EMAIL_PASSWORD],
        smtp_email_from_address: this.props.settings[settings.keys.SMTP_EMAIL_FROM_ADDRESS],
      }
    });
  }

  render() {
    return (
      <div className="EmailForm col-xs-12 col-sm-12 col-md-9">
        <form>
          <div className="form-group">
            <label className="control-label">{strings.get('App.settings.smtpSetting.host')}</label>
            <input className="form-control" type="text" name="smtp_email_host" value={ this.state.form.smtp_email_host.value } onChange={ this.handleInputChange } />
          </div>
          <div className="form-group">
            <label className="control-label">{strings.get('App.settings.smtpSetting.port')}</label>
            <input className="form-control" type="text" name="smtp_email_port" value={ this.state.form.smtp_email_port.value } onChange={ this.handleInputChange } />
          </div>
          <div className="form-group">
            <label className="control-label">{strings.get('App.settings.smtpSetting.username')}</label>
            <input className="form-control" type="text" name="smtp_email_username" value={ this.state.form.smtp_email_username.value } onChange={ this.handleInputChange } />
          </div>
          <div className="form-group">
            <label className="control-label">{strings.get('App.settings.smtpSetting.password')}</label>
            <input className="form-control" type="password" name="smtp_email_password" value={ this.state.form.smtp_email_password.value } onChange={ this.handleInputChange } />
          </div>
          <div className="form-group">
            <label className="control-label">{strings.get('App.settings.smtpSetting.fromAddress')}</label>
            <input className="form-control" type="text" name="smtp_email_from_address" value={ this.state.form.smtp_email_from_address.value } onChange={ this.handleInputChange } />
          </div>
          <div className="form-actions">
            <button className="btn btn-primary" onClick={ this.handleSaveClick }>{strings.get('App.email.save')}</button>
            <button className="btn btn-default" onClick={ this.handleCancelClick }>{strings.get('App.email.cancel')}</button>
          </div>
        </form>
      </div>
    );
  }

}

SmtpSettingForm.propTypes = {
  settings: PropTypes.object,
  saveSettings: PropTypes.func.isRequired,
}

export default SmtpSettingForm;
