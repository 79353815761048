import React, { Component } from 'react';
import autoBind from 'react-autobind';
import strings from '../../services/strings';
import language from '../../services/language';
import _ from 'lodash';
import PropTypes from "prop-types";

import './CompanyCategoryPagesize.scss';

class CompanyCategoryPagesize extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    getSorter() {
        if (this.props.sorter) {
            return this.props.sorter.pageLimit;
        }

        return '';
    }

    handleChange(e) {
        let sorter = {
            pageLimit: e.target.value,
        }

        this.props.setSorter(sorter);
        this.props.fetchItems(true);
    }

    render() {
        return (
            <div className="CompanyCategorySize">
                <select className="form-control" name="sorter" value={ this.getSorter() } onChange={ this.handleChange }>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
        );
    }

}

CompanyCategoryPagesize.propTypes = {
    sorter: PropTypes.object,
    setSorter: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
}

export default CompanyCategoryPagesize;
