import React, { Component } from "react";
import PropTypes from "prop-types";

import "./SearchBar.scss";

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
    };
  }

  componentDidMount() {
    this.setState({ searchTerm: this.props.searchTerm });
  }

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleChange = (e) => {
    let searchTerm = e.target.value;
    this.props.setSearchTerm(searchTerm);
    this.setState(
      {
        searchTerm: searchTerm,
      },
      () => {
        this.props.fetchItems(true);
      }
    );
  };

  render() {
    return (
      <div className="col-sm-3 col-md-3 col-xs-6 ">
        <form  onSubmit={this.handleSubmit}>
          <input
            className="form-control cate-select"
            type="text"
            name=""
            placeholder={this.props.placeholder}
            value={this.state.searchTerm}
            onChange={this.handleChange}
          />
        </form>
      </div>
    );
  }
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  searchTerm: PropTypes.string.isRequired,
  fetchItems: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
};

export default SearchBar;
