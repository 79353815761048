import React, { Component } from "react";
import autoBind from "react-autobind";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import { Link } from "react-router-dom";
import _ from "lodash";
import "../settings.scss";
import "../../Page.scss";
import "./LanguageAddPage.scss";
import * as languagesActions from "../../../store/languages/actions";
import * as languagesSelectors from "../../../store/languages/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";

import Modal from "reboron/DropModal";

import Topbar from "../../../components/Topbar";
import SubTopbar from "../../../components/SubTopbar";
import SettingsTabs from "../../../components/settings/SettingsTabs";
import { browserHistory } from "../../../helpers";



class LanguageEditPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      items: {},
      visibleList: [],
      visibleSteps: [],
      visibleStepsLoading: false,
      searchTerm: "",
      flatObjectForSearch: {},
      loading: true,
    };
  }

  componentDidMount() {
    this.props.setCurrentLanguageId(this.props.match.params.id);
    strings.getLatest().then((items) => {
      this.setState({ items, loading: false }, () => {
        this.loadCurrentSteps(
          this.state.items[
            this.props.currentLanguage ? this.props.currentLanguage.iso2 : ""
          ]
        );
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.size(nextProps.currentLanguage) && !this.state.visibleStepsLoading) {
      this.loadCurrentSteps(
        this.state.items[
          nextProps.currentLanguage ? nextProps.currentLanguage.iso2 : ""
        ]
      );
    }
  }

  loadCurrentSteps = (items) => {
    if (typeof items === "object") {
      let visibleSteps = this.state.visibleSteps;
      _.map(items, (value, k) => {
        if (typeof value === "object") {
          visibleSteps.push(k);
          this.loadCurrentSteps(value);
        }
      });
      this.setState(
        {
          visibleSteps,
          visibleStepsLoading: true,
        },
        () => {
          let flatObjectForSearch = this.flattenObject(items);
          this.setState({
            flatObjectForSearch,
          });
        }
      );
    }
  };

  flattenObject(ob) {
    var toReturn = {};
    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if (typeof ob[i] === "object") {
        var flatObject = this.flattenObject(ob[i]);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[i + "." + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  }

  componentWillUnmount() {
    this.props.unsetCurrentLanguageId();
  }

  count = 0;

  setDescendantProp(obj, desc, value) {
    let arr = desc.split(".");
    while (arr.length > 1) {
      obj = obj[arr.shift()];
    }
    obj[arr[0]] = value;
  }

  handleInputChange(e) {
    let items = _.extend({}, this.state.items);
    this.setDescendantProp(
      items[this.props.currentLanguage.iso2],
      e.target.name,
      e.target.value
    );
    this.setState({ items });
  }

  handleSaveClick(e) {
    e.preventDefault();
    this.props.updateCurrentStrings(
      this.state.items[this.props.currentLanguage.iso2],
      this.props.currentLanguage.id
    );
    this.refs.saveModal.show();
    _.delay(() => {
      this.refs.saveModal.hide();
      _.delay(() => {
        browserHistory.push("/settings/languages");
      }, 650);
    }, 1350);
  }

  handleCancelClick(e) {
    e.preventDefault();
    browserHistory.push("/settings/languages");
  }

  handleCollapse(e, k) {
    e.preventDefault();
    let visibleList = this.state.visibleList;
    if (visibleList.includes(k)) {
      visibleList.splice(visibleList.indexOf(k), 1);
    } else {
      visibleList.push(k);
    }
    this.setState({
      visibleList,
    });
    let targetArrow = document.getElementById(`arrow-${k}`);
    if (targetArrow.classList.contains("fa-arrow-up")) {
      targetArrow.classList.remove("fa-arrow-up");
      targetArrow.classList.add("fa-arrow-down");
    } else {
      targetArrow.classList.remove("fa-arrow-down");
      targetArrow.classList.add("fa-arrow-up");
    }
  }

  isObject(value, key, path = key) {
    let iso2 = this.props.currentLanguage
      ? this.props.currentLanguage.iso2
      : "";
    let self = this;
    let name = key + '"]["';
    let propPath = path + ".";
    if (typeof value === "object") {
      return _.map(value, (val, key) => {
        if (typeof val === "object") {
          let tmpName = name + key;
          let path = propPath + key;
          self.count++;
          return (
            <div
              className="col-sm-12 col-md-12 titleDiv"
              key={`item-${self.count}`}
            >
              <div
                className="col-sm-12 col-md-12 col-xs-12 label-arrow-container"
                onClick={(e) => this.handleCollapse(e, key)}
              >
                <div className="panel-body">
                  <div className="text-start">{key}</div>
                </div>
                <i
                  className="fa fa-arrow-up"
                  aria-hidden="true"
                  id={`arrow-${key}`}
                ></i>
              </div>
              <div
                className={
                  this.state.visibleList.includes(key)
                    ? "col-sm-12 col-md-12 col-xs-12 listDiv showList"
                    : "col-sm-12 col-md-12 col-xs-12 listDiv hideList"
                }
                id={key}
              >
                <div style={{ padding: "15px" }}>
                  {this.isObject(val, tmpName, path)}
                </div>
              </div>
            </div>
          );
        } else {
          self.count++;
          let tmpName = '["' + name + key + '"]';
          let a = `self.state.items["${iso2}"]${tmpName}`;
          tmpName = propPath + key;
          return (
            <div
              className="row"
              key={`item-${self.count}`}
              style={{ padding: "5px", display: "flex", alignItems: "center" }}
            >
              <div className="col-md-4 col-xs-12">
                <label htmlFor={`item-${self.count}`}>{key}</label>
              </div>
              <div className="col-md-8 col-xs-12" style={{ flex: "auto" }}>
                <textarea
                  className="form-control"
                  id={`item-${self.count}`}
                  name={tmpName}
                  value={eval(a)}
                  onChange={this.handleInputChange}
                  style={{ width: "100%", borderRadius: "5px", resize: "none" }}
                />
              </div>
            </div>
          );
        }
      });
    }
  }

  handleShowAllLanguage = () => {
    let { visibleList, visibleSteps } = this.state;
    if (visibleSteps.length === visibleList.length) {
      this.setState(
        {
          visibleList: [],
        },
        () => {
          visibleSteps.forEach((element) => {
            let targetArrow = document.getElementById(`arrow-${element}`);
            if (targetArrow.classList.contains("fa-arrow-down")) {
              targetArrow.classList.remove("fa-arrow-down");
              targetArrow.classList.add("fa-arrow-up");
            }
          });
        }
      );
    } else {
      this.setState(
        {
          visibleList: visibleSteps,
        },
        () => {
          visibleSteps.forEach((element) => {
            let targetArrow = document.getElementById(`arrow-${element}`);
            if (targetArrow.classList.contains("fa-arrow-up")) {
              targetArrow.classList.remove("fa-arrow-up");
              targetArrow.classList.add("fa-arrow-down");
            }
          });
        }
      );
    }
  };

  handleCodeSearch = () => {
    let { visibleList, searchTerm, flatObjectForSearch } = this.state;
    visibleList = [];
    if (searchTerm.length) {
      for (const key in flatObjectForSearch) {
        if (flatObjectForSearch.hasOwnProperty(key)) {
          let value = flatObjectForSearch[key];
          if (value !== "" && value.indexOf(searchTerm) > -1) {
            let splittedArray = key.split(".");
            visibleList = _.union(visibleList, splittedArray);
          }
        }
      }
      this.setState({
        visibleList,
      });
    }
  };

  handleSearchTerm = (e) => {
    let value = e.target.value.trim();
    this.setState({
      searchTerm: value,
    });
  };

  render() {
    let count = 0;
    let iso2 = this.props.currentLanguage
      ? this.props.currentLanguage.iso2
      : "";
    let tree = _.map(this.state.items[iso2], (value, k) => {
      count++;
      return (
        <div className="col-sm-12 col-md-12 titleDiv" key={`item-${count}`}>
          <div
            className="col-sm-12 col-md-12 col-xs-12 label-arrow-container"
            onClick={(e) => this.handleCollapse(e, k)}
          >
            <div className="panel-body">
              <div className="text-start"> {k} </div>
            </div>
            <i
              className="fa fa-arrow-up"
              aria-hidden="true"
              id={`arrow-${k}`}
            ></i>
          </div>
          <div
            className={
              this.state.visibleList.includes(k)
                ? "col-sm-12 col-md-12 col-xs-12 listDiv showList"
                : "col-sm-12 col-md-12 col-xs-12 listDiv hideList"
            }
            id={k}
          >
            <div style={{ padding: "15px" }}>{this.isObject(value, k)}</div>
          </div>
        </div>
      );
    });
    this.count = 0;
    return (
      <div className="LanguageAddPage">
        <Modal
          className="boron-modal no-body"
          ref="saveModal"
          onShow={this.handleShowModal}
        >
          <span>
            <h2>{strings.get("App.settings.settingsSaved")}</h2>
          </span>
        </Modal>
        <Topbar>
          <div className="title">
            <Link to="/settings/languages">
              {strings.get("App.settings.title")}
            </Link>
          </div>
        </Topbar>

        <div className="content settings-container">
          <SubTopbar>
            <SettingsTabs />
          </SubTopbar>
          <div className="setting-content">
            <div className="col-md-11">
              <div className="col-md-12 col-sm-12 col-xs-12 search-bar-holder">
                <span
                  className="btn btn-primary"
                  onClick={this.handleShowAllLanguage}
                >
                  {this.state.visibleList.length &&
                  this.state.visibleList.length ===
                    Object.keys(this.state.visibleSteps).length
                    ? strings.get("App.glossaries.glossaryForm.hideAll")
                    : strings.get("App.glossaries.glossaryForm.showAll")}
                </span>
                <span className="search-bar">
                  <input
                    type="text"
                    value={this.state.searchTerm}
                    placeholder={strings.get(
                      "App.glossaries.glossaryForm.codeSearchPlaceholder"
                    )}
                    onChange={this.handleSearchTerm}
                  />
                  <i
                    className="ion-android-search"
                    onClick={this.handleCodeSearch}
                  />
                </span>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                {this.state.loading ? (
                  <ReactLoading
                    className="react-loading"
                    type="spinningBubbles"
                    color="#132d5e"
                  />
                ) : (
                  tree
                )}
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <button
                  style={{ marginRight: ".4rem" }}
                  className="btn btn-primary"
                  onClick={this.handleSaveClick}
                >
                  {strings.get("App.settings.languages.save")}
                </button>
                <button
                  style={{ marginLeft: ".4rem" }}
                  className="btn btn-default"
                  onClick={this.handleCancelClick}
                >
                  {strings.get("App.settings.languages.cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: languagesSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentLanguageId: (id) => {
      dispatch(languagesActions.setCurrentItemId(id));
    },
    unsetCurrentLanguageId: () => {
      dispatch(languagesActions.unsetCurrentItemId());
    },
    updateCurrentStrings: (data, id) => {
      dispatch(languagesActions.updateStrings(data, id));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageEditPage);
