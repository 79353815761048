import React, { Component } from "react";
import autoBind from "react-autobind";
import strings from "../../services/strings";
import PropTypes from "prop-types";

import _ from "lodash";
import "./CategoryList.scss";

import { browserHistory } from "../../helpers";


import CustomDropModal from "../common/Popup";

class CategoryList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  getLogo(item) {
    if (item.imageURL) {
      return (
        <div
          className="item-logo"
          style={{ backgroundImage: `url('${item.imageURL}')` }}
        />
      );
    }

    return <div className="item-logo default-logo ion-android-list" />;
  }

  state = {
    deleteStatus: "",
  };

  getVisibilityIcon(item) {
    if (item.visibility === 0) {
      return <i className="visibility-icon ion-locked" />;
    } else if (item.visibility === 1) {
      return <i className="visibility-icon ion-earth" />;
    } else if (item.visibility === 2) {
      return <i className="visibility-icon ion-android-people" />;
    } else if (item.visibility === 3) {
      return <i className="visibility-icon ion-android-settings" />;
    }
  }

  getVisibilityLabel(item) {
    if (item.visibility === 0) {
      return strings.get("App.categoryForm.visibility.hidden");
    } else if (item.visibility === 1) {
      return strings.get("App.categoryForm.visibility.public");
    } else if (item.visibility === 2) {
      return strings.get("App.categoryForm.visibility.loggedIn");
    } else if (item.visibility === 3) {
      return strings.get("App.categoryForm.visibility.custom");
    }
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  showDeleteMsgModal() {
    this.refs.deleteMsgModal.show();
  }

  hideDeleteMsgModal() {
    this.refs.deleteMsgModal.hide();
    this.setState({ deleteStatus: "" });
  }

  handleCatDeleteResponse = (deleteStatus) => {
    this.setState({ deleteStatus }, () => {
      this.showDeleteMsgModal();
    });
  };

  handleCancelDeleteMsgClick() {
    this.hideDeleteMsgModal();
  }

  handleDeleteClick(id) {
    this.props.setCurrentItemId(id);
    this.showDeleteModal();
  }

  handleConfirmDeleteClick() {
    this.props.deleteItem(
      this.props.currentItem.id,
      this.handleCatDeleteResponse
    );
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleCancelDeleteClick() {
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleEditClick(id) {
    browserHistory.push(`/documents/categories/${id}`);
  }

  getCategoryTree(items, item, arr, depth = 0) {
    if (_.size(items) && _.find(items, item)) {
      if (item && item.parent_id && depth === 0) return;
      arr[0].push(item);
      arr[1].push(depth);
      _.map(items, (it) => {
        if (it && item.id === it.parent_id) {
          this.getCategoryTree(items, it, arr, depth + 1);
        }
      });
    }
  }

  getChildDivider(item) {
    if (item) {
      return <span className="child-divider" />;
    }
    return null;
  }

  render() {
    let arr = [[], []];
    _.map(this.props.items.concat(this.props.subCategories), (item) => {
      this.getCategoryTree(
        this.props.items.concat(this.props.subCategories),
        item,
        arr
      );
    });

    let items = _.map(arr[0], (item, i) => {
      let style = {
        marginLeft: `${arr[1][i] * 2}rem`,
      };
      return (
        <tr key={item.id}>
          <td>
            <div
              className="curs-pointer"
              onClick={() => this.handleEditClick(item.id)}
            >
              {this.getLogo(item)}
            </div>
          </td>
          <td>
            <div
              style={style}
              onClick={() => this.handleEditClick(item.id)}
              className="details curs-pointer"
            >
              <div className="name">
                {/*<span className="child-divider">{arr[1][i] ? "|"+"_ ": null}</span>*/}
                {this.getChildDivider(arr[1][i])}
                {item.name}
              </div>
              <div className="description">
                {this.getVisibilityIcon(item)}
                {this.getVisibilityLabel(item)}
              </div>
            </div>
          </td>
          <td>
            <i
              onClick={() => this.handleDeleteClick(item.id)}
              className="btn btn-default delete-btn ion-trash-b"
            />
          </td>
          <td>
            <i
              onClick={() => this.handleEditClick(item.id)}
              className="btn btn-default edit-btn ion-edit"
            />
          </td>
        </tr>
      );
    });

    let deleteModalContent = this.props.currentItem ? (
      <span>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleCancelDeleteClick}
          />
        </div>
        <h2>
          {strings.get("App.deleteModal.message", {
            itemName: this.props.currentItem.name,
          })}
        </h2>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger"
            onClick={this.handleConfirmDeleteClick}
          >
            {strings.get("App.deleteModal.delete")}
          </button>
          <button
            className="btn btn-lg btn-default cancel cancel"
            onClick={this.handleCancelDeleteClick}
          >
            {strings.get("App.deleteModal.cancel")}
          </button>
        </div>
      </span>
    ) : null;

    let deleteMsgModalContent =
      this.state.deleteStatus !== "" ? (
        <span>
          <div className="modal-close-btn">
            <i
              className="ion-android-cancel clickable"
              onClick={this.handleCancelDeleteMsgClick}
            />
          </div>
          {/* <h2>
          {strings.get("App.deleteModal.message")}
        </h2> */}
          <div className="form-actions">
            <h2>
              {this.state.deleteStatus === "success"
                ? strings.get("App.categoryList.deleteSuccessMsg")
                : strings.get("App.categoryList.deleteErrorMsg")}
            </h2>
          </div>
        </span>
      ) : null;

    return (
      <span className="CategoryList">

        <CustomDropModal className="boron-modal" ref="deleteModal">
          {deleteModalContent}
        </CustomDropModal>
        <CustomDropModal className="boron-modal" ref="deleteMsgModal">
          {deleteMsgModalContent}
        </CustomDropModal>
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-body">
                <table className="table table-striped" >
                  <tbody>{items}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  }
}

CategoryList.propTypes = {
  items: PropTypes.array.isRequired,
  fetchItems: PropTypes.func.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
};

export default CategoryList;
