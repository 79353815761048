import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import { Link } from "react-router-dom";
import _ from "lodash";
import "../settings.scss";
import "../../Page.scss";

import * as ipAddressesActions from "../../../store/ipAddresses/actions";
import * as ipAddressesSelectors from "../../../store/ipAddresses/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";

import Topbar from "../../../components/Topbar";
import SubTopbar from "../../../components/SubTopbar";
import SearchBar from "../../../components/SearchBar";
import SettingsTabs from "../../../components/settings/SettingsTabs";
import SmtpSettingsLeftTabs from "../../../components/settings/SmtpSettingsLeftTabs";
import IpAddressesForm from "../../../components/settings/ipAddresses/IpAddressesForm";

class IpAddressesEditPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchIpAddress(this.props.match.params.id);
    this.props.setCurrentIpAddressId(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.unsetCurrentIpAddressId();
    this.props.clearExceptions();
  }

  saveIpAddress(data) {
    this.props.udpateIpAddress(this.props.match.params.id, data.form);
  }

  render() {
    return (
      <div className="IpAddressesEditPage">
        <Topbar>
          <div className="title">
            <Link to="/settings/ipAddresses">
              {strings.get("App.settings.title")}
            </Link>
          </div>
        </Topbar>

        <div className="content settings-container">
          <SubTopbar>
            <SettingsTabs />
          </SubTopbar>
          <div className="setting-content row">
            <SmtpSettingsLeftTabs currentItemId={1} />
            <IpAddressesForm
              exceptions={this.props.exceptions}
              currentItem={this.props.currentIpAddress}
              saveItem={this.saveIpAddress}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentIpAddress: ipAddressesSelectors.getCurrentItem(state),exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchIpAddress: (id) => {
      dispatch(ipAddressesActions.fetchItem(id));
    },
    setCurrentIpAddressId: (id) => {
      dispatch(ipAddressesActions.setCurrentItemId(id));
    },
    unsetCurrentIpAddressId: () => {
      dispatch(ipAddressesActions.unsetCurrentItemId());
    },
    udpateIpAddress: (id, data) => {
      dispatch(ipAddressesActions.updateItem(id, data));
    },clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IpAddressesEditPage);
