import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import strings from "../../services/strings";

import _ from "lodash";
import "./GroupList.scss";

import Modal from "reboron/DropModal";
import { browserHistory } from "../../helpers";

class GroupList extends Component {
  state = {
    reason: "",
    deleteStatus : ""
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  getLogo(item) {
    return <div className="item-logo default-logo ion-android-people" />;
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  handleDeleteClick(id) {
    this.props.setCurrentItemId(id);
    this.showDeleteModal();
  }

  handleConfirmDeleteClick() {
    this.props.deleteItem(this.props.currentItem.id, this.handleGroupDeleteResponse);
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleCancelDeleteClick() {
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  showBlockModal() {
    this.setState({ reason: "" });
    this.refs.blockModal.show();
  }

  hideBlockModal() {
    this.refs.blockModal.hide();
  }

  handleBlockClick(id) {
    this.props.setCurrentItemId(id);
    this.showBlockModal();
  }

  handleConfirmBlockClick() {
    this.props.blockItem(this.props.currentItem.id, this.state);
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideBlockModal();
  }

  handleCancelBlockClick() {
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideBlockModal();
  }

  handleInputChange(e) {
    this.setState({ reason: e.target.value });
  }

  handleEditClick(id) {
    browserHistory.push(`/users/groups/${id}`);
    
  }

  showDeleteMsgModal() {
    this.refs.deleteMsgModal.show();
  }

  hideDeleteMsgModal() {
    this.refs.deleteMsgModal.hide();
    this.setState({deleteStatus : ''});
  }

  handleGroupDeleteResponse (deleteStatus){
    this.setState({deleteStatus}, ()=>{this.showDeleteMsgModal();})
  }

  handleCancelDeleteMsgClick() {
    this.hideDeleteMsgModal();
  }

  render() {
    let items = _.map(this.props.items, item => {
      return (
        <tr key={item.id}>
          <td>{this.getLogo(item)}</td>
          <td>
            <div className="details">
              <div className="name">{item.name}</div>
              <div className="description">
                {`${_.size(item.users)} ${strings.get(
                  "App.blockedGroups.listPage.users"
                )}`}
              </div>
            </div>
          </td>
          <td>
            <i
              onClick={() => this.handleEditClick(item.id)}
              className="btn btn-default edit-btn ion-edit"
            />
          </td>
          <td>
            <i
              onClick={() => this.handleDeleteClick(item.id)}
              className="btn btn-default delete-btn ion-trash-b"
            />
          </td>
          <td>
            <i
              onClick={() => this.handleBlockClick(item.id)}
              className="btn btn-default block-btn ion-locked"
            />
          </td>
        </tr>
      );
    });

    let deleteModalContent = this.props.currentItem ? (
      <span>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleCancelDeleteClick}
          />
        </div>
        <h2>
          {strings.get("App.deleteModal.message", {
            itemName: this.props.currentItem.name
          })}
        </h2>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger"
            onClick={this.handleConfirmDeleteClick}
          >
            {strings.get("App.deleteModal.delete")}
          </button>
          <button
            className="btn btn-lg btn-default cancel"
            onClick={this.handleCancelDeleteClick}
          >
            {strings.get("App.deleteModal.cancel")}
          </button>
        </div>
      </span>
    ) : null;

    let blockModalContent = this.props.currentItem ? (
      <span>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleCancelBlockClick}
          />
        </div>
        <h2>
          {strings.get("App.blockModal.message", {
            itemName: this.props.currentItem.name
          })}
        </h2>
        <div className="form-actions">
          <label htmlFor="reason">{strings.get("App.blockModal.reason")}</label>
          <textarea
            id="reason"
            className="form-control"
            type="text"
            name="reason"
            onChange={this.handleInputChange}
          />
          <br />
          <button
            className="btn btn-lg btn-danger blockmodalBtn"
            onClick={this.handleConfirmBlockClick}
          >
            {strings.get("App.blockModal.block")}
          </button>
          <button
            className="btn btn-lg btn-default cancel"
            onClick={this.handleCancelBlockClick}
          >
            {strings.get("App.blockModal.cancel")}
          </button>
        </div>
      </span>
    ) : null;

    let deleteMsgModalContent = this.state.deleteStatus !== '' ? (
      <span>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleCancelDeleteMsgClick}
          />
        </div>
        <div className="form-actions">
          <h2>
          { this.state.deleteStatus === 'success'
              ? strings.get('App.groupPages.deleteSuccessMsg')
              : strings.get('App.groupPages.deleteErrorMsg') }
          </h2>
        </div>
      </span>
    ) : null;

    return (
      <span className="GroupList">
        <Modal className="boron-modal" ref="deleteModal">
          {deleteModalContent}
        </Modal>
        <Modal className="boron-modal" ref="deleteMsgModal">
          {deleteMsgModalContent}
        </Modal>
        <Modal className="boron-modal" ref="blockModal">
          {blockModalContent}
        </Modal>
        <table className="table">
          <tbody>{items}</tbody>
        </table>
      </span>
    );
  }
}

GroupList.propTypes = {
  items: PropTypes.array.isRequired,
  sorter: PropTypes.object.isRequired,
  fetchItems: PropTypes.func.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  blockItem: PropTypes.func.isRequired,
  toggleSorter: PropTypes.func.isRequired
};

export default GroupList;
