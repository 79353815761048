import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import strings from "../../../services/strings";

import Editor from "../../Editor";

import "./EmailSubjectMessageForm.scss";

class EmailSubjectMessageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSettingsLoaded: false,
      form: {
        subject: { value: "" },
        message: { value: "" },
      },
    };
  }

  componentDidMount() {
    this.tryLoadCurrentSettings();
  }

  componentDidUpdate() {
    this.tryLoadCurrentSettings();
  }

  tryLoadCurrentSettings = () => {
    if (_.size(this.props.settings) && !this.state.currentSettingsLoaded) {
      this.setState({
        currentSettingsLoaded: true,
        form: {
          subject: this.props.settings[this.props.subjectKey],
          message: this.props.settings[this.props.messageKey],
        },
      });
    }
  };

  handleInputChange = (e) => {
    let form = _.extend(this.state.form, {});
    form[e.target.name] = form[e.target.name].asMutable
      ? form[e.target.name].asMutable()
      : form[e.target.name];
    form[e.target.name].value = e.target.value;

    this.setState({ form });
  };

  // handleMessageChange = (e) => {
  //   e.target.value = e.target.getContent();
  //   e.target.name = "message";
  //   this.handleInputChange(e);
  // };

  handleMessageChange = (e) => {
    let form = _.extend(this.state.form, {});
    form["message"] = form["message"].asMutable
      ? form["message"].asMutable()
      : form["message"];
    form["message"].value = e;

    this.setState({ form });
  };

  handleSaveClick = (e) => {
    e.preventDefault();
    let data = {};
    data[this.props.subjectKey] = this.state.form.subject;
    data[this.props.messageKey] = this.state.form.message;
    this.props.saveSettings(data);
  };

  handleCancelClick = (e) => {
    e.preventDefault();
    this.setState({
      form: {
        subject: this.props.settings[this.props.subjectKey],
        message: this.props.settings[this.props.messageKey],
      },
    });
  };

  getLinks = () => {
    let links = [];
    this.props.email_short_codes.forEach((link) => {
      links.push(
        <li
          style={{ marginRight: "10px", backgroundColor: "#F0F0F0" }}
          key={this.props.email_short_codes.indexOf(link)}
        >
          {link}
        </li>
      );
    });
    return links;
  };

  render() {
    let tooltip = (
      <Tooltip id="block-content-info">{this.props.info_msg}</Tooltip>
    );
    return (
      <div className="EmailForm EmailSubjectMessageForm col-xs-12 col-sm-9 col-md-9">
        <form>
          <div className="form-group">
            <div className="page-info-container">
              <label className="control-label">
                {strings.get("App.settings.reminderEmail.subject")}
              </label>
              <OverlayTrigger
                placement="left"
                overlay={tooltip}
                trigger="click"
                rootClose
              >
                <i className="ion-help-circled" />
              </OverlayTrigger>
            </div>
            <input
              className="form-control"
              type="text"
              name="subject"
              value={this.state.form.subject.value}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.reminderEmail.message")}
            </label>
            <Editor
              content={this.state.form.message.value}
              handleChange={this.handleMessageChange}
              height={250}
            />
            <div className="row short-codes-container">
              <div className="col-xs-12 col-sm-2 col-md-3">
                <label className="control-label">Short Codes</label>
              </div>
              <div className="col-xs-12 col-sm-10 col-md-9">
                <ul className="short-codes-listing">{this.getLinks()}</ul>
              </div>
            </div>
          </div>
          <div className="form-actions">
            <button className="btn btn-primary" onClick={this.handleSaveClick}>
              {strings.get("App.settings.reminderEmail.save")}
            </button>
            <button
              className="btn btn-default"
              onClick={this.handleCancelClick}
            >
              {strings.get("App.settings.reminderEmail.cancel")}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

EmailSubjectMessageForm.propTypes = {
  settings: PropTypes.object,
  saveSettings: PropTypes.func.isRequired,
  subjectKey: PropTypes.string.isRequired,
  messageKey: PropTypes.string.isRequired,
  email_short_codes: PropTypes.array.isRequired,
  info_msg: PropTypes.string.isRequired,
};

export default EmailSubjectMessageForm;
