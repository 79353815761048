import React, { Component } from "react";
import autoBind from "react-autobind";
import strings from "../../services/strings";
import language from "../../services/language";
import _ from "lodash";
import PropTypes from "prop-types";

import "./CompanyCategoryFilter.scss";

class CompanyCategoryFilter extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  getCompanyCategories() {
    return _.map(this.props.companyCategories, (item) => {
      return (
        <option key={`item-${item.id}`} value={item.id}>
          {item.tree_name}
        </option>
      );
    });
  }

  getSelectedCategory() {
    if (this.props.filters) {
      return this.props.filters.companyCategoryId;
    }

    return "";
  }

  handleChange(e) {
    this.props.setCompanyCategoryId(e.target.value);
    this.props.fetchItems(true);
  }

  render() {
    return (
      <div className="CompanyCategoryFilter">
        <label>{strings.get("App.documents.categoryFilter.from")}</label>
        <select
          className="form-control"
          name="groups"
          value={this.getSelectedCategory()}
          onChange={this.handleChange}
        >
          <option value="">
            {strings.get("App.documents.categoryFilter.allCategories")}
          </option>
          {this.getCompanyCategories()}
        </select>
      </div>
    );
  }
}

CompanyCategoryFilter.propTypes = {
  filters: PropTypes.object,
  companyCategories: PropTypes.object,
  setCompanyCategoryId: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
};

export default CompanyCategoryFilter;
