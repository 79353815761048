import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import strings from "../../services/strings";
import Topbar from "../../components/Topbar";
import PartnerList from "../../components/partner/PartnerList";
import PartnerPagesize from "../../components/partner/PartnerPagesize";
import Pagination from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";

import "../Page.scss";

import * as partnersActions from "../../store/partners/actions";
import * as partnersSelectors from "../../store/partners/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

class PartnersListPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchPartners();
  }

  componentWillUnmount() {
    this.props.unsetCurrentPartnerId();
    this.props.clearExceptions();
  }

  render() {
    return (
      <div className="PartnerListPage">
        <Topbar
          
        >
          <div className="title">
            <Link to="/modules/partners">
              {strings.get("Sidebar.partners")}
            </Link>
          </div>
          <SearchBar
              placeholder={strings.get("App.searchPlaceholders.partners")}
              searchTerm={this.props.filters.searchTerm}
              fetchItems={this.props.fetchPartners}
              setSearchTerm={this.props.setSearchTerm}
            />
          <div className="main-btns">
            <Link to="/modules/partners/add" className="btn btn-primary">
              <span>{strings.get("App.partnerPages.listPage.addPartner")}</span>{" "}
              <i className="ion-plus"></i>
            </Link>
          </div>
        </Topbar>

        <div className="content">
          <div className="row">
            <div className="col-xs-12">
              <PartnerPagesize
                sorter={this.props.sorter}
                fetchItems={this.props.fetchPartners}
                setSorter={this.props.setSorter}
              />
            </div>
          </div>
          <PartnerList
            items={this.props.partners}
            currentItem={this.props.currentPartner}
            fetchItems={this.props.fetchPartners}
            setCurrentItemId={this.props.setCurrentPartnerId}
            unsetCurrentItemId={this.props.unsetCurrentPartnerId}
            deleteItem={this.props.deletePartner}
            updateItem={this.props.updatePartner}
          />

          <Pagination
            pagination={this.props.pagination}
            setCurrentPage={this.props.setCurrentPage}
            fetchItems={this.props.fetchPartners}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sorter: partnersSelectors.getSorter(state),
    filters: partnersSelectors.getFilters(state),
    partners: partnersSelectors.getItemsByPage(
      state,
      partnersSelectors.getPagination(state).currentPage
    ),
    pagination: partnersSelectors.getPagination(state),
    currentPartner: partnersSelectors.getCurrentItem(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPartners: (deleteCache) => {
      dispatch(partnersActions.fetchItems(deleteCache));
    },
    setCurrentPage: (page) => {
      dispatch(partnersActions.setCurrentPage(page));
    },
    setCurrentPartnerId: (id) => {
      dispatch(partnersActions.setCurrentItemId(id));
    },
    unsetCurrentPartnerId: () => {
      dispatch(partnersActions.unsetCurrentItemId());
    },
    deletePartner: (id) => {
      dispatch(partnersActions.deleteItem(id));
    },
    updatePartner: (id, data) => {
      dispatch(partnersActions.updateItem(id, data));
    },
    setSorter: (sorter) => {
      dispatch(partnersActions.setSorter(sorter));
    },
    setSearchTerm: (searchTerm) =>{
      dispatch(partnersActions.setSearchTerm(searchTerm));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnersListPage);
