import React, { Component } from 'react';
import PropTypes from "prop-types";
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import strings from '../../services/strings';
import language from '../../services/language';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import './GlossaryImport.scss';

import CSVReader from 'react-csv-reader'
import { browserHistory } from '../../helpers';

class GlossaryImport extends Component {

    state = {
        currentItemLoaded: false,
        file: null,
        csvdata: [],
        form: {
            language_id: language.get(),
        }
    }

    constructor(props) {
        super(props);
        autoBind(this);
    }

    hasError(inputName) {
        return !!this.props.exceptions[inputName];
    }

    getErrorClass(inputName, defaultClasses = '') {
        return this.hasError(inputName) ? defaultClasses + ' has-error' : defaultClasses;
    }

    getErrorMessage(inputName) {
        return this.props.exceptions[inputName];
    }

    handleFileUpload(data) {
        this.setState({
            csvdata: data,
        });
    }

    handleSaveClick(e) {
        e.preventDefault();
        this.props.importItem(_.extend(this.state, {
            form: _.extend(this.state.form, {
                language_id: language.get(),
                csvdata: this.state.csvdata,
            })
        }));
    }

    handleCancelClick(e) {
        e.preventDefault();
        if( !this.props.currentRule )
            browserHistory.push('/glossaries');
        else
            this.props.hideAddRuleModal();
    }

    render() {
        return (
            <div className="GlossaryImport row">
                <form>
                    <div className="col-sm-12">
                        <div className={ this.getErrorClass('image', 'form-group clearfix') }>
                            <label className="col-sm-3 control-label">{ strings.get('App.glossaries.glossaryImport.file') }</label>
                            <div className="col-sm-9">
                                <CSVReader
                                    cssClass="react-csv-input"
                                    onFileLoaded={ this.handleFileUpload }
                                />
                            </div>
                        </div>
                        <div className="form-actions">
                            <button className="btn btn-primary" onClick={ this.handleSaveClick }>{strings.get('App.glossaries.glossaryImport.upload')}</button>
                            <button className="btn btn-default" onClick={ this.handleCancelClick }>{strings.get('App.glossaries.glossaryForm.cancel')}</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

}

GlossaryImport.propTypes = {
    exceptions: PropTypes.object,
    importItem: PropTypes.func,
}

export default GlossaryImport;
