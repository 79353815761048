import React, {Component} from 'react';
import * as _ from 'lodash';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import settings from "../../../services/settings";
import strings from '../../../services/strings';

import * as settingsActions from '../../../store/settings/actions';
import * as settingsSelectors from '../../../store/settings/selectors';
let componentInstance = null

class ApiSettingsForm extends Component {
    state= {
        form:{
            facebook_key: {value: ""},
            linkedin_key: {value: ""},
            linkedin_secret: {value: ""},
        }
    }

    componentDidMount() {
        this.tryLoadCurrentSettings()

    }

    componentDidUpdate() {
      this.tryLoadCurrentSettings();
      componentInstance= this;
    }

    decodeBase64 = (data) => {
      let values = {...data}
      values.value = window.atob(data.value)
      return values
    }

    tryLoadCurrentSettings() {
        if (_.size(this.props.settings) && !this.state.currentSettingsLoaded) {
          this.setState({
            currentSettingsLoaded: true,
            form: {
                facebook_key: this.decodeBase64(this.props.settings[settings.keys.FACEBOOK_KEY]),
                linkedin_key: this.decodeBase64(this.props.settings[settings.keys.LINKEDIN_KEY]),
                linkedin_secret: this.decodeBase64(this.props.settings[settings.keys.LINKEDIN_SECRET])
            },
          });
        }
      }

    handleInputChange = (e) => {
        let form = _.extend(this.state.form, {});
        form[e.target.name] = form[e.target.name].asMutable
          ? form[e.target.name].asMutable()
          : form[e.target.name];
        form[e.target.name].value = e.target.value;
        this.setState({ form });
    }

    handleSaveClick(e) {
        e.preventDefault();
        componentInstance.props.saveSettings(componentInstance.state);
      }


  handleCancelClick(e) {
    e.preventDefault();
    this.setState({
      form: {
        facebook_key: this.decodeBase64(this.props.settings[settings.keys.FACEBOOK_KEY]),
        linkedin_key: this.decodeBase64(this.props.settings[settings.keys.LINKEDIN_KEY]),
        linkedin_secret: this.decodeBase64(this.props.settings[settings.keys.LINKEDIN_SECRET])
      },
    });
  }

    render() {
        return (
            <div className="EmailForm row">
                <form className="col-sm-12 col-md-8">
                    <div className="form-group">
                        <label className="control-label">
                            {strings.get("App.settings.apiSettings.facebookKey")}
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            name="facebook_key"
                            value={this.state.form.facebook_key.value}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label className="control-label">
                            {strings.get("App.settings.apiSettings.linkedinKey")}
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            name="linkedin_key"
                            value={this.state.form.linkedin_key.value}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label className="control-label">
                            {strings.get("App.settings.apiSettings.linkedinSecret")}
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            name="linkedin_secret"
                            value={this.state.form.linkedin_secret.value}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className="form-actions">
                        <button className="btn btn-primary" onClick={this.handleSaveClick}>
                        {strings.get("App.email.save")}
                        </button>
                        <button className="btn btn-default" onClick={this.handleCancelClick}>
                        {strings.get("App.email.cancel")}
                        </button>
                    </div>
                </form>
                
            </div>
        )
    }
}


ApiSettingsForm.propTypes = {
    settings: PropTypes.object,
    saveSettings: PropTypes.func.isRequired,
  };
  function mapStateToProps(state) {
    return {
      settings: settingsSelectors.getItems(state),
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      fetchAllSettings: () => {
        dispatch(settingsActions.fetchAllItems());
      },
      updateSetting: (data) => {
        return dispatch(settingsActions.updateItem(data));
      },
      updateImageSetting: (data) => {
        return dispatch(settingsActions.updateImageItem(data));
      },
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(ApiSettingsForm)