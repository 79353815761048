import React, { Component } from "react";
import _ from "lodash";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "../Page.scss";

import * as stepsActions from "../../store/steps/actions";

import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import Topbar from "../../components/Topbar";
import StepForm from "../../components/step/StepForm";

class StepAddPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  saveStep(data) {
    this.props.createStep(data.form);
  }

  componentDidMount() {
    this.props.clearExceptions();
    if (!_.size(this.props.categories)) {
      this.props.fetchAllCategories();
    }
  }

  render() {
    return (
      <div className="StepAddPage">
        <Topbar>
          <div className="title">
            <Link to="/glossaries">
              {strings.get("App.stepPages.glossaries")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/glossaries/steps">
                {strings.get("App.stepPages.title")}
              </Link>
              <span className="divider">/</span>
              <Link to="/glossaries/steps/add">
                {strings.get("App.stepPages.add")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content">
          <StepForm
            exceptions={this.props.exceptions}
            saveItem={this.saveStep}
            categories={this.props.categories}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: categoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createStep: (data) => {
      dispatch(stepsActions.createItem(data));
    },
    fetchAllCategories: (deleteCache) => {
      dispatch(categoriesActions.fetchAllItems(deleteCache));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepAddPage);
