import React, { Component } from "react";

import { connect } from "react-redux";
import strings from "../../services/strings";
import { Link } from "react-router-dom";
import "./UserSettings.scss";
import "../Page.scss";

import Topbar from "../../components/Topbar";
import SubTopbar from "../../components/SubTopbar";
import UserSettingsTabs from "../../components/user/UserSettingsTabs";
import UserSharedDraftList from "../../components/user/UserSharedDraftList";
import SearchBar from "../../components/user/draft/SearchBar";
import DraftCategoryFilter from "../../components/user/draft/DraftCategoryFilter";
import Pagination from "../../components/Pagination";

import * as sharedDraftsActions from "../../store/sharedDrafts/actions";
import * as categoriesActions from '../../store/categories/actions';
import * as sharedDraftsSelectors from "../../store/sharedDrafts/selectors";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as sidebarSelectors from "../../store/sidebar/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

class UserSharedDraftPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.setCurrentUserId(this.props.match.params.id);
    this.props.fetchAllCategories();
    this.props.fetchAllSharedDrafts();
  }

  componentWillUnmount() {
    this.props.unsetCurrentUserId();
  }

  render() {
    return (
      <div className="UserSharedDraftPage">
        <Topbar>
          <div className="title">
            <Link to="/users">{strings.get("App.userPages.title")}</Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to={`/users/${this.props.match.params.id}/profile`}>
                {strings.get("App.userPages.edit")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content user-settings-container">
          <SubTopbar>
            <UserSettingsTabs userId={this.props.match.params.id} />
          </SubTopbar>
          <div className="col-sm-12 col-md-12 col-xs-12 draft-filters">
                <DraftCategoryFilter
                  filters={this.props.filters}
                  categories={this.props.categories}
                  fetchItems={this.props.fetchAllSharedDrafts}
                  setCategoryId={this.props.setCategoryId}
                />
                <SearchBar
                  placeholder={strings.get("Client.draftsPage.draftName")}
                  searchTerm={this.props.filters.searchTerm}
                  fetchItems={this.props.fetchAllSharedDrafts}
                  setSearchTerm={this.props.setSearchTerm}
                />
              </div>
          <div className="user-setting-content">
            <UserSharedDraftList
              items={this.props.drafts}
              siteURL={this.props.siteURL}
            />
            <Pagination
              pagination={this.props.pagination}
              setCurrentPage={this.props.setCurrentPage}
              fetchItems={this.props.fetchAllSharedDrafts}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    drafts: sharedDraftsSelectors.getItemsByPage(
      state,
      sharedDraftsSelectors.getPagination(state).currentPage
    ),
    pagination: sharedDraftsSelectors.getPagination(state),
    siteURL: sidebarSelectors.getSiteURL(state),
    exceptions: exceptionsSelectors.getItems(state),
    filters: sharedDraftsSelectors.getFilters(state),
    categories: categoriesSelectors.getItems(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
    setCurrentUserId: (id) => {
      dispatch(sharedDraftsActions.setCurrentUserId(id));
    },
    setCategoryId: (id) =>{
      dispatch(sharedDraftsActions.setCategoryId(id))
    },
    setSearchTerm: (searchTerm) => {
      dispatch(sharedDraftsActions.setSearchTerm(searchTerm));
    },
    unsetCurrentUserId: (id) => {
      dispatch(sharedDraftsActions.unsetCurrentUserId());
    },
    fetchAllSharedDrafts: (deleteCache) => {
      dispatch(sharedDraftsActions.fetchAllSharedDrafts(deleteCache));
    },
    fetchAllCategories: () => {
      dispatch(categoriesActions.fetchAllItems());
    },
    setCurrentPage: (page) => {
      dispatch(sharedDraftsActions.setCurrentPage(page));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSharedDraftPage);
