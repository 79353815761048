import React, { Component } from "react";
import autoBind from "react-autobind";
import strings from "../../services/strings";
import PropTypes from "prop-types";
import _ from "lodash";
import './ArticleList.scss'
import Immutable from 'seamless-immutable';

import Switch from "../Switch.js";

import { browserHistory } from "../../helpers";
import CustomDropModal from "../common/Popup";

let componentInstance = null;

class ArticleList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    componentInstance = this;
    this.state = {
      items : []
    }
  }

  componentDidMount(){
    this.setItems(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.state.items.length === 0 && nextProps.items){
      this.setItems(nextProps);
    }
    if(this.state.items.length !== 0 && nextProps.items !== this.props.items){
      this.setItems(nextProps);
    }
  }

  setItems = (props) => {
    if(props.items){
      this.setState({
        items : props.items
      })
    }
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  handleDeleteClick(id) {
    this.props.setCurrentItemId(id);
    this.showDeleteModal();
  }

  handleConfirmDeleteClick() {
    this.props.deleteItem(this.props.currentItem.id);
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleCancelDeleteClick() {
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleChange(id, item, enabled, index) {
    //update current state
    let items = Immutable.asMutable(this.state.items);
    let oldItem = Immutable.asMutable(items[index]);
    oldItem.visible = enabled ? 1 : 0;
    items[index] = oldItem;
    this.setState( { items } );

    //update props
    this.props.updateItem(id, {
      article_category_id: item.article_category_id,
      content: item.content,
      name: item.name,
      preview_content: item.preview_content,
      url: item.url,
      visible: enabled ? 1 : 0
    });
  }

  handleEditClick(id) {
    browserHistory.push(`/articles/${id}`);

    // window.location = '/articles/'+id;
  }

  render() {
    let items = _.map(this.state.items, (item, i) => {
      return (
        <tr key={item.id}>
          <td>
            <div className="details">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => this.handleEditClick(item.id)}
                className="name"
              >
                {item.name}
              </div>
            </div>
          </td>
          <td>
            <Switch
              enabled={!!item.visible}
              onChange={enabled =>
                componentInstance.handleChange(item.id, item, enabled, i)
              }
            />
          </td>
          <td>{item.article_category ? item.article_category.name : "-"}</td>
          <td>
            <i
              onClick={() => this.handleEditClick(item.id)}
              className="btn btn-default edit-btn ion-edit"
            />
            <i
              onClick={() => this.handleDeleteClick(item.id)}
              className="btn btn-default delete-btn ion-trash-b"
            />
          </td>
        </tr>
      );
    });

    let deleteModalContent = this.props.currentItem ? (
      <span>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleCancelDeleteClick}
          />
        </div>
        <h2>
          {strings.get("App.deleteModal.message", {
            itemName: this.props.currentItem.name
          })}
        </h2>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger confirm"
            onClick={this.handleConfirmDeleteClick}
          >
            {strings.get("App.deleteModal.delete")}
          </button>
          <button
            className="btn btn-lg btn-default cancel cancel"
            onClick={this.handleCancelDeleteClick}
          >
            {strings.get("App.deleteModal.cancel")}
          </button>
        </div>
      </span>
    ) : null;

    return (
      <span className="ArticleList">

        <CustomDropModal className="boron-CustomDropModal" ref="deleteModal">
          {deleteModalContent}
        </CustomDropModal >
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>{strings.get("App.articleForm.name")}</th>
                      <th>{strings.get("General.visibility")}</th>
                      <th>{strings.get("App.articleForm.articleCategory")}</th>
                      <th>{strings.get("General.action")}</th>
                    </tr>
                  </thead>
                  <tbody>{items}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  }
}

ArticleList.propTypes = {
  items: PropTypes.array.isRequired,
  fetchItems: PropTypes.func.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired
};

export default ArticleList;
