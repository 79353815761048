import React from "react";
import strings from "../services/strings";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./Sidebar.scss";

export default function Sidebar(props) {
  const getLinkClassName = (linkName) => {
    let path = window.location.pathname.split("/");
    return path[1] === linkName ? "active" : "";
  };

  const handleItemClick = () => {
    props.toggleSidebar();
  };

  const handleLogoutClick = () => {
    props.logout();
  };

  return (
    <span>
      <div className="title">
        <h1>{strings.get("Sidebar.title")}</h1>
      </div>
      <ul className="nav nav-sidebar">
        <li className={getLinkClassName("documents")} onClick={handleItemClick}>
          <Link to="/documents" href="#">
            {strings.get("Sidebar.documents")}
          </Link>
          <ul className="subnav">
            <li
              className={getLinkClassName("documents")}
              onClick={handleItemClick}
            >
              <Link to="/documents/categories">
                {strings.get("Sidebar.categories")}
              </Link>
            </li>
          </ul>
        </li>

        <li
          className={getLinkClassName("glossaries")}
          onClick={handleItemClick}
        >
          <Link to="/glossaries" href="#">
            {strings.get("Sidebar.glossaries")}
          </Link>
          <ul className="subnav">
            <li
              className={getLinkClassName("glossaries")}
              onClick={handleItemClick}
            >
              <Link to="/glossaries/companies" href="#">
                {strings.get("Sidebar.companies")}
              </Link>
              <ul className="subnav">
                <li
                  className={getLinkClassName("glossaries")}
                  onClick={handleItemClick}
                >
                  <Link to="/glossaries/companies/categories">
                    {strings.get("Sidebar.categories")}
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={getLinkClassName("glossaries")}
              onClick={handleItemClick}
            >
              <Link to="/glossaries/steps">{strings.get("Sidebar.steps")}</Link>
            </li>
          </ul>
        </li>

        <li className={getLinkClassName("workflows")} onClick={handleItemClick}>
          <Link to="/workflows">{strings.get("Sidebar.workflows")}</Link>
          <ul className="subnav">
            <li
              className={getLinkClassName("workflows")}
              onClick={handleItemClick}
            >
              <Link to="/workflows/categories" href="#">
                {strings.get("Sidebar.categories")}
              </Link>
            </li>
          </ul>
        </li>

        <li className={getLinkClassName("articles")} onClick={handleItemClick}>
          <Link to="/articles">{strings.get("Sidebar.articles")}</Link>
          <ul className="subnav">
            <li
              className={getLinkClassName("articles")}
              onClick={handleItemClick}
            >
              <Link to="/articles/categories" href="#">
                {strings.get("Sidebar.categories")}
              </Link>
            </li>
          </ul>
        </li>

        <li className={getLinkClassName("modules")} onClick={handleItemClick}>
          <Link to="/modules/partners"> {strings.get("Sidebar.modules")} </Link>
          <ul className="subnav">
            <li
              className={getLinkClassName("modules")}
              onClick={handleItemClick}
            >
              <Link to="/modules/partners" href="#">
                {" "}
                {strings.get("Sidebar.partners")}{" "}
              </Link>
            </li>
          </ul>
        </li>

        <li className={getLinkClassName("users")} onClick={handleItemClick}>
          <Link to="/users" href="#">
            {strings.get("Sidebar.users")}
          </Link>
          <ul className="subnav">
            <li className={getLinkClassName("users")} onClick={handleItemClick}>
              <Link to="/users/groups">
                {strings.get("Sidebar.manageGroups")}
              </Link>
            </li>
            <li className={getLinkClassName("users")} onClick={handleItemClick}>
              <Link to="/users/blockedUsers/">
                {strings.get("Sidebar.blockedUsers")}
              </Link>
            </li>
          </ul>
        </li>

        <li className={getLinkClassName("settings")} onClick={handleItemClick}>
          <Link to="/settings/translations" href="#">
            {strings.get("Sidebar.settings")}
          </Link>
          <ul className="subnav">
            <li
              className={getLinkClassName("translations")}
              onClick={handleItemClick}
            >
              <Link to="/settings/translations">
                {strings.get("App.settings.multiLanguage")}
              </Link>
            </li>
            <li
              className={getLinkClassName("languages")}
              onClick={handleItemClick}
            >
              <Link to="/settings/languages">
                {strings.get("App.settings.languages.title")}
              </Link>
            </li>
            <li className={getLinkClassName("email")} onClick={handleItemClick}>
              <Link to="/settings/email">
                {strings.get("App.settings.downloadEmail.title")}
              </Link>
            </li>
            <li
              className={getLinkClassName("shareEmail")}
              onClick={handleItemClick}
            >
              <Link to="/settings/shareEmail">
                {strings.get("App.settings.shareEmail.title")}
              </Link>
            </li>
            <li
              className={getLinkClassName("smtpSetting")}
              onClick={handleItemClick}
            >
              <Link to="/settings/smtpSetting">
                {strings.get("App.settings.smtpSetting.title")}
              </Link>
            </li>
            <li
              className={getLinkClassName("searchPlaceholder")}
              onClick={handleItemClick}
            >
              <Link to="/settings/searchPlaceholder">
                {strings.get("App.settings.documents.title")}
              </Link>
            </li>
            <li
              className={getLinkClassName("contact")}
              onClick={handleItemClick}
            >
              <Link to="/settings/contact">
                {strings.get("App.settings.contactSetting.title")}
              </Link>
            </li>
          </ul>
        </li>

        <li className="logout" onClick={handleItemClick}>
          <a href="#" onClick={handleLogoutClick}>
            {strings.get("Sidebar.logout")}
          </a>
        </li>
      </ul>
    </span>
  );
}

Sidebar.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  title: PropTypes.string,
};
