import React, { Component } from "react";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import strings from "../../services/strings";

import "../Page.scss";
import Topbar from "../../components/Topbar";
import WorkflowCategoryForm from "../../components/workflowCategory/WorkflowCategoryForm";

import * as workflowCategoriesActions from "../../store/workflowCategories/actions";
import * as workflowCategoriesSelectors from "../../store/workflowCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

class WorkflowCategoriesAddPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchWorkflowCategories();
  }

  componentWillUnmount() {
    this.props.clearExceptions();
  }

  saveWorkflowCategory(data) {
    if (data.bgFile) {
      this.props.createWorkflowCategoryWithLogo(data.form, data.bgFile);
    } else {
      this.props.createWorkflowCategory(data.form);
    }
  }

  render() {
    return (
      <div className="WorkflowCategoriesAddPage">
        <Topbar>
          <div className="title">
            <Link to="/workflows/categories">
              {strings.get("App.workflowCategoryPages.add.title")}
            </Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to="/workflows/categories/add">
                <span>{strings.get("App.workflowCategoryPages.add.add")}</span>{" "}
                <i className="ion-plus"></i>
              </Link>
            </span>
          </div>
          {/*<div className="main-btns">
                        <Link className="btn btn-primary" to="/workflows/add"><span>{strings.get('App.workflowCategoryPages.add.addWorkflow')}</span> <i className="ion-plus"></i></Link>
                    </div>  */}
        </Topbar>

        <div className="content">
          <WorkflowCategoryForm
            exceptions={this.props.exceptions}
            saveItem={this.saveWorkflowCategory}
            formHeading={strings.get("App.workflowCategoryForm.addFormHeading")}
            workflowCategories={this.props.workflowCategories}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    workflowCategories: workflowCategoriesSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createWorkflowCategory: (data) => {
      dispatch(workflowCategoriesActions.createItem(data));
    },
    fetchWorkflowCategories: () => {
      dispatch(workflowCategoriesActions.fetchAllItems());
    },
    createWorkflowCategoryWithLogo: (data, bgFile) => {
      dispatch(workflowCategoriesActions.createItemWithLogo(data, bgFile));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowCategoriesAddPage);
