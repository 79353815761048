import React, { Component } from "react";

import PropTypes from "prop-types";
import strings from "../../../services/strings";
import _ from "lodash";
import Select from "react-select";

import "./WorkflowFilter.scss";

class WorkflowFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflowType: "non-workflow",
      userWorkflowId: "",
      fetchOnlyWorkflowDraft: false,
      defaultUserWorkflowIndex: 0,
    };
  }

  componentWillUnmount() {
    this.props.setSavedWorkflowId("");
  }

  getfetchOnlyWorkflowDraft = () => {
    if (this.props.filters) {
      return this.props.filters.fetchOnlyWorkflowDraft;
    }
    return false;
  };

  handleWorkflowTypeChange = (ev) => {
    this.setState({
      fetchOnlyWorkflowDraft:
         ev.target.value === "workflow" ? true : false,
    });
    if (ev.target.value === "workflow") {
      this.props.setFetchOnlyWorkflowDraftStatus(true);
    } else {
      this.props.setFetchOnlyWorkflowDraftStatus(false);
      this.props.setSavedWorkflowId("");
    }
    this.props.fetchItems(true);
  };

  handleUserWorkflowSelector = (ev) => {
    let value = ev.target.value ? ev.target.value : "";
    this.setState({
      userWorkflowId: value,
    });
    this.props.setSavedWorkflowId(value);
    this.props.fetchItems(true);
  };

  render() {
    let userWorkflowOptions = [
      {
        value: "No Data",
        label: strings.get(
          "Client.documentsList.dataReuseModal.selectWorkflow"
        ),
        isDisabled: true,
      },
    ];
    if (_.size(this.props.currentUserWorkflows)) {
      userWorkflowOptions = _.map(
        this.props.currentUserWorkflows,
        (workflow) => {
          return {
            value: `${workflow.id}`,
            label: workflow.name,
          };
        }
      );
      userWorkflowOptions = [
        {
          value: "No Data",
          label: strings.get(
            "Client.documentsList.dataReuseModal.selectWorkflow"
          ),
          isDisabled: true,
        },
        ...userWorkflowOptions,
      ];
    }

    const workflowOptions = [
      {
        value: "non-workflow",
        label: strings.get("Client.documentsList.dataReuseModal.nonWorkflows"),
      },
      {
        value: "workflow",
        label: strings.get("Client.documentsList.dataReuseModal.workflow"),
      },
    ];

    let workflows = _.map(workflowOptions, (workflow, i) =>  (
        <option
          className={`depth-${i}`}
          value={workflow.value}
          key={workflow.i}
        >
          {`${workflow.label}`}
        </option>
      ));

    let userWorkflows = _.map(userWorkflowOptions, (userWorkflow, i) =>  (
        <option
          className={`depth-${i}`}
          value={userWorkflow.value}
          key={userWorkflow.i}
        >
          {`${userWorkflow.label}`}
        </option>
      ));

    return (
      <div
        className={
          this.state.fetchOnlyWorkflowDraft
            ? "WorkflowFilter col-sm-12 col-md-12 col-xs-12 top-margin-10"
            : "WorkflowFilter col-sm-3 col-md-3 col-xs-6 top-margin-10"
        }
      >
        <div
          className={
            this.state.fetchOnlyWorkflowDraft ? "col-sm-6 col-md-6 col-xs-6" : null
          }
          style={this.state.fetchOnlyWorkflowDraft ? {} : { width: "100%" }}
        >
          <select
            className="form-control cate-select"
            name="groups"
            value={this.getfetchOnlyWorkflowDraft()
              ? workflowOptions[1].value
              : workflowOptions[0].value}
            onChange={this.handleWorkflowTypeChange}
          >
            {workflows}
          </select>
        </div>

        {this.state.fetchOnlyWorkflowDraft ||
        this.getfetchOnlyWorkflowDraft() ? (
          <div className="col-sm-6 col-md-6 col-xs-6">
            <select
              className="form-control cate-select"
              name="groups"
              value={this.state.userWorkflowId || userWorkflowOptions[this.state.defaultUserWorkflowIndex].value}
              onChange={this.handleUserWorkflowSelector}
              placeholder={strings.get(
                "Client.documentsList.dataReuseModal.selectWorkflow"
              )}
            >
              {userWorkflows}
            </select>
          </div>
          ) : null}
      </div>
    );
  }
}

WorkflowFilter.propTypes = {
  filters: PropTypes.object,
  categories: PropTypes.object,
  setSavedWorkflowId: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
  currentUserWorkflows: PropTypes.any.isRequired,
  setFetchOnlyWorkflowDraftStatus: PropTypes.func.isRequired,
};

export default WorkflowFilter;
