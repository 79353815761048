import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import * as workflowActions from "../../store/workflows/actions";
import * as workflowSelectors from "../../store/workflows/selectors";
import * as workflowCategoriesActions from "../../store/workflowCategories/actions";
import * as workflowCategoriesSelectors from "../../store/workflowCategories/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";
import * as draftsActions from '../../store/drafts/actions';

import Topbar from "../../components/Topbar";
import SubTopbar from "../../components/SubTopbar";
import UserSettingsTabs from "../../components/user/UserSettingsTabs";
import DraftCategoryFilter from '../../components/user/draft/DraftCategoryFilter';
import SearchBar from '../../components/user/draft/SearchBar';
import UserWorkflowsList from '../../components/user/workflow/UserWorkflowList';
import Pagination from "../../components/Pagination";

import strings from "../../services/strings";
import auth from "../../services/auth";
import { browserHistory } from "../../helpers";

class UserWorkFlowPage extends Component {
  componentDidMount() {
    if (!auth.isAuthenticated()) {
      browserHistory.push("/");
      window.location.reload();
    } else {
      this.props.setCurrentUserId(this.props.match.params.id);
      this.props.fetchUserWorkflows();
      this.props.fetchAllCategories();
    }
  }

  componentWillUnmount() {
    this.props.unsetCurrentUserId();
  }

  render() {
    return (
        <div className="UserWorkflowPage">
        <Topbar>
          <div className="title">
            <Link to="/users">{strings.get("App.userPages.title")}</Link>
            <span className="hidden-xs">
              <span className="divider">/</span>
              <Link to={`/users/${this.props.match.params.id}/profile`}>
                {strings.get("App.userPages.edit")}
              </Link>
            </span>
          </div>
        </Topbar>

        <div className="content user-settings-container">
          <SubTopbar>
            <UserSettingsTabs userId={this.props.match.params.id} />
          </SubTopbar>
          <div className="row">
            <div className="ml-40">
                <DraftCategoryFilter
                  filters={this.props.filters}
                  categories={this.props.allCategories}
                  fetchItems={this.props.fetchUserWorkflows}
                  setCategoryId={this.props.setCategoryId}
                />
                <SearchBar
                  placeholder={strings.get("Client.workflows.nameSearch")}
                  searchTerm={this.props.filters.searchTerm}
                  fetchItems={this.props.fetchUserWorkflows}
                  setSearchTerm={this.props.setSearchTerm}
                />
            </div>
        </div>
          <div className="user-setting-content">
            <UserWorkflowsList
              {...this.props}
              deleteUserWorkflow={this.props.deleteUserWorkflow}
              updateUserWorkflowName={this.props.updateUserWorkflowName}
              downloadUserWorkflow={this.props.downloadUserWorkflow}
              shareUserWorkflow={this.props.shareUserWorkflow}
            />
            <Pagination
              pagination={this.props.pagination}
              setCurrentPage={this.props.setCurrentPage}
              fetchItems={this.props.fetchAllDrafts}
            />
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
    return {
      filters: workflowSelectors.getFilters(state),
      pagination: workflowSelectors.getPagination(state),
      currentUserWorkflows: workflowSelectors.getUserWorkflows(state),
      allCategories: workflowCategoriesSelectors.getItems(state),
      exceptions: exceptionsSelectors.getItems(state),
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      fetchUserWorkflows: (deleteCache) => {
        return dispatch(workflowActions.getUserWorkflows(deleteCache));
      },
      deleteUserWorkflow: (id) => {
        return dispatch(workflowActions.deleteUserWorkflow(id));
      },
      downloadUserWorkflow: (id, getZipURL) => {
        return dispatch(workflowActions.downloadUserWorkflow(id, getZipURL));
      },
      shareUserWorkflow: (data) => {
        return dispatch(workflowActions.shareUserWorkflowByEmail(data));
      },
      updateUserWorkflowName: (id, data) => {
        return dispatch(workflowActions.updateUserWorkflowName(id, data));
      },
      fetchAllCategories: (filtered = false) => {
        dispatch(workflowCategoriesActions.fetchAllItems(filtered));
      },
      setCategoryId: (id) => {
        dispatch(workflowActions.setCategoryId(id));
      },
      setSearchTerm: (searchTerm) => {
        dispatch(workflowActions.setSearchTerm(searchTerm));
      },
      setCurrentPage: (page) => {
        dispatch(workflowActions.setCurrentPage(page));
      },
      clearExceptions: () => {
        dispatch(exceptionsActions.clear());
      },
      setCurrentUserId: (id) => {
        dispatch(draftsActions.setCurrentUserId(id));
      },
      unsetCurrentUserId: (id) => {
        dispatch(draftsActions.unsetCurrentUserId());
      }
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(UserWorkFlowPage);