import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import strings from "../../../services/strings";

import _ from "lodash";
import "./TranslationList.scss";
import img from "../../../static/Flag.png";


import Modal from "reboron/DropModal";
import { browserHistory } from "../../../helpers";

class TranslationList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleChange(id, enabled) {
    this.props.updateItem(id, {
      status: enabled ? 1 : 0
    });
  }

  handleTranslationClick(id) {
    browserHistory.push(`/settings/translations/${id}`);

  }

  handleViewClick(id) {
    browserHistory.push(`/settings/translations/view/${id}`);
  }

  handleDeleteClick(id) {
    this.props.setCurrentItemId(id);
    this.showDeleteModal();
  }

  showDeleteModal() {
    this.refs.deleteModal.show();
  }

  hideDeleteModal() {
    this.refs.deleteModal.hide();
  }

  handleConfirmDeleteClick() {
    this.props.deleteItem(this.props.currentItem.id);
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  handleCancelDeleteClick() {
    _.delay(() => this.props.unsetCurrentItemId(), 250);
    this.hideDeleteModal();
  }

  render() {
    let deleteModalContent = this.props.currentItem ? (
      <span>
        <div className="modal-close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleCancelDeleteClick}
          />
        </div>
        <h2>
          {strings.get("App.deleteModal.message", {
            itemName: this.props.currentItem.name
          })}
        </h2>
        <div className="form-actions">
          <button
            className="btn btn-lg btn-danger"
            onClick={this.handleConfirmDeleteClick}
          >
            {strings.get("App.deleteModal.delete")}
          </button>
          <button
            className="btn btn-lg btn-default cancel"
            onClick={this.handleCancelDeleteClick}
          >
            {strings.get("App.deleteModal.cancel")}
          </button>
        </div>
      </span>
    ) : null;

    let items = _.map(this.props.items, value => {
      return (
        <div className="col-sm-4" key={value.id}>
          <div className="panel panel-default">
            <div className="panel-body">
              <div className="text-center">
                <figure onClick={() => this.handleViewClick(value.id)}>
                  <img
                    className="flag_img"
                    src={value.imageURL ? value.imageURL : img}
                    alt="flags"
                  />
                </figure>
                {value.name}
              </div>
              <div className="clearfix text-center">
                <i
                  onClick={() => this.handleViewClick(value.id)}
                  className="btn btn-default btn-color-change ion-eye"
                />
                &nbsp;
                <i
                  onClick={() => this.handleTranslationClick(value.id)}
                  className="btn btn-default btn-color-change ion-edit"
                />
                &nbsp;
                <i
                  onClick={() => this.handleDeleteClick(value.id)}
                  className="btn btn-default btn-color-change ion-trash-b"
                />
              </div>
              {/*<Switch
                        enabled={ !!value.status }
                        onChange={ (enabled) => this.handleChange(value.id, enabled) }
                    />*/}
            </div>
          </div>
        </div>
      );
    });

    return (
      <span className="TranslationList">
        <Modal className="boron-modal" ref="deleteModal">
          {deleteModalContent}
        </Modal>
        <div className="row">
          <div className="col-sm-12">{items}</div>
        </div>
      </span>
    );
  }
}

TranslationList.propTypes = {
  items: PropTypes.object.isRequired,
  updateItem: PropTypes.func.isRequired,
  setCurrentItemId: PropTypes.func.isRequired,
  unsetCurrentItemId: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired
};

export default TranslationList;
