import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import settings from "../../../services/settings";
import language from "../../../services/language";
import { Link } from "react-router-dom";
import _ from "lodash";

import Editor from "../../Editor";

const download_reminder_short_codes = [
  "[[FIRSTNAME]]",
  "[[DATE]]",
  "[[DOCNAME]]",
  "[[TEMPLATENAME]]",
  "[[RELATEDLINK]]",
];

class DownloadReminderEmailForm extends Component {
  state = {
    currentSettingsLoaded: false,
    form: {
      download_reminder_email_subject: { value: "" },
      download_reminder_email_message: { value: "" },
    },
  };

  componentDidMount() {
    this.tryLoadCurrentSettings();
  }

  componentDidUpdate() {
    this.tryLoadCurrentSettings();
  }

  constructor(props) {
    super(props);
    autoBind(this);
  }

  tryLoadCurrentSettings() {
    if (_.size(this.props.settings) && !this.state.currentSettingsLoaded) {
      this.setState({
        currentSettingsLoaded: true,
        form: {
          download_reminder_email_subject: this.props.settings[
            settings.keys.DOWNLOAD_REMINDER_EMAIL_SUBJECT
          ],
          download_reminder_email_message: this.props.settings[
            settings.keys.DOWNLOAD_REMINDER_EMAIL_MESSAGE
          ],
        },
      });
    }
  }

  handleInputChange(e) {
    let form = _.extend(this.state.form, {});
    form[e.target.name] = form[e.target.name].asMutable
      ? form[e.target.name].asMutable()
      : form[e.target.name];
    form[e.target.name].value = e.target.value;

    this.setState({ form });
  }

  // handleMessageChange(e) {
  //   e.target.value =  e.target.getContent();
  //   e.target.name = settings.keys.DOWNLOAD_REMINDER_EMAIL_MESSAGE;
  //   this.handleInputChange(e);
  // }

  handleMessageChange(e) {
    let form = _.extend(this.state.form, {});
    form[settings.keys.DOWNLOAD_REMINDER_EMAIL_MESSAGE] = form[
      settings.keys.DOWNLOAD_REMINDER_EMAIL_MESSAGE
    ].asMutable
      ? form[settings.keys.DOWNLOAD_REMINDER_EMAIL_MESSAGE].asMutable()
      : form[settings.keys.DOWNLOAD_REMINDER_EMAIL_MESSAGE];
    form[settings.keys.DOWNLOAD_REMINDER_EMAIL_MESSAGE].value = e;

    this.setState({ form });
  }

  handleSaveClick(e) {
    e.preventDefault();
    this.props.saveSettings(this.state);
  }

  handleCancelClick(e) {
    e.preventDefault();
    this.setState({
      form: {
        download_reminder_email_subject: this.props.settings[
          settings.keys.DOWNLOAD_REMINDER_EMAIL_SUBJECT
        ],
        download_reminder_email_message: this.props.settings[
          settings.keys.DOWNLOAD_REMINDER_EMAIL_MESSAGE
        ],
      },
    });
  }

  getLinks() {
    let links = [];
    download_reminder_short_codes.forEach((link) => {
      links.push(
        <li
          style={{ marginRight: "10px", backgroundColor: "#F0F0F0" }}
          key={download_reminder_short_codes.indexOf(link)}
        >
          {" "}
          {link}{" "}
        </li>
      );
    });
    return links;
  }

  render() {
    return (
      <div className="EmailForm col-xs-12 col-sm-12 col-md-9">
        <form>
          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.reminderEmail.subject")}
            </label>
            <input
              className="form-control"
              type="text"
              name="download_reminder_email_subject"
              value={this.state.form.download_reminder_email_subject.value}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="control-label">
              {strings.get("App.settings.reminderEmail.message")}
            </label>
            <Editor
              content={this.state.form.download_reminder_email_message.value}
              handleChange={this.handleMessageChange}
              height={250}
            />
            <div className="row" style={{ display: "flex", marginTop: "10px" }}>
              <div className="col-xs-12 col-sm-2 col-md-3">
                <label className="control-label">Short Codes</label>
              </div>
              <div className="col-xs-12 col-sm-10 col-md-9">
                <ul
                  style={{
                    listStyle: "none",
                    display: "flex",
                    fontWeight: "400",
                    padding: "0px 0px 0px 20px",
                    flexFlow: "wrap",
                  }}
                >
                  {this.getLinks()}
                </ul>
              </div>
            </div>
          </div>
          <div className="form-actions">
            <button className="btn btn-primary" onClick={this.handleSaveClick}>
              {strings.get("App.settings.reminderEmail.save")}
            </button>
            <button
              className="btn btn-default"
              onClick={this.handleCancelClick}
            >
              {strings.get("App.settings.reminderEmail.cancel")}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

DownloadReminderEmailForm.propTypes = {
  settings: PropTypes.object,
  saveSettings: PropTypes.func.isRequired,
};

export default DownloadReminderEmailForm;
