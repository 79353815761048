import React, { Component } from "react";
import _ from "lodash";
import autoBind from "react-autobind";
import { connect } from "react-redux";
import strings from "../../../services/strings";
import settings from "../../../services/settings";
import { Link } from "react-router-dom";
import "../settings.scss";
import "../../Page.scss";

import * as settingsActions from "../../../store/settings/actions";
import * as settingsSelectors from "../../../store/settings/selectors";
import * as exceptionsSelectors from "../../../store/exceptions/selectors";
import * as exceptionsActions from "../../../store/exceptions/actions";

import Modal from "reboron/DropModal";
import Topbar from "../../../components/Topbar";
import SubTopbar from "../../../components/SubTopbar";
import SearchBar from "../../../components/SearchBar";
import SettingsTabs from "../../../components/settings/SettingsTabs";
import MaintenanceSettingForm from "../../../components/settings/maintenance/MaintenanceSettingForm";

class MaintenanceSettingPage extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.props.fetchAllSettings();
  }

  showSaveModal() {
    this.refs.saveModal.show();
  }

  hideSaveModal() {
    this.refs.saveModal.hide();
  }

  saveSettings(data) {
    let maintenance_mode = this.props.updateSetting(
      data.form[settings.keys.MAINTENANCE_MODE]
    );
    let maintenance_heading = this.props.updateSetting(
      data.form[settings.keys.MAINTENANCE_HEADING]
    );
    let maintenance_desc = this.props.updateSetting(
      data.form[settings.keys.MAINTENANCE_DESC]
    );
    let glossary_time_out = this.props.updateSetting(
      data.form[settings.keys.GLOSSARY_TIME_OUT]
    );
    let maintenance_img = this.props.updateImageSetting(
      data.form[settings.keys.MAINTENANCE_IMG]
    );

    Promise.all([
      maintenance_mode,
      maintenance_heading,
      maintenance_desc,
      maintenance_img,
      glossary_time_out,
    ]).then(() => {
      this.showSaveModal();
    });
  }

  handleShowModal() {
    _.delay(() => {
      this.hideSaveModal();
    }, 500);
  }

  render() {
    return (
      <div className="MaintenanceSettingPage">
        <Modal
          className="boron-modal no-body"
          ref="saveModal"
          onShow={this.handleShowModal}
        >
          <span>
            <h2>{strings.get("App.settings.settingsSaved")}</h2>
          </span>
        </Modal>
        <Topbar>
          <div className="title">
            <Link to="/settings/maintenance">
              {strings.get("App.settings.title")}
            </Link>
          </div>
        </Topbar>

        <div className="content settings-container">
          <SubTopbar>
            <SettingsTabs />
          </SubTopbar>
          <div className="setting-content">
            <MaintenanceSettingForm
              settings={this.props.settings}
              saveSettings={this.saveSettings}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: settingsSelectors.getItems(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllSettings: () => {
      dispatch(settingsActions.fetchAllItems());
    },
    updateSetting: (data) => {
      return dispatch(settingsActions.updateItem(data));
    },
    updateImageSetting: (data) => {
      return dispatch(settingsActions.updateImageItem(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceSettingPage);
